import React, { useState } from "react";
import {
  Button,
  makeStyles,
  ClickAwayListener,
  Popper,
  Paper,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import StemLibraryList from "./StemLibraryList";
import { getStemLibraryFile } from "../../../../helpers/ApiCalls";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: "auto",
    maxHeight: "inherit",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  buttonContainer: {
    position: "relative",
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  popper: {
    zIndex: 1400,
    height: "50vh",
    width: "50vw",
    padding: "10px",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
  },
}));

const StemLibraryMenu = (props) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    anchorEl,
    handleMenuClose,
    stemLibrary,
    insertLibraryStems,
    index,
    selectedGroup,
  } = props;
  const classes = useStyles();

  const generateStemLibraryArray = async () => {
    setLoading(true);
    const stemLibraryArray = [];
    for (let index = 0; index < selected.length; index++) {
      const stemLibraryId = selected[index];

      const stemLibraryFile = await getStemLibraryFile(
        stemLibraryId,
        selectedGroup.group_id
      );

      stemLibraryArray.push(stemLibraryFile);
    }
    insertLibraryStems(stemLibraryArray, index);
    setLoading(false);
    setSelected([]);
    handleMenuClose();
  };

  return (
    <div>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleMenuClose}
      >
        <Popper
          placement={"right-end"}
          id="stemLibraryPopper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          className={classes.popper}
        >
          <Paper className={classes.paper} elevation={8}>
            <StemLibraryList
              insertLibraryStems={insertLibraryStems}
              stemLibrary={stemLibrary}
              index={index}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            <div className={classes.buttonContainer}>
              <Button
                onClick={handleMenuClose}
                className={classes.button}
                variant="outlined"
                disabled={loading}
              >
                Cancel
              </Button>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color={"secondary"}
                  onClick={generateStemLibraryArray}
                  disabled={loading}
                >
                  Insert
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

StemLibraryMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func.isRequired,
  stemLibrary: PropTypes.array.isRequired,
  insertLibraryStems: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selectedGroup: PropTypes.object.isRequired,
};

export default StemLibraryMenu;
