import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { titleCase } from "../../../helpers/SharedFunctions";
import CustomizedTextField from "../../../library/CustomizedTextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.action.active} !important`,
    },
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  formLabelRoot: {},
  pointer: {
    cursor: "pointer",
  },
}));
const Metadata = (props) => {
  const {
    data,
    categories,
    catalogs,
    keys,
    handleChange,
    handleDateChange,
    handleBlur,
    disabled,
    access,
  } = props;

  const {
    master_title,
    master_subcategory,
    category_id,
    catalog_id,
    master_key,
    master_bpm,
    master_display_date,
    master_description,
  } = data;

  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomizedTextField
        required
        fullWidth
        id="masterTitle"
        label="Title"
        value={master_title}
        handleOnChange={handleChange("master_title")}
        handleOnBlur={handleBlur("master_title")}
        disabled={disabled || !access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id="master_subcategory"
        label="Sub-Title"
        value={master_subcategory}
        handleOnChange={handleChange("master_subcategory")}
        handleOnBlur={handleBlur("master_subcategory")}
        disabled={disabled || !access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id="masterCategory"
        select
        label="Category"
        value={category_id}
        handleOnChange={handleChange("category_id")}
        handleOnBlur={handleBlur("category_id")}
        disabled={disabled || !access.update}
      >
        {categories.map((option) => (
          <MenuItem key={option.category_id} value={option.category_id}>
            {titleCase(option.category_name)}
          </MenuItem>
        ))}
      </CustomizedTextField>
      <CustomizedTextField
        required
        fullWidth
        id="masterCatalog"
        select
        label="Catalog"
        value={catalog_id}
        handleOnChange={handleChange("catalog_id")}
        handleOnBlur={handleBlur("catalog_id")}
        disabled={disabled || !access.update}
      >
        {catalogs.map((option) => (
          <MenuItem key={option.catalog_id} value={option.catalog_id}>
            {titleCase(option.catalog_name)}
          </MenuItem>
        ))}
      </CustomizedTextField>
      <CustomizedTextField
        fullWidth
        required
        id="masterKey"
        select
        label="Key"
        value={master_key}
        handleOnChange={handleChange("master_key")}
        handleOnBlur={handleBlur("master_key")}
        disabled={disabled || !access.update}
      >
        {keys.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.display}
          </MenuItem>
        ))}
      </CustomizedTextField>
      <CustomizedTextField
        fullWidth
        required
        id="masterTempo"
        label="Tempo"
        value={master_bpm}
        handleOnChange={handleChange("master_bpm")}
        handleOnBlur={handleBlur("master_bpm")}
        disabled={disabled || !access.update}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          required
          disableToolbar
          // disablePast
          autoOk={true}
          error={false}
          helperText={null}
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Display Date"
          value={new Date(master_display_date)}
          onChange={(date) => handleDateChange(`master_display_date`, date)}
          disabled={disabled || !access.update}
          InputLabelProps={{
            classes: {
              root: classes.formLabelRoot,
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
              input: classes.pointer,
            },
          }}
        />
      </MuiPickersUtilsProvider>
      <CustomizedTextField
        fullWidth
        id="masterDescription"
        label="Description"
        multiline
        maxRows={4}
        value={master_description}
        handleOnChange={handleChange("master_description")}
        handleOnBlur={handleBlur("master_description")}
        disabled={disabled || !access.update}
      />
    </React.Fragment>
  );
};

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  catalogs: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default Metadata;
