import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  DialogContentText,
  Tooltip,
  Button,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  removeFromCart,
  setDialogVisibility,
  clearCart,
} from "../actions/index";
import { sanitizeTitle } from "../helpers/SharedFunctions";
import CustomDialogContent from "../library/dialog/CustomDialogContent";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import { directDownload } from "../helpers/DownloadFunctions";

const styles = (theme) => ({
  cartText: {
    minWidth: 300,
  },
  lightFont: {
    color: theme.palette.text.disabled,
  },
  clearButton: {
    float: "right",
  },
});

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    skinConfig: state.skinConfig,
    userData: state.userData,
    activeGroup: state.activeGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    removeFromCart: (userId, groupId, cartItem) =>
      dispatch(removeFromCart(userId, groupId, cartItem)),
    clearCart: (userId, groupId, cartItem) =>
      dispatch(clearCart(userId, groupId)),
  };
};

const connectedCart = (props) => {
  const {
    cart,
    classes,
    skinConfig,
    userData,
    activeGroup,
    clearCart,
    removeFromCart,
    setDialogVisibility,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      title: null,
      body: null,
      fullWidth: false,
      fullScreen: false,
      cancel: { label: "Cancel", function: null },
      confirm: { label: "Confirm", function: null },
    };
    setDialogVisibility(dialogContent);
  };

  const handleCartDownload = () => {
    setLoading(true);
    directDownload(userData.user_id, activeGroup, cart).then((res) => {
      handleDialogClose();
      clearCart(userData.user_id, activeGroup);
      setLoading(false);
    });
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    ...(cart.length
      ? [
          {
            label: "Download",
            color: "secondary",
            hasLoadingState: true,
            hasDisabledState: true,
            fn: handleCartDownload,
          },
        ]
      : []),
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle title={"Download Cart"} />
      <CustomDialogContent>
        {cart.length ? (
          <React.Fragment>
            <List dense>
              {cart.map((cartItem) => (
                <ListItem key={cartItem.version_id}>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      alt={`Artwork`}
                      src={`${skinConfig.skin_artwork_url}/${cartItem.master_artwork}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.cartText}
                    primary={`${sanitizeTitle(cartItem.master_title)} (${
                      cartItem.version_name
                    })`.toUpperCase()}
                    secondary={cartItem.master_subcategory.toUpperCase()}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Remove">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={loading}
                        onClick={() => {
                          removeFromCart(
                            userData.user_id,
                            activeGroup,
                            cartItem
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {cart.length > 1 && (
                <Button
                  onClick={() => {
                    clearCart(userData.user_id, activeGroup);
                  }}
                  size="small"
                  disabled={loading}
                  className={classes.clearButton}
                >
                  CLEAR
                </Button>
              )}
            </List>
          </React.Fragment>
        ) : (
          <DialogContentText className={classes.lightFont}>
            Your Download Cart is Empty.
          </DialogContentText>
        )}
      </CustomDialogContent>
      <CustomDialogActions loading={loading} actionButtons={actionButtons} />
    </React.Fragment>
  );
};

connectedCart.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  skinConfig: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  activeGroup: PropTypes.number.isRequired,
  clearCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
};

const Cart = connect(mapStateToProps, mapDispatchToProps)(connectedCart);

export default withStyles(styles)(Cart);
