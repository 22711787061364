import React, { useState, useEffect } from "react";
import { Slider, Typography, Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { arraysEqual } from "../helpers/SharedFunctions";
import { connect } from "react-redux";
import { updateSearch } from "../actions/index";
import { emitEvent } from "../helpers/ApiCalls";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  sliderContainer: {
    margin: theme.spacing(2),
  },
  slider: {
    color: theme.palette.action.active,
    "& .MuiSlider-valueLabel": {
      top: -35,
      "& *": {
        background: "transparent",
        color: theme.palette.text.primary,
      },
    },
    "& .MuiSlider-rail": {
      color: theme.palette.action.selected,
    },
    "& .MuiSlider-thumb": {
      color: theme.palette.action.active,
    },
  },
  sliderReader: {
    fontSize: "100%",
  },
  clearButton: {
    float: "right",
  },
}));

const mapStateToProps = (state) => {
  return {
    activeSearch: state.activeSearch,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const ConnectedSliderSelector = React.memo((props) => {
  const {
    min,
    max,
    step,
    measurement,
    updateSearch,
    activeSearch,
    type,
    userData,
  } = props;
  const classes = useStyles();

  const [value, setValue] = useState([min, max]);

  useEffect(() => {
    setValue(activeSearch[type].length ? activeSearch[type] : [min, max]);
  }, [activeSearch[type]]);

  const valuetext = (value, measurement) => {
    return value[0] === value[1]
      ? `${value[0]}${measurement}`
      : `${value[0]} - ${value[1]}${measurement}`;
  };

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleCommitted = (event, committedValue) => {
    if (arraysEqual(committedValue, [min, max])) {
      committedValue = [];
    }
    emitEvent(`${type}SliderInteractionV1`, {
      value: committedValue,
      user_id: userData.user_id,
    });
    const newState = { ...activeSearch };
    newState[type] = committedValue;
    updateSearch(newState);
  };

  const handleClear = () => {
    setValue([min, max]);
    const newState = { ...activeSearch };
    newState[type] = [];
    updateSearch(newState);
  };

  return (
    <div className={classes.root}>
      <div className={classes.sliderContainer}>
        <Slider
          value={value}
          className={classes.slider}
          onChange={handleChange}
          onChangeCommitted={handleCommitted}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          min={min}
          max={max}
          step={step}
        />
        <Typography className={classes.sliderReader} align="center">
          {valuetext(value, measurement)}
        </Typography>
      </div>
      {!!activeSearch[type].length && (
        <Button onClick={handleClear} className={classes.clearButton}>
          Clear
        </Button>
      )}
    </div>
  );
});

ConnectedSliderSelector.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  measurement: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  activeSearch: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
};

const SliderSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSliderSelector);

export default SliderSelector;
