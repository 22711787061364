import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  waveform: {
    cursor: "pointer",
  },
  progress: {
    position: "absolute",
    left: theme.spacing(1),
    top: "50%",
    right: theme.spacing(1),
    height: "1px",
  },
  waveformWrapper: {
    position: "relative",
    padding: theme.spacing(1),
  },
}));

const mapStateToProps = (state) => {
  return { skinConfig: state.skinConfig };
};

const ConnectedAudioPlayer = (props) => {
  const [audioReady, setAudioReady] = useState(false);
  const {
    filename,
    playState,
    skinConfig,
    setPlayState,
    autoPlay,
    handleError,
  } = props;
  const classes = useStyles();
  const waveform = useRef(null);

  useEffect(() => {
    waveform.current = WaveSurfer.create({
      container: "#waveform",
      scrollParent: true,
      responsive: true,
      height: 50,
      cursorWidth: 0,
    });
    loadAudio(filename);
    return () => {
      setPlayState("stop");
      waveform.current.destroy();
    };
  }, [filename]);

  useEffect(() => {
    switch (playState) {
      case "play":
        handlePlayPause();
        break;
      case "pause":
        handlePlayPause();
        break;
      case "stop":
        handleStop();
        break;
      default:
        handleStop();
    }
  }, [playState]);

  const loadAudio = (filename) => {
    const url = `${skinConfig.skin_masters_url}/${filename}`;
    setAudioReady(false);
    waveform.current.load(url);
    waveform.current.on("ready", () => {
      setAudioReady(true);
      autoPlay && setPlayState("play");
    });
    waveform.current.on("finish", () => {
      setPlayState("stop");
    });
    waveform.current.on("error", (res) => {
      handleError(res);
    });
  };

  const handlePlayPause = () => {
    waveform.current.playPause();
  };

  const handleStop = () => {
    waveform.current.stop();
  };

  const waveformVisibility = !audioReady ? { visibility: "hidden" } : {};
  return (
    <React.Fragment>
      <div className={classes.waveformWrapper}>
        {!audioReady && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}
        <div
          className={classes.waveform}
          id="waveform"
          style={waveformVisibility}
        />
      </div>
    </React.Fragment>
  );
};

ConnectedAudioPlayer.propTypes = {
  filename: PropTypes.string.isRequired,
  playState: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  skinConfig: PropTypes.object.isRequired,
  setPlayState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

const AudioPlayer = connect(mapStateToProps, null)(ConnectedAudioPlayer);

export default AudioPlayer;
