import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import Metadata from "./Metadata";
import Processing from "./Processing";
import ChipSelector from "../../../library/ChipSelector";
import Confirmation from "../../../library/Confirmation";
import {
  sanitizeTitle,
  titleCase,
  createTimeStamp,
  sortArray,
} from "../../../helpers/SharedFunctions";
import {
  getUsers,
  createUser,
  createUserRelationship,
  getAllGroups,
  getRoles,
  resetPasswordRequest,
} from "../../../helpers/ApiCalls";
import CustomDialogActions from "../../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../../library/dialog/CustomDialogContent";
import {
  setSnackbarVisibility,
  setDialogVisibility,
} from "../../../actions/index";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: theme.spacing(3),
    right: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ConnectedUserBuilder = (props, ref) => {
  const {
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    userData,
    doSearch,
  } = props;

  const initialData = {
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_expiry_date: "",
    user_status: 0,
    role_id: 2,
    groups: [],
  };

  const [userState, setUserState] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [allGroups, setAllGroups] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [allRoles, setAllRoles] = useState([]);

  const [processing, setProcessing] = useState({
    user: { status: "pending" },
    groups: { status: "pending" },
    token: { status: "pending" },
  });

  const classes = useStyles();

  useEffect(() => {
    getAllGroups().then((res) => {
      setAllGroups(mapChipData(res));
    });
    getRoles().then((res) => {
      let roles = res;
      if (parseInt(userData.role_id) !== 5) {
        roles = res.filter(function(obj) {
          return parseInt(obj.role_id) !== 5;
        });
      }
      setAllRoles(sortArray(roles, "role_name"));
    });
  }, []);

  useEffect(() => {
    let currentState = { ...userState };
    if (parseInt(userState.role_id) === 4) {
      let future = new Date();
      future.setDate(future.getDate() + 7);
      currentState.user_expiry_date = createTimeStamp(future);
    } else {
      currentState.user_expiry_date = "";
    }
    setUserState(currentState);
  }, [userState.role_id]);

  const mapChipData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.group_id,
          value: titleCase(item.group_name),
        }))
      : [];
  };

  const steps = [
    {
      stepTitle: "Enter metadata",
      fields: [
        "user_first_name",
        "user_last_name",
        "user_email",
        "user_expiry_date",
        "user_status",
        "role_id",
      ],
    },
    { stepTitle: "Select groups", fields: ["groups"] },
  ];

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Metadata
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleFieldChange}
            data={userState}
            allRoles={allRoles}
            disabled={loading}
            access={access}
          />
        );
      case 1:
        return (
          <ChipSelector
            allData={allGroups}
            selectedData={userState.groups}
            setSnackbarVisibility={setSnackbarVisibility}
            handleSelect={handleFieldChange}
            fieldName={`groups`}
            disabled={loading}
            access={access}
            chipType={`Group`}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...userState };
    let value = event.target.value;
    if (input === "user_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setUserState(currentState);
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...userState };
    if (field === "user_expiry_date") {
      currentState[field] = createTimeStamp(value);
    } else {
      currentState[field] = value;
    }
    setUserState(currentState);
  };

  const validateField = async (inputField, value) => {
    if (!value || value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    if (inputField === "user_email") {
      const result = await getUsers(`email=${value.trim().toLowerCase()}`);
      if (result.users && result.users.length) {
        setSnackbarVisibility({
          open: true,
          message: "Email already exists",
        });
        return false;
      }
    }
    return true;
  };

  const triggerValidation = async () => {
    for (const field of steps[activeStep].fields) {
      if (
        field !== "groups" &&
        field !== "user_expiry_date" &&
        field !== "user_status"
      ) {
        if (!(await validateField(field, userState[field]))) {
          return false;
        }
      }
    }
    return true;
  };

  const handleNext = async () => {
    if (!(await triggerValidation())) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "user_first_name":
        message = "Please enter a first name";
        break;
      case "user_last_name":
        message = "Please enter a last name";
        break;
      case "user_email":
        message = "Please enter a valid email";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleClose = () => {
    setConfirmationOpen(true);
  };

  const handleSubmit = async () => {
    if (!triggerValidation()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoading(true);
    const userId = await createUserEntry();
    if (!userId) {
      setLoading(false);
      return;
    }
    if (userState.groups.length) {
      if (!(await handleGroups(userId))) {
        setLoading(false);
        return;
      }
    }

    const tokenGenerated = await handleToken();
    if (!tokenGenerated) {
      setLoading(false);
      return;
    }

    setLoading(false);
    doSearch();
  };

  const handleToken = () => {
    let currentProcessing = { ...processing };
    currentProcessing.token.status = "processing";
    setProcessing(currentProcessing);
    const postData = {
      username: userState.user_email.trim().toLowerCase(),
      newAccount: true,
    };

    return resetPasswordRequest(postData).then((res) => {
      if (!res.result) {
        currentProcessing.token.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.token.status = "success";
      setProcessing({ ...currentProcessing });
      return res.result;
    });
  };

  const handleGroups = async (userId) => {
    let postData = { user_id: userId };
    let currentProcessing = { ...processing };
    currentProcessing.groups.status = "processing";
    setProcessing(currentProcessing);
    for (const group of userState.groups) {
      const groupId = group.id;
      postData.group_id = groupId;
      const relationshipId = await createUserRelationship(
        groupId,
        userId,
        postData
      );
      if (!relationshipId) {
        currentProcessing.groups.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
    }
    currentProcessing.groups.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const createUserEntry = () => {
    const postData = {
      user_first_name: userState.user_first_name.trim(),
      user_last_name: userState.user_last_name.trim(),
      user_email: userState.user_email.trim().toLowerCase(),
      user_expiry_date:
        userState.user_expiry_date !== ""
          ? new Date(userState.user_expiry_date)
              .toISOString()
              .slice(0, 19)
              .replace("T", " ")
          : null,
      user_status: 1,
      role_id: userState.role_id,
    };

    let currentProcessing = { ...processing };
    currentProcessing.user.status = "processing";
    setProcessing(currentProcessing);

    return createUser(postData).then((res) => {
      if (!res.data.result) {
        currentProcessing.user.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.user.status = "success";
      setProcessing({ ...currentProcessing });
      return res.data.result;
    });
  };

  const actionButtons = [
    {
      label: activeStep === steps.length ? "Done" : "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      isHidden: false,
      fn: activeStep === steps.length ? handleConfirmationConfirm : handleClose,
    },
    {
      label: "Back",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      disabled: activeStep === 0,
      isHidden: activeStep === steps.length,
      fn: handleBack,
    },
    {
      label: activeStep === steps.length - 1 ? "Submit" : "Next",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      isHidden: activeStep === steps.length,
      fn: activeStep === steps.length - 1 ? handleSubmit : handleNext,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={
          userState.user_first_name.length || userState.user_last_name.length
            ? titleCase(
                sanitizeTitle(
                  `${userState.user_first_name} ${userState.user_last_name}`
                )
              )
            : `New User`
        }
      />
      <CustomDialogContent>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.stepTitle}>
                <StepLabel>{step.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <Processing processing={processing} userState={userState} />
          ) : (
            getStepContent(activeStep)
          )}
        </div>
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedUserBuilder.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  doSearch: PropTypes.func.isRequired,
};

const UserBuilder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUserBuilder);

export default UserBuilder;
