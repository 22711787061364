import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { titleCase, sortObject } from "../../../helpers/SharedFunctions";
import CustomizedTextField from "../../../library/CustomizedTextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const UserCredentials = (props) => {
  const getUserExpiryDate = (user_expiry_date) => {
    let date;
    if (user_expiry_date) {
      date = new Date(user_expiry_date);
    } else {
      date = new Date();
      date.setDate(date.getDate() + 30);
    }
    return date;
  };

  const {
    handleChange,
    handleBlur,
    cardData,
    handleDateChange,
    access,
    userData,
  } = props;
  const allRoles = sortObject(props.allRoles, "role_name");
  const {
    user_first_name,
    user_last_name,
    user_email,
    role_id,
    user_expiry_date,
  } = cardData;

  const isSuperUser = () => {
    return parseInt(userData.role_id) === 5;
  };

  return (
    <form noValidate autoComplete="off">
      <CustomizedTextField
        required
        fullWidth
        id={"userCardFirstName"}
        label={"First Name"}
        value={user_first_name}
        handleOnChange={handleChange("user_first_name")}
        handleOnBlur={handleBlur("user_first_name")}
        placeholder={"First Name"}
        disabled={!access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id={"userCardLastName"}
        label={"Last Name"}
        value={user_last_name}
        handleOnChange={handleChange("user_last_name")}
        handleOnBlur={handleBlur("user_last_name")}
        placeholder={"Last Name"}
        disabled={!access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id={"userCardEmail"}
        label={"Email"}
        value={user_email}
        handleOnChange={handleChange("user_email")}
        handleOnBlur={handleBlur("user_email")}
        placeholder={"Email"}
        disabled={!access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id={"userCardRole"}
        select
        label={"Role"}
        value={parseInt(role_id, 10)}
        handleOnChange={handleChange("role_id")}
        disabled={!access.update || (!isSuperUser() && parseInt(role_id) === 5)}
      >
        {allRoles.map((option) => (
          <MenuItem
            key={parseInt(option.role_id, 10)}
            value={parseInt(option.role_id, 10)}
          >
            {titleCase(option.role_name)}
          </MenuItem>
        ))}
      </CustomizedTextField>
      {parseInt(role_id, 10) === 4 && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableToolbar
            disablePast
            autoOk={true}
            error={false}
            helperText={null}
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Expiry Date"
            value={getUserExpiryDate(user_expiry_date)}
            onChange={(date) => handleDateChange(date)}
            disabled={!access.update}
          />
        </MuiPickersUtilsProvider>
      )}
    </form>
  );
};

UserCredentials.propTypes = {
  cardData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default UserCredentials;
