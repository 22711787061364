import React from "react";
import {
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
	Home,
	Settings,
	Group,
	QueueMusic,
	Business,
	LibraryMusic,
	LibraryBooks,
	Build,
	Category,
} from "@material-ui/icons";
import { Link, withRouter, matchPath } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
	return {
		userData: state.userData,
	};
};

const ConnectedAdminSideBarContent = (props) => {
	const { userData, location } = props;
	const permissions = userData.permissions.split`,`.map((x) => +x);

	return (
		<React.Fragment>
			<ListItem
				component={Link}
				to="/admin"
				selected={!!matchPath(location.pathname, "/admin").isExact}
				button={true}
			>
				<ListItemIcon>
					<Home />
				</ListItemIcon>
				<ListItemText primary="Home" />
			</ListItem>
			{permissions.indexOf(6) > -1 && (
				<ListItem
					component={Link}
					to="/admin/content"
					selected={!!matchPath(location.pathname, "/admin/content")}
					button={true}
				>
					<ListItemIcon>
						<QueueMusic />
					</ListItemIcon>
					<ListItemText primary="Content" />
				</ListItem>
			)}
			{permissions.indexOf(2) > -1 && (
				<ListItem
					component={Link}
					to="/admin/users"
					selected={!!matchPath(location.pathname, "/admin/users")}
					button={true}
				>
					<ListItemIcon>
						<Group />
					</ListItemIcon>
					<ListItemText primary="Users" />
				</ListItem>
			)}
			{permissions.indexOf(10) > -1 && (
				<ListItem
					component={Link}
					to="/admin/groups"
					selected={!!matchPath(location.pathname, "/admin/groups")}
					button={true}
				>
					<ListItemIcon>
						<Business />
					</ListItemIcon>
					<ListItemText primary="Groups" />
				</ListItem>
			)}
			{permissions.indexOf(14) > -1 && (
				<ListItem
					component={Link}
					to="/admin/catalogs"
					selected={!!matchPath(location.pathname, "/admin/catalogs")}
					button={true}
				>
					<ListItemIcon>
						<LibraryMusic />
					</ListItemIcon>
					<ListItemText primary="Catalogs" />
				</ListItem>
			)}
			{permissions.indexOf(30) > -1 && (
				<ListItem
					component={Link}
					to="/admin/categories"
					selected={
						!!matchPath(location.pathname, "/admin/categories")
					}
					button={true}
				>
					<ListItemIcon>
						<Category />
					</ListItemIcon>
					<ListItemText primary="Categories" />
				</ListItem>
			)}
			{permissions.indexOf(18) > -1 && (
				<ListItem
					component={Link}
					to="/admin/works"
					selected={!!matchPath(location.pathname, "/admin/works")}
					button={true}
				>
					<ListItemIcon>
						<LibraryBooks />
					</ListItemIcon>
					<ListItemText primary="Works" />
				</ListItem>
			)}
			{permissions.indexOf(26) > -1 && (
				<ListItem
					component={Link}
					to="/admin/builds"
					selected={!!matchPath(location.pathname, "/admin/builds")}
					button={true}
				>
					<ListItemIcon>
						<Build />
					</ListItemIcon>
					<ListItemText primary="Builds" />
				</ListItem>
			)}
			{permissions.indexOf(22) > -1 && (
				<ListItem
					component={Link}
					to="/admin/settings"
					selected={!!matchPath(location.pathname, "/admin/settings")}
					button={true}
				>
					<ListItemIcon>
						<Settings />
					</ListItemIcon>
					<ListItemText primary="Settings" />
				</ListItem>
			)}
			<Divider />
		</React.Fragment>
	);
};

ConnectedAdminSideBarContent.propTypes = {
	userData: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const AdminSideBarContent = connect(
	mapStateToProps,
	null
)(ConnectedAdminSideBarContent);

export default withRouter(AdminSideBarContent);
