import React, { useState, useEffect } from "react";
import { makeStyles, TableCell, Badge } from "@material-ui/core";
import {
  SaveAlt,
  AddShoppingCart,
  RemoveShoppingCart,
  Info,
  ShoppingCart,
} from "@material-ui/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { directDownload } from "../helpers/DownloadFunctions";
import ActionButton from "../library/ActionButton";
import {
  addToCart,
  removeFromCart,
  setDialogVisibility,
} from "../actions/index";
import { searchAssociativeArray } from "../helpers/SharedFunctions";
import { getVersions, getCreditInfo, getMasterTags } from "../helpers/ApiCalls";
import InfoContent from "./InfoContent";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  customBadge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
  skeletonWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
  skeleton: {
    margin: "0px auto",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (userId, groupId, cartItem) =>
      dispatch(addToCart(userId, groupId, cartItem)),
    removeFromCart: (userId, groupId, cartItemId) =>
      dispatch(removeFromCart(userId, groupId, cartItemId)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    activeGroup: state.activeGroup,
    userData: state.userData,
    searching: state.searching,
  };
};

const ConnectedContentListActions = (props) => {
  const {
    cart,
    activeGroup,
    setDialogVisibility,
    userData,
    addToCart,
    removeFromCart,
    className,
    data,
    searching,
  } = props;
  const classes = useStyles();

  const [availableVersions, setAvailableVersions] = useState(null);
  const [masterCredits, setMasterCredits] = useState(null);
  const [masterTags, setMasterTags] = useState(null);
  const [cartMatches, setCartMatches] = useState([]);

  const cartCompare = (controlArray, field, value) => {
    const matchedValues = [];
    for (var i = 0; i < controlArray.length; i++) {
      if (controlArray[i][field] === value) {
        matchedValues.push(controlArray[i]);
      }
    }
    return matchedValues;
  };

  //when cart or item data changes, check whether item is in cart
  useEffect(() => {
    const cartItems = cartCompare(cart, "master_id", data.master_id);
    setCartMatches(cartItems);
  }, [cart, data]);

  //if item is in cart, get available versions
  useEffect(() => {
    cartMatches.length && !availableVersions && loadAvailableVersions();
  }, [cartMatches]);

  //cleanup on item data change
  useEffect(() => {
    return () => {
      setAvailableVersions(null);
      setMasterTags(null);
      setMasterCredits(null);
      setCartMatches([]);
    };
  }, [data]);

  const loadAvailableVersions = () => {
    return getVersions(activeGroup, data.master_id).then((result) => {
      setAvailableVersions(result);
      return result;
    });
  };

  const loadMasterCreditInfo = () => {
    return getCreditInfo(activeGroup, data.master_id).then((result) => {
      setMasterCredits(result);
      return result;
    });
  };

  const loadMasterTags = () => {
    return getMasterTags(data.master_id).then((result) => {
      setMasterTags(result);
      return result;
    });
  };

  const handleShowInfo = async () => {
    const infoData = {
      availableVersions: !availableVersions
        ? await loadAvailableVersions()
        : availableVersions,
      credits: !masterCredits ? await loadMasterCreditInfo() : masterCredits,
      tags: !masterTags ? await loadMasterTags() : masterTags,
    };
    const dialogContent = {
      open: true,
      fullWidth: true,
      fullScreen: "xs",
      body: <InfoContent master={data} info={infoData} />,
    };
    setDialogVisibility(dialogContent);
    return false;
  };

  const handleRemoveFromCartClick = async () => {
    const result = !availableVersions
      ? await loadAvailableVersions()
      : availableVersions;
    if (result.length > 1) {
      return true;
    }
    const removeItem = searchAssociativeArray(
      data.version_id,
      cart,
      "version_id"
    );
    await removeFromCart(userData.user_id, activeGroup, removeItem);
    return false;
  };

  const handleAddToCartClick = async () => {
    const result = !availableVersions
      ? await loadAvailableVersions()
      : availableVersions;
    if (result.length > 1) {
      return true;
    }
    await addToCart(userData.user_id, activeGroup, data);
    return false;
  };

  const handleCartSelection = async (selectedItems) => {
    const itemsToRemove = [];
    const itemsToAdd = [];
    cartMatches.forEach((cartMatch) => {
      const isSelected = searchAssociativeArray(
        cartMatch.version_id,
        selectedItems,
        "version_id"
      );
      if (!isSelected) {
        itemsToRemove.push(cartMatch);
      }
    });
    selectedItems.forEach((version) => {
      const isInCart = searchAssociativeArray(
        version.version_id,
        cart,
        "version_id"
      );
      if (!isInCart) {
        itemsToAdd.push(version);
      }
    });
    for (let i = 0; i < itemsToAdd.length; i++) {
      await addToCart(userData.user_id, activeGroup, itemsToAdd[i]);
    }
    for (let i = 0; i < itemsToRemove.length; i++) {
      await removeFromCart(userData.user_id, activeGroup, itemsToRemove[i]);
    }
    return;
  };

  const handleDownloadClick = async () => {
    const result = !availableVersions
      ? await loadAvailableVersions()
      : availableVersions;
    if (result.length > 1) {
      return true;
    }
    await directDownload(userData.user_id, activeGroup, [data]);
    return false;
  };

  const handleDownloadSelection = async (selectedItems) => {
    await directDownload(userData.user_id, activeGroup, selectedItems);
    return;
  };

  return (
    <React.Fragment>
      <TableCell align={"right"} className={className}>
        {!searching ? (
          <div className={classes.buttonWrapper}>
            <ActionButton
              label={"Info"}
              multipleButtonLabel={"Info"}
              clickAction={handleShowInfo}
            >
              <Info />
            </ActionButton>
          </div>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Skeleton
              className={classes.skeleton}
              variant="circle"
              width={24}
              height={24}
            />
          </div>
        )}
      </TableCell>

      <TableCell align={"right"} className={className}>
        {!searching ? (
          <div className={classes.buttonWrapper}>
            <ActionButton
              initialSelection={cartMatches}
              selectionAction={handleCartSelection}
              menuItems={availableVersions}
              menuItemProps={{
                idField: "version_id",
                sortField: "version_name",
                displayField: "version_name",
              }}
              label={!cartMatches.length ? "Add To Cart" : "Update Cart"}
              clickAction={
                cartMatches.length
                  ? handleRemoveFromCartClick
                  : handleAddToCartClick
              }
            >
              {!cartMatches.length ? (
                <AddShoppingCart />
              ) : cartMatches.length &&
                availableVersions &&
                availableVersions.length > 1 ? (
                <Badge
                  badgeContent={cartMatches.length}
                  color={"secondary"}
                  classes={{ badge: classes.customBadge }}
                >
                  <ShoppingCart />
                </Badge>
              ) : (
                <RemoveShoppingCart />
              )}
            </ActionButton>
          </div>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Skeleton
              className={classes.skeleton}
              variant="circle"
              width={24}
              height={24}
            />
          </div>
        )}
      </TableCell>
      <TableCell align={"center"} className={className}>
        {!searching ? (
          <div className={classes.buttonWrapper}>
            <ActionButton
              clickAction={handleDownloadClick}
              selectionAction={handleDownloadSelection}
              menuItems={availableVersions}
              label={"Download"}
              menuItemProps={{
                idField: "version_id",
                sortField: "version_name",
                displayField: "version_name",
              }}
            >
              <SaveAlt />
            </ActionButton>
          </div>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Skeleton
              className={classes.skeleton}
              variant="circle"
              width={24}
              height={24}
            />
          </div>
        )}
      </TableCell>
    </React.Fragment>
  );
};

ConnectedContentListActions.propTypes = {
  data: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  activeGroup: PropTypes.number.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
};

const ContentListActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedContentListActions);

export default ContentListActions;
