export const Fonts = [
  { fontId: 0, fontName: "Roboto", fontDeclaration: "'Roboto', sans-serif" },
  {
    fontId: 1,
    fontName: "Source Sans Pro",
    fontDeclaration: "'Source Sans Pro', sans-serif",
  },
  {
    fontId: 3,
    fontName: "Open Sans",
    fontDeclaration: "'Open Sans', sans-serif",
  },
];

export default Fonts;