import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
	TableBody,
	TableCell,
	TableRow,
	makeStyles,
	Switch,
} from "@material-ui/core";
import Artwork from "./Artwork";
import { connect } from "react-redux";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
	pointer: {
		cursor: "pointer",
	},
	tableCell: {
		height: 41,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		padding: theme.spacing(0.5),
	},
	tableRow: {
		"&:focus": {
			backgroundColor: theme.palette.action.selected,
		},
	},
	skeletonWrapper: {
		position: "relative",
		height: "100%",
		width: "100%",
		padding: theme.spacing(1),
	},
	switchCell: {
		padding: 0,
	},
}));

const mapStateToProps = (state) => {
	return {
		dialog: state.dialog,
		searching: state.searching,
	};
};

const ConnectedEnhancedTableBody = (props) => {
	const {
		rowsPerPage,
		page,
		items,
		columns,
		handleRowClick,
		selectedRow,
		formatData,
		actions,
		idField,
		handleChangePage,
		dialog,
		lastPage,
		searching,
	} = props;

	const classes = useStyles();
	const myRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (event) => {
			const allowedKeys = [
				"ArrowUp",
				"ArrowDown",
				"ArrowLeft",
				"ArrowRight",
			];
			if (
				allowedKeys.includes(event.key) &&
				!myRef.current.contains(document.activeElement)
			) {
				handleRowNav(event, null);
			}
		};

		if (!dialog.open) {
			document.addEventListener("keydown", handleKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [dialog]);

	const handleRowNav = (event, index) => {
		event.stopPropagation();
		event.preventDefault();
		const isLastPage = page === lastPage;
		const isFirstElement = index === 0;
		const isLastElement = !isLastPage
			? index === rowsPerPage - 1
			: index ===
			  Math.ceil(rowsPerPage * (items.length / rowsPerPage - lastPage)) -
					1;
		let nextItemIndex;

		switch (event.key) {
			case "ArrowDown":
				//if last item of total results, do nothing
				if (isLastElement && isLastPage) {
					return;
				}
				//if last element of page, go to next page
				if (isLastElement) {
					handleChangePage(null, page + 1);
					nextItemIndex = 0;
				} else {
					nextItemIndex = index !== null ? index + 1 : 0;
				}
				myRef.current.childNodes[nextItemIndex].focus();
				break;
			case "ArrowUp":
				//if first item of total results, do nothing
				if (isFirstElement && page === 0) {
					return;
				}
				//if first element of page, go to previous page
				if (isFirstElement) {
					handleChangePage(null, page - 1);
					nextItemIndex = rowsPerPage - 1;
				} else {
					nextItemIndex = index - 1;
					nextItemIndex = index
						? index - 1
						: [
								...myRef.current.lastChild.parentNode.children,
						  ].indexOf(myRef.current.lastChild);
				}
				myRef.current.childNodes[nextItemIndex].focus();
				break;
			case "ArrowRight":
				!isLastPage && handleChangePage(null, page + 1);
				myRef.current.childNodes[0].focus();
				break;
			case "ArrowLeft":
				page !== 0 && handleChangePage(null, page - 1);
				myRef.current.childNodes[0].focus();
				break;
			case "Enter":
			case " ":
				myRef.current.childNodes[index].click();
				return;
			default:
				break;
		}
	};

	const getFieldType = (n, i, column) => {
		switch (column.type) {
			case "artwork":
				return (
					<Artwork
						key={i}
						styles={
							column.classes &&
							column.classes.length &&
							clsx(classes.tableCell, column.classes)
						}
						filename={n[column.id]}
					/>
				);
			case "switch":
				return (
					<TableCell
						key={i}
						component="td"
						scope="row"
						align="right"
						className={classes.switchCell}
						onClick={(event) => event.stopPropagation()}
					>
						<Switch
							checked={Boolean(parseInt(n[column.id]))}
							onChange={() => column.fn(n)}
							color="secondary"
							name="checkedB"
							inputProps={{ "aria-label": "primary checkbox" }}
							disabled={column.isDisabled && column.isDisabled(n)}
						/>
					</TableCell>
				);
			default:
				return (
					<TableCell
						key={i}
						component="td"
						scope="row"
						className={
							column.classes &&
							column.classes.length &&
							clsx(classes.tableCell, column.classes)
						}
					>
						{formatData(n[column.id], column.id)}
					</TableCell>
				);
		}
	};

	const isSelected = (rowData) => selectedRow === parseInt(rowData[idField]);

	return (
		<TableBody ref={myRef}>
			{items.map((n, i) => {
				return (
					<TableRow
						hover={true}
						onClick={() =>
							!searching &&
							handleRowClick &&
							handleRowClick(parseInt(n[idField]), n)
						}
						tabIndex={-1}
						key={i}
						selected={isSelected(n)}
						onKeyDown={(e) => handleRowNav(e, i)}
						className={clsx(
							handleRowClick ? classes.pointer : "",
							!isSelected(n) && classes.tableRow
						)}
					>
						{columns.map((column, i) =>
							!searching ? (
								getFieldType(n, i, column)
							) : (
								<TableCell
									key={i}
									component="td"
									scope="row"
									className={clsx(
										classes.tableCell,
										column.classes
									)}
								>
									<div className={classes.skeletonWrapper}>
										<Skeleton variant="text" />
									</div>
								</TableCell>
							)
						)}
						{actions &&
							React.cloneElement(actions, {
								data: n,
								key: { i },
								className: classes.tableCell,
							})}
					</TableRow>
				);
			})}
		</TableBody>
	);
};

ConnectedEnhancedTableBody.propTypes = {
	handleRowClick: PropTypes.func.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	items: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	selectedRow: PropTypes.number,
	formatData: PropTypes.func.isRequired,
	actions: PropTypes.object,
	idField: PropTypes.string.isRequired,
	handleChangePage: PropTypes.func.isRequired,
	dialog: PropTypes.object.isRequired,
	lastPage: PropTypes.number.isRequired,
	searching: PropTypes.bool.isRequired,
	page: PropTypes.number,
};

const EnhancedTableBody = connect(
	mapStateToProps,
	null
)(ConnectedEnhancedTableBody);

export default EnhancedTableBody;
