import React, { createRef } from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

class DropzoneContainer extends React.Component {
  state = {
    files: [],
  };

  dropzoneRef = createRef();

  openDialog = () => {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  };

  render() {
    const { addFiles, children, access, index, accept, loading } = this.props;
    return (
      <Dropzone
        ref={this.dropzoneRef}
        noClick
        noKeyboard
        disabled={loading || !access.update}
        accept={accept}
        onDropAccepted={(acceptedFiles) => addFiles(acceptedFiles, index)}
      >
        {({ getRootProps, getInputProps }) => (
          <section style={{ height: "100%" }}>
            <div style={{ height: "100%" }} {...getRootProps()}>
              <input {...getInputProps()} />
              {children}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

DropzoneContainer.propTypes = {
  access: PropTypes.object.isRequired,
  addFiles: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  accept: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default DropzoneContainer;
