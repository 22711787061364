import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { connect } from "react-redux";
import { updateSearch } from "../actions/index";
import SearchBar from "../library/SearchBar";
import SearchIcon from "@material-ui/icons/Search";
import { emitEvent } from "../helpers/ApiCalls";

const useStyles = makeStyles((theme) => ({
  menuIcons: {
    color: theme.palette.primary.contrastText,
  },
  searchWrapper: {
    margin: `${theme.spacing(1)}px 0px`,
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.only("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      margin: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px`,
    },
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    borderRadius: "4px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  iconButtonWrapper: {
    position: "relative",
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    activeSearch: state.activeSearch,
    searching: state.searching,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const ConnectedSearch = (props) => {
  const { searching, updateSearch, activeSearch, userData } = props;
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const open = Boolean(anchorEl);
  const id = open ? "search-popper" : undefined;

  useEffect(() => {
    setAnchorEl(null);
  }, [mobile]);

  const handleSearchIconClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(false);
  };

  const handleSearchSubmit = (query) => {
    const newState = { ...activeSearch };
    newState.query = query;
    emitEvent("keywordSearchV1", {
      query: query,
      user_id: userData.user_id,
    });
    mobile && handlePopperClose();
    updateSearch(newState);
  };

  return !mobile ? (
    <div className={classes.searchWrapper}>
      <SearchBar
        submitSearch={handleSearchSubmit}
        searching={searching}
        activeSearch={activeSearch}
        mobile={false}
      />
    </div>
  ) : (
    <React.Fragment>
      <div className={classes.iconButtonWrapper}>
        <IconButton
          className={classes.menuIcons}
          aria-label="Search"
          onClick={handleSearchIconClick}
          disabled={searching}
        >
          <SearchIcon />
        </IconButton>
        {searching && (
          <CircularProgress
            color="inherit"
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={true}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <ClickAwayListener onClickAway={handlePopperClose}>
          <div className={classes.paper}>
            <SearchBar
              submitSearch={handleSearchSubmit}
              searching={searching}
              activeSearch={activeSearch}
              mobile={true}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

ConnectedSearch.propTypes = {
  searching: PropTypes.bool.isRequired,
  updateSearch: PropTypes.func.isRequired,
  activeSearch: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

const Search = connect(mapStateToProps, mapDispatchToProps)(ConnectedSearch);
export default Search;
