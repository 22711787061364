import React from "react";
import { withStyles, Button, CircularProgress, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
  input: {
    display: "none",
  },
  updateButton: {
    float: "right",
    marginLeft: theme.spacing(2),
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
    float: "right",
  },
  paper: {
    padding: theme.spacing(1),
    display: "inline-block",
    overflow: "hidden",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  iconWrapper: {
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }
  handleChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleConfirm = (file, asset, extension) => {
    this.props.toggleLoading(asset);
    this.props.submitAsset(file, asset, extension).then((response) => {
      this.setState({ file: null });
      this.props.toggleLoading("");
    });
  };

  handleCancel = () => {
    this.setState({ file: null });
  };

  render() {
    const { classes, skinConfig, loading } = this.props;
    const { file } = this.state;
    const preview = file
      ? file
      : `${skinConfig.skin_branding_url}/${skinConfig.skin_favicon}`;
    return (
      <div>
        <div className={classes.paperWrapper}>
          <Paper className={classes.paper}>
            <div className={classes.iconWrapper}>
              <img src={preview} alt="preview" />
            </div>
          </Paper>
        </div>
        {this.props.children}
        <div>
          <input
            className={classes.input}
            id="favicon"
            type="file"
            accept="image/x-icon"
            onChange={this.handleChange}
          />

          {file ? (
            <React.Fragment>
              <div className={classes.buttonWrapper}>
                <Button
                  id="skin_favicon"
                  variant="outlined"
                  color="secondary"
                  className={classes.updateButton}
                  onClick={() =>
                    this.handleConfirm(file, "skin_favicon", "ico")
                  }
                  disabled={Boolean(loading.length)}
                >
                  Confirm
                </Button>
                {loading === "skin_favicon" && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>

              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  className={classes.updateButton}
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className={classes.buttonWrapper}>
              <label htmlFor="favicon">
                <Button
                  variant="outlined"
                  component="span"
                  className={classes.updateButton}
                  color="secondary"
                >
                  Replace
                </Button>
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.ThemeControl = {
  toggleLoading: PropTypes.func.isRequired,
  submitAsset: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  skinConfig: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(ImageUpload);
