import React from "react";
import PropTypes from "prop-types";
import {
  AddShoppingCart,
  RemoveShoppingCart,
  SaveAlt,
} from "@material-ui/icons";
import DownloadActionButton from "./DownloadActionButton";
import { searchAssociativeArray } from "../helpers/SharedFunctions";

function DownloadActions(props) {
  const { actionFunction, selectedVersion, cart } = props;

  const matchCartItem = searchAssociativeArray(
    selectedVersion.version_id,
    cart,
    "version_id"
  );

  return (
    <React.Fragment>
      {!matchCartItem ? (
        <DownloadActionButton
          color="secondary"
          aria-label={"Add To Cart"}
          size="medium"
          actionFunction={actionFunction}
          tooltipLabel={"Add To Cart"}
          action={"add"}
        >
          <AddShoppingCart />
        </DownloadActionButton>
      ) : (
        <DownloadActionButton
          color="secondary"
          aria-label={"Remove From Cart"}
          size="medium"
          actionFunction={actionFunction}
          tooltipLabel={"Remove From Cart"}
          action={"remove"}
        >
          <RemoveShoppingCart />
        </DownloadActionButton>
      )}
      <DownloadActionButton
        color="secondary"
        aria-label={"Download"}
        size="medium"
        actionFunction={actionFunction}
        tooltipLabel={"Download"}
        action={"download"}
      >
        <SaveAlt />
      </DownloadActionButton>
    </React.Fragment>
  );
}

DownloadActions.propTypes = {
  actionFunction: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
  selectedVersion: PropTypes.object.isRequired,
};

export default DownloadActions;
