import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { sanitizeTitle, titleCase } from "../../helpers/SharedFunctions";
import CustomDialogActions from "../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../library/dialog/CustomDialogContent";
import Metadata from "./Metadata";
import {
  setSnackbarVisibility,
  setDialogVisibility,
} from "../../actions/index";
import { connect } from "react-redux";
import {
  getStemLibrary,
  editCatalog,
  editStemLibrary,
} from "../../helpers/ApiCalls";
import StemLibraryList from "./StemLibraryList";
import Confirmation from "../../library/Confirmation";

const useStyles = makeStyles((theme) => ({
  dialogTabs: {
    color: theme.palette.text.primary,
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedContentCard = (props, ref) => {
  const {
    cardContent,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    doSearch,
  } = props;

  const timerRef = useRef(null);

  const initialData = {
    catalog_id: cardContent.catalog_id,
    catalog_name: cardContent.catalog_name,
    catalog_status: cardContent.catalog_status,
  };

  const [activeTab, setActiveTab] = useState(0);
  const [catalogState, setCatalogState] = useState(initialData);
  const [editedFields, setEditedFields] = useState([]);
  const [editedLibrary, setEditedLibrary] = useState({});
  const [stemLibrary, setStemLibrary] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const originalCatalogData = JSON.parse(JSON.stringify(initialData));
  const [originalStemLibraryData, setOriginalStemLibraryData] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      compareStates();
    }, 1000);
  }, [catalogState]);

  useEffect(() => {
    catalogState.catalog_id &&
      getStemLibrary(catalogState.catalog_id).then((res) => {
        const stemLibraryData = res.reduce((acc, curr) => {
          acc[curr.stem_library_id] = curr;
          return acc;
        }, {});
        setStemLibrary(stemLibraryData);
        setOriginalStemLibraryData(JSON.parse(JSON.stringify(stemLibraryData)));
      });
  }, [catalogState.catalog_id]);

  const compareStates = (input, value) => {
    let currentEditedFields = [...editedFields];
    for (const [key] of Object.entries(catalogState)) {
      if (hasFieldChanged(key) && currentEditedFields.indexOf(key) === -1) {
        currentEditedFields.push(key);
        setEditedFields(currentEditedFields);
      } else if (
        !hasFieldChanged(key) &&
        currentEditedFields.indexOf(key) !== -1
      ) {
        currentEditedFields.splice(currentEditedFields.indexOf(key), 1);
        setEditedFields(currentEditedFields);
      }
    }
  };

  const hasFieldChanged = (field) => {
    const originalState = originalCatalogData[field];
    const newState = catalogState[field];
    return originalState !== newState;
  };

  const handleEditCatalog = async () => {
    setLoading(true);
    const promises = [];
    if (editedFields.length) {
      const catalogPostData = {};
      editedFields.forEach((editedField) => {
        let value = catalogState[editedField];
        if (!validateField(editedField, value)) {
          setLoading(false);
          return;
        }
        if (typeof value === "string") {
          value.trim();
        }
        catalogPostData[editedField] = value;
      });

      promises.push(
        new Promise((resolve, reject) => {
          editCatalog(cardContent.catalog_id, catalogPostData).then((res) => {
            resolve(res.data);
          });
        })
      );
    }

    if (Object.keys(editedLibrary).length) {
      Object.entries(editedLibrary).forEach(([key, value]) => {
        const stemLibraryId = key;
        const libraryPostData = {};
        value.forEach((field) => {
          libraryPostData[field] = stemLibrary[key][field];
        });
        promises.push(
          new Promise((resolve, reject) => {
            editStemLibrary(
              catalogState.catalog_id,
              stemLibraryId,
              libraryPostData
            ).then((res) => {
              resolve(res.data);
            });
          })
        );
      });
    }

    Promise.all(promises).then((values) => {
      values.forEach((value) => {
        if (!value.result) {
          setSnackbarVisibility({
            open: true,
            message: value.message,
          });
        } else {
          closeDialog();
          doSearch();
          setSnackbarVisibility({
            open: true,
            message: "Update Successful",
          });
        }
      });
    });
  };

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...catalogState };
    let value = event.target.value;
    if (input === "catalog_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setCatalogState(currentState);
  };

  const validateField = (inputField, value) => {
    if (value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    return true;
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "catalog_name":
        message = "Please enter a name";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleDialogClose = () => {
    if (editedFields.length) {
      setConfirmationOpen(true);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Save",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleEditCatalog,
    },
  ];

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={titleCase(sanitizeTitle(catalogState.catalog_name))}
      />
      <Tabs
        className={classes.dialogTabs}
        value={activeTab}
        onChange={handleTabChange}
        centered
      >
        <Tab label="Metadata" />
        <Tab label="Library" />
      </Tabs>
      <CustomDialogContent>
        {activeTab === 0 && (
          <Metadata
            handleBlur={handleBlur}
            handleChange={handleChange}
            data={catalogState}
            disabled={loading}
            access={access}
          />
        )}
        {activeTab === 1 && (
          <StemLibraryList
            stemLibrary={stemLibrary}
            setStemLibrary={setStemLibrary}
            originalStemLibraryData={originalStemLibraryData}
            editedLibrary={editedLibrary}
            setEditedLibrary={setEditedLibrary}
          />
        )}
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={
          loading ||
          (editedFields.length === 0 && Object.keys(editedLibrary).length) ===
            0 ||
          !access.update
        }
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedContentCard.propTypes = {
  cardContent: PropTypes.object.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

const ContentCard = connect(null, mapDispatchToProps)(ConnectedContentCard);

export default ContentCard;
