import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Divider,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import {
  titleCase,
  isEmailValid,
  createTimeStamp,
  formatDate,
} from "../../../helpers/SharedFunctions";
import {
  getUser,
  getGroups,
  getAllGroups,
  getUsers,
  getRoles,
} from "../../../helpers/ApiCalls";
import UserCredentials from "./UserCredentials";
import UserGroups from "./UserGroups";
import UserStats from "./UserStats";
import { connect } from "react-redux";
import { setSnackbarVisibility } from "../../../actions/index";

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
  };
};

const styles = (theme) => ({
  dialogTabs: {
    color: theme.palette.text.primary,
  },
  dialog: {
    minHeight: "75vh",
    maxHeight: "75vh",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
    float: "right",
  },
  separator: { marginTop: theme.spacing(2) },
  progress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
  },
});

class ConnectedUserCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      role_id: null,
      user_groups: null,
      user_expiry_date: null,
      activeTab: 0,
      originalGroups: null,
      originalCredentials: null,
      loading: false,
      allGroups: null,
      allRoles: null,
      editedFields: [],
      user_stats: null,
    };
  }

  componentDidMount() {
    getUser(this.props.userId, `stats=true`).then((details) => {
      this.setStateData(details[0]);
    });
    getGroups(this.props.userId).then((groups) => {
      const userGroups = this.mapGroupData(groups);
      this.setState({
        user_groups: userGroups,
        originalGroups: JSON.parse(JSON.stringify(userGroups)),
      });
    });
    getAllGroups().then((groups) => {
      const allGroups = this.mapGroupData(groups);
      this.setState({ allGroups });
    });
    getRoles().then((allRoles) => this.setState({ allRoles }));
  }

  hasFieldChanged = (field) => {
    let originalState = this.state.originalCredentials[field];
    let newState = this.state[field];
    if (!isNaN(originalState)) {
      originalState = parseInt(originalState);
    }
    if (!isNaN(newState)) {
      newState = parseInt(newState);
    }
    return originalState !== newState;
  };

  mapGroupData = (groups) => {
    return groups.length
      ? groups.map((group) => ({
          id: group.group_id,
          value: group.group_name.toUpperCase(),
        }))
      : [];
  };

  setStateData = (credentials) => {
    const userCredentials = {
      user_first_name: credentials.user_first_name,
      user_last_name: credentials.user_last_name,
      user_email: credentials.user_email,
      role_id: parseInt(credentials.role_id),
      user_expiry_date: credentials.user_expiry_date,
      user_stats: {
        totalDownloads: credentials.total_downloads
          ? parseInt(credentials.total_downloads)
          : 0,
        uniqueDownloads: credentials.unique_downloads
          ? parseInt(credentials.unique_downloads)
          : 0,
        lastLogin: credentials.last_login
          ? formatDate(credentials.last_login)
          : "Never",
      },
      originalCredentials: JSON.parse(JSON.stringify(credentials)),
    };
    this.setState(userCredentials);
  };

  handleDateChange = (date) => {
    const timeStamp = createTimeStamp(date);
    this.setState({ user_expiry_date: timeStamp }, () => {
      this.registerStateChange("user_expiry_date", timeStamp);
    });
  };

  validateForm = async () => {
    this.setState({ loading: true });
    const fields = [
      "user_first_name",
      "user_last_name",
      "user_email",
      "role_id",
    ];
    const values = this.state;
    //create empty array to track valid fields
    const formValid = [];
    for (var i = 0; i < fields.length; i++) {
      const field = fields[i];
      const value = values[field];
      const isValid = await this.validateField(field, value);
      if (isValid) {
        formValid.push(true);
      } else {
        this.setState({ loading: false });
        break;
      }
    }
    //check all fields in step are valid
    if (formValid.length === fields.length) {
      this.updateUser().then(() => {
        this.setState({ loading: false });
        this.props.handleDialogClose();
      });
    }
  };

  updateUser = () => {
    const { editedFields } = this.state;
    const postData = {};
    editedFields.forEach((editedField) => {
      let value = this.state[editedField];
      if (typeof value === "string") {
        value.trim();
      }
      if (editedField === "user_groups") {
        value = this.haveGroupsChanged();
      }
      postData[editedField] = value;
    });
    return this.props.handleEditUser(this.props.userId, postData).then(() => {
      return;
    });
  };

  handleChange = (input) => (event) => {
    const value = event.target.value;
    this.setState({ [input]: value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.registerStateChange(input, value);
    }, 1000);
  };

  registerStateChange = (input, value) => {
    const { editedFields } = this.state;
    if (this.hasFieldChanged(input) && editedFields.indexOf(input) === -1) {
      editedFields.push(input);
      this.setState({ editedFields: editedFields });
    } else if (
      !this.hasFieldChanged(input) &&
      editedFields.indexOf(input) !== -1
    ) {
      editedFields.splice(editedFields.indexOf(input), 1);
      this.setState({ editedFields: editedFields });
    }
  };

  handleTabChange = (event, activeTab) => {
    this.setState({ activeTab });
  };

  handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    this.validateField(inputField, inputValue);
  };

  validateEmail = async (email) => {
    let message;
    //check regex for valid email
    if (!isEmailValid(email)) {
      message = "User's email address is invalid";
      this.props.setSnackbarVisibility({
        open: true,
        message: message,
      });
      return false;
    }
    //check if email already exists
    const queryString = `email=${email}`;
    return getUsers(queryString).then((user) => {
      if (user.length) {
        message = "User's email address already exists";
        this.props.setSnackbarVisibility({
          open: true,
          message: message,
        });
        return false;
      } else {
        return true;
      }
    });
  };

  haveGroupsChanged() {
    const { originalGroups, user_groups, editedFields } = this.state;
    const changes = { delete: [], create: [] };
    originalGroups.forEach((element) => {
      if (
        user_groups.findIndex(
          (x) => parseInt(x.id) === parseInt(element.id)
        ) === -1
      ) {
        changes.delete.push(parseInt(element.id));
      }
    });
    user_groups.forEach((element) => {
      if (
        originalGroups.findIndex(
          (x) => parseInt(x.id) === parseInt(element.id)
        ) === -1
      ) {
        changes.create.push(parseInt(element.id));
      }
    });
    if (changes.delete.length || changes.create.length) {
      !editedFields.includes("user_groups") && editedFields.push("user_groups");
      this.setState({ editedFields: editedFields });
    } else {
      const index = editedFields.indexOf("user_groups");
      if (index > -1) {
        editedFields.splice(index, 1);
        this.setState({ editedFields: editedFields });
      }
    }
    return changes;
  }

  handleGroupSelect = (user_groups) => {
    this.setState({ user_groups: user_groups }, function() {
      this.haveGroupsChanged();
    });
  };

  validateField = async (inputField, value) => {
    const { originalCredentials } = this.state;
    if (value.length === 0) {
      this.handleEmptyField(inputField);
      return false;
    }
    if (
      inputField === "user_email" &&
      value.toLowerCase() !== originalCredentials.user_email.toLowerCase()
    ) {
      const emailValid = await this.validateEmail(value);
      return emailValid;
    } else {
      return true;
    }
  };

  handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "user_email":
        message = "Please enter user's email address";
        break;
      case "user_first_name":
        message = "Please enter user's first name";
        break;
      case "user_last_name":
        message = "Please enter user's last name";
        break;
      case "role_id":
        message = "Please select a role";
        break;
      default:
        message = "Field cannot be empty";
    }
    this.props.setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  render() {
    const {
      classes,
      dialogCardOpen,
      handleDialogClose,
      access,
      userData,
    } = this.props;
    const {
      user_first_name,
      user_last_name,
      user_groups,
      allGroups,
      loading,
      editedFields,
      originalCredentials,
      originalGroups,
      user_stats,
      allRoles,
    } = this.state;
    return (
      <Dialog
        open={dialogCardOpen}
        fullWidth
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="form-dialog-title">
          {!!originalCredentials
            ? titleCase(`${user_first_name} ${user_last_name}`)
            : `Loading...`}
        </DialogTitle>
        <Divider />
        <Tabs
          className={classes.dialogTabs}
          value={this.state.activeTab}
          onChange={this.handleTabChange}
          centered
        >
          <Tab label="Credentials" />
          <Tab label="Groups" />
          <Tab label="Statistics" />
        </Tabs>
        <div className={classes.separator} />
        <DialogContent>
          {this.state.activeTab === 0 &&
            (!!originalCredentials && !!allRoles ? (
              <UserCredentials
                cardData={this.state}
                allRoles={allRoles}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                handleDateChange={this.handleDateChange}
                access={access}
                userData={userData}
              />
            ) : (
              <CircularProgress className={classes.progress} />
            ))}
          {this.state.activeTab === 1 &&
            (!!originalGroups && !!allGroups ? (
              <UserGroups
                allGroups={allGroups}
                userGroups={user_groups}
                handleDelete={this.handleGroupDelete}
                handleSelect={this.handleGroupSelect}
                access={access}
              />
            ) : (
              <CircularProgress className={classes.progress} />
            ))}
          {this.state.activeTab === 2 &&
            (!!originalCredentials ? (
              <UserStats userStats={user_stats} />
            ) : (
              <CircularProgress className={classes.progress} />
            ))}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Close
          </Button>
          {access.update && (
            <div className={classes.buttonWrapper}>
              <Button
                onClick={this.validateForm}
                color="secondary"
                disabled={loading || !editedFields.length}
              >
                Update
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ConnectedUserCard.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleEditUser: PropTypes.func.isRequired,
  dialogCardOpen: PropTypes.bool.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

const UserCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUserCard);

export default withStyles(styles)(UserCard);
