import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import {
  CheckCircleOutline,
  HighlightOff,
  MoreHoriz,
  FeaturedPlayList,
  LibraryMusic,
  GraphicEq,
  People,
  Schedule,
} from "@material-ui/icons";
import { titleCase } from "../../../helpers/SharedFunctions";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    position: "relative",
  },
  progressWrapper: {
    padding: theme.spacing(0.25),
    width: `24px`,
    height: `24px`,
  },
  iconWrapper: {
    marginLeft: theme.spacing(4),
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  progress: {
    color: theme.palette.text.primary,
  },
  nestedWrapper: {
    padding: 0,
  },
}));

const Processing = (props) => {
  const { processing, groupState, stemLibraries } = props;
  const classes = useStyles();

  const getIcon = (status) => {
    switch (status) {
      case "pending":
        return <MoreHoriz />;
      case "processing":
        return (
          <div className={classes.progressWrapper}>
            <CircularProgress size={20} className={classes.progress} />
          </div>
        );
      case "success":
        return <CheckCircleOutline color="secondary" />;
      case "fail":
        return <HighlightOff />;
      default:
        return <MoreHoriz />;
    }
  };

  const getCatalogIcon = (id) => {
    const catalog = processing.catalogs[id];
    if (!catalog) {
      return <MoreHoriz />;
    }
    return getIcon(catalog.status);
  };

  const getStemIcon = (stemLibraryId) => {
    const stemLibraryItem = processing.stemLibraryFiles[stemLibraryId];
    if (!stemLibraryItem) {
      return <MoreHoriz />;
    }
    return getIcon(stemLibraryItem.status);
  };

  const getBuildIcon = (catalogId) => {
    const buildItem = processing.buildSchedule[catalogId];
    if (!buildItem) {
      return <MoreHoriz />;
    }
    return getIcon(buildItem.status);
  };

  return (
    <div className={classes.wrapper}>
      <Paper>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <FeaturedPlayList />
            </ListItemIcon>
            <ListItemText primary="Submitting metadata" />
            <ListItemIcon className={classes.iconWrapper}>
              {getIcon(processing.group.status)}
            </ListItemIcon>
          </ListItem>
          {!!groupState.users.length && (
            <ListItem>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary="Adding users" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.users.status)}
              </ListItemIcon>
            </ListItem>
          )}
          {!!groupState.catalogs.length &&
            groupState.catalogs.map((catalog, index) => (
              <div key={index}>
                <ListItem>
                  <ListItemIcon>
                    <LibraryMusic />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Attaching catalog: "${titleCase(
                      catalog.catalog_name
                    )}"`}
                  />
                  <ListItemIcon className={classes.iconWrapper}>
                    {getCatalogIcon(catalog.catalog_id)}
                  </ListItemIcon>
                </ListItem>
                <List dense className={classes.nestedWrapper}>
                  <div>
                    {Object.keys(groupState.stemLibraryFiles).length &&
                      Object.entries(groupState.stemLibraryFiles).map(
                        ([index, stem]) =>
                          parseInt(stem.catalog_id) ===
                            parseInt(catalog.catalog_id) && (
                            <ListItem className={classes.nested} key={index}>
                              <ListItemIcon>
                                <GraphicEq />
                              </ListItemIcon>
                              <ListItemText
                                primary={`Processing library file: "${
                                  stemLibraries[stem.catalog_id][
                                    stem.stem_library_id
                                  ].stem_library_name
                                } - ${
                                  stemLibraries[stem.catalog_id][
                                    stem.stem_library_id
                                  ].stem_library_type
                                }"`}
                              />
                              <ListItemIcon className={classes.iconWrapper}>
                                {getStemIcon(stem.stem_library_id)}
                              </ListItemIcon>
                            </ListItem>
                          )
                      )}
                    <ListItem className={classes.nested}>
                      <ListItemIcon>
                        <Schedule />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Scheduling build: "${titleCase(
                          catalog.catalog_name
                        )}"`}
                      />
                      <ListItemIcon className={classes.iconWrapper}>
                        {getBuildIcon(catalog.catalog_id)}
                      </ListItemIcon>
                    </ListItem>
                  </div>
                </List>
              </div>
            ))}
        </List>
      </Paper>
    </div>
  );
};

Processing.propTypes = {
  processing: PropTypes.object.isRequired,
  groupState: PropTypes.object.isRequired,
  stemLibraries: PropTypes.object.isRequired,
};

export default Processing;
