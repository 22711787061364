export const Keys = [
	{ value: "ab", display: "A♭" },
	{ value: "abm", display: "A♭m" },
	{ value: "a", display: "A" },
	{ value: "am", display: "Am" },
	{ value: "a#", display: "A♯" },
	{ value: "a#m", display: "A♯m" },
	{ value: "bb", display: "B♭" },
	{ value: "bbm", display: "B♭m" },
	{ value: "b", display: "B" },
	{ value: "bm", display: "Bm" },
	{ value: "cb", display: "C♭" },
	{ value: "cbm", display: "C♭m" },
	{ value: "c", display: "C" },
	{ value: "cm", display: "Cm" },
	{ value: "c#", display: "C♯" },
	{ value: "c#m", display: "C♯m" },
	{ value: "db", display: "D♭" },
	{ value: "dbm", display: "D♭m" },
	{ value: "d", display: "D" },
	{ value: "dm", display: "Dm" },
	{ value: "d#", display: "D♯" },
	{ value: "d#m", display: "D♯m" },
	{ value: "eb", display: "E♭" },
	{ value: "ebm", display: "E♭m" },
	{ value: "e", display: "E" },
	{ value: "em", display: "Em" },
	{ value: "fb", display: "F♭" },
	{ value: "fbm", display: "F♭m" },
	{ value: "f", display: "F" },
	{ value: "fm", display: "Fm" },
	{ value: "f#", display: "F♯" },
	{ value: "f#m", display: "F♯m" },
	{ value: "gb", display: "G♭" },
	{ value: "gbm", display: "G♭m" },
	{ value: "g", display: "G" },
	{ value: "gm", display: "Gm" },
	{ value: "g#", display: "G♯" },
	{ value: "g#m", display: "G♯m" },
	{ value: "n", display: "None" },
];
