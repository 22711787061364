import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	notchedOutline: {},
	focused: {
		"& $notchedOutline": {
			borderColor: `${theme.palette.action.active} !important`,
		},
	},
	formLabelFocused: {
		color: `${theme.palette.text.primary} !important`,
	},
	formLabelRoot: {},
	dense:{
		margin: 0
	},
	root:{}
}));

const CustomizedTextField = (props) => {
	const {
		id,
		label,
		value,
		handleOnChange,
		handleOnBlur,
		required,
		fullWidth,
		placeholder,
		select,
		disabled,
		selectProps,
		type,
		multiline,
		maxRows,
		inputProps,
		inputRef,
		handleOnFocus,
		margin,
	} = props;

	const classes = useStyles();

	return (
		<TextField
			required={required}
			fullWidth={fullWidth}
			id={id}
			select={select}
			multiline={multiline}
			maxRows={maxRows}
			label={label}
			value={value}
			type={type}
			className={margin === 'dense' ? classes.dense : classes.root}
			placeholder={placeholder}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			disabled={disabled}
			SelectProps={selectProps}
			margin={margin ? margin : `normal`}
			variant="outlined"
			inputRef={inputRef}
			onFocus={handleOnFocus}
			InputLabelProps={{
				classes: {
					root: classes.formLabelRoot,
					focused: classes.formLabelFocused,
				},
			}}
			InputProps={{
				...inputProps,
				classes: {
					notchedOutline: classes.notchedOutline,
					focused: classes.focused,
				},
			}}
		>
			{props.children}
		</TextField>
	);
};

CustomizedTextField.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]).isRequired,
	handleOnChange: PropTypes.func,
	handleOnBlur: PropTypes.func,
	required: PropTypes.bool,
	fullWidth: PropTypes.bool,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	select: PropTypes.bool,
	disabled: PropTypes.bool,
	selectProps: PropTypes.object,
	type: PropTypes.string,
	multiline: PropTypes.bool,
	maxRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputProps: PropTypes.object,
	inputRef: PropTypes.object,
	handleOnFocus: PropTypes.func,
};

export default CustomizedTextField;
