import React, { useState } from "react";
import { makeStyles, TableCell } from "@material-ui/core";
import {
  TimerOff,
  Stop,
  CheckCircle,
  Cancel,
  Autorenew,
  Timer,
} from "@material-ui/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ActionButton from "../../library/ActionButton";
import { setSnackbarVisibility } from "../../actions/index";
import Skeleton from "@material-ui/lab/Skeleton";
import { editBuild } from "../../helpers/ApiCalls";
import Confirmation from "../../library/Confirmation";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  skeletonWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
  skeleton: {
    margin: "0px auto",
  },
  rotateIcon: {
    animation: "spin 4s linear infinite",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
  };
};

const mapStateToProps = (state) => {
  return {
    searching: state.searching,
    skinConfig: state.skinConfig,
  };
};

const ConnectedBuildListActions = (props) => {
  const {
    className,
    data,
    searching,
    skinConfig,
    doSearch,
    setSnackbarVisibility,
  } = props;
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = async () => {
    const skinId = skinConfig.skin_id;
    const buildId = data.build_id;
    const postData = { build_status: 3 };
    let snackbarContent = {
      open: true,
      message: "An error occured. Please contact support",
    };
    if (!(await editBuild(skinId, buildId, postData))) {
      setSnackbarVisibility(snackbarContent);
      setConfirmationOpen(false);
      return false;
    }
    doSearch();
    setConfirmationOpen(false);
    snackbarContent.message = "Update Successful";
    setSnackbarVisibility(snackbarContent);
  };

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  const handleBuildAction = async () => {
    setConfirmationOpen(true);
    return false;
  };

  const toggleHover = () => {
    setHover(!hover);
  };

  const getActionButton = () => {
    let icon;
    let disabled = true;
    let label = '';
    switch (parseInt(data.build_status)) {
      case 0:
        icon = <CheckCircle />;
        label = "Ended";
        break;
      case 1:
        icon = hover ? <TimerOff /> : <Timer />;
        disabled = false;
        label = "Cancel";
        break;
      case 2:
        icon = hover ? <Stop /> : <Autorenew className={classes.rotateIcon} />;
        disabled = false;
        label = "Stop";
        // progress = true;
        break;
      case 3:
        icon = <Cancel />;
        label = "Cancelled";
        break;
      default:
        icon = <Cancel />;
    }

    return (
      <ActionButton
        clickAction={handleBuildAction}
        label={label}
        disabled={disabled}
      >
        {icon}
      </ActionButton>
    );
  };

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, this scheduled build will be cancelled.`}
        open={confirmationOpen}
      />
      <TableCell align={"right"} className={className}>
        {!searching ? (
          <div
            className={classes.buttonWrapper}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
          >
            {getActionButton()}{" "}
            <style>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </div>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Skeleton
              className={classes.skeleton}
              variant="circle"
              width={24}
              height={24}
            />
          </div>
        )}
      </TableCell>
    </React.Fragment>
  );
};

ConnectedBuildListActions.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  skinConfig: PropTypes.object.isRequired,
  doSearch: PropTypes.func.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
};

const BuildListActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBuildListActions);

export default BuildListActions;
