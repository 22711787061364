import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";
import { translateKey } from "../../../../helpers/SharedFunctions";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "stem_library_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "stem_library_type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "stem_library_key",
    numeric: false,
    disablePadding: false,
    label: "Key",
  },
  {
    id: "stem_library_bpm",
    numeric: true,
    disablePadding: false,
    label: "Tempo",
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  table: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: "100vw",
  },
  tableWrapper: {
    flex: 1,
    position: "relative",
    maxWidth: "100vw",
  },
  customTableContainer: {
    height: "100%",
    overflowY: "auto",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    "&:focus": {
      outline: "none",
    },
  },
  tableRoot: {
    maxWidth: "100vw",
    width: "100%",
    tableLayout: "fixed",
    backgroundColor: "none",
  },
  largeCell: {
    width: "25%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

const StemLibraryList = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("stem_library_name");
  const { stemLibrary, selected, setSelected } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.table}>
        <TableContainer className={classes.customTableContainer}>
          <Table stickyHeader className={classes.tableRoot}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(stemLibrary, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.stem_library_id);
                  const labelId = `stemLibrary-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.stem_library_id)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.stem_library_id}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        className={classes.largeCell}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.stem_library_name}
                      </TableCell>
                      <TableCell
                        className={classes.largeCell}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.stem_library_type}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {translateKey(row.stem_library_key)}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="right"
                      >
                        {Math.round(row.stem_library_bpm)}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

StemLibraryList.propTypes = {
  stemLibrary: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default StemLibraryList;
