import React from "react";
import PropTypes from "prop-types";
import CustomizedTextField from "../../library/CustomizedTextField";

const Metadata = (props) => {
  const { data, handleChange, handleBlur, disabled, access } = props;
  const { catalog_name } = data;

  return (
    <React.Fragment>
      <CustomizedTextField
        required
        fullWidth
        id="catalogName"
        label="Name"
        value={catalog_name}
        handleOnChange={handleChange("catalog_name")}
        handleOnBlur={handleBlur("catalog_name")}
        disabled={disabled || !access.update}
        handleOnFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
      />
    </React.Fragment>
  );
};

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
};

export default Metadata;
