import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Chip,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox, FiberNew } from "@material-ui/icons";
import {
  sortObject,
  getSha,
  searchAssociativeArray,
  titleCase,
} from "../helpers/SharedFunctions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.action.active} !important`,
    },
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  formLabelRoot: {},
  chipWrapper: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
  },
}));

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ChipSelector = (props) => {
  const {
    selectedData,
    handleSelect,
    access,
    setSnackbarVisibility,
    fieldName,
    chipType,
    options,
    isAsync,
    asyncFunc,
    setAllData,
    allowCreate,
    loading,
  } = props;
  const allData = sortObject(props.allData, "value");
  const [chipLoading, setChipLoading] = useState(false);
  const [query, setQuery] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (!isAsync || !query.length) {
      return;
    }
    const timer = setTimeout(() => {
      searchQuery(query);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const searchQuery = async (query) => {
    setChipLoading(true);
    const results = await asyncFunc(query);
    setAllData(results);
    setChipLoading(false);
  };

  const filterOptions = createFilterOptions(options ? options : {});

  const handleOnChange = (newValue, reason, detail) => {
    let currentSelected = [...selectedData];
    switch (reason) {
      case "create-option":
        if (
          !searchAssociativeArray(
            titleCase(detail.option),
            selectedData,
            "value"
          )
        ) {
          const foundItem = searchAssociativeArray(
            titleCase(detail.option),
            allData,
            "value"
          );
          const chipObject = foundItem
            ? { ...foundItem }
            : { id: getSha(), value: titleCase(detail.option), tagIsNew: true };
          allowCreate && currentSelected.push(chipObject);
        } else {
          setSnackbarVisibility({
            open: true,
            message: `${chipType} already added`,
          });
        }
        break;
      case "select-option":
        if (!searchAssociativeArray(detail.option.id, selectedData, "id")) {
          currentSelected.push({ ...detail.option });
        } else {
          const indexOfObject = currentSelected.findIndex((object) => {
            return object.id === detail.option.id;
          });
          currentSelected.splice(indexOfObject, 1);
        }
        break;
      case "remove-option":
        const indexOfObject = currentSelected.findIndex((object) => {
          return object.id === detail.option.id;
        });
        currentSelected.splice(indexOfObject, 1);
        break;
      case "clear":
        currentSelected = [];
        break;
      default:
        return;
    }
    handleSelect(fieldName, currentSelected);
  };

  const handleOnChangeInput = async (value) => {
    if (value && value.length > 3) {
      setQuery(value);
    }
  };

  return (
    <div className={classes.chipWrapper}>
      <Autocomplete
        multiple
        disabled={!access.update || loading}
        fullWidth
        id="chips-filled"
        onChange={(event, newValue, reason, detail) => {
          handleOnChange(newValue, reason, detail);
        }}
        onInputChange={(event) => handleOnChangeInput(event.target.value)}
        getOptionLabel={(option) => option.value}
        options={allData}
        value={selectedData}
        freeSolo
        filterOptions={filterOptions}
        renderTags={(value, getChipProps) =>
          selectedData.map((option, index) => (
            <Chip
              variant="outlined"
              icon={option.tagIsNew && <FiberNew />}
              label={option.value}
              color={option.tagIsNew ? `secondary` : `default`}
              disabled={loading}
              {...getChipProps({ index })}
            />
          ))
        }
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                !!searchAssociativeArray(option.id, selectedData, "id") ||
                selected
              }
            />
            {option.value}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={`${chipType}s`}
            disabled={loading}
            placeholder={access.update ? `Search ${chipType}s` : ""}
            InputLabelProps={{
              classes: {
                root: classes.formLabelRoot,
                focused: classes.formLabelFocused,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              },
              endAdornment: (
                <React.Fragment>
                  {chipLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

ChipSelector.propTypes = {
  selectedData: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  chipType: PropTypes.string.isRequired,
  options: PropTypes.object,
  setAllData: PropTypes.func,
  asyncFunc: PropTypes.func,
  isAsync: PropTypes.bool,
  allowCreate: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ChipSelector;
