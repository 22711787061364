import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    top: "24px",
    left: "24px",
    right: "24px",
    bottom: "24px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(4),
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const BuildMessage = (props) => {
  const classes = useStyles();
  const { icon, children } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.paperWrapper}>
        <Paper variant="outlined" className={classes.paper}>
          <div className={classes.rowWrapper}>
            <div className={classes.columnWrapper}>{icon}</div>
            <div className={classes.columnWrapper}>{children}</div>
          </div>
        </Paper>
      </div>
      <style>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
              @keyframes blink {
                0% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.2;
                }
                100% {
                  opacity: 1;
                }
              }
            `}</style>
    </div>
  );
};

BuildMessage.propTypes = {
  icon: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default BuildMessage;
