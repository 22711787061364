import React from "react";
import { Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";

const SplashPageHeaderButtons = React.memo((props) => {
  return (
    <div>
      <Button
        color="inherit"
        value="/login"
        component={Link}
        to={{ pathname: "/login", search: props.location.search }}
      >
        Login
      </Button>
    </div>
  );
});

export default withRouter(SplashPageHeaderButtons);
