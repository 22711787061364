import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  InputBase,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";
 
const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      width: "70%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "70%",
    },
  },
  inputField: {
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  inputWrapper: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  iconButtonWrapper: {
    position: "relative",
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
  disabled: {
    color: "grey",
  },
}));

const SearchBar = (props) => {
  const { activeSearch, searching, mobile, submitSearch } = props;
  const [query, setQuery] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setQuery(activeSearch.query);
  }, [activeSearch]);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const catchEnter = (event) => {
    if (event.key === "Enter") {
      submitSearch(query);
    }
  };

  const searchInput = useRef(null);

  const clearQuery = () => {
    setQuery("");
    searchInput.current.focus();
  };

  return (
    <div className={classes.searchWrapper}>
      {!mobile && (
        <div className={classes.iconButtonWrapper}>
          <Tooltip title="Search">
            <span>
              <IconButton
                aria-label="Search"
                onClick={() => submitSearch(query)}
                disabled={query === activeSearch.query || searching}
                classes={{
                  root: classes.iconButton,
                  disabled: classes.disabled,
                }}
              >
                <Search />
              </IconButton>
            </span>
          </Tooltip>
          {searching && (
            <CircularProgress
              color="inherit"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </div>
      )}
      <div className={classes.inputWrapper}>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputField,
            disabled: classes.disabled,
          }}
          fullWidth
          placeholder="Search…"
          value={query}
          onKeyPress={catchEnter}
          onChange={handleChange}
          disabled={searching}
          inputRef={searchInput}
        />
      </div>
      <div className={classes.iconButtonWrapper}>
        <Tooltip title="Clear">
          <span>
            <IconButton
              aria-label="Clear"
              onClick={clearQuery}
              disabled={searching}
              className={!query.length ? classes.hidden : null}
              color="inherit"
            >
              <Clear />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  activeSearch: PropTypes.object.isRequired,
  searching: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  submitSearch: PropTypes.func.isRequired,
};

export default SearchBar;
