import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { Button } from "@material-ui/core";
import { withTheme, makeStyles } from "@material-ui/core";
import { searchArray } from "../helpers/SharedFunctions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSearch } from "../actions/index";
import { emitEvent } from "../helpers/ApiCalls";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
    "&::before": {
      content: '""',
      float: "left",
      paddingTop: "100%",
    },
  },
  clearButton: {
    float: "right",
  },
}));

const major = [
  { code: "d", note: "d", step: 0, scale: "major", value: 30 },
  { code: "g", note: "g", step: 0, scale: "major", value: 30 },
  { code: "c", note: "c", step: 0, scale: "major", value: 30 },
  { code: "f", note: "f", step: 0, scale: "major", value: 30 },
  { code: "bb", note: "b", step: -1, scale: "major", value: 30 },
  { code: "eb", note: "e", step: -1, scale: "major", value: 30 },
  { code: "ab", note: "a", step: -1, scale: "major", value: 30 },
  { code: "c#", note: "c", step: 1, scale: "major", value: 30 },
  { code: "f#", note: "f", step: 1, scale: "major", value: 30 },
  { code: "b", note: "b", step: 0, scale: "major", value: 30 },
  { code: "e", note: "e", step: 0, scale: "major", value: 30 },
  { code: "a", note: "a", step: 0, scale: "major", value: 30 },
];
const minor = [
  { code: "bm", note: "b", step: 0, scale: "minor", value: 30 },
  { code: "em", note: "e", step: 0, scale: "minor", value: 30 },
  { code: "am", note: "a", step: 0, scale: "minor", value: 30 },
  { code: "dm", note: "d", step: 0, scale: "minor", value: 30 },
  { code: "gm", note: "g", step: 0, scale: "minor", value: 30 },
  { code: "cm", note: "c", step: 0, scale: "minor", value: 30 },
  { code: "fm", note: "f", step: 0, scale: "minor", value: 30 },
  { code: "bbm", note: "b", step: -1, scale: "minor", value: 30 },
  { code: "ebm", note: "e", step: -1, scale: "minor", value: 30 },
  { code: "g#m", note: "g", step: 1, scale: "minor", value: 30 },
  { code: "c#m", note: "c", step: 1, scale: "minor", value: 30 },
  { code: "f#m", note: "f", step: 1, scale: "minor", value: 30 },
];

const mapStateToProps = (state) => {
  return {
    activeSearch: state.activeSearch,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const ConnectedKeySelector = React.memo((props) => {
  const { updateSearch, activeSearch, theme, userData } = props;
  const { selected } = theme.palette.action;
  const classes = useStyles();

  const onKeyClick = (data, index) => {
    const newState = { ...activeSearch };
    if (!searchArray(newState.keys, data.code)) {
      emitEvent("keyWheelClickV1", {
        key: data.code,
        user_id: userData.user_id,
      });
      newState.keys.push(data.code);
    } else {
      const index = newState.keys.indexOf(data.code);
      if (index > -1) {
        newState.keys.splice(index, 1);
      }
    }
    updateSearch(newState);
  };

  const handleClear = () => {
    const newState = { ...activeSearch };
    newState.keys = [];
    updateSearch(newState);
  };

  const keyStepTranslator = (step) => {
    switch (step) {
      case -1:
        return "♭";
      case 0:
        return "";
      case 1:
        return "♯";
      default:
        return "";
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    note,
    step,
    scale,
    data,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill={theme.palette.text.primary}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          letterSpacing: "-1px",
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        {note.toUpperCase()}
        <tspan
          style={{
            fontSize: "50%",
            verticalAlign: "text-top",
            letterSpacing: "-1px",
          }}
        >
          {keyStepTranslator(step)}
        </tspan>
        <tspan style={{ fontSize: "75%", letterSpacing: "-1px" }}>
          {scale === "minor" ? "m" : ""}
        </tspan>
      </text>
    );
  };

  return (
    <div className={classes.container}>
      <PieChart width={207} height={207}>
        <Pie
          onClick={onKeyClick}
          data={major}
          dataKey="value"
          innerRadius={78}
          outerRadius={103}
          fill="transparent"
          labelLine={false}
          label={renderCustomizedLabel}
          cursor="pointer"
          isAnimationActive={false}
          stroke={theme.palette.text.primary}
        >
          {major.map((data, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                searchArray(activeSearch.keys, data.code)
                  ? selected
                  : "transparent"
              }
            />
          ))}
        </Pie>
        <Pie
          onClick={onKeyClick}
          data={minor}
          dataKey="value"
          innerRadius={35}
          outerRadius={73}
          labelLine={false}
          label={renderCustomizedLabel}
          cursor="pointer"
          isAnimationActive={false}
          stroke={theme.palette.text.primary}
        >
          {minor.map((data, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                searchArray(activeSearch.keys, data.code)
                  ? selected
                  : "transparent"
              }
            />
          ))}
        </Pie>
      </PieChart>
      {!!activeSearch.keys.length && (
        <Button onClick={handleClear} className={classes.clearButton}>
          Clear
        </Button>
      )}
    </div>
  );
});

ConnectedKeySelector.propTypes = {
  activeSearch: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  updateSearch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

const KeySelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedKeySelector);

export default withTheme(KeySelector);
