import Axios from "axios";
import store from "../store/index.js";

export const getUserBalance = (userId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/api/users/${userId}/balance?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.users.user_balance_amount;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getRoles = () => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/roles?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.roles;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getTerms = (agreementId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/agreements/${agreementId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.agreements;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAcceptance = (skinId, agreementId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/agreements/${agreementId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.acceptances;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const acceptTerms = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/agreements?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllCredits = () => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/credits?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMasterCredits = (masterId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/works?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.credits;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getStemLibrary = (catalogId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}/stem_library?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.stem_library;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getGroupLibrary = (groupId, catalogId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/${catalogId}/stem_library?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.stem_library;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getStemLibraryFile = (stemLibraryId, groupId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/stem_library/${stemLibraryId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.stem_library_files;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllGroups = () => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.groups;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getGroupList = (queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getGroupUsers = (groupId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/users?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getUser = (userId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.users;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getUserCart = (userId, groupId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}/groups/${groupId}/cart?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.cart;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const registerTagClick = (tagObj) => {
	const currentState = store.getState();
	const userId = currentState.userData.user_id;
	tagObj.user_id = userId;
	const url = `${process.env.REACT_APP_API_HOST}/click/tag?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, tagObj)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const registerDownload = (groupId, userId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/download?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, {
		user_id: userId,
		group_id: groupId,
		version_id: versionId,
	})
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const setNewUserBalance = (userId, adjustment) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/api/users/${userId}/balance?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, {
		user_id: userId,
		adjustment: adjustment,
	})
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const updateUser = (userId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const updateGroup = (groupId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getSkinConfig = (siteData) => {
	const url = `${process.env.REACT_APP_API_HOST}/skins?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}`;
	return Axios.post(url, siteData)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.skins;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const setSkinConfig = (data) => {
	const url = `${process.env.REACT_APP_API_HOST}/skins?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}`;
	return Axios.put(url, data)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.skins;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getVersions = (groupId, masterId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/content/${masterId}/versions?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.versions;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getCreditInfo = (groupId, masterId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/content/${masterId}/credits?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.credits;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMasterTags = (masterId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/tags?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.tags;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllTags = ($tagId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/tags?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.tags;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getTrendingTags = (catalogId, sort) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}/tags?sort=${sort}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${catalogId}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.tags;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMixes = (masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.mixes;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllVersions = (masterId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.versions;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getWorks = (queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/works?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createRegisteredWork = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/works?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createCredit = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/credits/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createCreditRelationship = (workId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/works/${workId}/credits/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createWorkMasterRelationship = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/works/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const removeWorkMasterRelationship = (masterId, workId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/works/${workId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteStemLibraryFiles = (groupId, catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/${catalogId}/stem_library?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteGroupCatalogRelationship = (groupId, catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/${catalogId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteGroupCatalogContent = (groupId, catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/${catalogId}/content?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createGroupCatalogRelationship = (groupId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const packageCartFiles = (cart) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/api/download/cart?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, cart)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.versions;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const addItemToCart = (userId, groupId, cartItem) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}/groups/${groupId}/cart?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, {
		user_id: userId,
		group_id: groupId,
		version_id: cartItem.version_id,
	})
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createMaster = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/create?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createGroup = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createUser = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/create?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editMaster = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editGroup = (groupId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editCatalog = (catalogId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editStemLibrary = (catalogId, stemLibraryId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}/stem_library/${stemLibraryId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createVersion = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createMasterBuildEntry = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/build/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMasterBuilds = (skinId, masterId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/content/${masterId}/build?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.builds;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getGroupBuilds = (skinId, groupId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/groups/${groupId}/build?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.builds;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllBuilds = (skinId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/build?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteBuild = (skinId, buildId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/build/${buildId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editBuild = (skinId, buildId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/build/${buildId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createGroupBuildEntry = (groupId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/build/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createTagRelationship = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/tags/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const removeTagRelationship = (masterId, tagId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/tags/${tagId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createUserRelationship = (groupId, userId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/users/${userId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const removeUserRelationship = (groupId, userId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/users/${userId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createCatalogRelationship = (groupId, catalogId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs/${catalogId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createTag = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/tags/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editVersion = (masterId, versionId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createMix = (masterId, versionId, postData) => {
	const currentState = store.getState();
	const blob = postData.blob;
	let formData = new FormData();
	formData.append("mix", JSON.stringify(postData));
	formData.append("file", blob);
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, formData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createStem = (masterId, versionId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stems/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createStemLibraryFile = (stemLibraryId, groupId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/stem_library/${stemLibraryId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteStemLibraryFile = (groupId, stemLibraryId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/stem_library/${stemLibraryId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createStemRelationship = (masterId, versionId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stem_library/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editStemRelationship = (masterId, versionId, relId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stem_library/${relId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteStemRelationship = (masterId, versionId, relId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stem_library/${relId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editStem = (masterId, versionId, stemId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stems/${stemId}/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const uploadFile = (postData) => {
	const skinId = postData.skinId;
	const asset = postData.asset;
	const assetBlob = postData.assetBlob;
	const extension = postData.extension;
	let formData = new FormData();
	formData.append("file", assetBlob, `.${extension}`);
	formData.append("asset", asset);
	formData.append("skin_id", skinId);
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/assets?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}`;
	return Axios.post(url, formData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteFile = (postData) => {
	const skinId = postData.skinId;
	const data = { data: postData };
	const url = `${process.env.REACT_APP_API_HOST}/skins/${skinId}/assets?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}`;
	return Axios.delete(url, data)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const updateMix = (masterId, postData) => {
	const currentState = store.getState();
	const versionId = postData.versionId;
	const mixId = postData.mixId;
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes/${mixId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteMix = (masterId, versionId, mixId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes/${mixId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteMixes = (masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const updateVersion = (masterId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteVersion = (masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const deleteStem = (masterId, versionId, stemId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stems/${stemId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const removeItemFromCart = (userId, groupId, cartItemId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}/groups/${groupId}/cart/${cartItemId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const clearUserCart = (userId, groupId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}/groups/${groupId}/cart?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getStems = (groupId, masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/content/${masterId}/versions/${versionId}/stems?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getVersionStems = (masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/stems?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getVersionStemsLibraryFiles = (groupId, masterId, versionId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/content/${masterId}/versions/${versionId}/stem_library?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMixStems = (masterId, versionId, mixId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}/versions/${versionId}/mixes/${mixId}/stems?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.stems;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getCategories = (catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}/categories?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${currentState.catalog}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.categories;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllCategories = () => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/categories?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${currentState.catalog}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const editCategory = (categoryId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/categories/${categoryId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.put(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const createCategoryRelationship = (categoryId, catalogId, postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/categories/${categoryId}/catalogs/${catalogId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const removeCategoryRelationship = (categoryId, catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/categories/${categoryId}/catalogs/${catalogId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.delete(url)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAllCatalogs = (queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${currentState.catalog}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getCatalogs = (groupId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/catalogs?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${currentState.catalog}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.catalogs;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getCatalogsByCategory = (categoryId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/categories/${categoryId}/catalogs?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}&catalog=${currentState.catalog}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.catalogs;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getContent = (groupId, queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/groups/${groupId}/content?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMasters = (queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getUsers = (queryString) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users?${queryString}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getMasterById = (masterId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/content/${masterId}?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.content;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getGroups = (userId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/${userId}/groups?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.groups;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const getAvailableGroups = (catalogId) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/catalogs/${catalogId}/groups?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data.result.groups;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const checkTokenExpiry = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/index.php/login/check_token_expiry?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const checkTokenValid = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/index.php/login/check_token_valid?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const login = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/login/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const emitEvent = (eventName, payload) => {
	const currentState = store.getState();
	const eventObj = {};
	eventObj.Detail = JSON.stringify(payload);
	eventObj.DetailType = eventName;
	eventObj.Source = currentState.skinConfig.skin_hash;
	const url = `${process.env.REACT_APP_API_HOST}/analytics/?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, eventObj)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const changePassword = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/password?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const resetPassword = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/password/reset?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const resetPasswordRequest = (postData) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/password/reset_request?api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.post(url, postData)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};

export const validateAccessToken = (token) => {
	const currentState = store.getState();
	const url = `${process.env.REACT_APP_API_HOST}/users/validation?token=${token}&api_key=${process.env.REACT_APP_API_KEY}&api_id=${process.env.REACT_APP_API_ID}&product=${currentState.skinConfig.skin_hash}`;
	return Axios.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		})
		.catch((error) => {});
};
