import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
	TableContainer,
	Table,
	TablePagination,
	makeStyles,
} from "@material-ui/core";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableBody from "./EnhancedTableBody";

const useStyles = makeStyles((theme) => ({
	table: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		maxWidth: "100vw",
	},
	tableWrapper: {
		flex: 1,
		position: "relative",
		maxWidth: "100vw",
	},
	customTableContainer: {
		height: "100%",
		overflowY: "auto",
		width: "100%",
		position: "relative",
		overflow: "hidden",
		"&:focus": {
			outline: "none",
		},
	},
	tableRoot: {
		maxWidth: "100vw",
		width: "100%",
		tableLayout: "fixed",
	},
	pagination: {
		overflow: "hidden",
	},
}));

const EnhancedTable = (props) => {
	const {
		items,
		columns,
		handleRowClick,
		formatData,
		selectedRow,
		numberOfActions,
		children,
		classNames,
		idField,
		orderBy,
		orderDir,
		handleArrowNav,
		totalItems,
		rowsPerPage,
		setRowsPerPage,
		page,
		setPage,
		searching,
		setOrderDir,
		setOrderBy,
	} = props;
	const classes = { ...classNames, ...useStyles() };

	const lastPage = useMemo(() => {
		return Math.max(0, Math.ceil(totalItems / rowsPerPage) - 1);
	}, [totalItems, rowsPerPage]);

	const handleRequestSort = (event, property) => {
		let direction = "desc";
		if (orderBy === property && orderDir === "desc") {
			direction = "asc";
		}
		setOrderDir(direction);
		setOrderBy(property);
	};

	const handleChangePage = (event, page) => {
		setPage(page);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
	};

	return (
		<React.Fragment>
			<div className={classes.tableWrapper}>
				<div className={classes.table}>
					<TableContainer className={classes.customTableContainer}>
						<Table
							aria-labelledby="tableTitle"
							stickyHeader
							className={classes.tableRoot}
						>
							<EnhancedTableHead
								numberOfActions={numberOfActions}
								orderDir={orderDir}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={items.length}
								columns={columns}
								classNames={classNames}
							/>

							<EnhancedTableBody
								orderDir={orderDir}
								orderBy={orderBy}
								columns={columns}
								rowsPerPage={rowsPerPage}
								page={page}
								items={items}
								formatData={formatData}
								handleRowClick={handleRowClick}
								selectedRow={selectedRow}
								actions={children}
								classNames={classNames}
								idField={idField}
								handleArrowNav={handleArrowNav}
								handleChangePage={handleChangePage}
								totalItems={totalItems}
								lastPage={lastPage}
							/>
						</Table>
					</TableContainer>
				</div>
			</div>
			<div>
				<TablePagination
					rowsPerPageOptions={[25, 50, 75]}
					component="div"
					count={totalItems}
					rowsPerPage={rowsPerPage}
					page={items.length ? page : 0}
					backIconButtonProps={{
						"aria-label": "Previous Page",
						disabled: page === 0 || searching,
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page",
						disabled: page === lastPage || searching,
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					className={classes.pagination}
				/>
			</div>
		</React.Fragment>
	);
};

EnhancedTable.propTypes = {
	handleRowClick: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	formatData: PropTypes.func.isRequired,
	selectedRow: PropTypes.number,
	idField: PropTypes.string.isRequired,
	totalItems: PropTypes.number.isRequired,
	handleArrowNav: PropTypes.func.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	setRowsPerPage: PropTypes.func.isRequired,
	page: PropTypes.number,
	setPage: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	numberOfActions: PropTypes.number.isRequired,
	children: PropTypes.object,
	classNames: PropTypes.object,
	orderBy: PropTypes.string.isRequired,
	orderDir: PropTypes.string.isRequired,
	setOrderDir: PropTypes.func.isRequired,
	setOrderBy: PropTypes.func.isRequired,
};

export default EnhancedTable;
