import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles, IconButton } from "@material-ui/core";
import UserCard from "./user_card/UserCard";
import { getUsers, updateUser } from "../../helpers/ApiCalls";
import EnhancedTable from "../../library/EnhancedTable";
import {
	titleCase,
	formatDate,
	createSkeletonData,
} from "../../helpers/SharedFunctions";
import { connect } from "react-redux";
import {
	setSnackbarVisibility,
	setSearchState,
	setDialogVisibility,
	resetSearch,
} from "../../actions/index";
import { Add } from "@material-ui/icons";
import UserBuilder from "./wizard/UserBuilder";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
		position: "relative",
	},
	addButton: {
		position: "absolute",
		bottom: 0,
		left: 0,
	},
	nameColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "23%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "20%",
		},
		[theme.breakpoints.only("lg")]: {
			width: "15%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "12%",
		},
		paddingLeft: theme.spacing(1),
	},
	emailColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "42%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "30%",
		},
		[theme.breakpoints.only("lg")]: {
			width: "20%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "20%",
		},
	},
	statusColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "12%",
			flexDirection: "row",
		},
		[theme.breakpoints.up("sm")]: {
			width: "15%",
			flexDirection: "row",
		},
		[theme.breakpoints.only("lg")]: {
			width: "10%",
			flexDirection: "row-reverse",
		},
		[theme.breakpoints.only("xl")]: {
			width: "8%",
			flexDirection: "row-reverse",
		},
	},
	roleColumn: {
		[theme.breakpoints.up("sm")]: {
			width: "15%",
		},
		[theme.breakpoints.only("lg")]: {
			width: "10%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "12%",
		},
	},
	dateColumn: {
		[theme.breakpoints.only("lg")]: {
			width: "15%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "12%",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		searching: state.searching,
		activeSearch: state.activeSearch,
		userData: state.userData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSnackbarVisibility: (snackbarContent) =>
			dispatch(setSnackbarVisibility(snackbarContent)),
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
		setDialogVisibility: (dialogContent) =>
			dispatch(setDialogVisibility(dialogContent)),
		resetSearch: () => dispatch(resetSearch()),
	};
};

const ConnectedUserList = (props) => {
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("user_created_date");
	const [dialog, setDialog] = useState({ open: false, type: null });
	const [access, setAccess] = useState({
		create: false,
		read: false,
		update: false,
		delete: false,
	});
	const classes = useStyles();
	const {
		setSnackbarVisibility,
		permissions,
		setSearchState,
		searching,
		setDialogVisibility,
		resetSearch,
		activeSearch,
		userData,
	} = props;
	const {
		nameColumn,
		emailColumn,
		mdHidden,
		dateColumn,
		lgHidden,
		xsHidden,
		roleColumn,
		statusColumn,
	} = classes;

	const handleStatusChange = (item) => {
		const found = items.findIndex((x) => x.user_id === item.user_id);
		let currentItems = [...items];
		const status = (+!Boolean(parseInt(item.user_status))).toString();
		currentItems[found].user_status = status;
		const originalDate = currentItems[found].user_modified_date;
		currentItems[found].user_modified_date = new Date()
			.toISOString()
			.slice(0, 19)
			.replace("T", " ");
		setItems(currentItems);
		const postData = { user_status: status };
		updateUser(item.user_id, postData).then((res) => {
			let message;
			if (res && res.data && res.data.result) {
				message = "Update successful";
			} else {
				message = "An error occured. Please contact support";
				currentItems[found].user_status = (+!Boolean(
					parseInt(status)
				)).toString();
				setItems(currentItems);
				currentItems[found].user_modified_date = originalDate;
			}
			setSnackbarVisibility({
				open: true,
				message: message,
			});
		});
	};

	const isDisabled = (item) => {
		return parseInt(userData.role_id) !== 5 && parseInt(item.role_id) === 5;
	};

	const columns = [
		{
			id: "user_first_name",
			numeric: false,
			disablePadding: false,
			label: "First Name",
			classes: [nameColumn],
		},
		{
			id: "user_last_name",
			numeric: false,
			disablePadding: false,
			label: "Last Name",
			classes: [nameColumn],
		},

		{
			id: "user_email",
			numeric: false,
			disablePadding: false,
			label: "Email",
			classes: [emailColumn],
		},
		{
			id: "user_created_date",
			numeric: false,
			disablePadding: false,
			label: "Created",
			classes: [mdHidden, dateColumn],
		},
		{
			id: "user_modified_date",
			numeric: false,
			disablePadding: false,
			label: "Modified",
			classes: [lgHidden, dateColumn],
		},
		{
			id: "user_expiry_date",
			numeric: false,
			disablePadding: false,
			label: "Expiry",
			classes: [mdHidden, dateColumn],
		},
		{
			id: "role_name",
			numeric: false,
			disablePadding: false,
			label: "Role",
			classes: [xsHidden, roleColumn],
		},
		{
			id: "user_status",
			numeric: true,
			disablePadding: false,
			label: "Status",
			type: "switch",
			classes: [xsHidden, statusColumn],
			fn: handleStatusChange,
			isDisabled: isDisabled,
		},
	];

	useEffect(() => {
		const permissionObj = {
			create: permissions.indexOf(1) > -1,
			read: permissions.indexOf(2) > -1,
			update: permissions.indexOf(3) > -1,
			delete: permissions.indexOf(4) > -1,
		};
		setAccess(permissionObj);
		return function cleanup() {
			resetSearch();
		};
	}, []);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		doSearch();
	}, [rowsPerPage, orderBy, orderDir, activeSearch]);

	useEffect(() => {
		page !== null && doSearch();
	}, [page]);

	const doSearch = () => {
		setItems(createSkeletonData(columns, rowsPerPage));
		setSearchState(true);
		const { query } = activeSearch;
		let queryString = `limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;
		if (query.length) {
			queryString += `&q=${encodeURIComponent(query)}`;
		}

		getUsers(queryString).then((res) => {
			setItems(res.users);
			setTotalItems(res.total);
			setSearchState(false);
		});
	};

	const handleEditUser = (userId, postData) => {
		return updateUser(userId, postData).then((res) => {
			let message;
			if (res && res.data && res.data.result) {
				doSearch();
				message = "Update successful";
			} else {
				message = "An error occured. Please contact support";
			}
			setSnackbarVisibility({
				open: true,
				message: message,
			});
		});
	};

	const openDialog = (type) => {
		const newState = { open: true, type: type };
		setDialog(newState);
	};

	const handleDialogClose = () => {
		const newState = { open: false, type: null };
		setDialog(newState);
		setSelectedUser(null);
		setSelectedRow(null);
	};

	const getCardContent = (type) => {
		switch (dialog.type) {
			case "editUser":
				return (
					<UserCard
						userId={selectedUser}
						handleDialogClose={handleDialogClose}
						handleEditUser={handleEditUser}
						dialogCardOpen={dialog.open}
						access={access}
					/>
				);
			default:
				return;
		}
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	const formatData = (data, field) => {
		switch (field) {
			case "user_first_name":
			case "user_last_name":
			case "role_name":
				return titleCase(data);
			case "user_created_date":
			case "user_modified_date":
			case "user_expiry_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			case "user_status":
				return parseInt(data) ? "Active" : "Inactive";
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedUser(parseInt(value.user_id));
		setSelectedRow(index);
		openDialog("editUser");
	};

	const handleAddButtonClick = () => {
		const dialogContent = {
			open: true,
			body: (
				<UserBuilder
					setSnackbarVisibility={setSnackbarVisibility}
					setDialogVisibility={setDialogVisibility}
					doSearch={doSearch}
					access={access}
				/>
			),
			fullWidth: true,
			fullScreen: "sm",
			minHeight: true,
			maxWidth: "lg",
		};
		setDialogVisibility(dialogContent);
	};

	return (
		access.read && (
			<Paper className={classes.container}>
				{dialog.open ? getCardContent() : null}

				<EnhancedTable
					items={items}
					columns={columns}
					handleRowClick={handleRowClick}
					formatData={formatData}
					selectedRow={selectedRow}
					classNames={classes}
					numberOfActions={0}
					idField={"user_id"}
					orderBy={orderBy}
					orderDir={orderDir}
					handleArrowNav={handleArrowNav}
					totalItems={totalItems}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					page={page}
					setPage={setPage}
					searching={searching}
					setOrderDir={setOrderDir}
					setOrderBy={setOrderBy}
				/>
				{access.create && (
					<IconButton
						color="secondary"
						aria-label="add"
						onClick={handleAddButtonClick}
						className={classes.addButton}
					>
						<Add />
					</IconButton>
				)}
			</Paper>
		)
	);
};

ConnectedUserList.propTypes = {
	setSnackbarVisibility: PropTypes.func.isRequired,
	setDialogVisibility: PropTypes.func.isRequired,
	permissions: PropTypes.array.isRequired,
	setSearchState: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	resetSearch: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

const UserList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedUserList);

export default UserList;
