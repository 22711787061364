import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inputValidation } from "../helpers/SharedFunctions";
import CustomizedTextField from "../library/CustomizedTextField";
import { connect } from "react-redux";
import { setSnackbarVisibility, setDialogVisibility } from "../actions/index";
import { resetPasswordRequest } from "../helpers/ApiCalls";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../library/dialog/CustomDialogContent";

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedForgotPasswordCard = (props) => {
  const {
    setSnackbarVisibility,
    setDialogVisibility,
    history,
    prefill,
  } = props;

  let initialData = {
    email: { value: prefill, valid: false },
    loading: false,
  };

  const [formState, setFormState] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleBlur = (input) => (event) => {
    let valid = false;
    let message = "";
    const inputValue = event.target.value;

    const response = inputValidation(input, inputValue);
    valid = response.isValid;
    message = response.message;

    !valid &&
      setSnackbarVisibility({
        open: true,
        message: message,
      });
    let currentState = { ...formState };
    currentState[input].value = inputValue;
    currentState[input].valid = valid;
    setFormState(currentState);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...formState };
    currentState[input].value = event.target.value;
    setFormState(currentState);
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    history.push("/login" + history.location.search);
    setDialogVisibility(dialogContent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      const fields = ["email"];
      //create empty array to track valid fields
      const formValid = [];
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = formState[field].value;
        const response = inputValidation(field, value);
        if (!response.isValid) {
          setSnackbarVisibility({
            open: true,
            message: response.message,
          });
          setLoading(false);
        }
        //break on invalid field
        if (response.isValid) {
          formValid.push(true);
        } else {
          break;
        }
      }
      //check all fields in step are valid
      if (formValid.length === fields.length) {
        const postData = {
          username: formState.email.value,
        };

        resetPasswordRequest(postData).then((res) => {
          setSnackbarVisibility({
            open: true,
            message: "Password reset request submitted successfully",
          });
          setLoading(false);
          history.push("/login" + history.location.search);
        });
      }
    }
  };

  const actionButtons = [
    {
      label: "Cancel",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Submit",
      color: "secondary",
      type: "submit",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleSubmit,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle title={`Forgot Password`} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <CustomDialogContent>
          If an existing account is attached to the email stated below, we'll
          send a temporary reset password link to that address.
          <CustomizedTextField
            required
            fullWidth
            id={"email"}
            label={"Email"}
            value={formState.email.value}
            handleOnChange={handleChange("email")}
            handleOnBlur={handleBlur("email")}
            placeholder={"Email"}
          />
        </CustomDialogContent>

        <CustomDialogActions
          actionButtons={actionButtons}
          disabled={loading}
          loading={loading}
        />
      </form>
    </React.Fragment>
  );
};

ConnectedForgotPasswordCard.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  prefill: PropTypes.string.isRequired,
};

const ForgotPasswordCard = connect(
  null,
  mapDispatchToProps
)(ConnectedForgotPasswordCard);

export default withRouter(ForgotPasswordCard);
