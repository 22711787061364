import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Header from "./header/Header";
import AppFooter from "./AppFooter";
import Admin from "./admin/Admin";
import SplashPage from "./splash/SplashPage";
import Content from "./content/Content";
import SideBar from "./library/SideBar";
import SideBarContent from "./sidebar/SideBarContent";
import { connect } from "react-redux";
import { updateSearch, setDialogVisibility } from "./actions/index";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AdminSideBarContent from "./admin/AdminSideBarContent";
import ContentHeaderActions from "./header/ContentHeaderActions";
import SplashPageHeaderActions from "./header/SplashPageHeaderActions";
import Search from "./header/Search";
import MyAccount from "./header/MyAccount";

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    isLoggedIn: state.isLoggedIn,
    availableGroups: state.availableGroups,
    activeGroup: state.activeGroup,
    availableCatalogs: state.availableCatalogs,
    activeCatalog: state.activeCatalog,
    dialog: state.dialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (search) => dispatch(updateSearch(search)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const DRAWER_WIDTH = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    height: "100vh",
    maxHeight: "100vh",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.only("xs")]: {
      height: "100dvh",
      maxHeight: "100dvh",
    },
  },
  mainWrapper: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    height: "100%",
  },
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up("xs")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  content: {
    flexGrow: 1,
    position: "relative",
  },
  toolbar: {
    minHeight: 64,
    ...theme.mixins.toolbar,
  },
}));

const ConnectedContentWrapper = (props) => {
  const {
    isLoggedIn,
    skinConfig,
    userData,
    handleLoginState,
    activeGroup,
    location,
    activeCatalog,
    setDialogVisibility,
    dialog,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contentToRender, setContentToRender] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    dialog.open && handleDialogClose();
  }, [isLoggedIn]);

  useEffect(() => {
    setContentToRender(getRoute());
  }, [isLoggedIn, activeGroup, activeCatalog]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isAdmin = () => {
    return (
      parseInt(userData.role_id) === 1 ||
      parseInt(userData.role_id) === 3 ||
      parseInt(userData.role_id) === 5
    );
  };

  const splashPage = {
    sideBar: null,
    main: (
      <SplashPage
        logo={skinConfig.skin_logo}
        setLogInState={handleLoginState}
        skinConfig={skinConfig}
      />
    ),
    header: <SplashPageHeaderActions />,
  };

  const adminPage = {
    sideBar: <AdminSideBarContent />,
    main: <Admin />,
    header: (
      <React.Fragment>
        <Search />
        <MyAccount />
      </React.Fragment>
    ),
  };

  const contentPage = {
    sideBar: activeGroup && activeCatalog ? <SideBarContent /> : null,
    main: <Content />,
    header: <ContentHeaderActions />,
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const getRoute = () => {
    switch (isLoggedIn) {
      case true:
        const path = location.pathname;
        if (path.startsWith("/admin") && isAdmin()) {
          return adminPage;
        } else {
          return contentPage;
        }
      case false:
        return splashPage;
      default:
        return splashPage;
    }
  };

  return (
    <div className={classes.root}>
      {contentToRender && (
        <React.Fragment>
          <Header
            handleDrawerToggle={handleDrawerToggle}
            skinConfig={skinConfig}
            position="fixed"
            className={classes.appBar}
          >
            {contentToRender.header}
          </Header>
          <div className={classes.mainWrapper}>
            <div className={classes.toolbar} />
            {contentToRender.sideBar && (
              <SideBar
                drawerOpen={drawerOpen}
                handleDrawerToggle={handleDrawerToggle}
              >
                {contentToRender.sideBar}
              </SideBar>
            )}
            <main className={classes.content}>{contentToRender.main}</main>
          </div>
          <AppFooter skinTitle={skinConfig.skin_title} position="fixed" />
        </React.Fragment>
      )}
    </div>
  );
};

ConnectedContentWrapper.propTypes = {
  userData: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  availableCatalogs: PropTypes.array.isRequired,
  availableGroups: PropTypes.array.isRequired,
  activeGroup: PropTypes.number,
  activeCatalog: PropTypes.number,
  skinConfig: PropTypes.object.isRequired,
  handleLoginState: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const ContentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedContentWrapper);

export default withRouter(ContentWrapper);
