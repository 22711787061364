import jsonwebtoken from "jsonwebtoken";
import { checkTokenValid } from "./ApiCalls";

export const isAdmin = () => {
	const token = localStorage.getItem("id_token");
	const decodedToken = jsonwebtoken.decode(token, { complete: true });
	return parseInt(decodedToken.payload.role_id) === 1 ? true : false;
};

export const getToken = () => {
	return localStorage.getItem("id_token");
};

export const isTokenValid = (token) => {
	const postData = {
		token: token,
	};
	return checkTokenValid(postData).then((result) => {
		return result.data;
	});
};

export const loggedIn = () => {
	//check for stored token
	const token = getToken();
	//check if token is expired
	return isTokenValid(token).then((isValid) => {
		return !!token && isValid;
	});
};
