import React, { useState, useEffect } from "react";
import {
  Button,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  titleCase,
  searchAssociativeArray,
  sortObject,
} from "../helpers/SharedFunctions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  checkBox: {
    padding: theme.spacing(0.5),
  },
  checkBoxWrapper: {
    paddingLeft: theme.spacing(1),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
    marginTop: theme.spacing(1),
    display: "inline-block",
    float: "right",
    width: "100%",
  },
}));

const ActionMenu = (props) => {
  const {
    anchorEl,
    handleMenuClose,
    selectionAction,
    menuItems,
    label,
    initialSelection,
    menuItemProps,
  } = props;

  const { idField, sortField, displayField } = menuItemProps;

  const [selectedItems, setSelectedItems] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !loading && initialSelection && setSelectedItems(initialSelection);
  }, [initialSelection]);

  useEffect(() => {
    checkForChanges();
  }, [selectedItems]);

  useEffect(() => {
    if (!initialSelection || (initialSelection && !initialSelection.length)) {
      setSelectedItems([]);
    }
  }, [anchorEl]);

  const classes = useStyles();
  const sortedItems = sortObject(menuItems, sortField);

  const handleChange = (event, item) => {
    event.stopPropagation();
    const newSelection = [...selectedItems];
    const index = newSelection.findIndex(
      (x) => x[idField] === event.target.value
    );
    if (index !== -1) {
      newSelection.splice(index, 1);
    } else {
      newSelection.push(item);
    }
    setSelectedItems(newSelection);
  };

  const isSelected = (id) => {
    return searchAssociativeArray(id, selectedItems, idField);
  };

  const handleClose = () => {
    handleMenuClose();
  };

  const handleClick = async () => {
    setLoading(true);
    await selectionAction(selectedItems);
    setHasChanged(false);
    handleMenuClose();
    setLoading(false);
  };

  const checkForChanges = () => {
    if (
      (!initialSelection || !initialSelection.length) &&
      !selectedItems.length
    ) {
      setHasChanged(false);
      return;
    }
    if (
      (!initialSelection || !initialSelection.length) &&
      selectedItems.length
    ) {
      setHasChanged(true);
      return;
    }
    let result = false;
    for (let i = 0; i < selectedItems.length; i++) {
      const itemPresent = !!searchAssociativeArray(
        selectedItems[i][idField],
        initialSelection,
        idField
      );
      if (!itemPresent) {
        result = true;
        break;
      }
    }
    if (result) {
      setHasChanged(true);
      return;
    }
    for (let i = 0; i < initialSelection.length; i++) {
      const itemPresent = !!searchAssociativeArray(
        initialSelection[i][idField],
        selectedItems,
        idField
      );
      if (!itemPresent) {
        result = true;
        break;
      }
    }
    if (result) {
      setHasChanged(true);
      return;
    }
    setHasChanged(false);
  };

  return (
    <div>
      <Menu
        id="actionMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={classes.checkBoxWrapper}>
          <FormGroup>
            {sortedItems.map((item, index) => (
              <FormControlLabel
                key={item[idField]}
                control={
                  <Checkbox
                    checked={!!isSelected(item[idField])}
                    onChange={(event) => handleChange(event, item)}
                    value={item[idField]}
                    className={classes.checkBox}
                    disabled={loading}
                  />
                }
                label={titleCase(item[displayField])}
              />
            ))}
          </FormGroup>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.button}
            disabled={!hasChanged || loading}
            onClick={handleClick}
          >
            {label}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func.isRequired,
  selectionAction: PropTypes.func.isRequired,
  initialSelection: PropTypes.array,
  menuItemProps: PropTypes.object.isRequired,
};

export default ActionMenu;
