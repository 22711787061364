import React from "react";
import { Checkbox, TextField, Chip, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox, FiberNew } from "@material-ui/icons";
import {
  sortObject,
  getSha,
  searchAssociativeArray,
  titleCase,
} from "../../../helpers/SharedFunctions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.action.active} !important`,
    },
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  formLabelRoot: {},
  tagWrapper: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
  },
}));

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const Tags = (props) => {
  const {
    selectedTags,
    handleSelect,
    access,
    setSnackbarVisibility,
    fieldName,
    loading,
  } = props;
  const allTags = sortObject(props.allTags, "value");
  const classes = useStyles();

  const handleOnChange = (newValue, reason, detail) => {
    let currentSelected = [...selectedTags];

    switch (reason) {
      case "create-option":
        if (
          !searchAssociativeArray(
            titleCase(detail.option),
            selectedTags,
            "value"
          )
        ) {
          const foundItem = searchAssociativeArray(
            titleCase(detail.option),
            allTags,
            "value"
          );
          const tagObject = foundItem
            ? { ...foundItem }
            : { id: getSha(), value: titleCase(detail.option), tagIsNew: true };
          currentSelected.push(tagObject);
        } else {
          setSnackbarVisibility({
            open: true,
            message: `Tag already added`,
          });
        }
        break;
      case "select-option":
        if (
          !searchAssociativeArray(
            titleCase(detail.option.value),
            selectedTags,
            "value"
          )
        ) {
          currentSelected.push({ ...detail.option });
        }
        break;
      case "remove-option":
        const indexOfObject = currentSelected.findIndex((object) => {
          return object.id === detail.option.id;
        });
        currentSelected.splice(indexOfObject, 1);
        break;
      case "clear":
        currentSelected = [];
        break;
      default:
        return;
    }
    handleSelect(fieldName, currentSelected);
  };

  return (
    <div className={classes.tagWrapper}>
      <Autocomplete
        multiple
        disabled={loading || !access.update}
        fullWidth
        id="tags-filled"
        onChange={(event, newValue, reason, detail) => {
          handleOnChange(newValue, reason, detail);
        }}
        getOptionLabel={(option) => option.value}
        options={allTags}
        value={selectedTags}
        freeSolo
        renderTags={(value, getTagProps) =>
          selectedTags.map((option, index) => (
            <Chip
              variant="outlined"
              icon={option.tagIsNew && <FiberNew />}
              label={option.value}
              color={option.tagIsNew ? `secondary` : `default`}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                !!searchAssociativeArray(
                  titleCase(option.id),
                  selectedTags,
                  "id"
                ) || selected
              }
            />
            {option.value}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tags"
            placeholder={access.update ? "Search tags" : ""}
            InputLabelProps={{
              classes: {
                root: classes.formLabelRoot,
                focused: classes.formLabelFocused,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              },
            }}
          />
        )}
      />
    </div>
  );
};

Tags.propTypes = {
  allTags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Tags;
