import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { titleCase } from "../helpers/SharedFunctions";

const styles = (theme) => ({
	selectLabel: {
		color: "white",
		"&.Mui-focused": {
			color: "white",
		},
		right: 0,
		left: "unset",
	},
	select: {
		"&:before": {
			borderBottom: "0px",
		},
		"&:after": {
			borderBottom: "0px",
		},
		"&:hover:not(.Mui-disabled):not(.Mui-error):before": {
			borderBottom: "0px",
			backgroundColor: "none",
		},
	},
	versionWrapper: {
		paddingTop: theme.spacing(1),
	},
	selectOverride: {
		"&:focus": {
			backgroundColor: "transparent",
		},
	},
});

function VersionSelector(props) {
	const { selectedVersion, classes } = props;
	const versions = props.versions.sort((a, b) =>
		a.version_name.localeCompare(b.version_name)
	);

	return (
		<React.Fragment>
			{versions &&
			Object.keys(selectedVersion).length &&
			versions.length > 1 ? (
				<div className={classes.versionWrapper}>
					<FormControl>
						<InputLabel className={classes.selectLabel}>
							Version
						</InputLabel>
						<Select
							value={selectedVersion.version_id}
							onChange={props.handleChange}
							className={classes.select}
							classes={{
								root: classes.test,
								select: classes.selectOverride,
							}}
						>
							{versions.map((version, index) => (
								<MenuItem
									value={version.version_id}
									key={version.version_id}
								>
									{titleCase(version.version_name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			) : null}
		</React.Fragment>
	);
}

export default withStyles(styles)(VersionSelector);

VersionSelector.propTypes = {
	selectedVersion: PropTypes.object.isRequired,
	versions: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
};
