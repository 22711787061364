import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import {
  CheckCircleOutline,
  HighlightOff,
  MoreHoriz,
  FeaturedPlayList,
  ClearAll,
  AccountTree,
  Panorama,
  LocalOffer,
  Schedule,
  LibraryBooks,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    position: "relative",
  },
  progressWrapper: {
    padding: theme.spacing(0.25),
    width: `24px`,
    height: `24px`,
  },
  iconWrapper: {
    marginLeft: theme.spacing(4),
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  progress: {
    color: theme.palette.text.primary,
  },
  nestedWrapper: {
    padding: 0,
  },
}));

const Processing = (props) => {
  const { processing, masterState } = props;
  const classes = useStyles();

  const getIcon = (status) => {
    switch (status) {
      case "pending":
        return <MoreHoriz />;
      case "processing":
        return (
          <div className={classes.progressWrapper}>
            <CircularProgress size={20} className={classes.progress} />
          </div>
        );
      case "success":
        return <CheckCircleOutline color="secondary" />;
      case "fail":
        return <HighlightOff />;
      default:
        return <MoreHoriz />;
    }
  };

  const getVersionIcon = (id) => {
    const version = processing.versions[id];
    if (!version) {
      return <MoreHoriz />;
    }
    return getIcon(version.status);
  };

  const getStemIcon = (version_id, stem_id) => {
    const version = processing.versions[version_id];
    if (!version) {
      return <MoreHoriz />;
    }
    const stem = version.stems[stem_id];
    if (!stem) {
      return <MoreHoriz />;
    }
    return getIcon(stem.status);
  };

  return (
    <div className={classes.wrapper}>
      <Paper>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <FeaturedPlayList />
            </ListItemIcon>
            <ListItemText primary="Submitting metadata" />
            <ListItemIcon className={classes.iconWrapper}>
              {getIcon(processing.master.status)}
            </ListItemIcon>
          </ListItem>
          {masterState.versions.map((version, index) => (
            <div key={index}>
              <ListItem>
                <ListItemIcon>
                  <AccountTree />
                </ListItemIcon>
                <ListItemText
                  primary={`Creating version: "${version.version_name}"`}
                />
                <ListItemIcon className={classes.iconWrapper}>
                  {getVersionIcon(version.version_id)}
                </ListItemIcon>
              </ListItem>
              <List dense className={classes.nestedWrapper}>
                <div>
                  {version.version_stems.map((stem, index) => (
                    <ListItem className={classes.nested} key={index}>
                      <ListItemIcon>
                        <ClearAll />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Processing stem: "${stem.stem_name}"`}
                      />
                      <ListItemIcon className={classes.iconWrapper}>
                        {getStemIcon(version.version_id, stem.stem_id)}
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </div>
              </List>
            </div>
          ))}
          {!!masterState.master_tags.length && (
            <ListItem>
              <ListItemIcon>
                <LocalOffer />
              </ListItemIcon>
              <ListItemText primary="Adding tags" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.tags.status)}
              </ListItemIcon>
            </ListItem>
          )}
          {!!masterState.works.length && (
            <ListItem>
              <ListItemIcon>
                <LibraryBooks />
              </ListItemIcon>
              <ListItemText primary="Adding publishing details" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.works.status)}
              </ListItemIcon>
            </ListItem>
          )}
          {!!masterState.master_artwork.length && (
            <ListItem>
              <ListItemIcon>
                <Panorama />
              </ListItemIcon>
              <ListItemText primary="Processing artwork" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.artwork.status)}
              </ListItemIcon>
            </ListItem>
          )}
          {!!masterState.groups.length && (
            <ListItem>
              <ListItemIcon>
                <Schedule />
              </ListItemIcon>
              <ListItemText primary="Scheduling build" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.distribution.status)}
              </ListItemIcon>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  );
};

Processing.propTypes = {
  processing: PropTypes.object.isRequired,
  masterState: PropTypes.object.isRequired,
};

export default Processing;
