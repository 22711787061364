import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import Dropzone from "../../content/Dropzone";

const useStyles = makeStyles((theme) => ({
	uploadWrapper: {
		display: "flex",
		justifyContent: "flex-end",
	},
	wrapper: {
		marginRight: theme.spacing(1),
		display: "inline-block",
	},
	iconButton: {
		color: theme.palette.text.disabled,
	},
}));

const StemLibraryDropzone = (props) => {
	const dropzoneRef = useRef();
	const classes = useStyles();
	const { access, addFiles, index, accept, loading } = props;

	return (
		<div className={classes.uploadWrapper}>
			<Dropzone
				ref={dropzoneRef}
				addFiles={addFiles}
				access={access}
				index={index}
				accept={accept}
				loading={loading}
			>
				<div className={classes.wrapper}>
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="span"
						onClick={() => dropzoneRef.current.openDialog()}
						className={classes.iconButton}
						variant="contained"
						disabled={loading}
					>
						<CloudUpload />
					</IconButton>
				</div>
			</Dropzone>
		</div>
	);
};

StemLibraryDropzone.propTypes = {
	access: PropTypes.object.isRequired,
	addFiles: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	accept: PropTypes.string.isRequired,
};

export default StemLibraryDropzone;
