import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid,
	Typography,
	Button,
	FormControlLabel,
	Switch,
	CircularProgress,
	Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { titleCase } from "../../helpers/SharedFunctions";

const styles = (theme) => ({
	caption: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(2),
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	buttonWrapper: {
		position: "relative",
		display: "inline-block",
		float: "right",
	},
	formWrapper: {
		width: "100%",
	},
});

class ThemeControl extends React.Component {
	handleChange = (event, name) => {
		const value = this.transformValue(name, event.target.checked);
		this.props.updateSkinConfig(name, value);
	};

	transformValue = (name, value) => {
		switch (name) {
			case "skin_theme":
				return value ? "dark" : "light";
			case "skin_mode_override":
				return value ? `1` : `0`;
			default:
				break;
		}
	};

	render() {
		const {
			classes,
			skinConfig,
			loading,
			hasSettingChanged,
			handleConfirm,
		} = this.props;
		const theme = skinConfig.skin_theme === "dark";
		const userOverride = Boolean(parseInt(skinConfig.skin_mode_override));
		return (
			<Grid item xs={12} sm={6}>
				<Typography
					variant="caption"
					display="block"
					className={classes.caption}
				>
					Default Theme
				</Typography>
				<div className={classes.formWrapper}>
					<FormControlLabel
						control={
							<Switch
								checked={theme}
								onChange={(event) =>
									this.handleChange(event, "skin_theme")
								}
								value={theme}
							/>
						}
						label={`${titleCase(skinConfig.skin_theme)} Theme`}
					/>{" "}
					<FormControlLabel
						control={
							<Checkbox
								checked={userOverride}
								onChange={(event) =>
									this.handleChange(
										event,
										"skin_mode_override"
									)
								}
								value={userOverride}
							/>
						}
						label="Allow user to override"
					/>
				</div>
				{this.props.children}
				<div className={classes.buttonWrapper}>
					<Button
						id="skin_theme"
						variant="outlined"
						color="secondary"
						disabled={
							Boolean(loading.length) ||
							!(
								hasSettingChanged("skin_theme") ||
								hasSettingChanged("skin_mode_override")
							)
						}
						onClick={() =>
							handleConfirm(["skin_theme", "skin_mode_override"])
						}
					>
						Update
					</Button>
					{loading === "skin_theme" ||
						(loading === "skin_mode_override" && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						))}
				</div>
			</Grid>
		);
	}
}

ThemeControl.ThemeControl = {
	skinConfig: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.string.isRequired,
	hasSettingChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(ThemeControl);
