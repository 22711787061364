import React from "react";
import PropTypes from "prop-types";
import CustomizedTextField from "../../../library/CustomizedTextField";

const Metadata = (props) => {
  const { data, handleChange, handleBlur, disabled, access } = props;

  const { group_name } = data;

  return (
    <CustomizedTextField
      required
      fullWidth
      id="groupName"
      label="Name"
      value={group_name}
      handleOnChange={handleChange("group_name")}
      handleOnBlur={handleBlur("group_name")}
      disabled={disabled || !access.update}
    />
  );
};

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
};

export default Metadata;
