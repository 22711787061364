import React, { useState } from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { LibraryAdd } from "@material-ui/icons";
import StemLibraryMenu from "./StemLibraryMenu";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1),
  },
}));

const StemLibraryButton = (props) => {
  const {
    stemLibrary,
    insertLibraryStems,
    index,
    selectedGroup,
    disabled,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = async (event) => {
    event.stopPropagation();
    const element = event.currentTarget;
    setAnchorEl(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        title={"Stem Library"}
        enterDelay={300}
        enterNextDelay={300}
      >
        <span>
          <IconButton
            aria-label={"Stem Library"}
            aria-owns={anchorEl ? "selection-menu" : undefined}
            aria-haspopup="true"
            className={classes.button}
            onClick={(event) => handleClick(event)}
            disabled={disabled}
          >
            <LibraryAdd />
          </IconButton>
        </span>
      </Tooltip>
      {stemLibrary && stemLibrary.length ? (
        <StemLibraryMenu
          handleMenuClose={handleMenuClose}
          stemLibrary={stemLibrary}
          anchorEl={anchorEl}
          insertLibraryStems={insertLibraryStems}
          index={index}
          selectedGroup={selectedGroup}
        />
      ) : null}
    </React.Fragment>
  );
};

StemLibraryButton.propTypes = {
  stemLibrary: PropTypes.array.isRequired,
  insertLibraryStems: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedGroup: PropTypes.object.isRequired,
};

export default StemLibraryButton;
