const Styles = (theme) => ({
  video: {
    position: "fixed",
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
    zIndex: -1,
  },
  root: {
    flexGrow: 1,
    margin: 0,
  },
  button: {
    margin: "0 auto",
    display: "block",
    float: "none",
  },
  test: {
    fontWeight: 200,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  fullWidthGridItem: {
    width: "100%",
  },
  label: {
    flexDirection: "column",
    fontSize: "18px",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  icon: {
    marginBottom: theme.spacing(1),
    display: "block",
    float: "none",
    margin: "0 auto",
  },
  buttonDescription: {
    textTransform: "none",
    fontWeight: 200,
    lineHeight: "normal",
    textAlign: "left",
  },
  paper: {
    padding: "10px",
    margin: "10px",
    backgroundColor: "rgba(66, 66, 66, 0.9)",
  },
  divider: {
    margin: "10px 0px 10px 0",
  },
  formHeader: {
    fontWeight: 200,
  },
  formLabelFocused: {
    color: "white !important",
  },
  formLabelRoot: {},
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: "white !important",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #424242 inset",
      color: "white !important",
    },
    "&:-internal-autofill-selected": {
      color: "white !important",
    },
  },
  input2: {
    WebkitBoxShadow: "0 0 0 1000px white inset",
  },
});

export default Styles;
