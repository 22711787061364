import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
} from "@material-ui/core";
import {
  titleCase,
  sortArray,
  searchAssociativeArray,
} from "../helpers/SharedFunctions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 300,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const TransferList = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const {
    availableData,
    selectedData,
    handleSelectedData,
    idField,
    labelField,
    objProp,
    headerName,
    loading,
  } = props;

  const filteredAvailable = useMemo(() => {
    let currentAvailableData = [];
    availableData.forEach((element, index) => {
      const selectedIndex = searchAssociativeArray(
        element[idField],
        selectedData,
        idField
      );
      if (!selectedIndex) {
        currentAvailableData.push(element);
      }
    });
    return currentAvailableData;
  }, [selectedData]);

  const leftChecked = intersection(checked, filteredAvailable);
  const rightChecked = intersection(checked, selectedData);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    let currentData = [...selectedData];
    leftChecked.forEach((element) => {
      const index = currentData.indexOf(element);
      if (index === -1) {
        currentData.push(element);
      }
    });
    handleSelectedData(objProp, currentData);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let currentData = [...selectedData];
    rightChecked.forEach((element) => {
      const index = currentData.indexOf(element);
      if (index !== -1) {
        currentData.splice(index, 1);
      }
    });
    handleSelectedData(objProp, currentData);
    setChecked(not(checked, rightChecked));
  };

  const dataList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={loading || items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value[idField]}-label`;

          return (
            <ListItem
              key={value[idField]}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={loading}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={titleCase(value[labelField])}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{dataList(`Available ${headerName}`, filteredAvailable)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={loading || leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={loading || rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {dataList(`Chosen ${headerName}`, sortArray(selectedData, labelField))}
      </Grid>
    </Grid>
  );
};

TransferList.propTypes = {
  availableData: PropTypes.array.isRequired,
  selectedData: PropTypes.array.isRequired,
  handleSelectedData: PropTypes.func.isRequired,
  idField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  objProp: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default TransferList;
