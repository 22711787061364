import React, { useRef, useEffect } from "react";
import { IconButton, Badge, Tooltip, makeStyles } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setDialogVisibility } from "../actions/index";
import Cart from "./Cart";
import ProductSelector from "./ProductSelector";
import MyAccount from "./MyAccount";
import UserSettings from "./UserSettings";
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
  menuIcons: {
    color: theme.palette.primary.contrastText,
  },
  productWrapper: {
    [theme.breakpoints.up("md")]: {
      margin: `0px ${theme.spacing(2)}px`,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    activeGroup: state.activeGroup,
    activeCatalog: state.activeCatalog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const connectedContentHeaderActions = (props) => {
  const { cart, activeGroup, activeCatalog, setDialogVisibility } = props;

  const classes = useStyles();
  const latestCart = useRef();

  useEffect(() => {
    latestCart.current = cart;
  }, [cart]);

  const openDialog = (content) => {
    let dialogContent = {
      open: true,
    };
    switch (content) {
      case "cart":
        dialogContent = {
          ...dialogContent,
          title: "Download Cart",
          body: <Cart />,
          fullWidth: true,
          fullScreen: "xs",
        };
        break;
      case "UserSettings":
        dialogContent = {
          ...dialogContent,
          body: <UserSettings />,
        };
        break;
      default:
        return;
    }
    setDialogVisibility(dialogContent);
  };

  return (
    <React.Fragment>
      {activeGroup && activeCatalog && (
        <React.Fragment>
          <Search />
          <div className={classes.productWrapper}>
            <ProductSelector />
          </div>
        </React.Fragment>
      )}
      {cart && (
        <div className={classes.iconWrapper}>
          <Tooltip title="Cart">
            <IconButton
              className={classes.menuIcons}
              aria-label="Cart"
              onClick={() => openDialog("cart")}
            >
              <Badge badgeContent={cart.length} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <MyAccount />
    </React.Fragment>
  );
};

connectedContentHeaderActions.propTypes = {
  cart: PropTypes.array.isRequired,
  activeGroup: PropTypes.number,
  activeCatalog: PropTypes.number,
  setDialogVisibility: PropTypes.func.isRequired,
};

const ContentHeaderActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(connectedContentHeaderActions);

export default ContentHeaderActions;
