import React from "react";
import { Divider,DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";

const CustomDialogTitle = (props) => {
  const { title } = props;

  return (
    <React.Fragment>
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <Divider />
    </React.Fragment>
  );
};

CustomDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomDialogTitle;
