import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Drawer,
  withWidth,
  IconButton,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Close } from "@material-ui/icons";

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
        width: drawerWidth,
  },
  drawerPaper: {
      width: drawerWidth,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 4px",
    ...theme.mixins.toolbar,
  },
  footerPad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 4px",
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
  },
});

function SideBar(props) {
  const { classes, drawerOpen, width, handleDrawerToggle } = props;
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleDrawerToggle}
      open={drawerOpen}
      variant={width === "xs" || width === "sm" ? "temporary" : "permanent"}
    >
      <div className={classes.toolbar}>
        {(width === "xs" || width === "sm") && (
          <React.Fragment>
            <IconButton onClick={handleDrawerToggle}>
              <Close />
            </IconButton>
          </React.Fragment>
        )}
      </div>
      {(width === "xs" || width === "sm") && <Divider />}
      {props.children}
      <div className={classes.footerPad} />
    </Drawer>
  );
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default withWidth()(withRouter(withStyles(styles)(SideBar)));
