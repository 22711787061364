import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  sortArray,
  formatDate,
  createTimeStamp,
  sanitizeTitle,
  titleCase,
  searchAssociativeArray,
  getSha,
  getAudioBuffer,
  stretchAudio,
} from "../../helpers/SharedFunctions";
import Metadata from "./wizard/Metadata";
import Axios from "axios";
import {
  getAvailableGroups,
  getAllTags,
  getMasterTags,
  getAllVersions,
  getVersionStems,
  getVersionStemsLibraryFiles,
  createVersion,
  editVersion,
  deleteVersion,
  uploadFile,
  createStem,
  createStemRelationship,
  getStemLibrary,
  deleteStem,
  editStem,
  editStemRelationship,
  deleteStemRelationship,
  editMaster,
  createTag,
  createTagRelationship,
  removeTagRelationship,
  getMixes,
  deleteMixes,
  deleteMix,
  createMasterBuildEntry,
  getMasterCredits,
  getWorks,
  removeWorkMasterRelationship,
  createWorkMasterRelationship,
  createRegisteredWork,
  createCredit,
  createCreditRelationship,
  getMasterBuilds,
} from "../../helpers/ApiCalls";
import CustomDialogActions from "../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../library/dialog/CustomDialogContent";
import ChipSelector from "../../library/ChipSelector";
import BuildMessage from "../../library/BuildMessage";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateAvailableGroups,
} from "../../actions/index";
import { connect } from "react-redux";
import { Keys } from "../../helpers/Keys";
import Tags from "./wizard/Tags";
import Artwork from "./wizard/Artwork";
import Distribution from "./wizard/Distribution";
import Versions from "./wizard/Versions";
import Mime from "mime-types";
import { HourglassEmpty, Autorenew } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogTabs: {
    color: theme.palette.text.primary,
  },
  progress: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
  },
  actionButtons: {
    position: "absolute",
    bottom: 0,
  },
  rotateIcon: {
    animation: "spin 6s linear infinite",
  },
  waitIcon: {
    animation: "blink 2s linear 0s infinite normal forwards",
  },
}));

const sortedKeys = sortArray(Keys, "display");

const mapStateToProps = (state) => {
  return {
    allCategories: state.allCategories,
    allCatalogs: state.allCatalogs,
    skinConfig: state.skinConfig,
    availableGroups: state.availableGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateAvailableGroups: (availableGroups) =>
      dispatch(updateAvailableGroups(availableGroups)),
  };
};

const ConnectedContentCard = (props, ref) => {
  const {
    cardContent,
    allCategories,
    allCatalogs,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    skinConfig,
    availableGroups,
    updateAvailableGroups,
    doSearch,
  } = props;

  const timerRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [masterState, setMasterState] = useState(null);
  const [editedFields, setEditedFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const classes = useStyles();
  const [originalMasterData, setOriginalMasterData] = useState(null);
  const [imageSource, setImageSource] = useState(null);
  const [stemLibrary, setStemLibrary] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mixes, setMixes] = useState([]);
  const [allWorks, setAllWorks] = useState([]);
  const [buildStatus, setBuildStatus] = useState("");

  useEffect(() => {
    checkBuildStatus();
  }, []);

  useEffect(() => {
    if (!masterState || !originalMasterData) {
      return;
    }
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      compareStates();
    }, 1000);
  }, [masterState]);

  useEffect(() => {
    if (masterState && masterState.catalog_id) {
      getStemLibrary(masterState.catalog_id).then((res) => {
        setStemLibrary(res);
      });
    }
  }, [masterState && masterState.catalog_id]);

  const checkBuildStatus = async () => {
    const queryString = `status[]=pending&status[]=running&type[]=1`;
    const runningBuilds = await getMasterBuilds(
      skinConfig.skin_id,
      cardContent.master_id,
      queryString
    );
    if (runningBuilds && runningBuilds.length) {
      let buildStatus = "";
      switch (parseInt(runningBuilds[0].build_status)) {
        case 1:
          buildStatus = "pending";
          break;
        case 2:
          buildStatus = "running";
          break;
        default:
          return;
      }
      setBuildStatus(buildStatus);
      return;
    }
    prepareInitialData();
  };

  const getBuildMessage = () => {
    switch (buildStatus) {
      case "pending":
        return (
          <React.Fragment>
            <Typography align="center" variant="h5" gutterBottom>
              Build pending.
            </Typography>
            <Typography align="center" variant="body1" noWrap>
              Editing is unavailable at this time.
            </Typography>
            <Typography align="center" variant="body1" noWrap>
              Please check back soon.
            </Typography>
          </React.Fragment>
        );
      case "running":
        return (
          <React.Fragment>
            <Typography align="center" variant="h5" gutterBottom>
              Build in progress.
            </Typography>
            <Typography align="center" variant="body1" noWrap>
              Editing is unavailable at this time.
            </Typography>
            <Typography align="center" variant="body1" noWrap>
              Please check back soon.
            </Typography>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  const getBuildIcon = () => {
    return buildStatus === "running" ? (
      <Autorenew className={classes.rotateIcon} style={{ fontSize: "200px" }} />
    ) : (
      <HourglassEmpty
        className={classes.waitIcon}
        style={{ fontSize: "200px" }}
      />
    );
  };

  const prepareInitialData = async () => {
    const base64Image = await convertToBase64(
      `${skinConfig.skin_artwork_url}/${cardContent.master_artwork}`
    );

    const catalogGroups = await getAvailableGroups(cardContent.catalog_id);
    updateAvailableGroups(sortArray(catalogGroups, "group_name"));

    let primaryGroup = null;
    primaryGroup = searchAssociativeArray(
      "1",
      catalogGroups,
      "group-catalog_primary"
    );
    if (!primaryGroup) {
      primaryGroup = catalogGroups[0];
    }
    setSelectedGroup(primaryGroup);
    const versions = await getVersions(primaryGroup);
    const groups = await getVersionMixes(versions);
    const allTagData = await getAllTags();
    if (allTagData) {
      setAllTags(mapTagData(allTagData));
    }

    const queryString = `source[]=local`;

    const initialData = {
      master_title: cardContent.master_title,
      master_subcategory: cardContent.master_subcategory,
      catalog_id: cardContent.catalog_id,
      category_id: cardContent.category_id,
      master_key: cardContent.master_key.toLowerCase(),
      master_bpm: `${Math.round(cardContent.master_bpm)}`,
      master_display_date: formatDate(cardContent.master_display_date, false),
      master_description: cardContent.master_description,
      master_status: !!parseInt(cardContent.master_status),
      master_tags: mapTagData(await getMasterTags(cardContent.master_id)),
      master_artwork: `${skinConfig.skin_artwork_url}/${cardContent.master_artwork}`,
      versions: versions,
      groups: groups,
      works: mapWorksData(
        await getMasterCredits(cardContent.master_id, queryString)
      ),
    };
    setMasterState(initialData);
    setImageSource(base64Image);
    setOriginalMasterData(JSON.parse(JSON.stringify(initialData)));
  };

  const getVersions = async (selectedGroup) => {
    const versions = await getAllVersions(cardContent.master_id);
    return await prepareVersionStems(versions, selectedGroup);
  };

  const getVersionMixes = async (versions) => {
    const promises = [];
    versions.forEach((version) => {
      promises.push(
        new Promise((resolve, reject) => {
          getMixes(cardContent.master_id, version.version_id).then((res) => {
            resolve(res);
          });
        })
      );
    });

    return Promise.all(promises).then((values) => {
      const currentSelectedGroups = [];
      const mixes = [];
      values.forEach((value) => {
        value.forEach((mix) => {
          if (
            !searchAssociativeArray(
              mix["group_id"],
              currentSelectedGroups,
              "group_id"
            )
          ) {
            currentSelectedGroups.push(mix);
          }
          mixes.push(mix);
        });
      });
      setMixes(mixes);
      return currentSelectedGroups;
    });
  };

  const searchWorks = (query) => {
    const queryString = `source[]=spotify&source[]=local&q=${query}`;
    return getWorks(queryString).then((res) => {
      return mapWorksData(res.data.result.works);
    });
  };

  const prepareVersionStems = async (versions, selectedGroup) => {
    const promises = [];
    versions.forEach((version) => {
      promises.push(
        new Promise((resolve, reject) => {
          getVersionStems(cardContent.master_id, version.version_id).then(
            (res) => {
              let stems = res.stems;
              getVersionStemsLibraryFiles(
                selectedGroup.group_id,
                cardContent.master_id,
                version.version_id
              ).then(async (res) => {
                const stemLibraryFiles = res.stem_library_files;
                const stemLibraryFileArray = [];
                for (const stemLibraryFile of stemLibraryFiles) {
                  const filename = `${skinConfig.skin_masters_url}/${stemLibraryFile.stem_library_file_filename}`;
                  const masterBpm = parseInt(cardContent.master_bpm);
                  const stemBpm = Math.round(stemLibraryFile.stem_library_bpm);
                  let stretch = false;
                  const stemLibraryFileObj = {
                    stem_id: getSha(),
                    blob: filename,
                    stem_position:
                      stemLibraryFile["versions-stem_library_position"],
                    stem_volume:
                      stemLibraryFile["versions-stem_library_volume"],
                    stem_name: stemLibraryFile.stem_library_name,
                    "versions-stem_library_id":
                      stemLibraryFile["versions-stem_library_id"],
                  };

                  if (
                    Number.isInteger(stemBpm) &&
                    Number.isInteger(masterBpm) &&
                    stemBpm !== masterBpm
                  ) {
                    stretch = true;
                  }
                  if (stretch) {
                    const stretchRate = masterBpm / stemBpm;
                    const context = new AudioContext();
                    const audiobuffer = await getAudioBuffer(context, filename);
                    const inputData = audiobuffer.getChannelData(0);
                    const output = stretchAudio(
                      inputData,
                      stretchRate,
                      1,
                      context
                    );
                    const outputAudioBuffer = context.createBuffer(
                      1,
                      output.length,
                      context.sampleRate
                    );
                    outputAudioBuffer.getChannelData(0).set(output);
                    stemLibraryFileObj.blob = outputAudioBuffer;
                  }
                  stemLibraryFileArray.push(stemLibraryFileObj);
                }
                stems = [...stemLibraryFileArray, ...stems];
                const versionObj = { version_stems: stems, ...version };
                resolve(versionObj);
              });
            }
          );
        })
      );
    });

    return Promise.all(promises).then((values) => {
      const versions = [];
      values.forEach((value) => {
        let currentVersion = { ...value };
        currentVersion.version_primary = !!parseInt(value.version_primary);
        currentVersion.version_status = !!parseInt(value.version_status);
        const versionStems = [];
        value.version_stems.forEach((stem) => {
          let stemObj = { ...stem };
          stemObj.blob = stem.blob
            ? stem.blob
            : `${skinConfig.skin_stems_url}/${stem.stem_filename}`;
          stemObj.stem_position = parseFloat(stem.stem_position);
          stemObj.stem_volume = parseFloat(stem.stem_volume);
          versionStems.push(stemObj);
        });
        currentVersion.version_stems = versionStems;
        versions.push(currentVersion);
      });
      return versions;
    });
  };

  const mapTagData = (tags) => {
    return tags.length
      ? tags.map((tag) => ({
          id: tag.tag_id,
          value: titleCase(tag.tag_name),
        }))
      : [];
  };

  const mapWorksData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.registered_work_identification_code,
          value: `${titleCase(
            `${item.registered_work_title} - ${
              item.credits.length > 1
                ? item.credits.join(", ")
                : item.credits[0]
            }`.replace("&amp;", "&")
          )} [${item.registered_work_identification_code.toUpperCase()}]`,
          title: item.registered_work_title,
          id_code: item.registered_work_identification_code,
          credits: item.credits,
          registered_work_id: item.registered_work_id,
          source: item.source,
        }))
      : [];
  };

  const compareStates = (input, value) => {
    let currentEditedFields = [...editedFields];
    for (const [key] of Object.entries(masterState)) {
      if (hasFieldChanged(key) && currentEditedFields.indexOf(key) === -1) {
        currentEditedFields.push(key);
        setEditedFields(currentEditedFields);
      } else if (
        !hasFieldChanged(key) &&
        currentEditedFields.indexOf(key) !== -1
      ) {
        currentEditedFields.splice(currentEditedFields.indexOf(key), 1);
        setEditedFields(currentEditedFields);
      }
    }
  };

  const hasFieldChanged = (field) => {
    const originalState = originalMasterData[field];
    let newState = masterState[field];
    switch (field) {
      case "works":
        return evaluateDeepState(originalState, newState, "id", null);
      case "master_tags":
        return evaluateDeepState(originalState, newState, "id", null);
      case "groups":
        return evaluateDeepState(originalState, newState, "group_id", null);
      case "versions":
        let hasChanged = false;
        const versionFields = [
          "version_name",
          "version_primary",
          "version_status",
        ];
        hasChanged = evaluateDeepState(
          originalState,
          newState,
          "version_id",
          versionFields
        );
        if (!hasChanged) {
          let i = 0;
          for (i = 0; i < newState.length; ++i) {
            let originalVersionStems = originalState[i]["version_stems"];
            let newVersionStems = newState[i]["version_stems"];
            const stemFields = ["stem_name", "stem_position", "stem_volume"];
            hasChanged = evaluateDeepState(
              originalVersionStems,
              newVersionStems,
              "stem_id",
              stemFields
            );
            if (hasChanged) {
              break;
            }
          }
        }
        return hasChanged;
      case "master_display_date":
        newState = new Date(newState).toDateString();
        break;
      case "master_artwork":
        if (!newState && originalState) {
          return false;
        }
        break;
      default:
    }
    return originalState !== newState;
  };

  const evaluateDeepState = (originalArr, newArr, idField, fields) => {
    let hasChanged = false;
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalArr.length; ++i) {
      if (!searchAssociativeArray(originalArr[i][idField], newArr, idField)) {
        hasChanged = true;
        break;
      }
    }
    //check if current object was originally present
    for (i = 0; i < newArr.length; ++i) {
      let currentObj = newArr[i];
      let originalObj = searchAssociativeArray(
        currentObj[idField],
        originalArr,
        idField
      );
      if (!originalObj) {
        hasChanged = true;
        break;
      }
      //evaluate specified new object fields against original
      if (fields && fields.length) {
        let x = 0;
        for (x = 0; x < fields.length; ++x) {
          let field = fields[x];
          if (currentObj[field] !== originalObj[field]) {
            hasChanged = true;
            break;
          }
        }
      }
    }
    return hasChanged;
  };

  const handleEditMaster = async () => {
    setLoading(true);
    let snackbarContent = {
      open: true,
      message: "An error occured. Please contact support",
    };
    if (!(await handlehandleMetadata())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleVersions())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleTags())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleWorks())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleArtwork(cardContent.master_id))) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleDistribution())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    doSearch();
    setLoading(false);
    snackbarContent.message = "Update Successful";
    setSnackbarVisibility(snackbarContent);
    handleDialogClose();
    return true;
  };

  const isRebuildNeeded = () => {
    const originalVersions = originalMasterData.versions;
    const currentVersions = masterState.versions;

    if (editedFields.includes("catalog_id")) {
      return true;
    }

    if (editedFields.includes("versions")) {
      let i = 0;
      //check for new versions
      for (i = 0; i < currentVersions.length; ++i) {
        const match = searchAssociativeArray(
          currentVersions[i]["version_id"],
          originalVersions,
          "version_id"
        );
        if (!match) {
          return true;
        }
      }
      //check for mix changing stem changes
      const stemFields = ["stem_position", "stem_volume"];
      for (i = 0; i < originalVersions.length; ++i) {
        const found = evaluateDeepState(
          originalVersions[i]["version_stems"],
          currentVersions[i]["version_stems"],
          "stem_id",
          stemFields
        );
        if (found) {
          return true;
        }
      }
    }
    return false;
  };

  const handleBuild = async (groups) => {
    const postData = {
      skin_id: skinConfig.skin_id,
      build_type: 1,
      build_data: {
        master_id: cardContent.master_id,
        groups: groups,
      },
    };
    //TO DO delete any existing build schedule for this file
    await createMasterBuildEntry(cardContent.master_id, postData).then(
      (res) => {
        return res.data.result;
      }
    );
  };

  const handleDistribution = async () => {
    if (!editedFields.includes("groups")) {
      return true;
    }
    if (isRebuildNeeded()) {
      for (const version of originalMasterData.versions) {
        if (
          await !handleDeleteMixes(cardContent.master_id, version.version_id)
        ) {
          return false;
        }
      }
      const groupsArray = [];
      for (const buildGroup of masterState.groups) {
        groupsArray.push(buildGroup.group_id);
      }
      if (await !handleBuild(groupsArray)) {
        return false;
      }
      return true;
    }

    if (editedFields.includes("groups")) {
      const currentGroups = [...masterState.groups];
      const originalGroups = [...originalMasterData.groups];
      const groupsToRemove = [];
      const groupsToCreate = [];
      let i = 0;
      //check whether any original objects have been removed

      for (i = 0; i < originalGroups.length; ++i) {
        if (
          originalGroups.length &&
          !searchAssociativeArray(
            originalGroups[i]["group_id"],
            currentGroups,
            "group_id"
          )
        ) {
          groupsToRemove.push(originalGroups[i]);
        }
      }
      //check if current object was originally present
      for (i = 0; i < currentGroups.length; ++i) {
        if (
          !searchAssociativeArray(
            currentGroups[i]["group_id"],
            originalGroups,
            "group_id"
          )
        ) {
          groupsToCreate.push(currentGroups[i]["group_id"]);
        }
      }

      if (groupsToRemove.length) {
        for (const group of groupsToRemove) {
          for (const mix of mixes) {
            if (mix.group_id === group.group_id) {
              await deleteMix(mix.master_id, mix.version_id, mix.mix_id);
            }
          }
        }
      }

      if (groupsToCreate.length) {
        if (await !handleBuild(groupsToCreate)) {
          return false;
        }
      }
    }
    return true;
  };

  const getFileBlob = (file) => {
    return Axios.get(file, {
      responseType: "blob",
    }).then((blob) => {
      return blob.data;
    });
  };

  const handleArtwork = async (masterId) => {
    if (!editedFields.includes("master_artwork")) {
      return true;
    }
    const mimeType = imageSource.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const extension = Mime.extension(mimeType);
    const artworkBlob = await getFileBlob(masterState.master_artwork);
    const artworkFilename = await submitAsset(
      artworkBlob,
      "artwork",
      extension
    ).then((res) => {
      return res.data;
    });
    if (!artworkFilename) {
      return false;
    }
    const postData = { master_artwork: artworkFilename };
    return await editMaster(masterId, postData).then((res) => {
      if (!res) {
        return false;
      }
      return true;
    });
  };

  const handleTags = async () => {
    if (!editedFields.includes("master_tags")) {
      return true;
    }
    const currentTags = [...masterState.master_tags];
    const originalTags = [...originalMasterData.master_tags];

    const tagsToRemove = [];
    const tagsToCreate = [];
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalTags.length; ++i) {
      if (!searchAssociativeArray(originalTags[i]["id"], currentTags, "id")) {
        tagsToRemove.push(originalTags[i]["id"]);
      }
    }
    //check if current object was originally present
    for (i = 0; i < currentTags.length; ++i) {
      if (!searchAssociativeArray(currentTags[i]["id"], originalTags, "id")) {
        tagsToCreate.push(currentTags[i]);
      }
    }

    if (tagsToRemove.length) {
      for (const tagToRemove of tagsToRemove) {
        const masterId = cardContent.master_id;
        if (!(await removeTagRelationship(masterId, tagToRemove))) {
          return false;
        }
      }
    }

    if (tagsToCreate.length) {
      for (const tagToCreate of tagsToCreate) {
        let tagId;
        if (tagToCreate.tagIsNew) {
          const postData = { tag_name: tagToCreate.value };
          tagId = await createNewTag(postData);
        } else {
          tagId = tagToCreate.id;
        }
        if (!tagId) {
          return false;
        }
        const relData = { master_id: cardContent.master_id, tag_id: tagId };
        const relId = await createNewTagRelationship(
          cardContent.master_id,
          relData
        );
        if (!relId) {
          return false;
        }
      }
    }
    return true;
  };

  const handleWorks = async () => {
    if (!editedFields.includes("works")) {
      return true;
    }
    const currentWorks = [...masterState.works];
    const originalWorks = [...originalMasterData.works];

    const worksToRemove = [];
    const worksToCreate = [];
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalWorks.length; ++i) {
      if (!searchAssociativeArray(originalWorks[i]["id"], currentWorks, "id")) {
        worksToRemove.push(originalWorks[i]["registered_work_id"]);
      }
    }
    //check if current object was originally present
    for (i = 0; i < currentWorks.length; ++i) {
      if (!searchAssociativeArray(currentWorks[i]["id"], originalWorks, "id")) {
        worksToCreate.push(currentWorks[i]);
      }
    }

    if (worksToRemove.length) {
      for (const workToRemove of worksToRemove) {
        const masterId = cardContent.master_id;
        if (!(await removeWorkMasterRelationship(masterId, workToRemove))) {
          return false;
        }
      }
    }

    if (worksToCreate.length) {
      for (const workToCreate of worksToCreate) {
        let workId;
        if (workToCreate.source !== "local") {
          const newWork = {
            registered_work_title: workToCreate.title.trim(),
            registered_work_identification_code: workToCreate.id_code.trim(),
          };
          workId = await createRegisteredWork(newWork).then((res) => {
            return res.data.result;
          });
          if (!workId) {
            return false;
          }
          for (const credit of workToCreate.credits) {
            const newCredit = {
              credit_name: credit.trim(),
            };
            const creditId = await createCredit(newCredit).then((res) => {
              return res.data.result;
            });
            if (!creditId) {
              return false;
            }
            let postData = { registered_work_id: workId, credit_id: creditId };
            const workCreditRelId = await createCreditRelationship(
              workId,
              postData
            );
            if (!workCreditRelId) {
              return false;
            }
          }
        } else {
          workId = workToCreate.registered_work_id;
        }
        if (!workId) {
          return false;
        }
        const relData = {
          master_id: cardContent.master_id,
          registered_work_id: workId,
        };
        const relId = await createWorkMasterRelationship(
          cardContent.master_id,
          relData
        );
        if (!relId) {
          return false;
        }
      }
    }
    return true;
  };

  const createNewTag = (postData) => {
    return createTag(postData).then((res) => {
      if (res) {
        return res.data.result;
      }
      return false;
    });
  };

  const createNewTagRelationship = (masterId, relData) => {
    return createTagRelationship(masterId, relData).then((res) => {
      if (res) {
        return res.data.result;
      }
      return false;
    });
  };

  const handleVersions = async () => {
    if (!editedFields.includes("versions")) {
      return true;
    }
    const currentVersions = masterState.versions;
    const originalVersions = originalMasterData.versions;
    const toCreate = [];
    const toEdit = [];
    const toDelete = [];
    const fields = ["version_name", "version_primary", "version_status"];

    //check if any original versions have been removed
    for (let i = 0; i < originalVersions.length; i++) {
      let found = searchAssociativeArray(
        originalVersions[i]["version_id"],
        currentVersions,
        "version_id"
      );
      if (!found) {
        toDelete.push(originalVersions[i]);
        continue;
      }
    }

    //check if any new version have been added
    for (let i = 0; i < currentVersions.length; i++) {
      let found = searchAssociativeArray(
        currentVersions[i]["version_id"],
        originalVersions,
        "version_id"
      );
      for (let x = 0; x < fields.length; x++) {
        if (!validateField(fields[x], currentVersions[i][fields[x]])) {
          return false;
        }
      }
      if (!found) {
        toCreate.push(currentVersions[i]);
        continue;
      }

      //check what's changed
      const editedVsn = {};
      for (let x = 0; x < fields.length; x++) {
        if (currentVersions[i][fields[x]] !== found[fields[x]]) {
          editedVsn[fields[x]] = currentVersions[i][fields[x]];
        }
      }
      if (Object.keys(editedVsn).length) {
        editedVsn.version_id = currentVersions[i].version_id;
        toEdit.push(editedVsn);
      } else {
        if (await !evaluateStems(currentVersions[i].version_id)) {
          return false;
        }
      }
    }
    //handle new versions
    if (toCreate.length) {
      for (const versionToCreate of toCreate) {
        const versionId = await createVersionEntry(
          versionToCreate,
          cardContent.master_id
        );
        if (!versionId) {
          return false;
        }
        for (const stemToCreate of versionToCreate.version_stems) {
          const stemId = await createNewStem(
            stemToCreate,
            versionId,
            cardContent.master_id
          );
          if (!stemId) {
            return false;
          }
        }
      }
    }
    //handle edit versions
    if (toEdit.length) {
      for (const versionToEdit of toEdit) {
        const versionId = versionToEdit.version_id;
        if (
          await !editVersionEntry(
            cardContent.master_id,
            versionId,
            versionToEdit
          )
        ) {
          return false;
        }
        if (await !evaluateStems(versionId)) {
          return false;
        }
      }
    }
    //handle delete versions
    if (toDelete.length) {
      for (const versionToDelete of toDelete) {
        if (
          await !handleDeleteVersion(
            cardContent.master_id,
            versionToDelete.version_id
          )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const evaluateStems = async (versionId, version) => {
    const toDelete = [];
    const toEdit = [];
    const toCreate = [];
    const originalVersions = originalMasterData.versions;
    const currentVersions = masterState.versions;
    const originalVersion = searchAssociativeArray(
      versionId,
      originalVersions,
      "version_id"
    );
    const currentVersion = searchAssociativeArray(
      versionId,
      currentVersions,
      "version_id"
    );

    const originalStems = originalVersion.version_stems;
    const currentStems = currentVersion.version_stems;

    //check if any original stems have been removed
    for (let i = 0; i < originalStems.length; i++) {
      let found = searchAssociativeArray(
        originalStems[i]["stem_id"],
        currentStems,
        "stem_id"
      );
      if (!found) {
        toDelete.push(originalStems[i]);
        continue;
      }
    }

    //check if any new stens have been added
    let fields = [];
    for (let i = 0; i < currentStems.length; i++) {
      let found = searchAssociativeArray(
        currentStems[i]["stem_id"],
        originalStems,
        "stem_id"
      );
      fields = ["stem_name"];
      for (let x = 0; x < fields.length; x++) {
        if (!validateField(fields[x], currentStems[i][fields[x]])) {
          return false;
        }
      }
      if (!found) {
        toCreate.push(currentStems[i]);
        continue;
      }

      //check what's changed
      const editedStem = {};
      fields = ["stem_name", "stem_position", "stem_volume"];
      for (let x = 0; x < fields.length; x++) {
        if (currentStems[i][fields[x]] !== found[fields[x]]) {
          editedStem[fields[x]] = currentStems[i][fields[x]];
        }
      }
      if (Object.keys(editedStem).length) {
        editedStem.stem_id = currentStems[i].stem_id;
        editedStem["versions-stem_library_id"] =
          currentStems[i]["versions-stem_library_id"];
        toEdit.push(editedStem);
      }
    }

    if (toDelete.length) {
      for (const stemToDelete of toDelete) {
        const relId = stemToDelete["versions-stem_library_id"];
        if (relId) {
          if (
            await !handleDeleteStemRelationship(
              cardContent.master_id,
              versionId,
              relId
            )
          ) {
            return false;
          }
        } else {
          if (
            await !handleDeleteStem(
              cardContent.master_id,
              versionId,
              stemToDelete.stem_id
            )
          ) {
            return false;
          }
        }
      }
    }
    if (toEdit.length) {
      for (const stemToEdit of toEdit) {
        const stemId = stemToEdit.stem_id;
        const relId = stemToEdit["versions-stem_library_id"];
        if (relId) {
          if (
            await !editStemRelationshipEntry(
              cardContent.master_id,
              versionId,
              relId,
              stemToEdit
            )
          ) {
            return false;
          }
        } else {
          if (
            await !editStemEntry(
              cardContent.master_id,
              versionId,
              stemId,
              stemToEdit
            )
          ) {
            return false;
          }
        }
      }
    }

    if (toCreate.length) {
      for (const stemToCreate of toCreate) {
        if (
          await !createNewStem(stemToCreate, versionId, cardContent.master_id)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const createNewStem = async (stem, versionId, masterId) => {
    let stemId;

    if (!stem.stem_library_id) {
      const stemFilename = await submitAsset(stem.blob, "stem", "mp3").then(
        (res) => {
          return res.data;
        }
      );

      if (!stemFilename) {
        return false;
      }

      stemId = await createStemEntry(stem, stemFilename, versionId, masterId);
    } else {
      const payload = {
        stem_library_id: stem.stem_library_id,
        "versions-stem_library_position": stem.stem_position,
        "versions-stem_library_volume": stem.stem_volume,
      };

      stemId = await createStemRelationship(masterId, versionId, payload);
    }

    if (!stemId) {
      return false;
    }

    return stemId;
  };

  const createStemEntry = (stem, stemFilename, versionId, masterId) => {
    const postData = {
      stem_name: stem.stem_name.trim(),
      stem_position: stem.stem_position,
      stem_position_is_percent: 0,
      stem_volume: stem.stem_volume,
      stem_filename: stemFilename,
      version_id: versionId,
    };

    return createStem(masterId, versionId, postData).then((res) => {
      return res.data.result;
    });
  };

  const createVersionEntry = (version, masterId) => {
    const postData = {
      version_name: version.version_name.trim(),
      master_id: parseInt(masterId),
      version_primary: version.version_primary,
      version_duration: 10,
      version_status: version.version_status,
    };

    return createVersion(masterId, postData).then((res) => {
      return res.data.result;
    });
  };

  const handleDeleteVersion = (masterId, versionId) => {
    return deleteVersion(masterId, versionId).then((res) => {
      return res.data.result;
    });
  };

  const handleDeleteStem = (masterId, versionId, stemId) => {
    return deleteStem(masterId, versionId, stemId).then((res) => {
      return res.data.result;
    });
  };

  const handleDeleteMixes = (masterId, versionId) => {
    return deleteMixes(masterId, versionId).then((res) => {
      return res.data.result;
    });
  };

  const handleDeleteStemRelationship = (masterId, versionId, relId) => {
    return deleteStemRelationship(masterId, versionId, relId).then((res) => {
      return res.data.result;
    });
  };

  const editVersionEntry = (masterId, versionId, postData) => {
    delete postData.version_id;
    return editVersion(masterId, versionId, postData).then((res) => {
      return res.data.result;
    });
  };

  const editStemEntry = (masterId, versionId, stemId, postData) => {
    delete postData.stem_id;
    return editStem(masterId, versionId, stemId, postData).then((res) => {
      return res.data.result;
    });
  };

  const editStemRelationshipEntry = (masterId, versionId, relId, postData) => {
    const newPostData = {};
    if (postData.stem_position !== null) {
      newPostData["versions-stem_library_position"] = postData.stem_position;
    }
    if (postData.stem_volume !== null) {
      newPostData["versions-stem_library_volume"] = postData.stem_volume;
    }
    return editStemRelationship(masterId, versionId, relId, newPostData).then(
      (res) => {
        return res.data.result;
      }
    );
  };

  const submitAsset = (blob, asset, extension) => {
    const skinId = skinConfig.skin_id;

    const postData = {
      assetBlob: blob,
      skinId: skinId,
      asset: asset,
      extension: extension,
    };
    return uploadFile(postData).then((response) => {
      return response;
    });
  };

  const handlehandleMetadata = () => {
    const postData = {};
    const fields = [
      "master_title",
      "master_subcategory",
      "master_subcategory",
      "catalog_id",
      "category_id",
      "master_key",
      "master_bpm",
      "master_display_date",
      "master_description",
    ];

    for (let i = 0; i < fields.length; i++) {
      if (!editedFields.includes(fields[i])) {
        continue;
      }
      let value = masterState[fields[i]];

      if (!validateField(fields[i], value)) {
        return false;
      }
      if (fields[i] === "master_display_date") {
        value = createTimeStamp(masterState.master_display_date);
      }
      if (typeof value === "string") {
        value = value.trim();
      }
      postData[fields[i]] = value;
    }
    if (!Object.keys(postData).length) {
      return true;
    }
    return editMaster(cardContent.master_id, postData).then((res) => {
      if (!res.data.result) {
        return false;
      }
      return true;
    });
  };

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...masterState };
    let value = event.target.value;
    if (input === "master_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setMasterState(currentState);
  };

  const validateField = (inputField, value) => {
    if (value == null || value.length === 0) {
      if (inputField === "master_artwork" && !imageSource) {
        return true;
      }
      handleEmptyField(inputField);
      return false;
    }
    if (inputField === "versions") {
      for (let i = 0; i < masterState["versions"].length; i++) {
        if (!masterState["versions"][i].version_name.length) {
          handleEmptyField("version_name");
          return false;
        }
        if (!masterState["versions"][i].version_stems.length) {
          const message = "Each version must have at least one audio file";
          setSnackbarVisibility({
            open: true,
            message: message,
          });
          return false;
        }
        for (
          let x = 0;
          x < masterState["versions"][i].version_stems.length;
          x++
        ) {
          if (!masterState["versions"][i].version_stems[x].stem_name.length) {
            handleEmptyField("stem_name");
            return false;
          }
        }
      }
    }
    if (inputField === "master_bpm") {
      const minTempo = 60;
      const maxTempo = 180;
      const isInteger = Number.isInteger(parseFloat(value));
      const isInRange =
        isInteger &&
        parseFloat(value) >= minTempo &&
        parseFloat(value) <= maxTempo;
      if (!isInteger || !isInRange) {
        const message = "Tempo must be a whole number between 60 and 180 BPM";
        setSnackbarVisibility({
          open: true,
          message: message,
        });
        return false;
      }
    }
    return true;
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...masterState };
    if (field === "master_display_date") {
      currentState[field] = createTimeStamp(value);
    } else {
      currentState[field] = value;
    }
    setMasterState(currentState);
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "master_title":
        message = "Please enter a title";
        break;
      case "master_subcategory":
        message = "Please enter a subtitle";
        break;
      case "category_id":
        message = "Please select a category";
        break;
      case "catalog_id":
        message = "Please select a catalog";
        break;
      case "master_key":
        message = "Please select a key";
        break;
      case "master_bpm":
        message = "Please enter a tempo";
        break;
      case "version_name":
        message = "Version name cannot be empty";
        break;
      case "stem_name":
        message = "Stem name cannot be empty";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const convertToBase64 = async (url) => {
    return Axios.get(url, {
      responseType: "blob",
    })
      .then(async (response) => {
        if (!response) {
          return null;
        }
        const blob = response.data;
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
        });
      })
      .catch((err) => {
        return null;
      });
  };

  const categories = sortArray(allCategories, "category_name");
  const catalogs = sortArray(allCatalogs, "catalog_name");

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Save",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleEditMaster,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle
        title={titleCase(
          sanitizeTitle(
            masterState ? masterState.master_title : cardContent.master_title
          )
        )}
      />
      <React.Fragment>
        {masterState && (
          <Tabs
            className={classes.dialogTabs}
            value={activeTab}
            onChange={handleTabChange}
            centered
          >
            <Tab label="Metadata" />
            <Tab label="Content" />
            <Tab label="Tags" />
            <Tab label="Publishing" />
            <Tab label="Artwork" />
            <Tab label="Distribution" />
          </Tabs>
        )}
        <CustomDialogContent>
          {masterState ? (
            <span>
              {activeTab === 0 && (
                <Metadata
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleDateChange={handleFieldChange}
                  categories={categories}
                  catalogs={catalogs}
                  keys={sortedKeys}
                  data={masterState}
                  disabled={loading}
                  access={access}
                />
              )}
              {activeTab === 1 && (
                <Versions
                  disabled={loading}
                  access={access}
                  versions={masterState.versions}
                  fieldName={`versions`}
                  handleChange={handleFieldChange}
                  stemLibrary={stemLibrary}
                  skinConfig={skinConfig}
                  masterState={masterState}
                  selectedGroup={selectedGroup}
                  loading={loading}
                />
              )}
              {activeTab === 2 && (
                <Tags
                  handleSelect={handleFieldChange}
                  disabled={loading}
                  access={access}
                  allTags={allTags}
                  selectedTags={masterState.master_tags}
                  setSnackbarVisibility={setSnackbarVisibility}
                  fieldName={`master_tags`}
                  loading={loading}
                />
              )}
              {activeTab === 3 && (
                <ChipSelector
                  allData={allWorks}
                  setAllData={setAllWorks}
                  selectedData={masterState.works}
                  setSnackbarVisibility={setSnackbarVisibility}
                  handleSelect={handleFieldChange}
                  fieldName={`works`}
                  disabled={loading}
                  access={access}
                  chipType={`Work`}
                  allowCreate={false}
                  options={{
                    limit: 20,
                  }}
                  isAsync={true}
                  asyncFunc={searchWorks}
                />
              )}
              {activeTab === 4 && (
                <Artwork
                  setLoading={setLoading}
                  access={access}
                  loading={loading}
                  selectedImage={masterState.master_artwork}
                  handleArtworkChange={handleFieldChange}
                  imageSource={imageSource}
                  setImageSource={setImageSource}
                  fieldName={`master_artwork`}
                />
              )}
              {activeTab === 5 && (
                <Distribution
                  setLoading={setLoading}
                  access={access}
                  loading={loading}
                  availableGroups={availableGroups}
                  selectedGroups={masterState.groups}
                  handleSelectedGroups={handleFieldChange}
                  fieldName={`groups`}
                />
              )}
            </span>
          ) : buildStatus.length ? (
            <BuildMessage icon={getBuildIcon()}>
              {getBuildMessage()}
            </BuildMessage>
          ) : (
            <CircularProgress color="secondary" className={classes.progress} />
          )}
        </CustomDialogContent>
      </React.Fragment>

      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || editedFields.length === 0 || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedContentCard.propTypes = {
  cardContent: PropTypes.object.isRequired,
  allCategories: PropTypes.array.isRequired,
  allCatalogs: PropTypes.array.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  skinConfig: PropTypes.object.isRequired,
  availableGroups: PropTypes.array.isRequired,
  updateAvailableGroups: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
};

const ContentCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedContentCard);

export default ContentCard;
