import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
} from "@material-ui/core";
import {
  sanitizeTitle,
  titleCase,
  translateKey,
  formatDisplayDate,
} from "../helpers/SharedFunctions";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../library/dialog/CustomDialogContent";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import { connect } from "react-redux";
import { setDialogVisibility } from "../actions/index";

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const useStyles = makeStyles((theme) => ({
  captionHeader: {
    color: theme.palette.text.secondary,
  },
  sectionWrapper: {
    padding: theme.spacing(2),
  },
  creditBox: {
    flex: 1,
    overflow: "scroll",
    padding: `0px ${theme.spacing(1)}px`,
  },
  credits: {
    marginBottom: theme.spacing(1),
  },
  expansionPanelSummaryContent: {
    margin: "0px !important",
  },
  expansionPanelSummaryExpandIcon: {
    padding: theme.spacing(1),
  },
  expansionPanelSummaryRoot: {
    minHeight: "0px !important",
  },
  expansionPanelRoot: {
    minHeight: 0,
    margin: "0px !important",
    boxShadow: "none",
    "&::before": {
      opacity: "1 !important",
    },
  },
  details: {
    padding: theme.spacing(1),
  },
}));

const ConnectedInfoContent = (props) => {
  const { master, info, setDialogVisibility } = props;
  const { credits, tags, availableVersions } = info;
  const title = titleCase(sanitizeTitle(master.master_title));

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
      fullScreen: false,
      fullWidth: false,
    };
    setDialogVisibility(dialogContent);
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
  ];

  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomDialogTitle title={title} />
      <CustomDialogContent>
        <div>
          <div className={classes.sectionWrapper}>
            <Typography variant="body2">
              <span className={classes.captionHeader}>Title: </span>
              {title}
              <br />
              <span className={classes.captionHeader}>Subtitle: </span>
              {titleCase(master.master_subcategory)}
              <br />
              <span className={classes.captionHeader}>Category: </span>
              {titleCase(master.category_name)}
              <br />
              <span className={classes.captionHeader}>Description: </span>
              {titleCase(master.master_description)}
              <br />
              <span className={classes.captionHeader}>Key: </span>
              {translateKey(master.master_key)}
              <br />
              <span className={classes.captionHeader}>Tempo: </span>
              {Math.round(master.master_bpm)}
              <br />
              <span className={classes.captionHeader}>
                Available Versions:{" "}
              </span>
              {availableVersions.map(
                (version, index) =>
                  `${index ? ", " : ""}${titleCase(version.version_name)}`
              )}
              <br />
              <span className={classes.captionHeader}>Tags: </span>
              {Boolean(tags.length)
                ? tags.map(
                    (tag, index) =>
                      `${index ? ", " : ""}${titleCase(tag.tag_name)}`
                  )
                : `None`}
              <br />
              <span className={classes.captionHeader}>Date: </span>
              {formatDisplayDate(master.master_display_date, "md")}
            </Typography>
          </div>
        </div>
        {Boolean(credits.length) && (
          <Accordion
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelRootExpanded,
            }}
          >
            <AccordionSummary
              classes={{
                root: classes.expansionPanelSummaryRoot,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
              expandIcon={<ExpandMore />}
            >
              <Typography variant="body2">
                <span className={classes.captionHeader}>Credits: </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.creditBox}>
                {credits.map((credit, index) => (
                  <div key={index} className={classes.credits}>
                    <Typography variant="body2">
                      <span className={classes.captionHeader}>Title: </span>
                      {titleCase(
                        credit.registered_work_title.replace(/&amp;/g, "&")
                      )}
                      <br />
                      <span className={classes.captionHeader}>Artist: </span>
                      {titleCase(
                        credit.credits.join(", ").replace(/&amp;/g, "&")
                      )}
                      <br />
                    </Typography>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </CustomDialogContent>
      <CustomDialogActions actionButtons={actionButtons} />
    </React.Fragment>
  );
};

ConnectedInfoContent.propTypes = {
  master: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
};

const InfoContent = connect(null, mapDispatchToProps)(ConnectedInfoContent);

export default InfoContent;
