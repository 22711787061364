import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../library/dialog/CustomDialogContent";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import { connect } from "react-redux";
import {
  setDialogVisibility,
  setSnackbarVisibility,
  setTermsAcceptance,
} from "../actions/index";
import { acceptTerms, getTerms, getAcceptance } from "../helpers/ApiCalls";
import { titleCase } from "../helpers/SharedFunctions";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Divider,
  CircularProgress,
} from "@material-ui/core";

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setTermsAcceptance: (termsAcceptance) =>
      dispatch(setTermsAcceptance(termsAcceptance)),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    skinConfig: state.skinConfig,
    termsAcceptance: state.termsAcceptance,
  };
};

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: "12px",
  },
  confirmWrapper: {
    padding: theme.spacing(2),
    margin: 0,
  },
  progress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
  checkboxWrapper: {
    flexGrow: 0,
  },
  iframeWrapper: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const ConnectedTermsOfService = (props) => {
  const {
    termsAcceptance,
    userData,
    skinConfig,
    setDialogVisibility,
    setSnackbarVisibility,
    setTermsAcceptance,
  } = props;
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(null);

  useEffect(() => {
    !agreement &&
      getTerms(1).then((res) => {
        setAgreement(res[0]);
      });
  }, []);

  const handleAccept = () => {
    if (!isChecked) {
      setSnackbarVisibility({
        open: true,
        message: "Please acknowledge acceptance of terms",
      });
      return;
    }
    setLoading(true);
    const params = {
      user_id: userData.user_id,
      skin_id: skinConfig.skin_id,
      agreement_version_id: agreement.agreement_version_id,
    };
    acceptTerms(params).then((res) => {
      getAcceptance(skinConfig.skin_id, 1).then((res) => {
        setTermsAcceptance(res[0]);
        setLoading(false);
        handleDialogClose();
        setSnackbarVisibility({
          open: true,
          message: "Terms of service accepted successfully",
        });
      });
    });
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
      fullScreen: false,
      fullWidth: false,
    };
    setDialogVisibility(dialogContent);
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      disabled: loading,
      fn: handleDialogClose,
    },
    {
      label: !termsAcceptance ? "Accept" : "Accepted",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      disabled: !!termsAcceptance,
      fn: handleAccept,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle title="Terms of Service" />
      <CustomDialogContent>
        {agreement && agreement.agreement_version_hash ? (
          <div className={classes.container}>
            <div className={classes.iframeWrapper}>
              <iframe
                className={classes.iframe}
                src={`https://app.termly.io/document/terms-of-service/${agreement.agreement_version_hash}`}
                title="Terms of Service"
                frameBorder="0"
              ></iframe>
            </div>
            <div className={classes.checkboxWrapper}>
              <Divider className={classes.divider} />
              <FormControlLabel
                classes={{
                  label: classes.checkboxLabel,
                  root: classes.confirmWrapper,
                }}
                style={{ display: "table" }}
                control={
                  <div style={{ display: "table-cell" }}>
                    <Checkbox
                      checked={!!termsAcceptance || isChecked}
                      disabled={!!termsAcceptance}
                      onChange={() => setIsChecked(!isChecked)}
                      name="checkedB"
                      color="secondary"
                    />
                  </div>
                }
                label={
                  !termsAcceptance
                    ? `I acknowledge that I have read and understand the terms of this
        agreement and that I am authorized to accept on behalf of the company.`
                    : `${titleCase(
                        termsAcceptance.user_first_name
                      )} ${titleCase(
                        termsAcceptance.user_last_name
                      )} accepted terms on ${
                        termsAcceptance.agreement_acceptance_created_date
                      }`
                }
                labelPlacement="end"
              />
            </div>
          </div>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </CustomDialogContent>
      <CustomDialogActions actionButtons={actionButtons} loading={loading} />
    </React.Fragment>
  );
};

ConnectedTermsOfService.propTypes = {
  termsAcceptance: PropTypes.object,
  userData: PropTypes.object.isRequired,
  skinConfig: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setTermsAcceptance: PropTypes.func.isRequired,
};

const Terms = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTermsOfService);

export default Terms;
