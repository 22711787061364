import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles } from "@material-ui/core";
import { getAllCategories, editCategory } from "../../helpers/ApiCalls";
import CategoryCard from "./CategoryCard";
import EnhancedTable from "../../library/EnhancedTable";
import {
	titleCase,
	formatDate,
	createSkeletonData,
} from "../../helpers/SharedFunctions";
import { connect } from "react-redux";
import {
	setSnackbarVisibility,
	setSearchState,
	setDialogVisibility,
	resetSearch,
} from "../../actions/index";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
	},
	nameColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "36%",
		},
		paddingLeft: theme.spacing(1),
	},
	statusColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "12%",
			flexDirection: "row",
		},
		[theme.breakpoints.up("lg")]: {
			flexDirection: "row-reverse",
		},
	},
	dateColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "26%",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		searching: state.searching,
		activeSearch: state.activeSearch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSnackbarVisibility: (snackbarContent) =>
			dispatch(setSnackbarVisibility(snackbarContent)),
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
		setDialogVisibility: (dialogContent) =>
			dispatch(setDialogVisibility(dialogContent)),
		resetSearch: () => dispatch(resetSearch()),
	};
};

const ConnectedCategoryList = (props) => {
	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("category_created_date");
	const [access, setAccess] = useState({
		create: false,
		read: false,
		update: false,
		delete: false,
	});
	const classes = useStyles();
	const {
		permissions,
		setSearchState,
		searching,
		setDialogVisibility,
		setSnackbarVisibility,
		resetSearch,
		activeSearch,
	} = props;
	const {
		nameColumn,
		mdHidden,
		dateColumn,
		lgHidden,
		xsHidden,
		statusColumn,
	} = classes;

	const handleStatusChange = (item) => {
		const found = items.findIndex(
			(x) => x.category_id === item.category_id
		);
		let currentItems = [...items];
		const status = (+!Boolean(parseInt(item.category_status))).toString();
		currentItems[found].category_status = status;
		const originalDate = currentItems[found].category_modified_date;
		currentItems[found].category_modified_date = new Date()
			.toISOString()
			.slice(0, 19)
			.replace("T", " ");
		setItems(currentItems);
		const postData = { category_status: status };
		editCategory(item.category_id, postData).then((res) => {
			let message;
			if (res && res.data && res.data.result) {
				message = "Update successful";
			} else {
				message = "An error occured. Please contact support";
				currentItems[found].category_status = (+!Boolean(
					parseInt(status)
				)).toString();
				setItems(currentItems);
				currentItems[found].category_modified_date = originalDate;
			}
			setSnackbarVisibility({
				open: true,
				message: message,
			});
		});
	};

	const columns = [
		{
			id: "category_name",
			numeric: false,
			disablePadding: false,
			label: "Category Name",
			classes: [nameColumn],
		},
		{
			id: "category_created_date",
			numeric: false,
			disablePadding: false,
			label: "Created",
			classes: [mdHidden, dateColumn],
		},
		{
			id: "category_modified_date",
			numeric: false,
			disablePadding: false,
			label: "Modified",
			classes: [lgHidden, dateColumn],
		},
		{
			id: "category_status",
			numeric: true,
			disablePadding: false,
			label: "Status",
			type: "switch",
			classes: [xsHidden, statusColumn],
			fn: handleStatusChange,
		},
	];

	useEffect(() => {
		const permissionObj = {
			create: permissions.indexOf(13) > -1,
			read: permissions.indexOf(14) > -1,
			update: permissions.indexOf(15) > -1,
			delete: permissions.indexOf(16) > -1,
		};
		setAccess(permissionObj);
		return function cleanup() {
			resetSearch();
		};
	}, []);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		doSearch();
	}, [rowsPerPage, orderBy, orderDir, activeSearch]);

	useEffect(() => {
		page !== null && doSearch();
	}, [page]);

	const doSearch = () => {
		setItems(createSkeletonData(columns, rowsPerPage));
		setSearchState(true);
		const { query } = activeSearch;
		let queryString = `limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;
		if (query.length) {
			queryString += `&q=${encodeURIComponent(query)}`;
		}

		getAllCategories(queryString).then((res) => {
			setItems(res.categories);
			setTotalItems(res.total);
			setSearchState(false);
		});
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	const formatData = (data, field) => {
		switch (field) {
			case "category_name":
				return titleCase(data);
			case "category_created_date":
			case "category_modified_date":
			case "category_expiry_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			case "category_status":
				return parseInt(data) ? "Active" : "Inactive";
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedRow(index);
		const dialogContent = {
			open: true,
			body: (
				<CategoryCard
					setSnackbarVisibility={setSnackbarVisibility}
					setDialogVisibility={setDialogVisibility}
					cardContent={value}
					doSearch={doSearch}
					access={access}
				/>
			),
			fullWidth: true,
			fullScreen: "sm",
			minHeight: true,
			maxWidth: "lg",
		};
		setDialogVisibility(dialogContent);
	};

	return (
		access.read && (
			<Paper className={classes.container}>
				<EnhancedTable
					items={items}
					columns={columns}
					handleRowClick={handleRowClick}
					formatData={formatData}
					selectedRow={selectedRow}
					classNames={classes}
					numberOfActions={0}
					idField={"user_id"}
					orderBy={orderBy}
					orderDir={orderDir}
					handleArrowNav={handleArrowNav}
					totalItems={totalItems}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					page={page}
					setPage={setPage}
					searching={searching}
					setOrderDir={setOrderDir}
					setOrderBy={setOrderBy}
				/>
			</Paper>
		)
	);
};

ConnectedCategoryList.propTypes = {
	setSnackbarVisibility: PropTypes.func.isRequired,
	permissions: PropTypes.array.isRequired,
	setSearchState: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	setDialogVisibility: PropTypes.func.isRequired,
	resetSearch: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
};

const CategoryList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedCategoryList);

export default CategoryList;
