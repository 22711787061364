import {
	UPDATE_USER_BALANCE,
	UPDATE_SKIN_CONFIG,
	SET_DIALOG_VISIBILITY,
	ADD_TO_CART,
	REMOVE_FROM_CART,
	INITIATE_CART,
	UPDATE_USER_DATA,
	UPDATE_AVAILABLE_GROUPS,
	UPDATE_ACTIVE_CATALOG,
	UPDATE_ACTIVE_GROUP,
	CLEAR_CART,
	SET_LOGIN_STATE,
	UPDATE_AVAILABLE_CATEGORIES,
	SET_SNACKBAR_VISIBILITY,
	UPDATE_AVAILABLE_CATALOGS,
	UPDATE_SEARCH,
	UPDATE_AVAILABLE_TAGS,
	SET_SEARCH_STATE,
	UPDATE_ALL_CATALOGS,
	UPDATE_ALL_CATEGORIES,
	RESET_SEARCH,
	SET_TERMS_ACCEPTANCE,
} from "../constants/action-types";

const initialState = {
	userBalance: 0,
	skinConfig: {},
	userData: {},
	cart: [],
	groups: [],
	dialog: {
		open: false,
		body: null,
		maxWidth: "md",
		fullScreen: false,
		fullWidth: false,
		minHeight: false,
	},
	snackbar: {
		open: false,
		message: "",
	},
	isLoggedIn: false,
	availableCategories: [],
	availableCatalogs: [],
	availableGroups: [],
	availableTags: [],
	activeGroup: null,
	activeCatalog: null,
	activeSearch: {
		query: "",
		categories: [],
		keys: [],
		tags: [],
		tempos: [],
		durations: [],
	},
	allCategories: [],
	allCatalogs: [],
	searching: false,
	termsAcceptance: null,
};

function rootReducer(state = initialState, action) {
	if (action.type === UPDATE_USER_BALANCE) {
		return Object.assign({}, state, {
			userBalance: action.payload,
		});
	}
	if (action.type === SET_LOGIN_STATE) {
		return Object.assign({}, state, {
			isLoggedIn: action.payload,
		});
	}
	if (action.type === UPDATE_SKIN_CONFIG) {
		return Object.assign({}, state, {
			skinConfig: action.payload,
		});
	}
	if (action.type === SET_DIALOG_VISIBILITY) {
		return Object.assign({}, state, {
			dialog: action.payload,
		});
	}
	if (action.type === SET_SNACKBAR_VISIBILITY) {
		return Object.assign({}, state, {
			snackbar: action.payload,
		});
	}
	if (action.type === ADD_TO_CART) {
		return Object.assign({}, state, {
			cart: state.cart.concat(action.payload),
		});
	}
	if (action.type === INITIATE_CART) {
		return Object.assign({}, state, {
			cart: action.payload,
		});
	}
	if (action.type === REMOVE_FROM_CART) {
		return Object.assign({}, state, {
			cart: state.cart.filter((item) => item !== action.payload),
		});
	}
	if (action.type === CLEAR_CART) {
		return Object.assign({}, state, {
			cart: [],
		});
	}
	if (action.type === UPDATE_USER_DATA) {
		return Object.assign({}, state, {
			userData: action.payload,
		});
	}
	if (action.type === UPDATE_AVAILABLE_GROUPS) {
		return Object.assign({}, state, {
			availableGroups: action.payload,
		});
	}
	if (action.type === UPDATE_AVAILABLE_CATALOGS) {
		return Object.assign({}, state, {
			availableCatalogs: action.payload,
		});
	}
	if (action.type === UPDATE_AVAILABLE_CATEGORIES) {
		return Object.assign({}, state, {
			availableCategories: action.payload,
		});
	}

	if (action.type === UPDATE_ALL_CATEGORIES) {
		return Object.assign({}, state, {
			allCategories: action.payload,
		});
	}

	if (action.type === UPDATE_ALL_CATALOGS) {
		return Object.assign({}, state, {
			allCatalogs: action.payload,
		});
	}

	if (action.type === UPDATE_AVAILABLE_TAGS) {
		return Object.assign({}, state, {
			availableTags: action.payload,
		});
	}

	if (action.type === UPDATE_ACTIVE_GROUP) {
		return Object.assign({}, state, {
			activeGroup: action.payload,
		});
	}
	if (action.type === UPDATE_ACTIVE_CATALOG) {
		return Object.assign({}, state, {
			activeCatalog: action.payload,
		});
	}

	if (action.type === UPDATE_SEARCH) {
		return Object.assign({}, state, {
			activeSearch: action.payload,
		});
	}

	if (action.type === RESET_SEARCH) {
		return Object.assign({}, state, {
			activeSearch: initialState.activeSearch,
		});
	}

	if (action.type === SET_SEARCH_STATE) {
		return Object.assign({}, state, {
			searching: action.payload,
		});
	}

	if (action.type === SET_TERMS_ACCEPTANCE) {
		return Object.assign({}, state, {
			termsAcceptance: action.payload,
		});
	}

	return state;
}

export default rootReducer;
