import React from "react";
import PropTypes from "prop-types";
import {
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	tableHead: {
		padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
	},
	diabledSort: {
		cursor: "default",
	},
}));

const EnhancedTableHead = (props) => {
	const {
		orderDir,
		orderBy,
		columns,
		onRequestSort,
		numberOfActions,
	} = props;

	const classes = useStyles();

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{columns.map((row) => (
					<TableCell
						key={row.id}
						align={row.numeric ? "right" : "left"}
						padding={row.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === row.id ? orderDir : false}
						className={
							row.classes &&
							row.classes.length &&
							clsx(classes.tableHead, row.classes)
						}
					>
						<Tooltip
							title={!row.disableSort ? "Sort" : ""}
							placement={
								row.numeric ? "bottom-end" : "bottom-start"
							}
							enterDelay={300}
						>
							<TableSortLabel
								active={orderBy === row.id}
								direction={orderDir}
								onClick={
									!row.disableSort
										? createSortHandler(row.id)
										: null
								}
								hideSortIcon={row.disableSort}
								className={
									row.disableSort
										? classes.diabledSort
										: "undefined"
								}
							>
								{row.label}
							</TableSortLabel>
						</Tooltip>
					</TableCell>
				))}
				{Array(numberOfActions)
					.fill(0)
					.map((value, i) => (
						<TableCell key={i} />
					))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	orderBy: PropTypes.string.isRequired,
	orderDir: PropTypes.string.isRequired,
	columns: PropTypes.array.isRequired,
	numberOfActions: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
