import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@material-ui/core";
import { getSha } from "../../../helpers/SharedFunctions";
import VersionRow from "./VersionRow";
import ContentDropzone from "./ContentDropzone";
import Kali from "@descript/kali";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: `${theme.spacing(2)}px 0px`,
    float: "right",
  },
  textField: {
    marginBottom: theme.spacing(0.5),
    textAlign: "center",
  },
  versionWrapper: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const Versions = (props) => {
  const {
    access,
    versions,
    handleChange,
    fieldName,
    stemLibrary,
    skinConfig,
    masterState,
    selectedGroup,
    loading,
  } = props;
  const [open, setOpen] = useState(0);

  const [multitrackKeyboardActive, setMultitrackKeyboardActive] = useState(
    true
  );
  const classes = useStyles();

  const handleAddButtonClick = () => {
    let currentVersions = [...versions];
    const newVersion = createVersion();
    currentVersions.push(newVersion);
    handleChange(fieldName, currentVersions);
  };

  const createVersion = () => {
    const id = getSha();
    return {
      version_id: id,
      version_name: `Version ${id}`,
      version_status: versions.length === 0,
      version_primary: versions.length === 0,
      version_stems: [],
    };
  };

  const setFirstVersionAsPrimary = () => {
    let currentVersions = [...versions];
    currentVersions[0].version_primary = true;
    handleChange(fieldName, currentVersions);
  };

  const handleDelete = (key) => {
    let currentVersions = [...versions];
    currentVersions[key].version_primary && setFirstVersionAsPrimary();
    currentVersions.splice(key, 1);
    handleChange(fieldName, currentVersions);
  };

  const updateVersion = (index, versionState) => {
    let currentVersions = [...versions];
    if (versionState.version_primary) {
      currentVersions.forEach((currentVersion, key) => {
        if (key !== index) currentVersions[key].version_primary = false;
      });
    }
    currentVersions[index] = versionState;
    handleChange(fieldName, currentVersions);
  };

  const addFiles = (acceptedFile, index) => {
    let currentVersions = [...versions];

    if (currentVersions.length === 0) {
      const newVersion = createVersion();
      currentVersions.push(newVersion);
    }
    acceptedFile.forEach((acceptedFile) => {
      const id = getSha();
      const stem = {
        stem_id: id,
        stem_name: `Stem ${id}`,
        stem_position: 0,
        stem_volume: 1,
        stem_library_id: null,
        blob: acceptedFile,
      };
      currentVersions[index].version_stems.push(stem);
    });
    handleChange(fieldName, currentVersions);
  };

  const getBuffer = async (actx, stem) => {
    let audioBuffer;

    await new Promise(function(resolve) {
      const request = new XMLHttpRequest();
      request.open("GET", stem, true);
      request.responseType = "arraybuffer";
      request.onload = function() {
        const audioData = request.response;
        if (audioData) {
          actx.decodeAudioData(
            audioData,
            function(buffer) {
              audioBuffer = buffer;
              resolve();
            },
            function(e) {
              console.log(e.err);
            }
          );
        }
      };
      request.send();
    });
    return audioBuffer;
  };

  const insertLibraryStems = async (libraryStems, index) => {
    let currentVersions = [...versions];

    for (const libraryStem of libraryStems) {
      const id = getSha();
      const filename = `${skinConfig.skin_masters_url}/${libraryStem.stem_library_file_filename}`;
      const masterBpm = parseInt(masterState.master_bpm);
      const stemBpm = Math.round(libraryStem.stem_library_bpm);
      let stretch = false;

      if (
        Number.isInteger(stemBpm) &&
        Number.isInteger(masterBpm) &&
        stemBpm !== masterBpm
      ) {
        stretch = true;
      }

      const stem = {
        stem_id: id,
        stem_name: libraryStem.stem_library_name,
        stem_position: 0,
        stem_volume: 1,
        stem_library_id: libraryStem.stem_library_id,
        blob: filename,
      };
      if (stretch) {
        const stretchRate = masterBpm / stemBpm;
        const context = new AudioContext();
        const audiobuffer = await getBuffer(context, filename);
        const inputData = audiobuffer.getChannelData(0);
        const output = doStretch(inputData, stretchRate, 1, context);
        const outputAudioBuffer = context.createBuffer(
          1,
          output.length,
          context.sampleRate
        );
        outputAudioBuffer.getChannelData(0).set(output);
        stem.blob = outputAudioBuffer;
        currentVersions[index].version_stems.push(stem);
      } else {
        currentVersions[index].version_stems.push(stem);
      }
    }
    handleChange(fieldName, currentVersions);
  };

  const doStretch = (inputData, stretchFactor, numChannels, context) => {
    const numInputFrames = inputData.length / numChannels;
    const bufsize = 4096 * numChannels;
    const kali = new Kali(numChannels);
    kali.setup(context.sampleRate, stretchFactor, false);

    // Create an array for the stretched output
    const completed = new Float32Array(
      Math.floor((numInputFrames / stretchFactor) * numChannels + 1)
    );

    let inputOffset = 0;
    let completedOffset = 0;
    let flushed = false;

    while (completedOffset < completed.length) {
      // Read stretched samples into our output array
      completedOffset += kali.output(
        completed.subarray(
          completedOffset,
          Math.min(completedOffset + bufsize, completed.length)
        )
      );

      if (inputOffset < inputData.length) {
        // If we have more data to write, write it
        const dataToInput = inputData.subarray(
          inputOffset,
          Math.min(inputOffset + bufsize, inputData.length)
        );
        inputOffset += dataToInput.length;

        // Feed Kali samples
        kali.input(dataToInput);
        kali.process();
      } else if (!flushed) {
        // Flush if we haven't already
        kali.flush();
        flushed = true;
      }
    }

    return completed;
  };

  return (
    <div className={classes.versionWrapper}>
      {!!versions.length && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Version Name</TableCell>
                <TableCell className={classes.textField}>Stems</TableCell>
                <TableCell className={classes.textField}>Primary</TableCell>
                <TableCell className={classes.textField}>Status</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {versions.map((value, index) => (
                <VersionRow
                  key={index}
                  index={index}
                  row={value}
                  handleDelete={handleDelete}
                  updateVersion={updateVersion}
                  addFiles={addFiles}
                  access={access}
                  open={open}
                  setOpen={setOpen}
                  versions={versions}
                  setFirstVersionAsPrimary={setFirstVersionAsPrimary}
                  multitrackKeyboardActive={multitrackKeyboardActive}
                  setMultitrackKeyboardActive={setMultitrackKeyboardActive}
                  stemLibrary={stemLibrary}
                  insertLibraryStems={insertLibraryStems}
                  selectedGroup={selectedGroup}
                  loading={loading}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.buttonWrapper}>
        {!!versions.length && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={handleAddButtonClick}
            disabled={loading}
          >
            Add Version
          </Button>
        )}
      </div>
      {!versions.length && (
        <ContentDropzone
          addFiles={addFiles}
          access={access}
          index={0}
          contentType={`audio`}
          accept={"audio/mpeg"}
          disabled={loading}
          loading={loading}
        />
      )}
    </div>
  );
};

Versions.propTypes = {
  access: PropTypes.object.isRequired,
  versions: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  stemLibrary: PropTypes.array.isRequired,
  skinConfig: PropTypes.object.isRequired,
  masterState: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Versions;
