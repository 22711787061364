export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";
export const UPDATE_SKIN_CONFIG = "UPDATE_SKIN_CONFIG";
export const SET_DIALOG_VISIBILITY = "SET_DIALOG_VISIBILITY";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const INITIATE_CART = "INITIATE_CART";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_AVAILABLE_GROUPS = "UPDATE_AVAILABLE_GROUPS";
export const UPDATE_AVAILABLE_CATALOGS = "UPDATE_AVAILABLE_CATALOGS";
export const UPDATE_ACTIVE_GROUP = "UPDATE_ACTIVE_GROUP";
export const UPDATE_ACTIVE_CATALOG = "UPDATE_ACTIVE_CATALOG";
export const UPDATE_AVAILABLE_CATEGORIES = "UPDATE_AVAILABLE_CATEGORIES";
export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const SET_SNACKBAR_VISIBILITY = "SET_SNACKBAR_VISIBILITY";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const RESET_SEARCH = "RESET_SEARCH";
export const UPDATE_AVAILABLE_TAGS = "UPDATE_AVAILABLE_TAGS";
export const SET_SEARCH_STATE = "SET_SEARCH_STATE";
export const UPDATE_ALL_CATEGORIES = "UPDATE_ALL_CATEGORIES";
export const UPDATE_ALL_CATALOGS = "UPDATE_ALL_CATALOGS";
export const SET_TERMS_ACCEPTANCE = "SET_TERMS_ACCEPTANCE";
