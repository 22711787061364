import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import UserList from "./users/UserList";
import GroupList from "./groups/GroupList";
import WorksList from "./works/WorksList";
import BuildList from "./builds/BuildList";
import ContentList from "./content/ContentList";
import CatalogList from "./catalogs/CatalogList";
import CategoryList from "./categories/CategoryList";
import Settings from "./settings/Settings";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { setTermsAcceptance, setDialogVisibility } from "../actions/index";
import { getAcceptance } from "../helpers/ApiCalls";
import TermsOfService from "../library/TermsOfService";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    flex: 1,
    position: "relative",
    maxWidth: "100vw",
  },
  root: {
    display: "flex",
    flexFlow: "column",
    overflow: "hidden",
    position: "absolute",
    top: 64,
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}px 0px 0px 0px`,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    skinConfig: state.skinConfig,
    termsAcceptance: state.termsAcceptance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTermsAcceptance: (termsAcceptance) =>
      dispatch(setTermsAcceptance(termsAcceptance)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedAdmin = (props) => {
  const {
    userData,
    skinConfig,
    setTermsAcceptance,
    setDialogVisibility,
  } = props;
  const classes = useStyles();
  const permissions = userData.permissions.split`,`.map((x) => +x);

  useEffect(() => {
    getAcceptance(skinConfig.skin_id, 1).then((res) => {
      if (!res.length && parseInt(userData.role_id) === 5) {
        viewTerms();
        return;
      }
      setTermsAcceptance(res[0]);
    });
  }, []);

  const viewTerms = () => {
    const dialogContent = {
      open: true,
      fullWidth: true,
      fullScreen: "xs",
      body: <TermsOfService />,
      minHeight: true,
    };
    setDialogVisibility(dialogContent);
  };

  return (
    <div className={classes.root}>
      <Switch>
        {permissions.indexOf(6) > -1 && (
          <Route
            exact
            path="/admin/content"
            render={(props) => <ContentList permissions={permissions} />}
          />
        )}
        {permissions.indexOf(18) > -1 && (
          <Route
            exact
            path="/admin/works"
            render={(props) => <WorksList permissions={permissions} />}
          />
        )}
        {permissions.indexOf(26) > -1 && (
          <Route
            exact
            path="/admin/builds"
            render={(props) => <BuildList permissions={permissions} />}
          />
        )}
        <Route exact path="/admin/settings" render={(props) => <Settings />} />
        {permissions.indexOf(2) > -1 && (
          <Route
            exact
            path="/admin/users"
            render={(props) => <UserList permissions={permissions} />}
          />
        )}
        {permissions.indexOf(10) > -1 && (
          <Route
            exact
            path="/admin/groups"
            render={(props) => <GroupList permissions={permissions} />}
          />
        )}
        {permissions.indexOf(14) > -1 && (
          <Route
            exact
            path="/admin/catalogs"
            render={(props) => <CatalogList permissions={permissions} />}
          />
        )}
        {permissions.indexOf(30) > -1 && (
          <Route
            exact
            path="/admin/categories"
            render={(props) => <CategoryList permissions={permissions} />}
          />
        )}
        <Route render={() => <Redirect to="/admin" />} />
      </Switch>
    </div>
  );
};

ConnectedAdmin.propTypes = {
  userData: PropTypes.object.isRequired,
  skinConfig: PropTypes.object.isRequired,
  setTermsAcceptance: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
};

const Admin = connect(mapStateToProps, mapDispatchToProps)(ConnectedAdmin);

export default withRouter(Admin);
