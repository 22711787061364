import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inputValidation } from "../helpers/SharedFunctions";
import CustomizedTextField from "../library/CustomizedTextField";
import { connect } from "react-redux";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateUserData,
  setLogInState,
} from "../actions/index";
import { login } from "../helpers/ApiCalls";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../library/dialog/CustomDialogContent";
import { Link, makeStyles } from "@material-ui/core";

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    setLogInState: (logInState) => dispatch(setLogInState(logInState)),
  };
};

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ConnectedLoginCard = (props) => {
  const {
    setSnackbarVisibility,
    setDialogVisibility,
    history,
    updateUserData,
    setLogInState,
    setEmail,
  } = props;

  let initialData = {
    email: { value: "", valid: false },
    password: { value: "", valid: false },
    loading: false,
  };

  const [formState, setFormState] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    formState.email.value.length && setEmail(formState.email.value);
  }, [formState.email.value]);

  const handleBlur = (input) => (event) => {
    let valid = false;
    let message = "";
    const inputValue = event.target.value;

    const response = inputValidation(input, inputValue);
    valid = response.isValid;
    message = response.message;

    !valid &&
      setSnackbarVisibility({
        open: true,
        message: message,
      });
    let currentState = { ...formState };
    currentState[input].value = inputValue;
    currentState[input].valid = valid;
    setFormState(currentState);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...formState };
    currentState[input].value = event.target.value;
    setFormState(currentState);
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    history.push("/" + history.location.search);
    setDialogVisibility(dialogContent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      const fields = ["email", "password"];
      //create empty array to track valid fields
      const formValid = [];
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = formState[field].value;
        const response = inputValidation(field, value);
        if (!response.isValid) {
          setSnackbarVisibility({
            open: true,
            message: response.message,
          });
          setLoading(false);
        }
        //break on invalid field
        if (response.isValid) {
          formValid.push(true);
        } else {
          break;
        }
      }
      //check all fields in step are valid
      if (formValid.length === fields.length) {
        const postData = {
          username: formState.email.value,
          password: formState.password.value,
        };
        login(postData).then((user) => {
          if (user) {
            logInUser(user);
          } else {
            setSnackbarVisibility({
              open: true,
              message: "Invalid email address or password",
            });
            setLoading(false);
          }
        });
      }
    }
  };

  const logInUser = (data) => {
    localStorage.setItem("id_token", data.token);
    updateUserData(data);
    setLogInState(true);
    handleDialogClose();
  };

  const actionButtons = [
    {
      label: "Cancel",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Login",
      color: "secondary",
      type: "submit",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleSubmit,
    },
  ];

  const handleForgotPassword = (event) => {
    event.preventDefault();
    history.push("/forgot_password" + history.location.search);
  };

  return (
    <React.Fragment>
      <CustomDialogTitle title={`Login`} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <CustomDialogContent>
          <CustomizedTextField
            required
            fullWidth
            id={"email"}
            label={"Email"}
            value={formState.email.value}
            handleOnChange={handleChange("email")}
            handleOnBlur={handleBlur("email")}
            placeholder={"Email"}
          />
          <CustomizedTextField
            required
            fullWidth
            id={"password"}
            label={"Password"}
            type={"password"}
            value={formState.password.value}
            handleOnChange={handleChange("password")}
            handleOnBlur={handleBlur("password")}
            placeholder={"Password"}
          />
          <div className={classes.linkWrapper}>
            <Link href="#" onClick={handleForgotPassword} color="secondary">
              Forgot password?
            </Link>
          </div>
        </CustomDialogContent>

        <CustomDialogActions
          actionButtons={actionButtons}
          disabled={loading}
          loading={loading}
        />
      </form>
    </React.Fragment>
  );
};

ConnectedLoginCard.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  setLogInState: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
};

const LoginCard = connect(null, mapDispatchToProps)(ConnectedLoginCard);

export default withRouter(LoginCard);
