import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	portal: {
		width: "100%",
		height: "100%",
	},
});

function HomePagePortal(props) {
	const { classes } = props;
	return (
		<div className={classes.portal}>
			<iframe
				src={props.skinConfig.skin_homepage}
				title="Home"
				frameBorder="0"
				width="100%"
				height="100%"
			></iframe>
		</div>
	);
}

HomePagePortal.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePagePortal);
