import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Toolbar, Grid, Typography, withWidth } from "@material-ui/core";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 48,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  webLink: {
    color: "grey",
  },
  nightrainRef: {
    color: "grey",
  },
  footer: {
    minHeight: 48,
  },
});

const AppFooter = React.memo(function AppFooter(props) {
  const { classes, skinTitle, width } = props;

  return (
    <div position="fixed" className={classes.appBar}>
      <Toolbar className={classes.footer}>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={4} xs={false} />

          <Grid item sm={4} xs={6} align={width === "xs" ? "left" : "center"}>
            <Typography>
              © {new Date().getFullYear()} {skinTitle}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={6} align="right">
            <Typography className={classes.nightrainRef}>
              Delivered via{" "}
              <a className={classes.webLink} href="http://www.scoreandten.com">
                Nightrain
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
});

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  skinTitle: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles)(AppFooter));
