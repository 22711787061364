import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
import {
  titleCase,
  sortArray,
  searchAssociativeArray,
} from "../../helpers/SharedFunctions";
import CustomDialogActions from "../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../library/dialog/CustomDialogContent";
import Metadata from "./Metadata";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateAllCatalogs,
} from "../../actions/index";
import { connect } from "react-redux";
import {
  editCategory,
  getAllCatalogs,
  getCatalogsByCategory,
  createCategoryRelationship,
  removeCategoryRelationship,
} from "../../helpers/ApiCalls";
import Confirmation from "../../library/Confirmation";
import TransferList from "../../library/TransferList";

const useStyles = makeStyles((theme) => ({
  dialogTabs: {
    color: theme.palette.text.primary,
  },
}));

const mapStateToProps = (state) => {
  return {
    allCatalogs: state.allCatalogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateAllCatalogs: (allCatalogs) =>
      dispatch(updateAllCatalogs(allCatalogs)),
  };
};

const ConnectedContentCard = (props, ref) => {
  const {
    cardContent,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    doSearch,
    allCatalogs,
    updateAllCatalogs,
  } = props;

  const timerRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [categoryState, setCategoryState] = useState(null);
  const [editedFields, setEditedFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [originalCategoryData, setOriginalCategoryData] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const prepareInitialData = async () => {
    if (!allCatalogs.length) {
      const catalogResult = await getAllCatalogs();
      if (catalogResult && catalogResult.catalogs) {
        updateAllCatalogs(catalogResult.catalogs);
      }
    }
    const categoryId = cardContent.category_id;
    const categoryCatalogs = await getCatalogsByCategory(categoryId);
    const initialData = {
      category_id: cardContent.category_id,
      category_name: cardContent.category_name,
      category_status: cardContent.category_status,
      catalogs: categoryCatalogs,
    };
    setOriginalCategoryData(JSON.parse(JSON.stringify(initialData)));
    setCategoryState(initialData);
  };

  useEffect(() => {
    if (!categoryState) {
      return;
    }
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      compareStates();
    }, 1000);
  }, [categoryState]);

  useEffect(() => {
    prepareInitialData();
  }, []);

  const compareStates = (input, value) => {
    let currentEditedFields = [...editedFields];
    for (const [key] of Object.entries(categoryState)) {
      const hasChanged = hasFieldChanged(key);
      if (hasChanged && currentEditedFields.indexOf(key) === -1) {
        currentEditedFields.push(key);
        setEditedFields(currentEditedFields);
      } else if (!hasChanged && currentEditedFields.indexOf(key) !== -1) {
        currentEditedFields.splice(currentEditedFields.indexOf(key), 1);

        setEditedFields(currentEditedFields);
      }
    }
  };

  const evaluateDeepState = (originalArr, newArr, idField, fields) => {
    let hasChanged = false;
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalArr.length; ++i) {
      if (!searchAssociativeArray(originalArr[i][idField], newArr, idField)) {
        hasChanged = true;
        break;
      }
    }
    //check if current object was originally present
    for (i = 0; i < newArr.length; ++i) {
      let currentObj = newArr[i];
      let originalObj = searchAssociativeArray(
        currentObj[idField],
        originalArr,
        idField
      );
      if (!originalObj) {
        hasChanged = true;
        break;
      }
      //evaluate specified new object fields against original
      if (fields && fields.length) {
        let x = 0;
        for (x = 0; x < fields.length; ++x) {
          let field = fields[x];
          if (currentObj[field] !== originalObj[field]) {
            hasChanged = true;
            break;
          }
        }
      }
    }
    return hasChanged;
  };

  const hasFieldChanged = (field) => {
    const originalState = originalCategoryData[field];
    const newState = categoryState[field];

    switch (field) {
      case "catalogs":
        return evaluateDeepState(originalState, newState, "catalog_id", null);
      default:
    }
    return originalState !== newState;
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...categoryState };
    currentState[field] = value;
    setCategoryState(currentState);
  };

  const handleEditCategory = async () => {
    setLoading(true);
    let snackbarContent = {
      open: true,
      message: "An error occured. Please contact support",
    };
    if (!(await handleMetadata())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleCatalogs())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    doSearch();
    setLoading(false);
    snackbarContent.message = "Update Successful";
    setSnackbarVisibility(snackbarContent);
    closeDialog();
    return true;
  };

  const handleMetadata = () => {
    const postData = {};
    const fields = ["category_name"];

    for (let i = 0; i < fields.length; i++) {
      if (!editedFields.includes(fields[i])) {
        continue;
      }
      let value = categoryState[fields[i]];

      if (!validateField(fields[i], value)) {
        return false;
      }
      if (typeof value === "string") {
        value = value.trim();
      }
      postData[fields[i]] = value;
    }
    if (!Object.keys(postData).length) {
      return true;
    }
    return editCategory(cardContent.category_id, postData).then((res) => {
      if (!res.data.result) {
        return false;
      }
      return true;
    });
  };

  const handleCatalogs = async () => {
    if (!editedFields.includes("catalogs")) {
      return true;
    }

    const currentCatalogs = [...categoryState.catalogs];
    const originalCatalogs = [...originalCategoryData.catalogs];
    const catalogsToRemove = [];
    const catalogsToCreate = [];
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalCatalogs.length; ++i) {
      if (
        originalCatalogs.length &&
        !searchAssociativeArray(
          originalCatalogs[i]["catalog_id"],
          currentCatalogs,
          "catalog_id"
        )
      ) {
        catalogsToRemove.push(originalCatalogs[i]["catalog_id"]);
      }
    }
    //check if current object was originally present
    for (i = 0; i < currentCatalogs.length; ++i) {
      if (
        !searchAssociativeArray(
          currentCatalogs[i]["catalog_id"],
          originalCatalogs,
          "catalog_id"
        )
      ) {
        catalogsToCreate.push(currentCatalogs[i]["catalog_id"]);
      }
    }
    if (catalogsToRemove.length) {
      for (const catalog of catalogsToRemove) {
        removeCategoryRelationship(cardContent.category_id, catalog).then(
          (res) => {
            if (!res) {
              return false;
            }
          }
        );
      }
    }
    if (catalogsToCreate.length) {
      for (const catalog of catalogsToCreate) {
        const postData = {
          category_id: cardContent.category_id,
          catalog_id: catalog,
        };
        createCategoryRelationship(
          cardContent.category_id,
          catalog,
          postData
        ).then((res) => {
          if (!res) {
            return false;
          }
        });
      }
    }
    return true;
  };

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...categoryState };
    let value = event.target.value;
    if (input === "category_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setCategoryState(currentState);
  };

  const validateField = (inputField, value) => {
    if (value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    return true;
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "category_name":
        message = "Please enter a name";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleDialogClose = () => {
    if (editedFields.length) {
      setConfirmationOpen(true);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const catalogs = useMemo(() => {
    return sortArray(
      allCatalogs.filter(function(obj) {
        return parseInt(obj.catalog_status) && obj;
      }),
      "catalog_name"
    );
  }, [allCatalogs]);

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Save",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleEditCategory,
    },
  ];

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={titleCase(
          categoryState
            ? categoryState.category_name
            : cardContent.category_name
        )}
      />
      {categoryState && (
        <Tabs
          className={classes.dialogTabs}
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Metadata" />
          <Tab label="Catalogs" />
        </Tabs>
      )}
      <CustomDialogContent>
        {categoryState && (
          <div className={classes.root}>
            {activeTab === 0 && (
              <Metadata
                handleBlur={handleBlur}
                handleChange={handleChange}
                data={categoryState}
                disabled={loading}
                access={access}
              />
            )}
            {activeTab === 1 && (
              <TransferList
                availableData={catalogs}
                selectedData={categoryState.catalogs}
                handleSelectedData={handleFieldChange}
                idField={"catalog_id"}
                labelField={"catalog_name"}
                objProp={"catalogs"}
                headerName={"catalogs"}
                loading={loading}
              />
            )}
          </div>
        )}
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || editedFields.length === 0 || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedContentCard.propTypes = {
  cardContent: PropTypes.object.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  allCatalogs: PropTypes.array.isRequired,
  updateAllCatalogs: PropTypes.func.isRequired,
};

const ContentCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedContentCard);

export default ContentCard;
