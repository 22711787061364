import React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import TagSelector from "./TagSelector";
import SliderSelector from "./SliderSelector";
import CategorySelector from "./CategorySelector";
import KeySelector from "./KeySelector";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 !important",
  },
  panelDetails: {
    padding: "8px 11px 11px",
  },
  expanded: {
    minHeight: "0px !important",
  },
  content: {
    margin: "10px 0 !important",
  },
}));

const filters = [
  { defaultExpanded: true, title: "Category", component: <CategorySelector /> },
  { defaultExpanded: false, title: "Key", component: <KeySelector /> },
  {
    defaultExpanded: false,
    title: "Tempo",
    component: (
      <SliderSelector
        min={60}
        max={180}
        measurement={" BPM"}
        step={5}
        type={"tempos"}
      />
    ),
  },
  {
    defaultExpanded: false,
    title: "Duration",
    component: (
      <SliderSelector
        min={0}
        max={180}
        measurement={'"'}
        step={5}
        type={"durations"}
      />
    ),
  },
  { defaultExpanded: false, title: "Tags", component: <TagSelector /> },
];

const SideBarContent = React.memo((props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {filters.map((filter, index) => (
        <Accordion
          className={classes.root}
          defaultExpanded={filter.defaultExpanded}
          key={index}
        >
          <AccordionSummary
            classes={{
              root: classes.root,
              expanded: classes.expanded,
              content: classes.content,
            }}
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.heading}>{filter.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelDetails}>
            {filter.component}
          </AccordionDetails>
          <Divider />
        </Accordion>
      ))}
    </React.Fragment>
  );
});

export default SideBarContent;
