import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Paper, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ContentDropzone from "./ContentDropzone";

const useStyles = makeStyles((theme) => ({
  updateButton: {
    float: "right",
    marginLeft: theme.spacing(2),
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
    float: "right",
  },
  paper: {
    padding: theme.spacing(1),
    display: "inline-block",
    overflow: "hidden",
    marginBottom: theme.spacing(2),
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cropWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  previewWrapper: {
    width: 480,
    height: 270,
  },
  previewImg: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  artworkWrapper: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
  },
}));

const Artwork = (props, ref) => {
  const initialCrop = {
    unit: "%",
    width: 100,
    aspect: 16 / 9,
  };

  const [crop, setCrop] = useState(initialCrop);
  const [imageRef, setImageRef] = useState(null);
  const {
    loading,
    access,
    imageSource,
    setImageSource,
    selectedImage,
    handleArtworkChange,
    fieldName,
  } = props;
  const classes = useStyles();

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const handleReset = () => {
    handleArtworkChange(fieldName, null);
  };

  const handleConfirm = (file, asset, extension) => {
    makeClientCrop(crop);
  };

  const addFiles = (acceptedFile, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => setImageSource(reader.result));
    reader.readAsDataURL(acceptedFile[0]);
  };

  const handleCancel = () => {
    handleArtworkChange(fieldName, null);
    setCrop(initialCrop);
    setImageSource(null);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.png"
      );
      handleArtworkChange(fieldName, croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/png");
    });
  };

  return (
    <div className={classes.artworkWrapper}>
      {imageSource ? (
        <React.Fragment>
          <div className={classes.paperWrapper}>
            <Paper className={classes.paper}>
              {selectedImage ? (
                <div className={classes.previewWrapper}>
                  <img
                    alt="Crop"
                    style={{ maxWidth: "100%" }}
                    src={selectedImage}
                    className={classes.previewImg}
                  />
                </div>
              ) : (
                <div className={classes.cropWrapper}>
                  <ReactCrop
                    src={imageSource}
                    crop={crop}
                    minHeight={180}
                    minWidth={320}
                    onImageLoaded={onImageLoaded}
                    onChange={onCropChange}
                    disabled={loading}
                  />
                </div>
              )}
            </Paper>
            <div className={classes.actionContainer}>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.updateButton}
                  onClick={() =>
                    selectedImage
                      ? handleReset()
                      : handleConfirm(selectedImage, "skin_logo", "png")
                  }
                  disabled={loading}
                >
                  {selectedImage ? `Reset` : `Set`}
                </Button>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  className={classes.updateButton}
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Replace
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <ContentDropzone
          addFiles={addFiles}
          access={access}
          index={0}
          contentType={`image`}
          accept={"image/jpeg,image/png"}
          loading={loading}
        />
      )}
    </div>
  );
};

Artwork.propTypes = {
  handleArtworkChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
  selectedImage: PropTypes.string,
  imageSource: PropTypes.string,
  setImageSource: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default Artwork;
