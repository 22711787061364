import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const UserStats = (props) => {
  const { userStats } = props;
  return (
    <React.Fragment>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Downloads (Total)</TableCell>
            <TableCell align="right">{userStats.totalDownloads}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Downloads (Unique)</TableCell>
            <TableCell align="right">{userStats.uniqueDownloads}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Login</TableCell>
            <TableCell align="right">{userStats.lastLogin}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

UserStats.propTypes = {
  userStats: PropTypes.object.isRequired,
};

export default UserStats;
