import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import Metadata from "./Metadata";
import ChipSelector from "./ChipSelector";
import TabView from "./TabView";
import TransferList from "../../../library/TransferList";
import Confirmation from "../../../library/Confirmation";
import Processing from "./Processing";
import {
  sortArray,
  sanitizeTitle,
  titleCase,
} from "../../../helpers/SharedFunctions";
import {
  getStemLibrary,
  getAllCatalogs,
  getUsers,
  createGroup,
  createUserRelationship,
  createCatalogRelationship,
  createStemLibraryFile,
  uploadFile,
  createGroupBuildEntry,
} from "../../../helpers/ApiCalls";
import CustomDialogActions from "../../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../../library/dialog/CustomDialogContent";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateAllCatalogs,
} from "../../../actions/index";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    allCatalogs: state.allCatalogs,
    skinConfig: state.skinConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateAllCatalogs: (catalogs) => dispatch(updateAllCatalogs(catalogs)),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: theme.spacing(3),
    right: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ConnectedGroupBuilder = (props, ref) => {
  const {
    allCatalogs,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    updateAllCatalogs,
    skinConfig,
  } = props;

  const initialData = {
    group_name: "",
    group_status: 0,
    catalogs: [],
    users: [],
    stemLibraryFiles: {},
  };

  const [groupState, setGroupState] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [stemLibraries, setStemLibraries] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [processing, setProcessing] = useState({
    group: { status: "pending" },
    users: { status: "pending" },
    buildSchedule: [],
    catalogs: [],
    stemLibraryFiles: [],
  });

  const classes = useStyles();

  useEffect(() => {
    !allCatalogs.length &&
      getAllCatalogs().then((res) => {
        updateAllCatalogs(sortArray(res.catalogs, "catalog_name"));
      });
    const queryString = `status=${encodeURIComponent(1)}`;
    !allUsers.length &&
      getUsers(queryString).then((res) => {
        setAllUsers(mapChipData(res.users));
      });
  }, []);

  useEffect(() => {
    if (groupState.catalogs.length) {
      getStemLibraries();
    }
  }, [groupState.catalogs]);

  const getStemLibraries = async () => {
    const promises = [];
    groupState.catalogs.forEach((catalog) => {
      const catalogId = catalog.catalog_id;
      promises.push(
        new Promise((resolve, reject) => {
          getStemLibrary(catalogId).then((res) => {
            const stemLibraryData = res.reduce((acc, curr) => {
              acc[curr.stem_library_id] = curr;
              return acc;
            }, {});
            const stemLibrayObj = {};
            stemLibrayObj[catalogId] = stemLibraryData;
            resolve(stemLibrayObj);
          });
        })
      );
    });
    Promise.all(promises).then((values) => {
      let libraries = {};
      values.forEach((value) => {
        libraries = { ...value, ...libraries };
      });
      setStemLibraries(libraries);
    });
  };

  const removeLibraryFile = (stemLibraryId) => {
    let currentState = { ...groupState };
    delete currentState.stemLibraryFiles[stemLibraryId];
    setGroupState(currentState);
  };

  const mapChipData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.user_id,
          value: `${titleCase(
            `${item.user_first_name} ${item.user_last_name}`
          )} [${item.user_email}]`,
        }))
      : [];
  };

  const steps = [
    {
      stepTitle: "Enter metadata",
      fields: ["group_name"],
    },
    { stepTitle: "Select catalogs", fields: ["catalogs"] },
    { stepTitle: "Select users", fields: ["users"] },
    { stepTitle: "Add library content", fields: ["stemLibraryFiles"] },
  ];

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  const catalogs = useMemo(() => {
    return sortArray(
      allCatalogs.filter(function(obj) {
        return parseInt(obj.catalog_status) && obj;
      }),
      "catalog_name"
    );
  }, [allCatalogs]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Metadata
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleFieldChange}
            catalogs={catalogs}
            data={groupState}
            disabled={loading}
            access={access}
          />
        );
      case 1:
        return (
          <TransferList
            availableData={catalogs}
            selectedData={groupState.catalogs}
            handleSelectedData={handleFieldChange}
            idField={"catalog_id"}
            labelField={"catalog_name"}
            objProp={"catalogs"}
            headerName={"catalogs"}
          />
        );
      case 2:
        return (
          <ChipSelector
            allData={allUsers}
            selectedData={groupState.users}
            setSnackbarVisibility={setSnackbarVisibility}
            handleSelect={handleFieldChange}
            fieldName={`users`}
            disabled={loading}
            access={access}
            chipType={`User`}
            allowCreate={false}
          />
        );
      case 3:
        return (
          <TabView
            access={access}
            handleChange={handleFieldChange}
            stemLibraryFiles={groupState.stemLibraryFiles}
            allCatalogs={allCatalogs}
            stemLibraries={stemLibraries}
            removeLibraryFile={removeLibraryFile}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...groupState };
    let value = event.target.value;
    if (input === "group_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setGroupState(currentState);
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...groupState };
    currentState[field] = value;
    setGroupState(currentState);
  };

  const validateField = (inputField, value) => {
    if (typeof value === "object") {
      value = Object.keys(value);
    }

    if (!value || value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    if (inputField === "stemLibraryFiles") {
      for (let i = 0; i < groupState["catalogs"].length; i++) {
        const stemLibrary = Object.values(
          stemLibraries[groupState["catalogs"][i].catalog_id]
        );
        for (let i = 0; i < stemLibrary.length; i++) {
          if (!groupState["stemLibraryFiles"][stemLibrary[i].stem_library_id]) {
            const message = "Missing library files";
            setSnackbarVisibility({
              open: true,
              message: message,
            });
            return false;
          }
        }
      }
    }
    return true;
  };

  const triggerValidation = () => {
    for (const field of steps[activeStep].fields) {
      if (field !== "users") {
        if (!validateField(field, groupState[field])) {
          return false;
        }
      }
    }
    return true;
  };

  const handleNext = () => {
    if (!triggerValidation()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "group_name":
        message = "Please enter a name";
        break;
      case "catalogs":
        message = "Please add at least one catalog";
        break;
      case "stemLibraryFiles":
        message = "Missing library files";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleClose = () => {
    setConfirmationOpen(true);
  };

  const handleSubmit = async () => {
    // if (!triggerValidation()) {
    //   return;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoading(true);
    const groupId = await createGroupEntry();
    if (!groupId) {
      setLoading(false);
      return;
    }
    if (groupState.users.length) {
      if (!(await handleUsers(groupId))) {
        setLoading(false);
        return;
      }
    }
    if (groupState.catalogs.length) {
      if (!(await handleCatalogs(groupId))) {
        setLoading(false);
        return;
      }
    }
    if (!(await handleStemLibraryFiles(groupId))) {
      setLoading(false);
      return false;
    }
    if (!(await handleBuildSchedule(groupId))) {
      setLoading(false);
      return false;
    }
    setLoading(false);
  };

  const handleBuildSchedule = async (groupId) => {
    let currentProcessing = { ...processing };
    let newBuild;

    for (const catalog of Object.values(groupState.catalogs)) {
      newBuild = {
        catalog_id: catalog.catalog_id,
        status: "processing",
      };

      const postData = {
        skin_id: skinConfig.skin_id,
        build_type: 2,
        build_data: {
          group_id: groupId,
          catalog_id: catalog.catalog_id,
        },
      };

      currentProcessing.buildSchedule[catalog.catalog_id] = newBuild;
      setProcessing({ ...currentProcessing });
      const buildId = await createGroupBuildEntry(groupId, postData).then(
        (res) => {
          return res.data.result;
        }
      );
      if (!buildId) {
        currentProcessing.buildSchedule[catalog.catalog_id].status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.buildSchedule[catalog.catalog_id].status = "success";
      setProcessing({ ...currentProcessing });
    }
    return true;
  };

  const handleStemLibraryFiles = async (groupId) => {
    let currentProcessing = { ...processing };
    let newStem;
    let stemLibraryFileId;

    for (const stem of Object.values(groupState.stemLibraryFiles)) {
      newStem = {
        stem_library_id: groupState.stemLibraryFiles.stem_library_id,
        status: "processing",
      };

      currentProcessing.stemLibraryFiles[stem.stem_library_id] = newStem;
      setProcessing({ ...currentProcessing });

      const stemFilename = await submitAsset(stem.blob, "stem", "mp3").then(
        (res) => {
          return res.data;
        }
      );

      if (!stemFilename) {
        currentProcessing.stemLibraryFiles[stem.stem_library_id].status =
          "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }

      stemLibraryFileId = await createStemLibraryFileEntry(
        stem,
        stemFilename,
        groupId
      );

      if (!stemLibraryFileId) {
        currentProcessing.stemLibraryFiles[stem.stem_library_id].status =
          "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }

      currentProcessing.stemLibraryFiles[stem.stem_library_id].status =
        "success";
      setProcessing({ ...currentProcessing });
    }
    return true;
  };

  const createStemLibraryFileEntry = (stem, stemFilename, groupId) => {
    const postData = {
      stem_library_id: stem.stem_library_id,
      stem_library_file_filename: stemFilename,
      group_id: groupId,
    };

    return createStemLibraryFile(stem.stem_library_id, groupId, postData).then(
      (res) => {
        return res.data.result;
      }
    );
  };

  const submitAsset = (blob, asset, extension) => {
    const skinId = skinConfig.skin_id;

    const postData = {
      assetBlob: blob,
      skinId: skinId,
      asset: asset,
      extension: extension,
    };
    return uploadFile(postData).then((response) => {
      return response;
    });
  };

  const handleCatalogs = async (groupId) => {
    let postData = { group_id: groupId };
    let newCatalog;
    for (const catalog of groupState.catalogs) {
      const catalogId = catalog.catalog_id;
      let currentProcessing = { ...processing };

      newCatalog = {
        catalog_id: catalog.catalog_id,
        status: "processing",
      };
      currentProcessing.catalogs[catalog.catalog_id] = newCatalog;

      setProcessing(currentProcessing);

      postData.catalog_id = catalogId;
      const relationshipId = await createCatalogRelationship(
        groupId,
        catalogId,
        postData
      );
      if (!relationshipId) {
        currentProcessing.catalogs[catalogId].status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.catalogs[catalogId].status = "success";
      setProcessing({ ...currentProcessing });
    }

    return true;
  };

  const handleUsers = async (groupId) => {
    let postData = { group_id: groupId };
    let currentProcessing = { ...processing };
    currentProcessing.users.status = "processing";
    setProcessing(currentProcessing);
    for (const user of groupState.users) {
      const userId = user.id;
      postData.user_id = userId;
      const relationshipId = await createUserRelationship(
        groupId,
        userId,
        postData
      );
      if (!relationshipId) {
        currentProcessing.users.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
    }
    currentProcessing.users.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const createGroupEntry = () => {
    const postData = {
      group_name: groupState.group_name.trim(),
      group_status: 0,
    };

    let currentProcessing = { ...processing };
    currentProcessing.group.status = "processing";
    setProcessing(currentProcessing);

    return createGroup(postData).then((res) => {
      if (!res.data.result) {
        currentProcessing.group.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.group.status = "success";
      setProcessing({ ...currentProcessing });
      return res.data.result;
    });
  };

  const actionButtons = [
    {
      label: activeStep === steps.length ? "Done" : "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      isHidden: false,
      fn: activeStep === steps.length ? handleConfirmationConfirm : handleClose,
    },
    {
      label: "Back",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      disabled: activeStep === 0,
      isHidden: activeStep === steps.length,
      fn: handleBack,
    },
    {
      label: activeStep === steps.length - 1 ? "Submit" : "Next",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      isHidden: activeStep === steps.length,
      fn: activeStep === steps.length - 1 ? handleSubmit : handleNext,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={
          groupState.group_name.length
            ? titleCase(sanitizeTitle(groupState.group_name))
            : `New Group`
        }
      />
      <CustomDialogContent>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.stepTitle}>
                <StepLabel>{step.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <Processing
              processing={processing}
              groupState={groupState}
              allCatalogs={allCatalogs}
              stemLibraries={stemLibraries}
            />
          ) : (
            getStepContent(activeStep)
          )}
        </div>
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedGroupBuilder.propTypes = {
  allCatalogs: PropTypes.array.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  updateAllCatalogs: PropTypes.func.isRequired,
  skinConfig: PropTypes.object.isRequired,
};

const GroupBuilder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedGroupBuilder);

export default GroupBuilder;
