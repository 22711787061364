import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Chip,
  Divider,
  CardHeader,
  Tooltip,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  titleCase,
  searchAssociativeArray,
  translateKey,
  sanitizeTitle,
} from "../helpers/SharedFunctions";
import { directDownload } from "../helpers/DownloadFunctions";
import PropTypes from "prop-types";
import { getVersions } from "../helpers/ApiCalls";
import { connect } from "react-redux";
import {
  addToCart,
  removeFromCart,
  setDialogVisibility,
} from "../actions/index";
import DownloadActions from "./DownloadActions";
import VersionSelector from "./VersionSelector";
import AudioPlayer from "./AudioPlayer";
import { Close, SpeedOutlined, MusicNoteOutlined } from "@material-ui/icons";
import AudioPlayerControls from "./AudioPlayerControls";
import Alert from "../library/dialog/Alert";
import { emitEvent } from "../helpers/ApiCalls";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },
  },
  cardHeader: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      maxWidth: "100vw",
    },
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  cardContent: {
    padding: `0px ${theme.spacing(1)}px`,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  cardTitle: {
    display: "inline",
    paddingLeft: theme.spacing(1),
  },
  cardSubtitle: {
    display: "inline",
  },
  buttonContainer: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chipContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  coverWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  chipWrapper: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
  audioWrapper: {
    height: "100%",
  },
  iconButton: {
    padding: theme.spacing(2),
  },
  cardHeaderText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (userId, groupId, cartItem) =>
      dispatch(addToCart(userId, groupId, cartItem)),
    removeFromCart: (userId, groupId, cartItemId) =>
      dispatch(removeFromCart(userId, groupId, cartItemId)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    skinConfig: state.skinConfig,
    activeGroup: state.activeGroup,
    userData: state.userData,
  };
};

const ConnectedMediaInteraction = (props) => {
  const {
    activeGroup,
    audioFile,
    userData,
    addToCart,
    removeFromCart,
    cart,
    skinConfig,
    closeMediaInteraction,
    setDialogVisibility,
  } = props;

  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [playState, setPlayState] = useState("stop");
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    setFilename(audioFile.filename);
    loadAvailableVersions();
    return () => {
      setSelectedVersion({});
    };
  }, [audioFile]);

  useEffect(() => {
    Object.keys(selectedVersion).length &&
      setFilename(selectedVersion.filename);
  }, [selectedVersion]);

  const loadAvailableVersions = () => {
    getVersions(activeGroup, audioFile.master_id).then((result) => {
      setVersions(result);
      const selectedVersion = result.length
        ? searchAssociativeArray(audioFile.filename, result, "filename")
        : result;
      setSelectedVersion(selectedVersion);
    });
  };

  const handlePlayPause = () => {
    let newState = playState;
    if (newState === "play") {
      newState = "pause";
    } else {
      newState = "play";
    }
    setPlayState(newState);
  };

  const handleStop = () => {
    setPlayState("stop");
  };

  const handleChange = (event) => {
    const selectedVersion = searchAssociativeArray(
      event.target.value,
      versions,
      "version_id"
    );
    setSelectedVersion(selectedVersion);
  };

  const handleActionButtonClick = (action) => {
    switch (action) {
      case "add":
        return addToCart(userData.user_id, activeGroup, selectedVersion);
      case "remove":
        return removeFromCart(userData.user_id, activeGroup, selectedVersion);
      case "download":
        return directDownload(userData.user_id, activeGroup, [selectedVersion]);
      default:
        return;
    }
  };

  const handleError = (error) => {
    errorMessage(
      <div>
        <Typography>This audio preview is currently unavailable.</Typography>
        <Typography>Please try again later or contact support.</Typography>
      </div>
    );
    emitEvent("audioPlaybackErrorV1", {
      error: `${error}`,
      user_id: userData.user_id,
      group_id: audioFile.group_id,
      filename: audioFile.filename,
      master_id: audioFile.master_id,
      mix_id: audioFile.mix_id,
    });
  };

  const errorMessage = (message) => {
    const dialogContent = {
      open: true,
      body: (
        <Alert
          callback={closeMediaInteraction}
          message={message}
          title={"Oh no, something went wrong  : ("}
        />
      ),
      fullScreen: "xs",
    };
    setDialogVisibility(dialogContent);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Grid container>
          <Grid container>
            <Grid item md={2}>
              <div className={classes.coverWrapper}>
                <CardMedia
                  className={classes.cover}
                  image={`${skinConfig.skin_artwork_url}/${audioFile.master_artwork}`}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <CardHeader
                    title={titleCase(sanitizeTitle(audioFile.master_title))}
                    titleTypographyProps={{
                      className: classes.cardTitle,
                      variant: "h6",
                    }}
                    subheader={` - ${titleCase(audioFile.master_subcategory)}`}
                    subheaderTypographyProps={{
                      className: classes.cardSubtitle,
                    }}
                    classes={{
                      root: classes.cardHeader,
                      content: classes.cardHeaderText,
                    }}
                    action={
                      <Tooltip title="Close">
                        <IconButton onClick={closeMediaInteraction}>
                          <Close />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Divider variant="middle" />
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <CardContent className={classes.cardContent}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={10}>
                          <Grid
                            container
                            className={classes.audioWrapper}
                            alignContent="center"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={12} sm={12} align="center">
                              {filename && (
                                <AudioPlayer
                                  filename={filename}
                                  playState={playState}
                                  setPlayState={setPlayState}
                                  autoPlay={true}
                                  handleError={handleError}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box
                          component={Grid}
                          item
                          md={2}
                          display={{ xs: "none", sm: "none", md: "block" }}
                        >
                          <div className={classes.details}>
                            <Grid item container>
                              <Grid item xs={12} align="right">
                                <VersionSelector
                                  selectedVersion={selectedVersion}
                                  versions={versions}
                                  handleChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} align="right">
                                <div className={classes.chipContainer}>
                                  <div className={classes.chipWrapper}>
                                    <Chip
                                      size="small"
                                      icon={<MusicNoteOutlined />}
                                      label={translateKey(audioFile.master_key)}
                                      variant="outlined"
                                      className={classes.chip}
                                    />
                                  </div>
                                  <div className={classes.chipWrapper}>
                                    <Chip
                                      size="small"
                                      icon={<SpeedOutlined />}
                                      label={`${Math.round(
                                        audioFile.master_bpm
                                      )} BPM`}
                                      variant="outlined"
                                      className={classes.chip}
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Box>
                      </Grid>
                    </CardContent>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
                <Grid item xs={6} align="left">
                  <AudioPlayerControls
                    handlePlayPause={handlePlayPause}
                    handleStop={handleStop}
                    playState={playState}
                  />
                </Grid>
                <Grid item xs={6} align="right">
                  <div className={classes.buttonContainer}>
                    <DownloadActions
                      selectedVersion={selectedVersion}
                      cart={cart}
                      actionFunction={handleActionButtonClick}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

ConnectedMediaInteraction.propTypes = {
  audioFile: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
  activeGroup: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  skinConfig: PropTypes.object.isRequired,
  closeMediaInteraction: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
};

const MediaInteraction = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedMediaInteraction);

export default MediaInteraction;
