import React, { useState } from "react";
import PropTypes from "prop-types";
import UserSettings from "./UserSettings";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setDialogVisibility, setLogInState } from "../actions/index";
import MyAccountMenu from "./MyAccountMenu";
import { AccountCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.primary.contrastText,
  },
  iconWrapper: {
    margin: "0px -12px 0px 0px",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    setLogInState: (logInState) => dispatch(setLogInState(logInState)),
  };
};

const ConnectedMyAccount = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setLogInState, setDialogVisibility } = props;
  const classes = useStyles();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    localStorage.removeItem("id_token");
    setLogInState(false);
  };

  const handleClose = () => {
    const dialogContent = {
      open: false,
      title: null,
      body: null,
      cancel: { label: "Cancel", function: null },
      confirm: { label: "Confirm", function: null },
    };
    setDialogVisibility(dialogContent);
  };

  const openDialog = () => {
    const dialogContent = {
      open: true,
      title: "My Account",
      body: <UserSettings />,
      cancel: { label: "Close", function: handleClose },
      confirm: { label: "Confirm", function: null },
    };
    setDialogVisibility(dialogContent);
  };

  return (
    <div className={classes.iconWrapper}>
      <Tooltip title="Account">
        <IconButton
          aria-haspopup="true"
          className={classes.menuIcon}
          onClick={handleAvatarClick}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <MyAccountMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        openDialog={openDialog}
        logout={logout}
      />
    </div>
  );
};

ConnectedMyAccount.propTypes = {
  setDialogVisibility: PropTypes.func.isRequired,
  setLogInState: PropTypes.func.isRequired,
};

const MyAccount = connect(null, mapDispatchToProps)(ConnectedMyAccount);
export default MyAccount;
