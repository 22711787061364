import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { titleCase } from "../../../helpers/SharedFunctions";
import CustomizedTextField from "../../../library/CustomizedTextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.action.active} !important`,
    },
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  formLabelRoot: {},
  pointer: {
    cursor: "pointer",
  },
  wrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

const Metadata = (props) => {
  const {
    data,
    handleChange,
    handleBlur,
    disabled,
    access,
    allRoles,
    handleDateChange,
  } = props;

  const {
    user_first_name,
    user_last_name,
    user_email,
    user_expiry_date,
    role_id,
  } = data;

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <CustomizedTextField
          required
          fullWidth
          id="userFirstName"
          label="First Name"
          value={user_first_name}
          handleOnChange={handleChange("user_first_name")}
          handleOnBlur={handleBlur("user_first_name")}
          disabled={disabled || !access.update}
        />
        <CustomizedTextField
          required
          fullWidth
          id="userLastName"
          label="Last Name"
          value={user_last_name}
          handleOnChange={handleChange("user_last_name")}
          handleOnBlur={handleBlur("user_last_name")}
          disabled={disabled || !access.update}
        />
        <CustomizedTextField
          required
          fullWidth
          id="userEmail"
          label="Email"
          value={user_email}
          handleOnChange={handleChange("user_email")}
          handleOnBlur={handleBlur("user_email")}
          disabled={disabled || !access.update}
        />
        <CustomizedTextField
          required
          fullWidth
          id="roles"
          select
          label="Role"
          value={role_id}
          handleOnChange={handleChange("role_id")}
          handleOnBlur={handleBlur("role_id")}
          disabled={disabled || !access.update}
        >
          {allRoles.map((option) => (
            <MenuItem key={option.role_id} value={option.role_id}>
              {titleCase(option.role_name)}
            </MenuItem>
          ))}
        </CustomizedTextField>
        {parseInt(role_id) === 4 && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              required
              disableToolbar
              disablePast
              autoOk={true}
              error={false}
              helperText={null}
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Expiry Date"
              value={new Date(user_expiry_date)}
              onChange={(date) => handleDateChange(`user_expiry_date`, date)}
              disabled={disabled || !access.update}
              InputLabelProps={{
                classes: {
                  root: classes.formLabelRoot,
                  focused: classes.formLabelFocused,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  focused: classes.focused,
                  input: classes.pointer,
                },
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
    </React.Fragment>
  );
};

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
  allRoles: PropTypes.array.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default Metadata;
