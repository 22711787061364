import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Switch,
  Radio,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, Delete } from "@material-ui/icons";
import CustomizedTextField from "../../../library/CustomizedTextField";
import Dropzone from "../Dropzone";
import Multitrack from "./Multitrack";
import ContentDropzone from "./ContentDropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      padding: `0px 0px ${theme.spacing(0.5)}px 0px`,
    },
  },
  buttonWrapper: {
    margin: `${theme.spacing(2)}px 0px`,
    float: "right",
  },
  textField: {
    marginBottom: theme.spacing(0.5),
    textAlign: "center",
  },
  uploadWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    padding: theme.spacing(8),
    position: "relative",
  },
  browseButton: {
    marginTop: theme.spacing(1),
  },
}));

const VersionRow = (props) => {
  const classes = useStyles();
  const {
    index,
    row,
    handleDelete,
    updateVersion,
    addFiles,
    access,
    open,
    setOpen,
    versions,
    setFirstVersionAsPrimary,
    multitrackKeyboardActive,
    setMultitrackKeyboardActive,
    stemLibrary,
    insertLibraryStems,
    selectedGroup,
    loading,
  } = props;

  const refValue = useRef(row);
  useEffect(() => {
    refValue.current = row;
  }, [row]);

  const handleChange = (input) => (event) => {
    let currentState = { ...row };
    let value = event.target.value;
    if (input === "version_status") {
      value = event.target.checked;
      if (row.version_primary) {
        currentState["version_primary"] = false;
        setFirstVersionAsPrimary();
      }
    }
    if (input === "version_primary") {
      value = event.target.checked;
    }
    currentState[input] = value;
    updateVersion(index, currentState);
  };

  const handleStemChange = (input) => (event) => {
    let currentState = { ...row };
    let value = event.target.value;
    currentState.version_stems[input].stem_name = value;
    updateVersion(index, currentState);
  };

  const handleStemPositionChange = (stemId, stemPosition) => {
    let currentState = { ...refValue.current };
    const stemIndex = currentState.version_stems.findIndex(
      (x) => x.stem_id === stemId
    );
    currentState.version_stems[stemIndex].stem_position = stemPosition;
    updateVersion(index, currentState);
  };

  const deleteStem = (stemIndex) => {
    let currentState = { ...row };
    currentState.version_stems.splice(stemIndex, 1);
    updateVersion(index, currentState);
  };

  const handleExpandCollapse = () => {
    const openState = open === index ? null : index;
    setOpen(openState);
  };

  const toggleKeyboardActive = () => {
    const active = !multitrackKeyboardActive;
    setMultitrackKeyboardActive(active);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandCollapse(index)}
            disabled={loading}
          >
            {open === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <CustomizedTextField
            value={row.version_name}
            margin={"dense"}
            handleOnChange={handleChange("version_name")}
            placeholder={"Version Name"}
            handleOnFocus={toggleKeyboardActive}
            handleOnBlur={toggleKeyboardActive}
            id={`version_name_${index}`}
            disabled={loading}
          />
        </TableCell>
        <TableCell className={classes.textField}>
          {row.version_stems.length}
        </TableCell>
        <TableCell className={classes.textField}>
          <Radio
            checked={row.version_primary}
            onClick={handleChange("version_primary")}
            name="versionPrimary"
            disabled={loading || !row.version_status || versions.length === 1}
          />
        </TableCell>
        <TableCell className={classes.textField}>
          <Switch
            checked={row.version_status}
            onChange={handleChange("version_status")}
            name="versionStatus"
            disabled={loading || versions.length === 1}
          />
        </TableCell>
        <TableCell className={classes.textField}>
          <IconButton
            disabled={loading}
            onClick={() => handleDelete(index)}
            aria-label="delete"
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === index} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!!row.version_stems.length ? (
                <Dropzone
                  loading={loading}
                  addFiles={addFiles}
                  access={access}
                  index={index}
                  accept={"audio/mpeg"}
                >
                  <Multitrack
                    key={index}
                    index={index}
                    stems={row.version_stems}
                    deleteStem={deleteStem}
                    keyboardActive={multitrackKeyboardActive}
                    handleOnFocus={toggleKeyboardActive}
                    handleOnBlur={toggleKeyboardActive}
                    handleStemChange={handleStemChange}
                    stemLibrary={stemLibrary}
                    insertLibraryStems={insertLibraryStems}
                    handleStemPositionChange={handleStemPositionChange}
                    selectedGroup={selectedGroup}
                    loading={loading}
                  />
                </Dropzone>
              ) : (
                <ContentDropzone
                  addFiles={addFiles}
                  access={access}
                  index={index}
                  contentType={`audio`}
                  accept={"audio/mpeg"}
                  loading={loading}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

VersionRow.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  updateVersion: PropTypes.func.isRequired,
  addFiles: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  open: PropTypes.number,
  setOpen: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  setFirstVersionAsPrimary: PropTypes.func.isRequired,
  multitrackKeyboardActive: PropTypes.bool.isRequired,
  setMultitrackKeyboardActive: PropTypes.func.isRequired,
  stemLibrary: PropTypes.array.isRequired,
  insertLibraryStems: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default VersionRow;
