import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

const SimpleAudioPlayer = (props) => {
  const { src, handleEnded, playing } = props;

  return (
    <ReactPlayer
      url={src}
      playing={playing}
      onEnded={handleEnded}
      onError={(e) => console.log("onError", e)}
    />
  );
};

SimpleAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  playing: PropTypes.bool.isRequired,
  handleEnded: PropTypes.func.isRequired,
};

export default SimpleAudioPlayer;
