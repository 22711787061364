import React from "react";
import {
  withStyles,
  Paper,
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  setSnackbarVisibility,
  updateSkinConfig,
  setDialogVisibility,
} from "../../actions/index";
import { sortObject } from "../../helpers/SharedFunctions";
import { setSkinConfig } from "../../helpers/ApiCalls";
import ApplicationVariables from "./ApplicationVariables";
import Aesthetic from "./Aesthetic";
import Assets from "./Assets";
import ThemeControl from "./ThemeControl";
import Fonts from "../../helpers/Fonts";
import CustomizedTextField from "../../library/CustomizedTextField";
import PropTypes from "prop-types";
import TermsOfService from "../../library/TermsOfService";

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    updateSkinConfig: (skinConfig) => dispatch(updateSkinConfig(skinConfig)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const mapStateToProps = (state) => {
  return {
    skinConfig: state.skinConfig,
    snackbar: state.snackbar,
    termsAcceptance: state.termsAcceptance,
  };
};

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  iframe: {
    width: "400%",
    height: "400%",
    "-moz-transform": "scale(0.25, 0.25)",
    "-webkit-transform": "scale(0.25, 0.25)",
    "-o-transform": "scale(0.25, 0.25)",
    "-ms-transform": "scale(0.25, 0.25)",
    transform: "scale(0.25, 0.25)",
    "-moz-transform-origin": "top left",
    "-webkit-transform-origin": "top left",
    "-o-transform-origin": "top left",
    "-ms-transform-origin": "top left",
    "transform-origin": "top left",
    pointerEvents: "none",
  },
  iframeWrapper: {
    width: 512,
    height: 288,
    maxWidth: "100%",
    position: "relative",
  },
  paper: {
    padding: theme.spacing(1),
    display: "inline-block",
    overflow: "hidden",
  },
  caption: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
    float: "right",
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    maxWidth: "100vw",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
});

class ConnectedSettings extends React.Component {
  state = {
    originalSkinConfig: this.props.skinConfig,
    loading: "",
    skin_title: this.props.skinConfig.skin_title,
    skin_homepage: this.props.skinConfig.skin_homepage
      ? this.props.skinConfig.skin_homepage
      : "",
  };

  hasSettingChanged = (field) => {
    const { originalSkinConfig } = this.state;
    const newSkinConfig = this.props.skinConfig;
    return originalSkinConfig[field] !== newSkinConfig[field];
  };

  updateSkinConfig = (name, value) => {
    const skinConfig = { ...this.props.skinConfig };
    if (typeof value === "string") {
      value = value.trim();
    }
    skinConfig[name] = value;
    this.props.updateSkinConfig(skinConfig);
  };

  handleTextBoxChange = (name) => (event) => {
    const value = event.target.value;
    const currentState = { ...this.state };
    currentState[name] = value;
    this.setState(currentState);
    const that = this;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      that.updateSkinConfig(name, value);
    }, 1000);
  };

  handleConfirm = (fields) => {
    fields.forEach((field) => {
      if (this.hasSettingChanged(field)) {
        this.toggleLoading(field);
        const data = {};
        data[field] = this.props.skinConfig[field];
        data.skin_id = this.props.skinConfig.skin_id;
        setSkinConfig(data).then((skinConfig) => {
          this.props.setSnackbarVisibility({
            open: true,
            message: "Settings Updated",
          });
          this.setState({
            originalSkinConfig: this.props.skinConfig,
            loading: "",
          });
        });
      }
    });
  };

  isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  toggleLoading = (field) => {
    this.setState({ loading: field });
  };

  submitChanges = (field) => {
    this.setState({ loading: field });
    const data = {};
    data[field] = this.props.skinConfig[field];
    data.skin_id = this.props.skinConfig.skin_id;
    setSkinConfig(data).then((skinConfig) => {
      this.props.setSnackbarVisibility({
        open: true,
        message: "Settings Updated",
      });
      this.setState({
        originalSkinConfig: this.props.skinConfig,
        loading: "",
      });
    });
  };

  viewTerms = () => {
    const dialogContent = {
      open: true,
      fullWidth: true,
      fullScreen: "xs",
      body: <TermsOfService />,
      minHeight: true,
    };
    this.props.setDialogVisibility(dialogContent);
  };

  render() {
    const {
      classes,
      skinConfig,
      setSnackbarVisibility,
      termsAcceptance,
    } = this.props;
    const { loading, skin_title, skin_homepage } = this.state;
    const fonts = sortObject(Fonts, "fontName");
    return (
      <div className={classes.container}>
        <ApplicationVariables
          handleTextBoxChange={this.handleTextBoxChange}
          updateSkinConfig={this.updateSkinConfig}
          hasSettingChanged={this.hasSettingChanged}
          handleConfirm={this.handleConfirm}
          skinConfig={skinConfig}
          skin_title={skin_title}
          loading={loading}
        />
        <Divider className={classes.divider} />
        <Assets
          skinConfig={skinConfig}
          updateSkinConfig={this.updateSkinConfig}
          setSnackbarVisibility={setSnackbarVisibility}
          toggleLoading={this.toggleLoading}
          loading={loading}
        />
        <Divider className={classes.divider} />
        <Aesthetic
          handleClickAway={this.handleClickAway}
          skinConfig={skinConfig}
          updateSkinConfig={this.updateSkinConfig}
          loading={loading}
          handleConfirm={this.handleConfirm}
          hasSettingChanged={this.hasSettingChanged}
        />
        <Grid container spacing={4}>
          <ThemeControl
            skinConfig={skinConfig}
            loading={loading}
            hasSettingChanged={this.hasSettingChanged}
            updateSkinConfig={this.updateSkinConfig}
            handleConfirm={this.handleConfirm}
          >
            <Typography
              variant="caption"
              display="block"
              className={classes.caption}
            >
              This toggle determines the application's default theme. Enabling
              user override will enable them to set there own preference in
              their settings.
            </Typography>
          </ThemeControl>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              fullWidth
              id={"skin_font"}
              select
              label={"Font"}
              value={parseInt(skinConfig.skin_font, 10)}
              handleOnChange={(event) =>
                this.updateSkinConfig(
                  "skin_font",
                  event.target.value.toString()
                )
              }
            >
              {fonts.map((option) => (
                <MenuItem
                  key={parseInt(option.fontId, 10)}
                  value={parseInt(option.fontId, 10)}
                  style={{ fontFamily: option.fontDeclaration }}
                >
                  {option.fontName}
                </MenuItem>
              ))}
            </CustomizedTextField>
            <Typography
              variant="caption"
              display="block"
              className={classes.caption}
            >
              Font selection is applied throughout the App in various weights
              and sizes.
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                id="skin_font"
                variant="outlined"
                color="secondary"
                disabled={
                  Boolean(loading.length) ||
                  !this.hasSettingChanged("skin_font")
                }
                onClick={(event) => this.handleConfirm(["skin_font"])}
              >
                Update
              </Button>
              {loading === "skin_font" && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              required
              fullWidth
              id={"skinHomepage"}
              label={"Homepage URL"}
              placeholder={"http://www.example.com"}
              value={skin_homepage}
              handleOnChange={this.handleTextBoxChange("skin_homepage")}
              handleOnBlur={(event) =>
                this.updateSkinConfig("skin_homepage", event.target.value)
              }
            />
            <Typography
              variant="caption"
              display="block"
              className={classes.caption}
            >
              This URL points to your company/product landing page and will be
              overlayed with the App header and footer. All URL's must be HTTPS
              compliant and allow X-Frame access from this App's domain.
            </Typography>
            <div className={classes.paperWrapper}>
              <Paper className={classes.paper}>
                <div className={classes.iframeWrapper}>
                  <iframe
                    src={
                      skinConfig.skin_homepage &&
                      skinConfig.skin_homepage.length &&
                      this.isValidUrl(skinConfig.skin_homepage)
                        ? skinConfig.skin_homepage
                        : null
                    }
                    title="Homepage"
                    className={classes.iframe}
                  ></iframe>
                </div>
              </Paper>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                id="skin_homepage"
                variant="outlined"
                color="secondary"
                disabled={
                  Boolean(loading.length) ||
                  !this.hasSettingChanged("skin_homepage")
                }
                onClick={(event) => this.handleConfirm(["skin_homepage"])}
              >
                Update
              </Button>
              {loading === "skin_homepage" && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div>
              <CustomizedTextField
                required
                fullWidth
                id={"subscription"}
                label={"Subscription"}
                placeholder={"Subscription"}
                value={"Pro - $1,750/month"}
                disabled
              />
              <div className={classes.buttonWrapper}>
                <Button
                  id="skin_terms_and_conditions"
                  onClick={(event) => this.viewTerms()}
                  endIcon={
                    termsAcceptance ? <CheckCircleOutline /> : <ErrorOutline />
                  }
                >
                  Terms of Service
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ConnectedSettings.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  updateSkinConfig: PropTypes.func.isRequired,
  skinConfig: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  termsAcceptance: PropTypes.object,
  setDialogVisibility: PropTypes.func.isRequired,
};

const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSettings);

export default withStyles(styles)(Settings);
