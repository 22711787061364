import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid,
	Typography,
	Button,
	CircularProgress,
	ClickAwayListener,
	Popper,
	Paper,
	InputAdornment,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";
import CustomizedTextField from "../../library/CustomizedTextField";

const styles = (theme) => ({
	caption: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(2),
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	buttonWrapper: {
		position: "relative",
		display: "inline-block",
		float: "right",
	},
	swatch: {
		padding: "5px",
		background: "#fff",
		borderRadius: "1px",
		boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
		display: "inline-block",
		cursor: "pointer",
	},
	popper: {
		zIndex: 2,
	},
	color: {
		width: "36px",
		height: "14px",
		borderRadius: "2px",
	},
});

class Aesthetic extends React.Component {
	state = {
		anchorEl: null,
		type: null,
	};

	handleClickAway = (event) => {
		this.setState({ anchorEl: null, type: null });
	};

	handleColorPickerClick = (event, type) => {
		const { currentTarget } = event;
		this.setState((state) => ({
			anchorEl: state.anchorEl ? null : currentTarget,
			type: type,
		}));
	};

	render() {
		const {
			skinConfig,
			updateSkinConfig,
			loading,
			classes,
			handleConfirm,
			hasSettingChanged,
		} = this.props;
		const { anchorEl, type } = this.state;
		const open = Boolean(anchorEl);
		const id = open ? "no-transition-popper" : null;

		return (
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<div>
						<ClickAwayListener
							mouseEvent="onMouseDown"
							touchEvent="onTouchStart"
							onClickAway={this.handleClickAway}
						>
							<Popper
								placement={"right"}
								id={id}
								open={open}
								anchorEl={anchorEl}
								className={classes.popper}
							>
								<Paper>
									<SketchPicker
										color={skinConfig[type]}
										onChange={(color) =>
											updateSkinConfig(type, color.hex)
										}
									/>
								</Paper>
							</Popper>
						</ClickAwayListener>
						<CustomizedTextField
							required
							fullWidth
							id={"skinPrimaryColor"}
							label={"Primary Color"}
							value={skinConfig.skin_primary_color}
							// disabled
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<div
											className={classes.swatch}
											onClick={(event) =>
												this.handleColorPickerClick(
													event,
													"skin_primary_color"
												)
											}
										>
											<div
												style={{
													background:
														skinConfig.skin_primary_color,
												}}
												className={classes.color}
											/>
										</div>
									</InputAdornment>
								),
							}}
						/>
					</div>
					<Typography
						variant="caption"
						display="block"
						className={classes.caption}
					>
						The Primary Color is displayed most frequently across
						your app’s screens and components, most notably in the
						main header.
					</Typography>
					<div className={classes.buttonWrapper}>
						<Button
							id="skin_primary_color"
							variant="outlined"
							color="secondary"
							disabled={
								Boolean(loading.length) ||
								!hasSettingChanged("skin_primary_color")
							}
							onClick={(event) =>
								handleConfirm(["skin_primary_color"])
							}
						>
							Update
						</Button>
						{loading === "skin_primary_color" && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</div>
				</Grid>
				<Grid item xs={12} sm={6}>
					<div>
						<CustomizedTextField
							required
							fullWidth
							id={"skinSecondaryColor"}
							label={"Secondary Color"}
							value={skinConfig.skin_secondary_color}
							disabled
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<div
											className={classes.swatch}
											onClick={(event) =>
												this.handleColorPickerClick(
													event,
													"skin_secondary_color"
												)
											}
										>
											<div
												style={{
													background:
														skinConfig.skin_secondary_color,
												}}
												className={classes.color}
											/>
										</div>
									</InputAdornment>
								),
							}}
						/>
					</div>
					<Typography
						variant="caption"
						display="block"
						className={classes.caption}
					>
						The Secondary Color is used throughout the application
						to complement to the Primary Color.
					</Typography>
					<div className={classes.buttonWrapper}>
						<Button
							id="skin_secondary_color"
							variant="outlined"
							color="secondary"
							disabled={
								Boolean(loading.length) ||
								!hasSettingChanged("skin_secondary_color")
							}
							onClick={(event) =>
								handleConfirm(["skin_secondary_color"])
							}
						>
							Update
						</Button>
						{loading === "skin_secondary_color" && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</div>
				</Grid>
			</Grid>
		);
	}
}

Aesthetic.propTypes = {
	skinConfig: PropTypes.object.isRequired,
	updateSkinConfig: PropTypes.func.isRequired,
	loading: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	hasSettingChanged: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Aesthetic);
