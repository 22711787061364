import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoginCard from "./LoginCard";
import ResetCard from "./ResetCard";
import ForgotPasswordCard from "./ForgotPasswordCard";
import HomePagePortal from "./HomePagePortal";
import { validateAccessToken } from "../helpers/ApiCalls";
import { withRouter, matchPath } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { setDialogVisibility, setLogInState } from "../actions/index";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  homePagePortal: {
    flex: 1,
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    setLogInState: (logInState) => dispatch(setLogInState(logInState)),
  };
};

const ConnectedSplashPage = (props) => {
  const {
    location,
    setDialogVisibility,
    history,
    skinConfig,
    setLogInState,
  } = props;

  const classes = useStyles();

  const [email, setEmail] = useState("");

  useEffect(() => {
    getRoute();
  }, [location]);

  const logout = () => {
    localStorage.removeItem("id_token");
    setLogInState(false);
  };

  const getRoute = () => {
    const path = location.pathname;
    let checkPath;
    let dialogContent;
    switch (path) {
      case "/login":
        checkPath = matchPath(path, "/login");
        if (checkPath && checkPath.isExact) {
          dialogContent = {
            open: true,
            body: (
              <LoginCard setLogInState={setLogInState} setEmail={setEmail} />
            ),
            fullWidth: false,
            fullScreen: "sm",
            minHeight: false,
          };
          setDialogVisibility(dialogContent);
        }
        break;
      case "/reset":
        checkPath = matchPath(path, "/reset");
        if (checkPath && checkPath.isExact) {
          const location = window.location;
          const urlParams = new URLSearchParams(location.search);
          const tokenHash = urlParams.get("token");
          validateAccessToken(tokenHash).then((res) => {
            if (res.result) {
              logout();
              dialogContent = {
                open: true,
                body: <ResetCard tokenHash={tokenHash} />,
                fullWidth: false,
                fullScreen: "sm",
                minHeight: false,
              };
              setDialogVisibility(dialogContent);
            } else {
              history.push("/");
            }
          });
        }
        break;
      case "/admin":
        checkPath = matchPath(path, "/admin");
        if (checkPath && checkPath.isExact) {
          dialogContent = {
            open: true,
            body: (
              <LoginCard setLogInState={setLogInState} setEmail={setEmail} />
            ),
            fullWidth: false,
            fullScreen: "sm",
            minHeight: false,
          };
          setDialogVisibility(dialogContent);
        }
        break;
      case "/forgot_password":
        checkPath = matchPath(path, "/forgot_password");
        if (checkPath && checkPath.isExact) {
          dialogContent = {
            open: true,
            body: <ForgotPasswordCard prefill={email} />,
            fullWidth: false,
            fullScreen: "sm",
            minHeight: false,
          };
          setDialogVisibility(dialogContent);
        }
        break;
      default:
        if (matchPath(path, "/admin/")) {
          dialogContent = {
            open: true,
            body: (
              <LoginCard setLogInState={setLogInState} setEmail={setEmail} />
            ),
            fullWidth: false,
            fullScreen: "sm",
            minHeight: false,
          };
          setDialogVisibility(dialogContent);
        } else if (path !== "/") {
          history.push("/");
        }
        break;
    }
  };

  return (
    <React.Fragment>
      <HomePagePortal
        skinConfig={skinConfig}
        className={classes.homePagePortal}
      />
    </React.Fragment>
  );
};

ConnectedSplashPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setLogInState: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  skinConfig: PropTypes.object.isRequired,
};

const SplashPage = connect(null, mapDispatchToProps)(ConnectedSplashPage);

export default withRouter(SplashPage);
