import React from "react";
import { DialogContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    position: "relative",
  },
}));

const CustomDialogContent = (props) => {
  const classes = useStyles();

  return (
    <DialogContent classes={{ root: classes.content }}>
      {props.children}
    </DialogContent>
  );
};

export default CustomDialogContent;
