import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inputValidation } from "../helpers/SharedFunctions";
import CustomizedTextField from "../library/CustomizedTextField";
import { connect } from "react-redux";
import { setSnackbarVisibility, setDialogVisibility } from "../actions/index";
import CustomDialogActions from "../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../library/dialog/CustomDialogContent";
import { resetPassword } from "../helpers/ApiCalls";

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedLoginFormCard = (props) => {
  const {
    setSnackbarVisibility,
    setDialogVisibility,
    tokenHash,
    history,
  } = props;

  let initialData = {
    newPassword: { value: "", valid: false },
    newPasswordConfirm: { value: "", valid: false },
    loading: false,
  };

  const [formState, setFormState] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleBlur = (field) => (event) => {
    const value = event.target.value;
    validateField(field, value);
  };

  const validateField = (field, value) => {
    let currentState = { ...formState };
    let valid = false;
    let message = "";

    if (field === "newPasswordConfirm") {
      valid = checkFieldsMatch(value);
      message = "Passwords do not match";
    } else {
      const response = inputValidation(field, value);
      valid = response.isValid;
      message = response.message;
    }
    !valid &&
      setSnackbarVisibility({
        open: true,
        message: message,
      });

    currentState[field].valid = valid;
    setFormState(currentState);
    return valid;
  };

  const checkFieldsMatch = (inputValue) => {
    return formState.newPassword.value === inputValue;
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...formState };
    currentState[input].value = event.target.value;
    setFormState(currentState);
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    history.push("/");
    setDialogVisibility(dialogContent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      const fields = ["newPassword", "newPasswordConfirm"];
      //create empty array to track valid fields
      const formValid = [];
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = formState[field].value;
        const valid = validateField(field, value);
        if (!valid) {
          break;
        }
        formValid.push(true);
      }
      //check all fields in step are valid
      if (formValid.length === fields.length) {
        const postData = {
          password: formState.newPassword.value,
          token: tokenHash,
        };
        resetPassword(postData).then((res) => {
          let message;
          if (res.result) {
            message = "Password set successfully. Please login.";
            history.push("/login");
          } else {
            message = "An error occured. Please contact support";
          }
          setLoading(false);
          setSnackbarVisibility({
            open: true,
            message: message,
          });
        });
      } else {
        setLoading(false);
      }
    }
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Submit",
      color: "secondary",
      type: "submit",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleSubmit,
      disabled:
        !formState.newPassword.value.length ||
        !formState.newPasswordConfirm.value.length,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle title={`Reset Password`} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <CustomDialogContent>
          Passwords must contain eight characters, at least one uppercase
          letter, one lowercase letter and one number.
          <CustomizedTextField
            required
            fullWidth
            id={"newPassword"}
            label={"New Password"}
            type={"password"}
            value={formState.newPassword.value}
            handleOnChange={handleChange("newPassword")}
            handleOnBlur={handleBlur("newPassword")}
            placeholder={"New Password"}
          />
          <CustomizedTextField
            required
            fullWidth
            id={"newPasswordConfirm"}
            label={"Confirm New Password"}
            type={"password"}
            value={formState.newPasswordConfirm.value}
            handleOnChange={handleChange("newPasswordConfirm")}
            handleOnBlur={handleBlur("newPasswordConfirm")}
            placeholder={"Confirm New Password"}
            disabled={!formState.newPassword.value.length}
          />
        </CustomDialogContent>

        <CustomDialogActions
          actionButtons={actionButtons}
          disabled={loading}
          loading={loading}
        />
      </form>
    </React.Fragment>
  );
};

ConnectedLoginFormCard.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  tokenHash: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const LoginFormCard = connect(null, mapDispatchToProps)(ConnectedLoginFormCard);

export default withRouter(LoginFormCard);
