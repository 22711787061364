import Axios from "axios";
import fileDownload from "js-file-download";
import JSZip from "jszip";
import { getStems, registerDownload } from "./ApiCalls";
import store from "../store/index.js";
import { getSha, sanitizeTitle, translateKey } from "./SharedFunctions";

export const downloadFile = (path, filename, downloadFilename) => {
	const url = `${path}/${filename}`;
	Axios.get(url, {
		responseType: "blob",
	}).then((res) => {
		fileDownload(res.data, downloadFilename);
	});
};

export const directDownload = (userId, groupId, versions) => {
	const currentState = store.getState();
	const productName = sanitizeString(
		currentState.skinConfig.skin_title
	).toUpperCase();
	let promises = [];
	let downloadCue = [];
	const masters = [];
	const mastersWithMultiple = [];
	const filenames = [];

	const countOccurrences = (arr, val) =>
		arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

	const sanitizeKey = (key) => {
		return key && key.toLowerCase() !== "n" ? translateKey(key) : null;
	};

	const sanitizeTempo = (tempo) => {
		return tempo ? Math.round(tempo) : null;
	};

	versions.forEach(function(version, index) {
		let versionObj = {
			masterId: version.master_id,
			masterTitle: sanitizeString(sanitizeTitle(version.master_title)),
			masterSubcategory: sanitizeString(version.master_subcategory),
			masterKey: sanitizeKey(version.master_key),
			masterTempo: sanitizeTempo(version.master_bpm),
			versionName: sanitizeString(version.version_name),
			versionExtension: version.filename.split(".").pop(),
		};

		//check if other versions of master exists in cue and keep a reference for filetree
		if (!masters.includes(version.master_id)) {
			masters.push(version.master_id);
		} else {
			!mastersWithMultiple.includes(version.master_id) &&
				mastersWithMultiple.push(version.master_id);
		}

		const versionUrl = `${currentState.skinConfig.skin_masters_url}/${version.filename}`;

		promises.push(
			getVersionStems(groupId, version).then((response) => {
				versionObj.stems = response;
			})
		);
		promises.push(
			Axios.get(versionUrl, {
				responseType: "blob",
			}).then((versionBlob) => {
				versionObj.versionBlob = versionBlob;
			})
		);
		downloadCue.push(versionObj);
		registerDownload(groupId, userId, version.version_id);
	});

	return Promise.all(promises).then(() => {
		const numVersions = downloadCue.length;

		//download single file
		if (numVersions === 1 && downloadCue[0].stems.length < 2) {
			let masterTitle = downloadCue[0].masterTitle;
			let { masterKey, masterTempo } = downloadCue[0];
			fileDownload(
				downloadCue[0].versionBlob.data,
				`${masterTitle}${
					masterKey ? `_${masterKey}` : ``
				}${masterTempo && `_${masterTempo}`}.${
					downloadCue[0].versionExtension
				}`
			);
			return true;
		} else {
			const zip = new JSZip();
			let rootFolder;
			if (masters.length > 1) {
				rootFolder = `${productName}_${getSha()}`;
			} else {
				rootFolder = downloadCue[0].masterTitle;
			}

			downloadCue.forEach(function(cueItem, index) {
				let {
					masterTitle,
					versionName,
					versionExtension,
					masterKey,
					masterTempo,
					masterSubcategory,
					versionBlob,
					stems,
				} = cueItem;
				const numStems = cueItem.stems.length;
				const hasSiblings = mastersWithMultiple.includes(
					cueItem.masterId
				);
				let filename = `${masterTitle}${
					masterSubcategory ? `_${masterSubcategory}` : ``
				}${masterKey ? `_${masterKey}` : ``}${masterTempo &&
					`_${masterTempo}`}`;

				let path = "";

				if (hasSiblings) {
					filename += `_${versionName}`;
					path += masterTitle;
					zip.folder(path);
					if (numStems > 1) {
						path += `/${versionName}`;
						zip.folder(path);
					}
				} else if (!hasSiblings && numStems > 1) {
					path += masterTitle;
					zip.folder(path);
				}

				filenames.push(filename);

				const filenameOccurrences = countOccurrences(
					filenames,
					filename
				);

				//avoid filename overwrites
				if (filenameOccurrences > 1) {
					filename += `_${getSha()}`;
				}

				//handle empty paths on windows
				path = path.length ? `${path}/${filename}` : filename;

				zip.file(`${path}.${versionExtension}`, versionBlob.data);

				if (numStems > 1) {
					path += `/Stems`;
					zip.folder(path);
					let count = 1;
					stems.forEach(function(stem, index) {
						const { stemName, stemBlob, stemExtension } = stem;
						let stemDownloadName = `${filename}_${String(
							count
						).padStart(2, "0")}`;

						stemDownloadName += `_${sanitizeString(stemName)}`;

						zip.file(
							`${path}/${stemDownloadName}.${stemExtension}`,
							stemBlob
						);
						count++;
					});
				}
			});
			zip.generateAsync({ type: "blob" }).then(function(blob) {
				fileDownload(blob, `${rootFolder}.zip`);
				return true;
			});
		}
	});
};

export const sanitizeString = (str) => {
	return str
		.toLowerCase()
		.replace(/[^a-zA-Z0-9 ]/g, "")
		.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
		.replace(/ +(?= )/g, "")
		.replace(/ /g, "_");
};

export const sanitizeFilename = (version) => {
	const masterTitle = version.master_title;
	const versionName = version.version_name;
	const extension = version.version_filename.split(".").pop();
	const filename = `${masterTitle} ${versionName}`
		.toLowerCase()
		.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
		.replace(/[^a-zA-Z0-9 ]/g, "")
		.replace(/ /g, "_");
	return `${filename}.${extension}`;
};

export const JSDownload = (cart, skinConfig) => {
	let fileList = [];
	cart.forEach((version) => {
		let versionData = {
			title: version.master_title,
			versionName: version.version_name,
			blob: null,
			stems: [],
		};

		const url = `${process.env.REACT_APP_MASTER_AUDIO_LOCATION}/${version.version_filename}`;
		Axios.get(url, {
			responseType: "blob",
		})
			.then((response) => {
				versionData.blob = response.data;
				getStems(version.master_id, version.version_id).then(
					(stems) => {
						let promises = [];
						if (stems.length) {
							stems.forEach((stem) => {
								const url = `${process.env.REACT_APP_MASTER_AUDIO_LOCATION}/${stem.stem_filename}`;
								promises.push(
									Axios.get(url, {
										responseType: "blob",
									}).then((response) => {
										let stemData = {
											stemName: stem.stem_name,
											stemBlob: response.data,
										};
										versionData.stems.push(stemData);
									})
								);
							});
						}
						Promise.all(promises)
							.then(() => {
								fileList.push(versionData);
							})
							.then(() => {});
					}
				);
			})
			.then((res) => {});
	});
};

const getVersionStems = (groupId, version) => {
	const currentState = store.getState();
	let stemList = [];
	return getStems(groupId, version.master_id, version.version_id).then(
		(response) => {
			let promises = [];
			const stems = response.stems;
			const library = response.library;
			if (stems.length > 1) {
				stems.forEach(function(stem) {
					const url = `${currentState.skinConfig.skin_stems_url}/${stem.stem_filename}`;
					promises.push(
						Axios.get(url, {
							responseType: "blob",
						})
							.then((response) => {
								let stemData = {
									stemName: stem.stem_name,
									stemBlob: response.data,
									stemExtension: stem.stem_filename
										.split(".")
										.pop(),
								};
								stemList.push(stemData);
							})
							.catch((error) => {
								console.log("Error: ", error);
							})
					);
				});
			}
				if (library.length) {
				library.forEach(function(library_file) {
					const url = `${currentState.skinConfig.skin_stems_url}/${library_file.stem_library_file_filename}`;
					promises.push(
						Axios.get(url, {
							responseType: "blob",
						})
							.then((response) => {
								let stemData = {
									stemName: library_file.stem_library_name,
									stemBlob: response.data,
									stemExtension: library_file.stem_library_file_filename
										.split(".")
										.pop(),
								};
								stemList.push(stemData);
							})
							.catch((error) => {
								console.log("Error: ", error);
							})
					);
				});
			}
			return Promise.all(promises).then(() => {
				return stemList;
			});
		}
	);
};
