import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import Metadata from "./Metadata";
import ChipSelector from "../../../library/ChipSelector";
import Confirmation from "../../../library/Confirmation";
import Processing from "./Processing";
import { sanitizeTitle, titleCase } from "../../../helpers/SharedFunctions";
import {
  getAllCredits,
  createRegisteredWork,
  createCreditRelationship,
  createCredit,
} from "../../../helpers/ApiCalls";
import CustomDialogActions from "../../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../../library/dialog/CustomDialogContent";
import {
  setSnackbarVisibility,
  setDialogVisibility,
} from "../../../actions/index";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: theme.spacing(3),
    right: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ConnectedGroupBuilder = (props, ref) => {
  const {
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    doSearch,
  } = props;

  const initialData = {
    registered_work_title: "",
    registered_work_identification_code: "",
    credits: [],
  };

  const [workState, setWorkState] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [allCredits, setAllCredits] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [processing, setProcessing] = useState({
    work: { status: "pending" },
    credits: { status: "pending" },
  });

  const classes = useStyles();

  useEffect(() => {
    !allCredits.length &&
      getAllCredits().then((res) => {
        setAllCredits(mapChipData(res.credits, "credit_id", "credit_name"));
      });
  }, []);

  const mapChipData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.credit_id,
          value: `${titleCase(`${item.credit_name.replace("&amp;", "&")}`)}`,
        }))
      : [];
  };

  const steps = [
    {
      stepTitle: "Enter metadata",
      fields: ["registered_work_title", "registered_work_identification_code"],
    },
    { stepTitle: "Select credits", fields: ["credits"] },
  ];

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Metadata
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleFieldChange}
            data={workState}
            disabled={loading}
            access={access}
          />
        );
      case 1:
        return (
          <ChipSelector
            allData={allCredits}
            setAllData={setAllCredits}
            selectedData={workState.credits}
            setSnackbarVisibility={setSnackbarVisibility}
            handleSelect={handleFieldChange}
            fieldName={`credits`}
            disabled={loading}
            access={access}
            chipType={`Credit`}
            allowCreate={true}
            options={{
              limit: 20,
            }}
            isAsync={false}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...workState };
    const value = event.target.value;
    currentState[input] = value;
    setWorkState(currentState);
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...workState };
    currentState[field] = value;
    setWorkState(currentState);
  };

  const validateField = (inputField, value) => {
    if (typeof value === "object") {
      value = Object.keys(value);
    }

    if (!value || value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    return true;
  };

  const triggerValidation = () => {
    for (const field of steps[activeStep].fields) {
      if (!validateField(field, workState[field])) {
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (!triggerValidation()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "registered_work_title":
        message = "Please enter a title";
        break;
      case "registered_work_identification_code":
        message = "Please enter an identification code";
        break;
      case "credits":
        message = "Please add at least one credit";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleClose = () => {
    setConfirmationOpen(true);
  };

  const handleSubmit = async () => {
    if (!triggerValidation()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoading(true);
    const workId = await createWorkEntry();
    if (!workId) {
      setLoading(false);
      return;
    }
    if (workState.credits.length) {
      if (!(await handleCredits(workId))) {
        setLoading(false);
        return;
      }
    }
    doSearch();
    setLoading(false);
  };

  const handleCredits = async (workId) => {
    let postData = { registered_work_id: workId };
    let currentProcessing = { ...processing };
    currentProcessing.credits.status = "processing";
    setProcessing(currentProcessing);
    for (const credit of workState.credits) {
      let creditId;
      if (credit.tagIsNew) {
        const newCredit = { credit_name: credit.value };
        creditId = await createCredit(newCredit).then((res) => {
          return res.data.result;
        });
        if (!creditId) {
          currentProcessing.credits.status = "fail";
          setProcessing({ ...currentProcessing });
          return false;
        }
      } else {
        creditId = credit.id;
      }
      postData.credit_id = creditId;
      const relationshipId = await createCreditRelationship(workId, postData);
      if (!relationshipId) {
        currentProcessing.credits.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
    }
    currentProcessing.credits.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const createWorkEntry = () => {
    const postData = {
      registered_work_title: workState.registered_work_title.trim(),
      registered_work_identification_code: workState.registered_work_identification_code.trim(),
    };

    let currentProcessing = { ...processing };
    currentProcessing.work.status = "processing";
    setProcessing(currentProcessing);

    return createRegisteredWork(postData).then((res) => {
      if (!res.data.result) {
        currentProcessing.work.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.work.status = "success";
      setProcessing({ ...currentProcessing });
      return res.data.result;
    });
  };

  const actionButtons = [
    {
      label: activeStep === steps.length ? "Done" : "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      isHidden: false,
      fn: activeStep === steps.length ? handleConfirmationConfirm : handleClose,
    },
    {
      label: "Back",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      disabled: activeStep === 0,
      isHidden: activeStep === steps.length,
      fn: handleBack,
    },
    {
      label: activeStep === steps.length - 1 ? "Submit" : "Next",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      isHidden: activeStep === steps.length,
      fn: activeStep === steps.length - 1 ? handleSubmit : handleNext,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={
          workState.registered_work_title.length
            ? titleCase(sanitizeTitle(workState.registered_work_title))
            : `New Work`
        }
      />
      <CustomDialogContent>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.stepTitle}>
                <StepLabel>{step.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <Processing processing={processing} workState={workState} />
          ) : (
            getStepContent(activeStep)
          )}
        </div>
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedGroupBuilder.propTypes = {
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  doSearch: PropTypes.func.isRequired,
};

const GroupBuilder = connect(null, mapDispatchToProps)(ConnectedGroupBuilder);

export default GroupBuilder;
