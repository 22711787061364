import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles } from "@material-ui/core";
import { getAllBuilds } from "../../helpers/ApiCalls";
import EnhancedTable from "../../library/EnhancedTable";
import {
	titleCase,
	formatDate,
	createSkeletonData,
} from "../../helpers/SharedFunctions";
import { connect } from "react-redux";
import { setSearchState, resetSearch } from "../../actions/index";
import BuildListActions from "./BuildListActions";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
		position: "relative",
	},
	typeColumn: {
		[theme.breakpoints.down("md")]: {
			width: "20%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "10%",
		},
	},
	nameColumn: {
		[theme.breakpoints.down("sm")]: {
			width: "30%",
		},
		[theme.breakpoints.only("md")]: {
			width: "25%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "20%",
		},
		paddingLeft: theme.spacing(1),
	},
	catalogColumn: {
		[theme.breakpoints.down("md")]: {
			width: "20%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "10%",
		},
	},
	statusColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "20%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "10%",
		},
	},
	dateColumn: {
		[theme.breakpoints.up("md")]: {
			width: "15%",
		},
	},
	actionColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "10%",
		},
		[theme.breakpoints.only("sm")]: {
			width: "8%",
		},
		[theme.breakpoints.up("md")]: {
			width: "5%",
		},
	},
	xsHidden: {
		[theme.breakpoints.only("xs")]: {
			display: "none",
		},
	},
	smHidden: {
		[theme.breakpoints.only("sm")]: {
			display: "none",
		},
	},
	mdHidden: {
		[theme.breakpoints.only("md")]: {
			display: "none",
		},
	},
	lgHidden: {
		[theme.breakpoints.only("lg")]: {
			display: "none",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		searching: state.searching,
		activeSearch: state.activeSearch,
		skinConfig: state.skinConfig,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
		resetSearch: () => dispatch(resetSearch()),
	};
};

const ConnectedBuildList = (props) => {
	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("build_created_date");
	const [access, setAccess] = useState({
		create: false,
		read: false,
		update: false,
		delete: false,
	});
	const classes = useStyles();
	const {
		permissions,
		setSearchState,
		searching,
		resetSearch,
		activeSearch,
		skinConfig,
	} = props;
	const {
		statusColumn,
		dateColumn,
		xsHidden,
		typeColumn,
		smHidden,
		nameColumn,
		catalogColumn,
		mdHidden,
	} = classes;

	const columns = [
		{
			id: "build_name",
			numeric: false,
			disablePadding: false,
			label: "Item",
			classes: [nameColumn],
			disableSort: true,
		},
		{
			id: "build_type",
			numeric: false,
			disablePadding: false,
			label: "Type",
			classes: [typeColumn],
		},
		{
			id: "build_catalog",
			numeric: false,
			disablePadding: false,
			label: "Catalog",
			classes: [catalogColumn],
			disableSort: true,
		},
		{
			id: "build_created_date",
			numeric: false,
			disablePadding: false,
			label: "Created",
			classes: [dateColumn, xsHidden, smHidden],
		},
		{
			id: "build_start_date",
			numeric: false,
			disablePadding: false,
			label: "Started",
			classes: [xsHidden, smHidden, mdHidden, dateColumn],
		},
		{
			id: "build_end_date",
			numeric: false,
			disablePadding: false,
			label: "Ended",
			classes: [xsHidden, smHidden, mdHidden, dateColumn],
		},
		{
			id: "build_status",
			numeric: false,
			disablePadding: false,
			label: "Status",
			classes: [statusColumn],
		},
	];

	useEffect(() => {
		const permissionObj = {
			create: permissions.indexOf(25) > -1,
			read: permissions.indexOf(26) > -1,
			update: permissions.indexOf(27) > -1,
			delete: permissions.indexOf(28) > -1,
		};
		setAccess(permissionObj);
		return function cleanup() {
			resetSearch();
		};
	}, []);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		doSearch();
	}, [rowsPerPage, orderBy, orderDir, activeSearch]);

	useEffect(() => {
		page !== null && doSearch();
	}, [page]);

	const doSearch = () => {
		setItems(createSkeletonData(columns, rowsPerPage));
		setSearchState(true);
		const { query } = activeSearch;
		let queryString = `limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;
		if (query.length) {
			queryString += `&q=${encodeURIComponent(query)}`;
		}

		getAllBuilds(skinConfig.skin_id, queryString).then((res) => {
			setItems(res.builds);
			setTotalItems(res.total);
			setSearchState(false);
		});
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	const formatData = (data, field) => {
		switch (field) {
			case "build_name":
			case "build_catalog":
				return data ? titleCase(data) : "Unknown";
			case "build_status":
				switch (parseInt(data)) {
					case 0:
						return "Ended";
					case 1:
						return "Pending";
					case 2:
						return "Running";
					case 3:
						return "Cancelled";
					default:
						return "Unknown";
				}
			case "build_type":
				switch (parseInt(data)) {
					case 1:
						return "Content";
					case 2:
						return "Group";
					default:
						return "Unknown";
				}
			case "build_created_date":
			case "build_modified_date":
			case "build_requested_date":
			case "build_start_date":
			case "build_end_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedRow(index);
	};

	return (
		<React.Fragment>
			{access.read && (
				<Paper className={classes.container}>
					<EnhancedTable
						items={items}
						columns={columns}
						handleRowClick={handleRowClick}
						formatData={formatData}
						selectedRow={selectedRow}
						classNames={classes}
						numberOfActions={1}
						idField={"user_id"}
						orderBy={orderBy}
						orderDir={orderDir}
						handleArrowNav={handleArrowNav}
						totalItems={totalItems}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						page={page}
						setPage={setPage}
						searching={searching}
						setOrderDir={setOrderDir}
						setOrderBy={setOrderBy}
					>
						<BuildListActions
							doSearch={doSearch}
							className={classes.actionColumn}
						/>
					</EnhancedTable>
				</Paper>
			)}
		</React.Fragment>
	);
};

ConnectedBuildList.propTypes = {
	permissions: PropTypes.array.isRequired,
	setSearchState: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	resetSearch: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
};

const BuildList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedBuildList);

export default BuildList;
