import React, { useState } from "react";
import {
  IconButton,
  CircularProgress,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import ActionMenu from "./ActionMenu";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    padding: theme.spacing(0.5),
  },
}));

const ActionButton = (props) => {
  const {
    label,
    children,
    menuItems,
    menuItemProps,
    clickAction,
    selectionAction,
    initialSelection,
    disabled,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClick = async (event) => {
    event.stopPropagation();
    const element = event.currentTarget;
    setLoading(true);
    const showMenu = await clickAction();
    setAnchorEl(showMenu ? element : null);
    setLoading(false);
  };

  const handleMenuClose = () => {
    setLoading(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        title={label}
        enterDelay={300}
        enterNextDelay={300}
      >
        <span>
          <IconButton
            color="secondary"
            disabled={loading || disabled}
            aria-label={label}
            aria-owns={anchorEl ? "selection-menu" : undefined}
            aria-haspopup="true"
            className={classes.button}
            onClick={(event) => handleClick(event)}
          >
            {children}
          </IconButton>
        </span>
      </Tooltip>
      {loading && (
        <CircularProgress
          color="secondary"
          size={24}
          className={classes.buttonProgress}
        />
      )}
      {menuItems && menuItems.length ? (
        <ActionMenu
          label={label}
          handleMenuClose={handleMenuClose}
          initialSelection={initialSelection}
          anchorEl={anchorEl}
          menuItems={menuItems}
          selectionAction={selectionAction}
          menuItemProps={menuItemProps}
        />
      ) : null}
    </React.Fragment>
  );
};

ActionButton.propTypes = {
  children: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  initialSelection: PropTypes.array,
  selectionAction: PropTypes.func,
  clickAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  menuItemProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ActionButton;
