import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import PropTypes from "prop-types";
import { titleCase } from "../helpers/SharedFunctions";
import { connect } from "react-redux";
import { updateSearch } from "../actions/index";
import {
  MusicNoteOutlined,
  TimerOutlined,
  LocalOfferOutlined,
  SpeedOutlined,
  Class,
  Search,
} from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  paper: {
    padding: theme.spacing(0.5),
  },
  clearButton: {
    margin: theme.spacing(0.25),
    borderRadius: "0px",
  },
  chipsWrapper: {
    height: "auto",
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px 0px`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px ${theme.spacing(1)}px`,
    },
  },
});

const keyTranslations = {
  d: "D",
  g: "G",
  c: "C",
  f: "F",
  bb: "B♭",
  eb: "E♭",
  ab: "A♭",
  "c#": "C♯",
  "f#": "F♯",
  b: "B",
  e: "E",
  a: "A",
  bm: "Bm",
  em: "Em",
  am: "Am",
  dm: "Dm",
  gm: "Gm",
  cm: "Cm",
  fm: "Fm",
  bbm: "B♭m",
  ebm: "E♭m",
  "g#m": "G♯m",
  "c#m": "C♯m",
  "f#m": "F♯m",
};

const mapStateToProps = (state) => {
  return {
    activeSearch: state.activeSearch,
    availableTags: state.availableTags,
    availableCategories: state.availableCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (field, search) => dispatch(updateSearch(field, search)),
  };
};

const ConnectedFilterChips = (props) => {
  const { activeSearch, updateSearch, classes, availableCategories } = props;
  const { categories, keys, tempos, tags, durations, query } = activeSearch;

  const handleTagDelete = (tagId) => () => {
    const newState = { ...activeSearch };
    newState.tags.forEach(function(item, index) {
      if (item.tag_id === tagId) {
        newState.tags.splice(index, 1);
      }
    });
    updateSearch(newState);
  };

  const handleKeyDelete = (key) => () => {
    const newState = { ...activeSearch };
    newState.keys.forEach(function(item, index) {
      if (item === key) {
        newState.keys.splice(index, 1);
      }
    });
    updateSearch(newState);
  };

  const handleCategoryDelete = (key) => () => {
    const newState = { ...activeSearch };
    if (newState.categories.indexOf(key) !== -1) {
      newState.categories.splice(newState.categories.indexOf(key), 1);
    }
    updateSearch(newState);
  };

  const handleTempoDelete = () => () => {
    const newState = { ...activeSearch };
    newState.tempos = [];
    updateSearch(newState);
  };

  const clearChips = () => () => {
    const newState = { ...activeSearch };
    Object.keys(newState).forEach((element) => {
      newState[element] = [];
    });
    updateSearch(newState);
  };

  const handleDurationDelete = () => () => {
    const newState = { ...activeSearch };
    newState.durations = [];
    updateSearch(newState);
  };

  const handleQueryDelete = () => {
    const newState = { ...activeSearch };
    newState.query = "";
    updateSearch(newState);
  };

  const getCategoryName = (catId) => {
    let categoryName = availableCategories.find((x) => x.category_id === catId)
      .category_name;
    return titleCase(categoryName);
  };

  const queryArray = query.length ? [query] : [];
  const combinedChips = categories.concat(
    queryArray,
    keys,
    tempos.length ? tempos[0] : [],
    durations.length ? durations[0] : [],
    tags
  );

  const chipsPresent = combinedChips.length > 1;

  return (
    <div className={classes.chipsWrapper}>
      <div className={classes.root}>
        {!!query.length && (
          <Chip
            icon={<Search />}
            size="small"
            label={query}
            className={classes.chip}
            onDelete={handleQueryDelete}
          />
        )}
        {Object.entries(categories).length
          ? Object.entries(categories).map(([key, value]) => (
              <Chip
                icon={<Class />}
                size="small"
                key={key}
                label={getCategoryName(value)}
                className={classes.chip}
                onDelete={handleCategoryDelete(value)}
              />
            ))
          : null}
        {Object.entries(keys).length
          ? Object.entries(keys).map(([key, value]) => (
              <Chip
                icon={<MusicNoteOutlined />}
                size="small"
                key={key}
                label={keyTranslations[value]}
                className={classes.chip}
                onDelete={handleKeyDelete(value)}
              />
            ))
          : null}
        {Object.entries(tempos).length ? (
          <Chip
            icon={<SpeedOutlined />}
            size="small"
            label={`${tempos[0]} - ${tempos[1]} BPM`}
            className={classes.chip}
            onDelete={handleTempoDelete()}
          />
        ) : null}
        {Object.entries(durations).length ? (
          <Chip
            icon={<TimerOutlined />}
            size="small"
            label={`${durations[0]} - ${durations[1]}"`}
            className={classes.chip}
            onDelete={handleDurationDelete()}
          />
        ) : null}
        {Object.entries(tags).length
          ? Object.entries(tags).map(([key, value]) => (
              <Chip
                icon={<LocalOfferOutlined />}
                size="small"
                key={key}
                label={titleCase(value.tag_name)}
                className={classes.chip}
                onDelete={handleTagDelete(value.tag_id)}
              />
            ))
          : null}
        {chipsPresent ? (
          <Chip
            size="small"
            label={"CLEAR ALL"}
            variant="outlined"
            className={classes.clearButton}
            onClick={clearChips()}
          />
        ) : null}
      </div>
    </div>
  );
};

ConnectedFilterChips.propTypes = {
  classes: PropTypes.object.isRequired,
  activeSearch: PropTypes.object.isRequired,
  updateSearch: PropTypes.func.isRequired,
  availableCategories: PropTypes.array.isRequired,
};

const FilterChips = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedFilterChips);

export default withStyles(styles)(FilterChips);
