import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid, IconButton, Hidden } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 64,
  },
  logo: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100%",

    cursor: "pointer",
    [theme.breakpoints.only("md")]: {
      left: 0,
      transform: "translate(0, -50%)",
      maxWidth: "85%",
    },

    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
    },

    [theme.breakpoints.only("sm")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.only("lg")]: {
      maxWidth: "95%",
    },
    [theme.breakpoints.only("xl")]: {
      maxWidth: "80%",
    },
  },
  logoWrapper: {
    padding: `${theme.spacing(1)}px 0px`,
    height: 64,
    position: "relative",
    overflow: "hidden",
  },
  imgHelper: {
    display: "inline-block",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const Header = (props) => {
  const { handleDrawerToggle, skinConfig, children } = props;
  const classes = useStyles();

  const redirectToRoot = () => {
    window.location.href = "/";
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          align="center"
        >
          <Hidden only="md">
            <Grid
              component={Grid}
              item
              xl={5}
              lg={5}
              sm={3}
              xs={2}
              display={{ md: "none", sm: "block" }}
              align="left"
            >
              <Hidden mdUp>
                <div className={classes.iconWrapper}>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <Menu />
                  </IconButton>
                </div>
              </Hidden>
            </Grid>
          </Hidden>
          <Grid item xl={2} lg={2} md={3} sm={5} xs={5} align="center">
            <div className={classes.logoWrapper}>
              {skinConfig.skin_logo ? (
                <span className={classes.imgHelper}>
                  <img
                    className={classes.logo}
                    src={`${skinConfig.skin_branding_url}/${skinConfig.skin_logo}`}
                    alt="logo"
                    onClick={redirectToRoot}
                    onError={(e) => {
                      e.target.src =
                        "https://s3.amazonaws.com/nightrain.assets/default.png";
                    }}
                  />
                </span>
              ) : null}
            </div>
          </Grid>
          <Grid item xl={5} lg={5} md={9} sm={4} xs={5} align="right">
            <div className={classes.actionWrapper}>{children}</div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  skinConfig: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default Header;
