import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { changePassword } from "../helpers/ApiCalls";

const styles = (theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: "white !important",
    },
  },
  formLabelFocused: {
    color: "white !important",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    margin: `${theme.spacing(2)}px 0px`,
    position: "relative",
    float: "right",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
});

const initialState = {
  currentPassword: { value: "", valid: false },
  newPassword: { value: "", valid: false },
  confirmPassword: { value: "", valid: false },
  loading: false,
};

class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleBlur = (input) => (event) => {
    const inputValue = event.target.value;
    const response = this.passwordValidation(input, inputValue);
    !response.isValid && this.context(response.message);
    this.setState({
      [input]: { ...this.state[input], valid: response.isValid },
    });
  };

  handleChange = (input) => (event) => {
    this.setState({
      [input]: { ...this.state[input], value: event.target.value },
    });
  };

  passwordValidation = (inputField, inputValue) => {
    let response = { isValid: false, message: null };
    switch (inputField) {
      case "currentPassword":
        if (!inputValue) {
          response.message = "Please enter your current password.";
        } else {
          response.isValid = true;
        }
        break;
      case "newPassword":
        let containsUppercase = !!inputValue.match(/^(?=.*[A-Z])/);
        let containsLowercase = !!inputValue.match(/^(?=.*[a-z])/);
        let containsNumber = !!inputValue.match(/^(?=.*\d)/);
        let betweenMinMax = !!(
          inputValue.length >= 6 && inputValue.length <= 20
        );
        if (!inputValue) {
          response.message = "Please enter a new password.";
        } else if (!betweenMinMax) {
          response.message = "Password must contain 6 to 20 characters.";
        } else if (!containsUppercase) {
          response.message =
            "Password must contain at least one uppercase letter.";
        } else if (!containsLowercase) {
          response.message =
            "Password must contain at least one lowercase letter.";
        } else if (!containsNumber) {
          response.message =
            "Password must contain at least one numeric digit.";
        } else {
          response.isValid = true;
        }
        break;
      case "confirmPassword":
        if (!inputValue) {
          response.message = "Please confirm your new password.";
        } else if (inputValue !== this.state.newPassword.value) {
          response.message = "Passwords do not match.";
        } else {
          response.isValid = true;
        }
        break;
      default:
        break;
    }
    return response;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.loading) {
      this.setState({ loading: true });
      const fields = ["currentPassword", "newPassword", "confirmPassword"];
      //create empty array to track valid fields
      const formValid = [];
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = this.state[field].value;
        const response = this.passwordValidation(field, value);
        if (!response.isValid) {
          this.context(response.message);
          this.setState({ loading: false });
        }
        //break on invalid field
        if (response.isValid) {
          formValid.push(true);
        } else {
          break;
        }
      }
      //check all fields in step are valid
      if (formValid.length === fields.length) {
        const postData = {
          current_password: this.state.currentPassword.value,
          new_password: this.state.newPassword.value,
          user_id: this.props.userId,
        };

        changePassword(postData).then((data) => {
          if (data.result) {
            localStorage.setItem("id_token", data.result.token.token);
            this.setState(initialState);
            this.context("Password changed successfully.");
          } else {
            this.context(data.message);
            this.setState({ loading: false });
          }
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        className={classes.container}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          fullWidth
          id="currentPassword"
          name="currentPassword"
          type="password"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={this.state.currentPassword.value}
          onChange={this.handleChange("currentPassword")}
          onBlur={this.handleBlur("currentPassword")}
          placeholder="Current Password"
          InputLabelProps={{
            FormLabelClasses: {
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
            },
          }}
        />
        <TextField
          required
          fullWidth
          id="newPassword"
          name="newPassword"
          type="password"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={this.state.newPassword.value}
          onChange={this.handleChange("newPassword")}
          onBlur={this.handleBlur("newPassword")}
          placeholder="New Password"
          InputLabelProps={{
            FormLabelClasses: {
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
            },
          }}
        />
        <TextField
          required
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={this.state.confirmPassword.value}
          onChange={this.handleChange("confirmPassword")}
          onBlur={this.handleBlur("confirmPassword")}
          placeholder="Confirm Password"
          InputLabelProps={{
            FormLabelClasses: {
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
            },
          }}
        />
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            disabled={this.state.loading}
            color="secondary"
            className={classes.button}
            variant="outlined"
          >
            Confirm
          </Button>
          {this.state.loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </form>
    );
  }
}
ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withRouter(withStyles(styles)(ChangePassword));
