import React from "react";
import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const mapStateToProps = (state) => {
  return { dialog: state.dialog };
};

const ConnectedResponsiveDialog = (props) => {
  const { dialog } = props;

  const useStyles = makeStyles((theme) => ({
    dialog: {
      minHeight: useMediaQuery(theme.breakpoints.down(dialog.fullScreen))
        ? "100dvh"
        : dialog.minHeight && "75vh",
      maxHeight:
        useMediaQuery(theme.breakpoints.up(dialog.fullScreen)) && "75vh",
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(dialog.fullScreen));
  const classes = useStyles();

  return dialog ? (
    <Dialog
      fullScreen={fullScreen}
      open={dialog.open}
      fullWidth={dialog.fullWidth}
      aria-labelledby="responsive-dialog-title"
      maxWidth={dialog.maxWidth}
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
    >
      {dialog.body}
    </Dialog>
  ) : null;
};

ConnectedResponsiveDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
};

const ResponsiveDialog = connect(mapStateToProps)(ConnectedResponsiveDialog);

export default ResponsiveDialog;
