import queryString from "query-string";
import { sha256 } from "js-sha256";
import Kali from "@descript/kali";

export const titleCase = (str) => {
	if (str != null) {
		const words = str.toLowerCase().split(" ");
		const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
		return words.map(capitalize).join(" ");
	}
};

export const sortObject = (obj, field) => {
	return obj.sort((a, b) => a[field].localeCompare(b[field]));
};

export const sortArray = (arr, field) => {
	return arr.sort((a, b) => a[field].localeCompare(b[field]));
};

export const searchAssociativeArray = (searchValue, array, field) => {
	for (var i = 0; i < array.length; i++) {
		if (array[i][field] === searchValue) {
			return array[i];
		}
	}
};

export const getAudioBuffer = async (actx, audioFile) => {
	let audioBuffer;

	await new Promise(function(resolve) {
		const request = new XMLHttpRequest();
		request.open("GET", audioFile, true);
		request.responseType = "arraybuffer";
		request.onload = function() {
			const audioData = request.response;
			if (audioData) {
				actx.decodeAudioData(
					audioData,
					function(buffer) {
						audioBuffer = buffer;
						resolve();
					},
					function(e) {
						console.log(e.err);
					}
				);
			}
		};
		request.send();
	});
	return audioBuffer;
};

export const stretchAudio = (
	inputData,
	stretchFactor,
	numChannels,
	context
) => {
	const numInputFrames = inputData.length / numChannels;
	const bufsize = 4096 * numChannels;
	const kali = new Kali(numChannels);
	kali.setup(context.sampleRate, stretchFactor, false);

	// Create an array for the stretched output
	const completed = new Float32Array(
		Math.floor((numInputFrames / stretchFactor) * numChannels + 1)
	);

	let inputOffset = 0;
	let completedOffset = 0;
	let flushed = false;

	while (completedOffset < completed.length) {
		// Read stretched samples into our output array
		completedOffset += kali.output(
			completed.subarray(
				completedOffset,
				Math.min(completedOffset + bufsize, completed.length)
			)
		);

		if (inputOffset < inputData.length) {
			// If we have more data to write, write it
			const dataToInput = inputData.subarray(
				inputOffset,
				Math.min(inputOffset + bufsize, inputData.length)
			);
			inputOffset += dataToInput.length;

			// Feed Kali samples
			kali.input(dataToInput);
			kali.process();
		} else if (!flushed) {
			// Flush if we haven't already
			kali.flush();
			flushed = true;
		}
	}

	return completed;
};

export const createTimeStamp = (date) => {
	let timeStamp = new Date(date);
	timeStamp.setHours(0, 0, 0, 0);
	timeStamp
		.toISOString()
		.slice(0, 19)
		.replace("T", " ");
	return timeStamp;
};

export const createSkeletonData = (columns, rowsPerPage) => {
	const item = {};
	const itemArray = [];

	columns.forEach((column) => {
		item[column.id] = "";
	});

	for (let i = 0; i < rowsPerPage; i++) {
		itemArray.push(item);
	}
	return itemArray;
};

export const formatDuration = (seconds) => {
	// Hours, minutes and seconds
	var hrs = ~~(seconds / 3600);
	var mins = ~~((seconds % 3600) / 60);
	var secs = ~~seconds % 60;

	// Output like "1:01" or "4:03:59" or "123:03:59"
	var ret = "";
	if (hrs > 0) {
		ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
	}
	ret += "" + mins + ":" + (secs < 10 ? "0" : "");
	ret += "" + secs;
	return ret;
};

export const formatDisplayDate = (date, width) => {
	const today = new Date();
	let yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);
	const t = date.split(/[- :]/);
	const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

	switch (d.setHours(0, 0, 0, 0)) {
		case today.setHours(0, 0, 0, 0):
			return "Today";
		case yesterday.setHours(0, 0, 0, 0):
			return "Yesterday";
		default:
			return width !== "xs"
				? d.toDateString()
				: d.toLocaleString("default", {
						month: "short",
						day: "2-digit",
				  });
	}
};

export const base64ToBlob = (base64, mime) => {
	mime = mime || "";
	var sliceSize = 1024;
	var byteChars = window.atob(base64);
	var byteArrays = [];

	for (
		var offset = 0, len = byteChars.length;
		offset < len;
		offset += sliceSize
	) {
		var slice = byteChars.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: mime });
};

export const searchArray = (array, value) => {
	return array.includes(value);
};

export const arraysEqual = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (var i = arr1.length; i--; ) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
};

export const getSha = () => {
	return sha256(Date.now() + Math.random().toString()).substring(0, 6);
};

export const desc = (a, b, orderBy) => {
	a[orderBy] = a[orderBy] ? a[orderBy].toLowerCase() : "";
	b[orderBy] = b[orderBy] ? b[orderBy].toLowerCase() : "";
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const stableSort = (array, cmp) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
	const sortDesc = (a, b) => desc(a, b, orderBy);
	const sortAsc = (a, b) => -1 * desc(a, b, orderBy);
	return order === "desc" ? sortDesc : sortAsc;
};

export const emailExists = (postData) => {
	return fetch(
		`${process.env.REACT_APP_API_HOST}/index.php/api/check_email`,
		{
			method: "POST",
			body: JSON.stringify({ postData }),
		}
	).then((response) => {
		return response.json();
	});
};

export const isEmailValid = (email) => {
	return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export const formatDate = (date, prettify = true) => {
	if (!date) {
		return false;
	}
	const today = new Date();
	let yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);
	const t = date.split(/[- :]/);
	const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
	if (!prettify) {
		return d.toDateString();
	}
	switch (d.setHours(0, 0, 0, 0)) {
		case today.setHours(0, 0, 0, 0):
			return "Today";
		case yesterday.setHours(0, 0, 0, 0):
			return "Yesterday";
		default:
			return d.toDateString();
	}
};

export const fetchHelper = (method, APIurl, postData) => {
	return fetch(APIurl, { method, body: JSON.stringify({ postData }) }).then(
		(response) => {
			if (response.ok) {
				return response.json();
			}
			throw new Error();
		}
	);
};

export const getStations = () => {
	const APIurl = `${process.env.REACT_APP_API_HOST}/index.php/api/get_stations`;
	return fetchHelper("POST", APIurl);
};

export const getQueryString = (str) => {
	return queryString.parse(str);
};

export const getCountry = () => {
	const ipUrl = "https://api.ipify.org/";
	return fetch(ipUrl)
		.then(function(response) {
			return response.text();
			// pass the data as promise to next then block
		})
		.then((data) => {
			const countryUrl = `https://api.ipstack.com/${data}?access_key=${process.env.REACT_APP_IPIFY_API_KEY}`;
			// make a 2nd request and return a promise
			return fetch(countryUrl);
		})
		.then((response) => {
			return response.json();
		})
		.catch((error) => {
			console.log("Request failed", error);
		});
};

export const translateKey = (str) => {
	str = str.toLowerCase();
	if (str.charAt(1) === "b") {
		str = str.substring(0, 1) + "♭" + str.substring(2);
	}
	if (str === "n") {
		str = str.replace(str, "N/A");
	}
	return str.toUpperCase().replace(/M/g, "m");
};

export const sanitizeTitle = (str) => {
	return str.toString().replace(/\.[^/.]+$/, "");
};

export const inputValidation = (inputField, inputValue) => {
	let response = { isValid: false, message: null };
	switch (inputField) {
		case "firstName":
			if (!inputValue) {
				response.message = "Please enter your first name";
			} else {
				response.isValid = true;
			}
			break;
		case "lastName":
			if (!inputValue) {
				response.message = "Please enter your last name";
			} else {
				response.isValid = true;
			}
			break;
		case "name":
			if (!inputValue) {
				response.message = "Please enter your name";
			} else {
				response.isValid = true;
			}
			break;
		case "stationName":
			if (!inputValue) {
				response.message = "Please enter your station name";
			} else {
				response.isValid = true;
			}
			break;
		case "username":
		case "email":
			let emailFormatValid = inputValue.match(
				/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
			);
			if (!inputValue) {
				response.message = "Please enter your email address";
			} else if (inputValue && !emailFormatValid) {
				response.message = "Your email address is invalid";
			} else {
				response.isValid = true;
			}
			break;
		case "country":
			if (!inputValue) {
				response.message = "Please select your country";
			} else {
				response.isValid = true;
			}
			break;
		case "password":
			if (!inputValue) {
				response.message = "Please enter your password";
			} else {
				response.isValid = true;
			}
			break;
		case "newPassword":
			if (!inputValue) {
				response.message = "Please enter a password";
				break;
			}
			let passwordFormatValid = inputValue.match(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
			);
			if (!passwordFormatValid) {
				response.message =
					"Please enter at least 8 characters, 1 uppercase, 1 lowercase & 1 number";
				break;
			}
			response.isValid = true;
			break;
		case "individualReason":
			if (!inputValue) {
				response.message = "Please enter your reason for a trial";
			} else {
				response.isValid = true;
			}
			break;
		case "message":
			if (!inputValue) {
				response.message = "Please enter your message";
			} else {
				response.isValid = true;
			}
			break;
		case "selectedProducts":
			const values = Object.values(inputValue);
			var count = values.reduce(function(n, val) {
				return n + (val === true);
			}, 0);
			if (count === 0) {
				response.message = "Please select at least one format";
			} else {
				response.isValid = true;
			}
			break;
		case "masterTitle":
			if (!inputValue) {
				response.message = "Please enter a title";
			} else {
				response.isValid = true;
			}
			break;
		case "masterSubtitle":
			if (!inputValue) {
				response.message = "Please enter a sub-title";
			} else {
				response.isValid = true;
			}
			break;
		case "masterCatalog":
			if (!inputValue) {
				response.message = "Please select a catalog";
			} else {
				response.isValid = true;
			}
			break;
		case "masterCategory":
			if (!inputValue) {
				response.message = "Please select a category";
			} else {
				response.isValid = true;
			}
			break;
		case "masterKey":
			if (!inputValue) {
				response.message = "Please select a key";
			} else {
				response.isValid = true;
			}
			break;
		case "masterTempo":
			if (!inputValue) {
				response.message = "Please enter a tempo";
			} else if (!Number.isInteger(parseInt(inputValue))) {
				response.message = "Tempo must be a whole number";
			} else {
				response.isValid = true;
			}
			break;
		case "masterDisplayDate":
			if (!inputValue) {
				response.message = "Please select a display date";
			} else {
				response.isValid = true;
			}
			break;
		case "versionName":
			if (!inputValue) {
				response.message = "Please enter a version name";
			} else {
				response.isValid = true;
			}
			break;
		case "stemName":
			if (!inputValue) {
				response.message = "Please enter a stem name";
			} else {
				response.isValid = true;
			}
			break;
		default:
			response.isValid = true;
			break;
	}
	return response;
};
