import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, CircularProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
  input: {
    display: "none",
  },
  updateButton: {
    float: "right",
    marginLeft: theme.spacing(2),
  },
  buttonWrapper: {
    position: "relative",
    display: "inline-block",
    float: "right",
  },
  paper: {
    padding: theme.spacing(1),
    display: "inline-block",
    overflow: "hidden",
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cropWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
});

class ImageManager extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      src: null,
      crop: {
        unit: "%",
        height: 100,
        width: 100,
      },
    };
    this.state = this.initialState;
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  handleConfirm = (file, asset, extension) => {
    const that = this;
    this.props.toggleLoading(asset);
    this.props.submitAsset(file, asset, extension).then((response) => {
      that.props.toggleLoading("");
      that.setState(that.initialState);
    });
  };

  handleCancel = () => {
    this.props.handleCancel();
    this.setState(this.initialState);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );
      this.props.handleImageChange(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/png");
    });
  }

  render() {
    const { crop, src } = this.state;
    const { classes, loading, previewImage } = this.props;
    return (
      <div>
        {this.props.children}
        {src && (
          <div className={classes.paperWrapper}>
            <Paper className={classes.paper}>
              <div className={classes.cropWrapper}>
                <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </div>
            </Paper>
          </div>
        )}
        <div>
          <Typography
            variant="caption"
            display="block"
            className={classes.caption}
          >
            This is your company, product or brand logo. A transparent .png is
            required.
          </Typography>
          <input
            accept="image/png"
            className={classes.input}
            id="contained-button-file"
            type="file"
            variant="outlined"
            color="secondary"
            onChange={this.onSelectFile}
          />

          {src ? (
            <React.Fragment>
              <div className={classes.buttonWrapper}>
                <Button
                  id="skin_logo"
                  variant="outlined"
                  color="secondary"
                  className={classes.updateButton}
                  onClick={() =>
                    this.handleConfirm(previewImage, "skin_logo", "png")
                  }
                  disabled={Boolean(loading.length)}
                >
                  Confirm
                </Button>
                {loading === "skin_logo" && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>

              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  className={classes.updateButton}
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className={classes.buttonWrapper}>
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  color="secondary"
                  className={classes.updateButton}
                >
                  Replace
                </Button>
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ImageManager.propTypes = {
  loading: PropTypes.string.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  submitAsset: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageManager);
