import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Switch,
} from "@material-ui/core";
import { translateKey } from "../../helpers/SharedFunctions";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "stem_library_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    class: "firstCell",
    sortable: true,
  },
  {
    id: "stem_library_type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_key",
    numeric: false,
    disablePadding: false,
    label: "Key",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_bpm",
    numeric: true,
    disablePadding: false,
    label: "Tempo",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    class: "switchHead",
    sortable: false,
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes[headCell["class"]]}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={headCell.sortable && createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  table: {
    position: "absolute",
    top: theme.spacing(3),
    bottom: 0,
    left: theme.spacing(3),
    right: theme.spacing(3),
    maxWidth: "100vw",
  },
  tableWrapper: {
    flex: 1,
    maxWidth: "100vw",
  },
  customTableContainer: {
    height: "100%",
    overflowY: "auto",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    "&:focus": {
      outline: "none",
    },
  },
  tableRoot: {
    maxWidth: "100vw",
    width: "100%",
    tableLayout: "fixed",
    backgroundColor: "none",
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
  },
  firstCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px`,
  },
  lastCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.5)}px`,
  },
  switchCell: {
    padding: "0px",
  },
  switchHead: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.5)}px`,
  },
}));

const StemLibraryList = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("stem_library_name");
  const {
    stemLibrary,
    setStemLibrary,
    originalStemLibraryData,
    editedLibrary,
    setEditedLibrary,
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChange = (stemLibraryId) => {
    const field = "stem_library_status";
    let currentState = { ...stemLibrary };
    currentState[stemLibraryId][field] = (+!Boolean(
      parseInt(currentState[stemLibraryId][field])
    )).toString();
    setStemLibrary(currentState);
    compareStates(stemLibraryId, field);
  };

  const compareStates = (stemLibraryId, field) => {
    let currentEditedLibrary = { ...editedLibrary };
    if (
      hasFieldChanged(stemLibraryId, field) &&
      currentEditedLibrary[stemLibraryId] &&
      currentEditedLibrary[stemLibraryId].indexOf(field) === -1
    ) {
      currentEditedLibrary[stemLibraryId].push(field);
      setEditedLibrary(currentEditedLibrary);
      return;
    }
    if (
      hasFieldChanged(stemLibraryId, field) &&
      !currentEditedLibrary[stemLibraryId]
    ) {
      currentEditedLibrary[stemLibraryId] = [field];
      setEditedLibrary(currentEditedLibrary);
      return;
    }
    if (
      !hasFieldChanged(stemLibraryId, field) &&
      currentEditedLibrary[stemLibraryId] &&
      currentEditedLibrary[stemLibraryId].indexOf(field) !== -1
    ) {
      currentEditedLibrary[stemLibraryId].splice(
        currentEditedLibrary[stemLibraryId].indexOf(field),
        1
      );
      !Object.keys(currentEditedLibrary[stemLibraryId]).length &&
        delete currentEditedLibrary[stemLibraryId];
      setEditedLibrary(currentEditedLibrary);
    }
  };

  const hasFieldChanged = (stemLibraryId, field) => {
    const originalState = originalStemLibraryData[stemLibraryId][field];
    const newState = stemLibrary[stemLibraryId][field];
    return originalState !== newState;
  };

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.table}>
        <TableContainer className={classes.customTableContainer}>
          <Table stickyHeader className={classes.tableRoot}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(
                Object.values(stemLibrary),
                getComparator(order, orderBy)
              ).map((row) => {
                const labelId = `stemLibrary-checkbox-${row.stem_id}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.stem_library_id}
                  >
                    <TableCell
                      className={classes.firstCell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.stem_library_name}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.stem_library_type}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {translateKey(row.stem_library_key)}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="right"
                      className={classes.tableCell}
                    >
                      {Math.round(row.stem_library_bpm)}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      align="right"
                      className={classes.switchCell}
                    >
                      <Switch
                        checked={Boolean(parseInt(row.stem_library_status))}
                        onChange={() => handleChange(row.stem_library_id)}
                        color="secondary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

StemLibraryList.propTypes = {
  stemLibrary: PropTypes.object.isRequired,
  setStemLibrary: PropTypes.func.isRequired,
  originalStemLibraryData: PropTypes.object.isRequired,
  editedLibrary: PropTypes.object.isRequired,
  setEditedLibrary: PropTypes.func.isRequired,
};

export default StemLibraryList;
