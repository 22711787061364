import React, { useState, useEffect } from "react";
import { Chip, CircularProgress, Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { searchAssociativeArray, titleCase } from "../helpers/SharedFunctions";
import { registerTagClick, getTrendingTags, emitEvent } from "../helpers/ApiCalls";
import { connect } from "react-redux";
import { updateAvailableTags, updateSearch } from "../actions/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.25),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedChip: {
    backgroundColor: theme.palette.action.selected,
    margin: theme.spacing(0.25),
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    float: "right",
    marginLeft: theme.spacing(1),
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
}));

const mapStateToProps = (state) => {
  return {
    activeCatalog: state.activeCatalog,
    activeSearch: state.activeSearch,
    availableTags: state.availableTags,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAvailableTags: (tags) => dispatch(updateAvailableTags(tags)),
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const ConnectedTagSelector = React.memo((props) => {
  const {
    activeCatalog,
    availableTags,
    activeSearch,
    updateAvailableTags,
    updateSearch,
    userData,
  } = props;
  const classes = useStyles();

  const [tagLimit, setTagLimit] = useState(25);

  useEffect(() => {
    activeCatalog && loadCatalogTags(activeCatalog);
  }, [activeCatalog]);

  const loadCatalogTags = async (groupId) => {
    const catalogTags = await getTrendingTags(activeCatalog, "trending");
    updateAvailableTags(catalogTags);
  };

  const handleClick = (tag) => {
    const tagObj = { tag_id: tag.tag_id, tag_name: tag.tag_name };
    const newState = { ...activeSearch };
    if (!searchAssociativeArray(tag.tag_id, newState.tags, "tag_id")) {
      registerTagClick(tagObj);
      emitEvent("tagClickV1", {
        tag_id: tag.tag_id,
        user_id: userData.user_id,
      });
      newState.tags.push(tagObj);
    } else {
      newState.tags.forEach(function(item, index) {
        if (item.tag_id === tag.tag_id) {
          newState.tags.splice(index, 1);
        }
      });
    }
    updateSearch(newState);
  };

  const handleLoadMore = () => {
    const newLimit = tagLimit + 25;
    setTagLimit(newLimit);
  };

  const handleClear = () => {
    const newState = { ...activeSearch };
    newState.tags = [];
    updateSearch(newState);
  };

  const isActive = (tagId) => {
    let active = searchAssociativeArray(tagId, activeSearch.tags, "tag_id");
    return active ? true : false;
  };

  const tags = availableTags.slice(0, tagLimit);
  const totalTags = availableTags.length;

  return tags.length ? (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.tagWrapper}>
          {tags.map((value) => (
            <Chip
              size="small"
              key={value.tag_id}
              label={titleCase(value.tag_name)}
              className={`${classes.chip} ${
                isActive(value.tag_id) ? classes.selectedChip : null
              }`}
              variant="outlined"
              clickable={false}
              onClick={(event) => handleClick(value)}
            />
          ))}
        </div>
        <div className={classes.buttonWrapper}>
          {!!activeSearch.tags.length && (
            <Button className={classes.button} onClick={handleClear}>
              Clear
            </Button>
          )}
          {tags.length < totalTags && (
            <Button className={classes.button} onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : (
    <div className={classes.progressWrapper}>
      <CircularProgress color={"inherit"} />
    </div>
  );
});

ConnectedTagSelector.propTypes = {
  activeCatalog: PropTypes.number,
  availableTags: PropTypes.array.isRequired,
  activeSearch: PropTypes.object.isRequired,
  updateAvailableTags: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

const TagSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTagSelector);

export default TagSelector;
