import React from "react";
import {
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import Styles from "./helpers/FormStyles";
import SnackBar from "./library/SnackBar";
import { Helmet } from "react-helmet";
import { getSkinConfig } from "./helpers/ApiCalls";
import { connect } from "react-redux";
import { searchAssociativeArray } from "./helpers/SharedFunctions";
import {
  updateSkinConfig,
  updateUserData,
  setLogInState,
  setSnackbarVisibility,
} from "./actions/index";
import ResponsiveDialog from "./library/ResponsiveDialogV2";
import { loggedIn } from "./helpers/AuthService";
import PropTypes from "prop-types";
import Fonts from "./helpers/Fonts";
import ContentWrapper from "./ContentWrapper";

const mapDispatchToProps = (dispatch) => {
  return {
    updateSkinConfig: (skinConfig) => dispatch(updateSkinConfig(skinConfig)),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    setLogInState: (logInState) => dispatch(setLogInState(logInState)),
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
  };
};

const mapStateToProps = (state) => {
  return {
    skinConfig: state.skinConfig,
    isLoggedIn: state.isLoggedIn,
    snackbar: state.snackbar,
  };
};

class ConnectedApp extends React.Component {
  constructor() {
    super();
    this.state = {
      drawerOpen: false,
    };
  }

  componentDidMount() {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    const siteHash = urlParams.get("product");
    const siteData = { skin_hash: siteHash, hostname: location.hostname };
    getSkinConfig(siteData).then((skinConfig) => {
      this.props.updateSkinConfig(skinConfig);
      this.checkLogin();
    });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason !== "clickaway") {
      this.props.setSnackbarVisibility({ open: false, message: "" });
    }
  };

  checkLogin = () => {
    loggedIn().then((isLoggedIn) => {
      this.handleLoginState(isLoggedIn);
    });
  };

  handleLoginState = (data) => {
    if (data) {
      this.props.updateUserData(data);
      this.props.setLogInState(true);
    } else {
      localStorage.removeItem("id_token");
      this.props.setLogInState(false);
    }
  };

  render() {
    const { skinConfig, snackbar, classes } = this.props;

    const font = searchAssociativeArray(
      parseInt(skinConfig.skin_font),
      Fonts,
      "fontId"
    );
    let theme = Object.entries(skinConfig).length
      ? createTheme({
          palette: {
            primary: { main: skinConfig.skin_primary_color },
            secondary: { main: skinConfig.skin_secondary_color },
            type: skinConfig.skin_theme,
          },
          typography: {
            fontFamily: font.fontDeclaration,
            useNextVariants: true,
          },
        })
      : null;

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{skinConfig.skin_title}</title>
          {skinConfig.skin_favicon ? (
            <link
              rel="icon"
              type="image/png"
              href={`${skinConfig.skin_branding_url}/${skinConfig.skin_favicon}`}
              sizes="16x16"
            />
          ) : null}
        </Helmet>
        <Router>
          {theme ? (
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <ResponsiveDialog />
              <div className={classes.root}>
                <ContentWrapper
                  handleLoginState={this.handleLoginState}
                  skinConfig={skinConfig}
                />
              </div>

              <SnackBar
                content={snackbar}
                handleClose={this.handleSnackbarClose}
              />
            </MuiThemeProvider>
          ) : null}
        </Router>
      </React.Fragment>
    );
  }
}

ConnectedApp.propTypes = {
  classes: PropTypes.object.isRequired,
  updateSkinConfig: PropTypes.func.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  skinConfig: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  setLogInState: PropTypes.func.isRequired,
  snackbar: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default withStyles(Styles)(App);
