import React, { useEffect } from "react";
import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { titleCase } from "../helpers/SharedFunctions";
import { getCategories, emitEvent } from "../helpers/ApiCalls";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateAvailableCategories, updateSearch } from "../actions/index";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    width: "100%",
  },
  formControl: {
    padding: 0,
  },
  categoryWrapper: {
    display: "flex",
  },
  clearButton: {
    float: "right",
  },
  clearButtonWrapper: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  checkBox: {
    padding: theme.spacing(0.5),
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const mapStateToProps = (state) => {
  return {
    activeCatalog: state.activeCatalog,
    activeSearch: state.activeSearch,
    availableCategories: state.availableCategories,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAvailableCategories: (categories) =>
      dispatch(updateAvailableCategories(categories)),
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const ConnectedCategorySelector = React.memo((props) => {
  const {
    activeCatalog,
    availableCategories,
    updateAvailableCategories,
    activeSearch,
    updateSearch,
    userData,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    activeCatalog && loadCatalogCategories(activeCatalog);
  }, [activeCatalog]);

  const loadCatalogCategories = async (groupId) => {
    const catalogCategories = await getCategories(activeCatalog);
    const sortedCategories = catalogCategories.sort((a, b) =>
      a.category_name.localeCompare(b.category_name)
    );
    updateAvailableCategories(sortedCategories);
  };

  const handleChange = (input) => (event) => {
    const newState = { ...activeSearch };
    if (newState.categories.indexOf(event.target.value) === -1) {
      emitEvent("categoryClickV1", {
        category_id: event.target.value,
        user_id: userData.user_id,
      });
      newState.categories.push(event.target.value);
    } else {
      newState.categories.splice(
        newState.categories.indexOf(event.target.value),
        1
      );
    }
    updateSearch(newState);
  };

  const handleClear = () => {
    const newState = { ...activeSearch };
    newState.categories = [];
    updateSearch(newState);
  };

  const isCategoryActive = (catId) => {
    return activeSearch.categories.indexOf(catId) > -1;
  };

  return (
    <React.Fragment>
      {availableCategories.length ? (
        <div className={classes.root}>
          <React.Fragment>
            <div className={classes.categoryWrapper}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {availableCategories.map((cat, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={isCategoryActive(cat.category_id)}
                          onChange={handleChange(cat.category_id)}
                          value={cat.category_id}
                          className={classes.checkBox}
                        />
                      }
                      label={titleCase(cat.category_name)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
            {!!activeSearch.categories.length && (
              <div className={classes.clearButtonWrapper}>
                <Button onClick={handleClear} className={classes.clearButton}>
                  Clear
                </Button>
              </div>
            )}
          </React.Fragment>
        </div>
      ) : (
        <div className={classes.progressWrapper}>
          <CircularProgress color={"inherit"} />
        </div>
      )}
    </React.Fragment>
  );
});

ConnectedCategorySelector.propTypes = {
  activeSearch: PropTypes.object.isRequired,
  updateSearch: PropTypes.func.isRequired,
  activeCatalog: PropTypes.number,
  availableCategories: PropTypes.array.isRequired,
  updateAvailableCategories: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

const CategorySelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedCategorySelector);

export default CategorySelector;
