import React, { useState } from "react";
import { TableCell, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  rowArtwork: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
  },
  artwork: {
    position: "relative",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  artworkPlaceholder: {
    textAlign: "center",
    position: "relative",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  skeletonWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const mapStateToProps = (state) => {
  return {
    skinConfig: state.skinConfig,
    searching: state.searching
  };
};

const ConnectedArtwork = (props) => {
  const [loaded, setLoaded] = useState(false);

  function handleImageLoaded() {
    setLoaded(true);
  }

  const { filename, styles, skinConfig, searching } = props;

  const classes = useStyles();
  const placeholderUrl =
    "https://s3.amazonaws.com/nightrain.assets/artwork_placeholder.jpg";
  const url = filename
    ? `${skinConfig.skin_artwork_url}/${filename}`
    : placeholderUrl;

  return (
    <React.Fragment>
      {!searching && loaded ? (
        <TableCell
          component="td"
          scope="row"
          className={clsx(classes.artwork, styles)}
          style={{
            backgroundImage: `url(${url})`,
          }}
        />
      ) : (
        <TableCell
          component="td"
          scope="row"
          className={classes.artworkPlaceholder}
        >
          <div className={classes.skeletonWrapper}>
            <img
              src={url}
              onLoad={handleImageLoaded.bind(this)}
              alt=""
              style={{ display: "none" }}
            />
            <Skeleton variant="rect" height={24} />
          </div>
        </TableCell>
      )}
    </React.Fragment>
  );
};

ConnectedArtwork.propTypes = {
  filename: PropTypes.string.isRequired,
  skinConfig: PropTypes.object.isRequired,
  styles: PropTypes.string.isRequired,
};

const Artwork = connect(mapStateToProps, null)(ConnectedArtwork);

export default Artwork;
