import React, { useState,useEffect } from "react";
import {
  Menu,
  MenuItem,
  Button,
  withStyles,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { sortObject, searchAssociativeArray } from "../helpers/SharedFunctions";
import PropTypes from "prop-types";
import { updateActiveGroup } from "../actions/index";
import { connect } from "react-redux";
import { LibraryMusic } from "@material-ui/icons";

const styles = (theme) => ({
  productSelector: {
    whiteSpace: "nowrap",
  },
});

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    activeGroup: state.activeGroup,
    availableGroups: state.availableGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveGroup: (groupId) => dispatch(updateActiveGroup(groupId)),
  };
};

const ConnectedProductSelector = (props) => {
  const { activeGroup, classes, updateActiveGroup, availableGroups } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value) => {
    updateActiveGroup(parseInt(value.group_id));
    handleClose();
  };

  const groups =
    availableGroups &&
    Object.values(sortObject(Object.values(availableGroups), "group_name"));
  const numGroups = availableGroups && availableGroups.length;
  const activeGroupObj = searchAssociativeArray(
    `${activeGroup}`,
    availableGroups,
    "group_id"
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setAnchorEl(null);
  }, [mobile]);

  return (
    <div>
      {!!numGroups && activeGroup && (
        <React.Fragment>
          {!mobile ? (
            <Button
              variant="outlined"
              aria-haspopup="true"
              onClick={handleClick}
              disabled={groups && !Boolean(groups.length)}
              color="inherit"
              className={classes.productSelector}
              startIcon={<LibraryMusic />}
            >
              {activeGroupObj.group_name}
            </Button>
          ) : (
            <Tooltip title="Libraries">
              <IconButton
                aria-label="Library"
                onClick={handleClick}
                color="inherit"
              >
                <LibraryMusic />
              </IconButton>
            </Tooltip>
          )}

          <Menu
            id="groupsMenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {groups.map((value) => (
              <MenuItem
                key={value.group_id}
                selected={value.group_id === activeGroup}
                onClick={(event) => handleMenuItemClick(event, value)}
              >
                {value.group_name.toUpperCase()}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </div>
  );
};

ConnectedProductSelector.propTypes = {
  updateActiveGroup: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  activeGroup: PropTypes.number.isRequired,
  availableGroups: PropTypes.array.isRequired,
};

const ProductSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedProductSelector);

export default withStyles(styles)(ProductSelector);
