import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import ImageManager from "./ImageManager";
import ImageUpload from "../../library/ImageUpload";
import Axios from "axios";
import { uploadFile, deleteFile } from "../../helpers/ApiCalls";

const styles = (theme) => ({
	preview: {
		maxHeight: 48,
		verticalAlign: "middle",
		display: "block",
		margin: "0 auto",
	},
	headerSimulator: {
		lineHeight: "48px",
		padding: theme.spacing(1),
		maxHeight: 64,
		width: "100%",
		verticalAlign: "middle",
		backgroundColor: theme.palette.primary.main,
		marginBottom: theme.spacing(1),
	},
	previewWrapper: {
		width: "100%",
		position: "relative",
	},
	caption: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(2),
	},
});

class Assets extends React.Component {
	state = {
		previewImage: null,
		originalImage: null,
	};

	componentDidMount() {
		this.reloadState(this.props.skinConfig);
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.props.skinConfig.skin_logo !== nextProps.skinConfig.skin_logo
		) {
			this.reloadState(nextProps.skinConfig);
		}
	}

	reloadState = (skinConfig) => {
		const previewImage = `${skinConfig.skin_branding_url}/${skinConfig.skin_logo}`;
		this.setState({
			previewImage: previewImage,
			originalImage: previewImage,
		});
	};

	handleImageChange = (image) => {
		this.setState({ previewImage: image });
	};

	handleCancel = () => {
		const { originalImage } = this.state;
		this.handleImageChange(originalImage);
		this.setState({ previewImage: originalImage });
	};

	getFileBlob = (file) => {
		return Axios.get(file, {
			responseType: "blob",
		}).then((blob) => {
			return blob;
		});
	};

	submitAsset = (file, asset, extension) => {
		const skinId = this.props.skinConfig.skin_id;
		const originalFile = this.props.skinConfig[asset];
		return this.getFileBlob(file).then((blob) => {
			const postData = {
				assetBlob: blob.data,
				skinId: skinId,
				asset: asset,
				extension: extension,
			};
			return uploadFile(postData).then((response) => {
				deleteFile({ skinId: skinId, filename: originalFile });
				this.props.updateSkinConfig(
					asset,
					response.data.result.skins[asset]
				);
				this.props.setSnackbarVisibility({
					open: true,
					message: "Settings Updated",
				});
				return true;
			});
		});
	};

	render() {
		const {
			classes,
			skinConfig,
			updateSkinConfig,
			toggleLoading,
			loading,
		} = this.props;
		const { previewImage, originalImage } = this.state;
		const favicon = skinConfig.skin_favicon
			? `${skinConfig.skin_branding_url}/${skinConfig.skin_favicon}`
			: null;
		return (
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<Typography
						variant="caption"
						display="block"
						className={classes.caption}
					>
						Logo *
					</Typography>
					<ImageManager
						originalImage={originalImage}
						logo={skinConfig.skin_logo}
						skinConfig={skinConfig}
						handleImageChange={this.handleImageChange}
						previewImage={this.state.previewImage}
						handleCancel={this.handleCancel}
						updateSkinConfig={updateSkinConfig}
						loading={loading}
						toggleLoading={toggleLoading}
						submitAsset={this.submitAsset}
					>
						<Paper
							className={`${classes.paper} ${classes.previewWrapper}`}
						>
							{previewImage && (
								<div className={classes.headerSimulator}>
									<img
										alt="Crop"
										style={{ maxWidth: "100%" }}
										src={previewImage}
										className={classes.preview}
									/>
								</div>
							)}
						</Paper>
					</ImageManager>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography
						variant="caption"
						display="block"
						className={classes.caption}
					>
						Favicon
					</Typography>

					{favicon && (
						<ImageUpload
							originalImage={favicon}
							logo={favicon}
							skinConfig={skinConfig}
							handleImageChange={this.handleImageChange}
							previewImage={this.state.previewImage}
							handleCancel={this.handleCancel}
							updateSkinConfig={updateSkinConfig}
							submitAsset={this.submitAsset}
							toggleLoading={toggleLoading}
							loading={loading}
						>
							<Typography
								variant="caption"
								display="block"
								className={classes.caption}
							>
								A Favicon is the small icon that is displayed
								inside the browser tab and is presented
								alongside user's bookmarks. A 16 x 16 .ico file
								is required.
							</Typography>
						</ImageUpload>
					)}
				</Grid>
			</Grid>
		);
	}
}

Assets.propTypes = {
	skinConfig: PropTypes.object.isRequired,
	updateSkinConfig: PropTypes.func.isRequired,
	setSnackbarVisibility: PropTypes.func.isRequired,
	toggleLoading: PropTypes.func.isRequired,
	loading: PropTypes.string.isRequired,
};

export default withStyles(styles)(Assets);
