import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { translateKey, getSha } from "../../../helpers/SharedFunctions";
import StemLibraryDropzone from "./StemLibraryDropzone";
import {
  Delete,
  PlayCircleOutline,
  PauseCircleOutline,
} from "@material-ui/icons";
import SimpleAudioPlayer from "../../../library/SimpleAudioPlayer";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "stem_library_playback",
    numeric: false,
    disablePadding: false,
    label: "",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    class: "firstCell",
    sortable: true,
  },
  {
    id: "stem_library_type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_key",
    numeric: false,
    disablePadding: false,
    label: "Key",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_bpm",
    numeric: true,
    disablePadding: false,
    label: "Tempo",
    class: "tableCell",
    sortable: true,
  },
  {
    id: "stem_library_action",
    numeric: true,
    disablePadding: false,
    label: "",
    class: "switchHead",
    sortable: false,
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes[headCell["class"]]}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={headCell.sortable && createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  table: {
    position: "absolute",
    top: theme.spacing(3),
    bottom: 0,
    left: theme.spacing(3),
    right: theme.spacing(3),
    maxWidth: "100vw",
  },
  tableWrapper: {
    flex: 1,
    maxWidth: "100vw",
  },
  customTableContainer: {
    height: "100%",
    overflowY: "auto",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    "&:focus": {
      outline: "none",
    },
  },
  tableRoot: {
    maxWidth: "100vw",
    width: "100%",
    tableLayout: "fixed",
    backgroundColor: "none",
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
  },
  firstCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px`,
  },
  lastCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.5)}px`,
  },
  actionCell: {
    padding: "0px",
  },
  switchHead: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.5)}px`,
  },
  buttonWrapper: {
    marginRight: theme.spacing(1),
    display: "inline-block",
    position: "relative",
  },
  rowDisabled: {
    color: theme.palette.text.disabled,
    "& th": {
      color: theme.palette.text.disabled,
    },
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -18,
    marginLeft: -18,
  },
}));

const StemLibrary = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("stem_library_name");
  const {
    stemLibrary,
    access,
    stemLibraryFiles,
    handleChange,
    catalogId,
    removeLibraryFile,
    loading,
  } = props;

  const [src, setSrc] = useState(null);
  const [loadedAudio, setLoadedAudio] = useState(null);
  const [playing, setPlaying] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const addFiles = (acceptedFile, index) => {
    let currentStemLibraryFiles = { ...stemLibraryFiles };

    acceptedFile.forEach((acceptedFile) => {
      const id = getSha();
      const stem = {
        stem_library_file_id: id,
        stem_library_id: index,
        catalog_id: catalogId,
        blob: acceptedFile,
      };
      currentStemLibraryFiles[index] = stem;
    });
    handleChange("stemLibraryFiles", currentStemLibraryFiles);
  };

  const togglePlayPause = (audioFile) => {
    const id = parseInt(audioFile.stem_library_id);
    if (loadedAudio === id) {
      setPlaying(!playing);
      return;
    }
    setLoadedAudio(id);
    const url = audioFile.blob
      ? window.URL.createObjectURL(audioFile.blob)
      : audioFile.filename;
    setSrc(url);
  };

  const isPlaying = (id) => {
    if (loadedAudio === parseInt(id)) {
      return true;
    }
    return false;
  };

  const handleEnded = () => {
    destroyAudio();
  };

  const destroyAudio = () => {
    setSrc(null);
    setLoadedAudio(null);
  };

  const handleRemove = (id) => {
    if (loadedAudio === parseInt(id)) {
      destroyAudio();
    }
    removeLibraryFile(id);
  };

  return (
    <div className={classes.tableWrapper}>
      {src && (
        <SimpleAudioPlayer
          src={src}
          playing={playing}
          handleEnded={handleEnded}
        />
      )}
      <div className={classes.table}>
        <TableContainer className={classes.customTableContainer}>
          <Table stickyHeader className={classes.tableRoot}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(
                Object.values(stemLibrary),
                getComparator(order, orderBy)
              ).map((row) => {
                const labelId = `stemLibrary-checkbox-${row.stem_id}`;
                const rowClass = !stemLibraryFiles[row.stem_library_id]
                  ? classes.rowDisabled
                  : null;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.stem_library_id}
                    className={rowClass}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.actionCell}
                    >
                      <div className={classes.buttonWrapper}>
                        {isPlaying(row.stem_library_id) && (
                          <CircularProgress
                            color={"secondary"}
                            size={36}
                            className={classes.progress}
                          />
                        )}
                        <IconButton
                          onClick={() =>
                            togglePlayPause(
                              stemLibraryFiles[row.stem_library_id]
                            )
                          }
                          color="secondary"
                          disabled={
                            !stemLibraryFiles[row.stem_library_id] || loading
                          }
                        >
                          {isPlaying(row.stem_library_id) && playing ? (
                            <PauseCircleOutline />
                          ) : (
                            <PlayCircleOutline />
                          )}
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.firstCell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.stem_library_name}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.stem_library_type}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {translateKey(row.stem_library_key)}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="right"
                      className={classes.tableCell}
                    >
                      {Math.round(row.stem_library_bpm)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="right"
                      className={classes.actionCell}
                    >
                      {stemLibraryFiles[row.stem_library_id] ? (
                        <div className={classes.buttonWrapper}>
                          <IconButton
                            onClick={() => handleRemove(row.stem_library_id)}
                            color="secondary"
                            disabled={loading}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      ) : (
                        <StemLibraryDropzone
                          access={access}
                          addFiles={addFiles}
                          index={parseInt(row.stem_library_id)}
                          contentType={`audio`}
                          accept={"audio/mpeg"}
                          loading={loading}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

StemLibrary.propTypes = {
  stemLibrary: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  stemLibraryFiles: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  catalogId: PropTypes.string.isRequired,
  removeLibraryFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default StemLibrary;
