import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import Dropzone from "../Dropzone";

const useStyles = makeStyles((theme) => ({
	uploadWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	paper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
		position: "relative",
	},
	browseButton: {
		marginTop: theme.spacing(1),
	},
	wrapper: {
		margin: theme.spacing(8),
		display: "inline-block",
	},
	disabled: {
		color: theme.palette.text.disabled,
	},
}));

const ContentDropzone = (props) => {
	const dropzoneRef = useRef();
	const classes = useStyles();
	const { access, addFiles, index, contentType, accept, loading } = props;

	return (
		<div className={classes.uploadWrapper}>
			<Paper variant="outlined" elevation={3} className={classes.paper}>
				<Dropzone
					ref={dropzoneRef}
					addFiles={addFiles}
					access={access}
					index={index}
					accept={accept}
					disabled={loading}
					loading={loading}
				>
					<div className={classes.wrapper}>
						<span className={loading ? classes.disable : undefined}>
							<CloudUpload style={{ fontSize: "50px" }} />
							<Typography variant="subtitle1" align="center">
								Drag & Drop to Add {contentType}
							</Typography>
							<Typography variant="subtitle2" align="center">
								OR
							</Typography>
							<Button
								onClick={() => dropzoneRef.current.openDialog()}
								className={classes.browseButton}
								variant="contained"
								color="secondary"
								disabled={loading}
							>
								Browse Files
							</Button>
						</span>
					</div>
				</Dropzone>
			</Paper>
		</div>
	);
};

ContentDropzone.propTypes = {
	access: PropTypes.object.isRequired,
	addFiles: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	contentType: PropTypes.string.isRequired,
	accept: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default ContentDropzone;
