import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, Box, makeStyles } from "@material-ui/core";
import StemLibrary from "./StemLibrary";
import {
  searchAssociativeArray,
  sortArray,
} from "../../../helpers/SharedFunctions";

const TabPanel = (props) => {
  const { children, value, index, classes, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    width: "100%",
    position: "relative",
  },
}));

const TabView = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const {
    stemLibraries,
    allCatalogs,
    access,
    handleChange,
    stemLibraryFiles,
    removeLibraryFile,
    loading,
  } = props;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSortedCatalogs = () => {
    let sortedList = [];
    Object.keys(stemLibraries).forEach((catalogId) => {
      const catalogName = searchAssociativeArray(
        catalogId,
        allCatalogs,
        "catalog_id"
      ).catalog_name;
      const catObj = { catalog_id: catalogId, catalog_name: catalogName };
      sortedList.push(catObj);
    });
    return sortArray(sortedList, "catalog_name");
  };
  const catalogs = getSortedCatalogs();

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleTabChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {!!catalogs.length &&
          catalogs.map((row, index) => {
            return (
              <Tab
                key={index}
                disabled={loading}
                label={row.catalog_name}
                {...a11yProps(index)}
              />
            );
          })}
      </Tabs>
      {!!catalogs.length &&
        catalogs.map((row, index) => {
          return (
            <TabPanel classes={classes} value={value} index={index} key={index}>
              <StemLibrary
                handleChange={handleChange}
                stemLibraryFiles={stemLibraryFiles}
                stemLibrary={stemLibraries[row.catalog_id]}
                access={access}
                catalogId={row.catalog_id}
                removeLibraryFile={removeLibraryFile}
                loading={loading}
              />
            </TabPanel>
          );
        })}
    </div>
  );
};

TabView.propTypes = {
  stemLibraries: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  allCatalogs: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  stemLibraryFiles: PropTypes.object.isRequired,
  removeLibraryFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default TabView;
