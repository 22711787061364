import {
	UPDATE_USER_BALANCE,
	UPDATE_SKIN_CONFIG,
	SET_DIALOG_VISIBILITY,
	ADD_TO_CART,
	REMOVE_FROM_CART,
	CLEAR_CART,
	INITIATE_CART,
	UPDATE_USER_DATA,
	UPDATE_ACTIVE_GROUP,
	UPDATE_AVAILABLE_GROUPS,
	UPDATE_ACTIVE_CATALOG,
	SET_LOGIN_STATE,
	UPDATE_AVAILABLE_CATEGORIES,
	SET_SNACKBAR_VISIBILITY,
	UPDATE_AVAILABLE_CATALOGS,
	UPDATE_AVAILABLE_TAGS,
	UPDATE_SEARCH,
	RESET_SEARCH,
	SET_SEARCH_STATE,
	UPDATE_ALL_CATEGORIES,
	UPDATE_ALL_CATALOGS,
	SET_TERMS_ACCEPTANCE,
} from "../constants/action-types";
import {
	addItemToCart,
	removeItemFromCart,
	clearUserCart,
} from "../helpers/ApiCalls";

export function setLogInState(payload) {
	return { type: SET_LOGIN_STATE, payload };
}

export function setTermsAcceptance(payload) {
	return { type: SET_TERMS_ACCEPTANCE, payload };
}

export function setSearchState(payload) {
	return { type: SET_SEARCH_STATE, payload };
}

export function updateUserBalance(payload) {
	return { type: UPDATE_USER_BALANCE, payload };
}

export function updateActiveGroup(payload) {
	return { type: UPDATE_ACTIVE_GROUP, payload };
}

export function updateAvailableCategories(payload) {
	return { type: UPDATE_AVAILABLE_CATEGORIES, payload };
}

export function updateAllCategories(payload) {
	return { type: UPDATE_ALL_CATEGORIES, payload };
}

export function updateAllCatalogs(payload) {
	return { type: UPDATE_ALL_CATALOGS, payload };
}

export function updateAvailableGroups(payload) {
	return { type: UPDATE_AVAILABLE_GROUPS, payload };
}

export function updateAvailableCatalogs(payload) {
	return { type: UPDATE_AVAILABLE_CATALOGS, payload };
}

export function updateAvailableTags(payload) {
	return { type: UPDATE_AVAILABLE_TAGS, payload };
}

export function updateActiveCatalog(payload) {
	return { type: UPDATE_ACTIVE_CATALOG, payload };
}

export function updateSkinConfig(payload) {
	return { type: UPDATE_SKIN_CONFIG, payload };
}

export function updateSearch(payload) {
	return { type: UPDATE_SEARCH, payload };
}

export function resetSearch(payload) {
	return { type: RESET_SEARCH, payload };
}

export function updateUserData(payload) {
	return { type: UPDATE_USER_DATA, payload };
}

export function setDialogVisibility(payload) {
	return { type: SET_DIALOG_VISIBILITY, payload };
}

export function setSnackbarVisibility(payload) {
	return { type: SET_SNACKBAR_VISIBILITY, payload };
}

export function clearCart(userId, groupId) {
	return function(dispatch) {
		return clearUserCart(userId, groupId).then(
			dispatch({ type: CLEAR_CART })
		);
	};
}

export function initiateCart(payload) {
	return { type: INITIATE_CART, payload };
}

export function addToCart(userId, groupId, payload) {
	return function(dispatch) {
		return addItemToCart(userId, groupId, payload).then((response) => {
			payload.cart_item_id = response.data.result.cart_item_id;
			dispatch({ type: ADD_TO_CART, payload: payload });
		});
	};
}

export function removeFromCart(userId, groupId, payload) {
	return function(dispatch) {
		return removeItemFromCart(userId, groupId, payload.cart_item_id).then(
			(response) => {
				dispatch({ type: REMOVE_FROM_CART, payload: payload });
			}
		);
	};
}
