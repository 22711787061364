import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles, IconButton } from "@material-ui/core";
import { getGroupList, updateGroup } from "../../helpers/ApiCalls";
import EnhancedTable from "../../library/EnhancedTable";
import {
	titleCase,
	formatDate,
	createSkeletonData,
} from "../../helpers/SharedFunctions";
import { connect } from "react-redux";
import {
	setSnackbarVisibility,
	setSearchState,
	setDialogVisibility,
	resetSearch,
} from "../../actions/index";
import { Add } from "@material-ui/icons";
import GroupBuilder from "./wizard/GroupBuilder";
import GroupCard from "./GroupCard";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
		position: "relative",
	},
	addButton: {
		position: "absolute",
		bottom: 0,
		left: 0,
	},
	nameColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "36%",
		},
		paddingLeft: theme.spacing(1),
	},
	statusColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "12%",
			flexDirection: "row",
		},
		[theme.breakpoints.up("lg")]: {
			flexDirection: "row-reverse",
		},
	},
	dateColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "26%",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		searching: state.searching,
		activeSearch: state.activeSearch,
		allCatalogs: state.allCatalogs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSnackbarVisibility: (snackbarContent) =>
			dispatch(setSnackbarVisibility(snackbarContent)),
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
		setDialogVisibility: (dialogContent) =>
			dispatch(setDialogVisibility(dialogContent)),
		resetSearch: () => dispatch(resetSearch()),
	};
};

const ConnectedGroupList = (props) => {
	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("group_created_date");
	const [access, setAccess] = useState({
		create: false,
		read: false,
		update: false,
		delete: false,
	});
	const classes = useStyles();
	const {
		permissions,
		setSearchState,
		searching,
		setDialogVisibility,
		setSnackbarVisibility,
		resetSearch,
		activeSearch,
	} = props;
	const {
		nameColumn,
		mdHidden,
		dateColumn,
		lgHidden,
		xsHidden,
		statusColumn,
	} = classes;

	const handleStatusChange = (item) => {
		const found = items.findIndex((x) => x.group_id === item.group_id);
		let currentItems = [...items];
		const status = (+!Boolean(parseInt(item.group_status))).toString();
		currentItems[found].group_status = status;
		const originalDate = currentItems[found].group_modified_date;
		currentItems[found].group_modified_date = new Date()
			.toISOString()
			.slice(0, 19)
			.replace("T", " ");
		setItems(currentItems);
		const postData = { group_status: status };
		updateGroup(item.group_id, postData).then((res) => {
			let message;
			if (res && res.data && res.data.result) {
				message = "Update successful";
			} else {
				message = "An error occured. Please contact support";
				currentItems[found].group_status = (+!Boolean(
					parseInt(status)
				)).toString();
				currentItems[found].group_modified_date = originalDate;
				setItems(currentItems);
			}
			setSnackbarVisibility({
				open: true,
				message: message,
			});
		});
	};

	const columns = [
		{
			id: "group_name",
			numeric: false,
			disablePadding: false,
			label: "Group Name",
			classes: [nameColumn],
		},
		{
			id: "group_created_date",
			numeric: false,
			disablePadding: false,
			label: "Created",
			classes: [mdHidden, dateColumn],
		},
		{
			id: "group_modified_date",
			numeric: false,
			disablePadding: false,
			label: "Modified",
			classes: [lgHidden, dateColumn],
		},
		{
			id: "group_status",
			numeric: true,
			type: "switch",
			disablePadding: false,
			label: "Status",
			classes: [xsHidden, statusColumn],
			fn: handleStatusChange,
		},
	];

	useEffect(() => {
		setAccessControl();
		return function cleanup() {
			resetSearch();
		};
	}, []);

	const setAccessControl = () => {
		const permissionObj = {
			create: permissions.indexOf(9) > -1,
			read: permissions.indexOf(10) > -1,
			update: permissions.indexOf(11) > -1,
			delete: permissions.indexOf(12) > -1,
		};
		setAccess(permissionObj);
	};

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		doSearch();
	}, [rowsPerPage, orderBy, orderDir, activeSearch]);

	useEffect(() => {
		page !== null && doSearch();
	}, [page]);

	const doSearch = () => {
		setItems(createSkeletonData(columns, rowsPerPage));
		setSearchState(true);
		const { query } = activeSearch;
		let queryString = `limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;
		if (query.length) {
			queryString += `&q=${encodeURIComponent(query)}`;
		}

		getGroupList(queryString).then((res) => {
			setItems(res.groups);
			setTotalItems(res.total);
			setSearchState(false);
		});
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	const formatData = (data, field) => {
		switch (field) {
			case "group_name":
				return titleCase(data);
			case "group_created_date":
			case "group_modified_date":
			case "group_expiry_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			case "group_status":
				return parseInt(data) ? "Active" : "Inactive";
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedRow(index);
		const dialogContent = {
			open: true,
			body: (
				<GroupCard
					setSnackbarVisibility={setSnackbarVisibility}
					setDialogVisibility={setDialogVisibility}
					cardContent={value}
					doSearch={doSearch}
					access={access}
				/>
			),
			fullWidth: true,
			fullScreen: "sm",
			minHeight: true,
			maxWidth: "lg",
		};
		setDialogVisibility(dialogContent);
	};

	const handleAddButtonClick = () => {
		const dialogContent = {
			open: true,
			body: (
				<GroupBuilder
					setSnackbarVisibility={setSnackbarVisibility}
					setDialogVisibility={setDialogVisibility}
					doSearch={doSearch}
					access={access}
				/>
			),
			fullWidth: true,
			fullScreen: "sm",
			minHeight: true,
			maxWidth: "lg",
		};
		setDialogVisibility(dialogContent);
	};

	return (
		<React.Fragment>
			{access.read && (
				<Paper className={classes.container}>
					<EnhancedTable
						items={items}
						columns={columns}
						handleRowClick={handleRowClick}
						formatData={formatData}
						selectedRow={selectedRow}
						classNames={classes}
						numberOfActions={0}
						idField={"user_id"}
						orderBy={orderBy}
						orderDir={orderDir}
						handleArrowNav={handleArrowNav}
						totalItems={totalItems}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						page={page}
						setPage={setPage}
						searching={searching}
						setOrderDir={setOrderDir}
						setOrderBy={setOrderBy}
					/>
					{access.create && (
						<IconButton
							color="secondary"
							aria-label="add"
							onClick={handleAddButtonClick}
							className={classes.addButton}
						>
							<Add />
						</IconButton>
					)}
				</Paper>
			)}
		</React.Fragment>
	);
};

ConnectedGroupList.propTypes = {
	setSnackbarVisibility: PropTypes.func.isRequired,
	permissions: PropTypes.array.isRequired,
	setSearchState: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	setDialogVisibility: PropTypes.func.isRequired,
	resetSearch: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
};

const GroupList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedGroupList);

export default GroupList;
