import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles, IconButton } from "@material-ui/core";
import { getWorks } from "../../helpers/ApiCalls";
import EnhancedTable from "../../library/EnhancedTable";
import {
	titleCase,
	formatDate,
	createSkeletonData,
} from "../../helpers/SharedFunctions";
import { connect } from "react-redux";
import {
	setSearchState,
	setDialogVisibility,
	setSnackbarVisibility,
	resetSearch,
} from "../../actions/index";
import { Add } from "@material-ui/icons";
import WorksBuilder from "./wizard/WorksBuilder";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
		position: "relative",
	},
	addButton: {
		position: "absolute",
		bottom: 0,
		left: 0,
	},
	titleColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "30%",
		},
		paddingLeft: theme.spacing(1),
	},
	creditColumn: {
		[theme.breakpoints.up("xs")]: {
			width: "30%",
		},
	},
	identificationColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "40%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "20%",
		},
	},
	dateColumn: {
		[theme.breakpoints.up("sm")]: {
			width: "20%",
		},
	},
	xsHidden: {
		[theme.breakpoints.only("xs")]: {
			display: "none",
		},
	},
	smHidden: {
		[theme.breakpoints.only("sm")]: {
			display: "none",
		},
	},
	mdHidden: {
		[theme.breakpoints.only("md")]: {
			display: "none",
		},
	},
	lgHidden: {
		[theme.breakpoints.only("lg")]: {
			display: "none",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		searching: state.searching,
		activeSearch: state.activeSearch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
		setSnackbarVisibility: (snackbarContent) =>
			dispatch(setSnackbarVisibility(snackbarContent)),
		setDialogVisibility: (dialogContent) =>
			dispatch(setDialogVisibility(dialogContent)),
		resetSearch: () => dispatch(resetSearch()),
	};
};

const ConnectedWorksList = (props) => {
	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("registered_work_created_date");
	const [access, setAccess] = useState({
		create: false,
		read: false,
		update: false,
		delete: false,
	});
	const classes = useStyles();
	const {
		permissions,
		setSearchState,
		searching,
		setSnackbarVisibility,
		setDialogVisibility,
		resetSearch,
		activeSearch,
	} = props;
	const {
		identificationColumn,
		dateColumn,
		xsHidden,
		titleColumn,
		smHidden,
		creditColumn,
	} = classes;

	const columns = [
		{
			id: "registered_work_title",
			numeric: false,
			disablePadding: false,
			label: "Title",
			classes: [titleColumn],
		},
		{
			id: "credits",
			numeric: false,
			disablePadding: false,
			label: "Credits",
			classes: [creditColumn],
		},
		{
			id: "registered_work_identification_code",
			numeric: false,
			disablePadding: false,
			label: "ID Code",
			classes: [identificationColumn],
		},
		{
			id: "registered_work_created_date",
			numeric: false,
			disablePadding: false,
			label: "Created",
			classes: [xsHidden, dateColumn],
		},
		{
			id: "registered_work_modified_date",
			numeric: false,
			disablePadding: false,
			label: "Modified",
			classes: [xsHidden, smHidden, dateColumn],
		},
	];

	useEffect(() => {
		const permissionObj = {
			create: permissions.indexOf(17) > -1,
			read: permissions.indexOf(18) > -1,
			update: permissions.indexOf(19) > -1,
			delete: permissions.indexOf(20) > -1,
		};
		setAccess(permissionObj);
		return function cleanup() {
			resetSearch();
		};
	}, []);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		doSearch();
	}, [rowsPerPage, orderBy, orderDir, activeSearch]);

	useEffect(() => {
		page !== null && doSearch();
	}, [page]);

	const doSearch = () => {
		setItems(createSkeletonData(columns, rowsPerPage));
		setSearchState(true);
		const { query } = activeSearch;
		let queryString = `limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;
		if (query.length) {
			queryString += `&q=${encodeURIComponent(query)}`;
		}
		queryString += `&source[]=local`;

		getWorks(queryString).then((res) => {
			setItems(res.data.result.works);
			setTotalItems(res.data.result.total);
			setSearchState(false);
		});
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	const formatData = (data, field) => {
		switch (field) {
			case "registered_work_title":
				return titleCase(data.replace("&amp;", "&"));
			case "credits":
				return titleCase(data.join(', ').replace("&amp;", "&"));
			case "registered_work_identification_code":
				return data.toUpperCase();
			case "registered_work_created_date":
			case "registered_work_modified_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedRow(index);
	};

	const handleAddButtonClick = () => {
		const dialogContent = {
			open: true,
			body: (
				<WorksBuilder
					setSnackbarVisibility={setSnackbarVisibility}
					setDialogVisibility={setDialogVisibility}
					doSearch={doSearch}
					access={access}
				/>
			),
			fullWidth: true,
			fullScreen: "sm",
			minHeight: true,
			maxWidth: "lg",
		};
		setDialogVisibility(dialogContent);
	};

	return (
		<React.Fragment>
			{access.read && (
				<Paper className={classes.container}>
					<EnhancedTable
						items={items}
						columns={columns}
						handleRowClick={handleRowClick}
						formatData={formatData}
						selectedRow={selectedRow}
						classNames={classes}
						numberOfActions={0}
						idField={"user_id"}
						orderBy={orderBy}
						orderDir={orderDir}
						handleArrowNav={handleArrowNav}
						totalItems={totalItems}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						page={page}
						setPage={setPage}
						searching={searching}
						setOrderDir={setOrderDir}
						setOrderBy={setOrderBy}
					/>
					{access.create && (
						<IconButton
							color="secondary"
							aria-label="add"
							onClick={handleAddButtonClick}
							className={classes.addButton}
						>
							<Add />
						</IconButton>
					)}
				</Paper>
			)}
		</React.Fragment>
	);
};

ConnectedWorksList.propTypes = {
	permissions: PropTypes.array.isRequired,
	setSearchState: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	setSnackbarVisibility: PropTypes.func.isRequired,
	setDialogVisibility: PropTypes.func.isRequired,
	resetSearch: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
};

const WorksList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedWorksList);

export default WorksList;
