import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Pause, PlayArrow, Stop } from "@material-ui/icons";
import { Tooltip, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    padding: theme.spacing(2),
  },
}));

const AudioPlayerControls = (props) => {
  const { handlePlayPause, handleStop, playState, disabled } = props;

  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      {playState === "play" ? (
        <Tooltip title="Pause">
          <span>
            <IconButton
              onClick={handlePlayPause}
              aria-label="play"
              className={classes.IconButton}
              disabled={disabled}
            >
              <Pause fontSize="small" className={classes.controlIcon} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="Play">
          <span>
            <IconButton
              onClick={handlePlayPause}
              aria-label="pause"
              className={classes.IconButton}
              disabled={disabled}
            >
              <PlayArrow fontSize="small" className={classes.controlIcon} />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip title="Stop">
        <span>
          <IconButton
            className={classes.IconButton}
            onClick={handleStop}
            aria-label="stop"
            disabled={disabled}
          >
            <Stop fontSize="small" className={classes.controlIcon} />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

AudioPlayerControls.propTypes = {
  handlePlayPause: PropTypes.func.isRequired,
  handleStop: PropTypes.func.isRequired,
  playState: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default AudioPlayerControls;
