import React, { useState, useEffect } from "react";
import FilterChips from "./FilterChips";
import PropTypes from "prop-types";
import MediaInteraction from "../audioplayer/MediaInteraction";
import { connect } from "react-redux";
import { getUserCart, getCatalogs, getGroups } from "../helpers/ApiCalls";
import {
  initiateCart,
  updateActiveCatalog,
  updateActiveGroup,
  updateAvailableGroups,
  updateAvailableCatalogs,
  setDialogVisibility,
} from "../actions/index";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import ContentList from "./ContentList";
import { makeStyles, Typography } from "@material-ui/core";
import Alert from "../library/dialog/Alert";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    flex: 1,
    position: "relative",
    maxWidth: "100vw",
  },
  root: {
    display: "flex",
    flexFlow: "column",
    overflow: "hidden",
    position: "absolute",
    top: 64,
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}px 0px 0px 0px`,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    availableGroups: state.availableGroups,
    availableCatalogs: state.availableCatalogs,
    activeCatalog: state.activeCatalog,
    activeGroup: state.activeGroup,
    userData: state.userData,
    dialog: state.dialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initiateCart: (userCart) => dispatch(initiateCart(userCart)),
    updateActiveGroup: (groupId) => dispatch(updateActiveGroup(groupId)),
    updateActiveCatalog: (catalogId) =>
      dispatch(updateActiveCatalog(catalogId)),
    updateAvailableGroups: (groups) => dispatch(updateAvailableGroups(groups)),
    updateAvailableCatalogs: (catalogs) =>
      dispatch(updateAvailableCatalogs(catalogs)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedContent = (props) => {
  const {
    availableGroups,
    availableCatalogs,
    activeGroup,
    activeCatalog,
    userData,
    initiateCart,
    updateAvailableGroups,
    updateAvailableCatalogs,
    updateActiveCatalog,
    updateActiveGroup,
    setDialogVisibility,
  } = props;
  const classes = useStyles();
  const [playingMaster, setPlayingMaster] = useState(null);

  //load available groups on mount
  useEffect(() => {
    loadUserGroups(userData.user_id);
  }, []);
  //if available groups change, load a new active group
  useEffect(() => {
    availableGroups.length && loadActiveGroup(availableGroups);
  }, [availableGroups]);
  //if active group changes, get available catalogs and change cart group
  useEffect(() => {
    if (activeGroup) {
      loadGroupCatalogs(activeGroup);
      getUserCart(userData.user_id, activeGroup).then((res) => {
        initiateCart(res);
      });
    }
  }, [activeGroup]);
  //if available catalogs change, load a new active catalog
  useEffect(() => {
    availableCatalogs.length && loadActiveCatalog(availableCatalogs);
  }, [availableCatalogs]);

  const loadUserGroups = async (userId) => {
    const userGroups = await getGroups(userId);
    updateAvailableGroups(userGroups);
    if (!userGroups.length) {
      errorMessage(
        <div>
          <Typography>You are not currently assigned to a group.</Typography>
          <Typography>Please contact support.</Typography>
        </div>
      );
    }
  };

  const loadActiveGroup = (availableGroups) => {
    const groupId = availableGroups[0].group_id;
    updateActiveGroup(parseInt(groupId));
  };

  const loadGroupCatalogs = async (groupId) => {
    const groupCatalogs = await getCatalogs(groupId);
    updateAvailableCatalogs(groupCatalogs);
    if (!groupCatalogs.length) {
      errorMessage(
        <div>
          <Typography>
            Your group is not currently assigned to a library.
          </Typography>
          <Typography>Please contact support.</Typography>
        </div>
      );
    }
  };

  const loadActiveCatalog = (availableCatalogs) => {
    const catalogId = availableCatalogs[0].catalog_id;
    updateActiveCatalog(parseInt(catalogId));
  };

  const errorMessage = (message) => {
    const dialogContent = {
      open: true,
      body: (
        <Alert message={message} title={"Oh no, something went wrong  : ("} />
      ),
      fullScreen: "xs",
    };
    setDialogVisibility(dialogContent);
  };

  const playMaster = (master) => {
    setPlayingMaster(master);
  };

  const closeMediaInteraction = () => {
    setPlayingMaster(null);
  };

  return (
    <Switch>
      <Route exact path="/">
        <div className={classes.root}>
          {activeGroup && activeCatalog && (
            <React.Fragment>
              {playingMaster && (
                <div className={classes.mediaWrapper}>
                  <MediaInteraction
                    audioFile={playingMaster}
                    closeMediaInteraction={closeMediaInteraction}
                  />
                </div>
              )}
              <FilterChips />
              <div className={classes.tableWrapper}>
                <ContentList
                  closeMediaInteraction={closeMediaInteraction}
                  playMaster={playMaster}
                  audioFile={playingMaster}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

ConnectedContent.propTypes = {
  initiateCart: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  activeGroup: PropTypes.number,
  updateAvailableGroups: PropTypes.func.isRequired,
  updateAvailableCatalogs: PropTypes.func.isRequired,
  updateActiveGroup: PropTypes.func.isRequired,
  updateActiveCatalog: PropTypes.func.isRequired,
};

const Content = connect(mapStateToProps, mapDispatchToProps)(ConnectedContent);

export default withRouter(Content);
