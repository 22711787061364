import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import Metadata from "./Metadata";
import Versions from "./Versions";
import Tags from "./Tags";
import Artwork from "./Artwork";
import Distribution from "./Distribution";
import Processing from "./Processing";
import Confirmation from "../../../library/Confirmation";
import ChipSelector from "../../../library/ChipSelector";
import Axios from "axios";
import Mime from "mime-types";
import {
  sortArray,
  createTimeStamp,
  sanitizeTitle,
  titleCase,
  searchAssociativeArray,
} from "../../../helpers/SharedFunctions";
import {
  getAvailableGroups,
  getStemLibrary,
  getAllTags,
  createMaster,
  createVersion,
  createStem,
  uploadFile,
  editMaster,
  createTagRelationship,
  createTag,
  createMasterBuildEntry,
  createStemRelationship,
  getWorks,
  createRegisteredWork,
  createCredit,
  createWorkMasterRelationship,
  createCreditRelationship,
} from "../../../helpers/ApiCalls";
import CustomDialogActions from "../../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../../library/dialog/CustomDialogContent";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateAvailableGroups,
} from "../../../actions/index";
import { connect } from "react-redux";
import { Keys } from "../../../helpers/Keys";

const mapStateToProps = (state) => {
  return {
    allCategories: state.allCategories,
    allCatalogs: state.allCatalogs,
    skinConfig: state.skinConfig,
    availableGroups: state.availableGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateAvailableGroups: (availableGroups) =>
      dispatch(updateAvailableGroups(availableGroups)),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const sortedKeys = sortArray(Keys, "display");

const ConnectedContentBuilder = (props, ref) => {
  const {
    allCategories,
    allCatalogs,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    skinConfig,
    availableGroups,
    updateAvailableGroups,
    doSearch,
  } = props;

  const initialData = {
    master_title: "",
    master_subcategory: "",
    category_id: "",
    catalog_id: "",
    master_key: "",
    master_bpm: "",
    master_display_date: createTimeStamp(new Date()),
    master_description: "",
    master_status: 0,
    master_artwork: "",
    master_tags: [],
    versions: [],
    groups: [],
    works: [],
  };

  const [masterState, setMasterState] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [stemLibrary, setStemLibrary] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allWorks, setAllWorks] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [imageSource, setImageSource] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [processing, setProcessing] = useState({
    master: { status: "pending" },
    versions: [],
    artwork: { status: "pending" },
    tags: { status: "pending" },
    works: { status: "pending" },
    distribution: { status: "pending" },
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const classes = useStyles();
  const categories = sortArray(allCategories, "category_name");
  const catalogs = sortArray(allCatalogs, "catalog_name");

  useEffect(() => {
    if (masterState.catalog_id) {
      getAvailableGroups(masterState.catalog_id).then((res) => {
        updateAvailableGroups(sortArray(res, "group_name"));
        let primaryGroup = null;
        primaryGroup = searchAssociativeArray(
          "1",
          res,
          "group-catalog_primary"
        );
        if (!primaryGroup) {
          primaryGroup = res[0];
        }
        setSelectedGroup(primaryGroup);
      });

      getStemLibrary(masterState.catalog_id).then((res) => {
        setStemLibrary(res);
      });
    }
  }, [masterState.catalog_id]);

  useEffect(() => {
    getAllTags().then((res) => {
      setAllTags(mapTagData(res));
    });
  }, []);

  const steps = [
    {
      stepTitle: "Enter metadata",
      fields: [
        "master_title",
        "master_subcategory",
        "category_id",
        "catalog_id",
        "master_key",
        "master_bpm",
        "master_display_date",
        "master_description",
      ],
    },
    { stepTitle: "Add content", fields: ["versions"] },
    { stepTitle: "Add tags", fields: ["master_tags"] },
    { stepTitle: "Add publishing", fields: ["works"] },
    { stepTitle: "Add artwork", fields: ["master_artwork"] },
    { stepTitle: "Select distribution", fields: ["groups"] },
  ];

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const confirmationButtons = [
    {
      label: "Cancel",
      color: "default",
      fn: handleConfirmationClose,
    },
    {
      label: "Confirm",
      color: "secondary",
      fn: handleConfirmationConfirm,
    },
  ];

  const mapTagData = (tags) => {
    return tags.length
      ? tags.map((tag) => ({
          id: tag.tag_id,
          value: titleCase(tag.tag_name),
        }))
      : [];
  };

  const mapWorksData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.registered_work_identification_code,
          value: `${titleCase(
            `${item.registered_work_title} - ${
              item.credits.length > 1
                ? item.credits.join(", ")
                : item.credits[0]
            }`.replace("&amp;", "&")
          )} [${item.registered_work_identification_code.toUpperCase()}]`,
          title: item.registered_work_title,
          id_code: item.registered_work_identification_code,
          credits: item.credits,
          registered_work_id: item.registered_work_id,
          source: item.source,
        }))
      : [];
  };

  const submitAsset = (blob, asset, extension) => {
    const skinId = skinConfig.skin_id;

    const postData = {
      assetBlob: blob,
      skinId: skinId,
      asset: asset,
      extension: extension,
    };
    return uploadFile(postData).then((response) => {
      return response;
    });
  };

  const searchWorks = (query) => {
    const queryString = `source[]=spotify&source[]=local&q=${query}`;
    return getWorks(queryString).then((res) => {
      return mapWorksData(res.data.result.works);
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Metadata
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleFieldChange}
            categories={categories}
            catalogs={catalogs}
            keys={sortedKeys}
            data={masterState}
            disabled={loading}
            access={access}
          />
        );
      case 1:
        return (
          <Versions
            disabled={loading}
            access={access}
            versions={masterState.versions}
            fieldName={`versions`}
            handleChange={handleFieldChange}
            stemLibrary={stemLibrary}
            skinConfig={skinConfig}
            masterState={masterState}
            availableGroups={availableGroups}
            selectedGroup={selectedGroup}
            loading={loading}
          />
        );
      case 2:
        return (
          <Tags
            handleSelect={handleFieldChange}
            disabled={loading}
            access={access}
            allTags={allTags}
            selectedTags={masterState.master_tags}
            setSnackbarVisibility={setSnackbarVisibility}
            fieldName={`master_tags`}
            loading={loading}
          />
        );
      case 3:
        return (
          <ChipSelector
            allData={allWorks}
            setAllData={setAllWorks}
            selectedData={masterState.works}
            setSnackbarVisibility={setSnackbarVisibility}
            handleSelect={handleFieldChange}
            fieldName={`works`}
            disabled={loading}
            access={access}
            chipType={`Work`}
            allowCreate={false}
            options={{
              limit: 20,
            }}
            isAsync={true}
            asyncFunc={searchWorks}
          />
        );
      case 4:
        return (
          <Artwork
            setLoading={setLoading}
            access={access}
            loading={loading}
            selectedImage={masterState.master_artwork}
            handleArtworkChange={handleFieldChange}
            imageSource={imageSource}
            setImageSource={setImageSource}
            fieldName={`master_artwork`}
          />
        );
      case 5:
        return (
          <Distribution
            setLoading={setLoading}
            access={access}
            loading={loading}
            availableGroups={availableGroups}
            selectedGroups={masterState.groups}
            handleSelectedGroups={handleFieldChange}
            fieldName={`groups`}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleBlur = (inputField) => (event) => {
    if (inputField === "master_description") {
      return;
    }
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...masterState };
    let value = event.target.value;
    if (input === "master_status") {
      value = event.target.checked;
    }
    currentState[input] = value;
    setMasterState(currentState);
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...masterState };
    if (field === "master_display_date") {
      currentState[field] = createTimeStamp(value);
    } else {
      currentState[field] = value;
    }
    setMasterState(currentState);
  };

  const validateField = (inputField, value) => {
    if (!value || value.length === 0) {
      if (inputField === "master_artwork" && !imageSource) {
        return true;
      }
      handleEmptyField(inputField);
      return false;
    }
    if (inputField === "versions") {
      for (let i = 0; i < masterState["versions"].length; i++) {
        if (!masterState["versions"][i].version_name.length) {
          handleEmptyField("version_name");
          return false;
        }
        if (!masterState["versions"][i].version_stems.length) {
          const message = "Each version must have at least one audio file";
          setSnackbarVisibility({
            open: true,
            message: message,
          });
          return false;
        }
        for (
          let x = 0;
          x < masterState["versions"][i].version_stems.length;
          x++
        ) {
          if (!masterState["versions"][i].version_stems[x].stem_name.length) {
            handleEmptyField("stem_name");
            return false;
          }
        }
      }
    }
    if (inputField === "master_bpm") {
      const minTempo = 60;
      const maxTempo = 180;
      const isInteger = Number.isInteger(parseFloat(value));
      const isInRange =
        isInteger &&
        parseFloat(value) >= minTempo &&
        parseFloat(value) <= maxTempo;
      if (!isInteger || !isInRange) {
        const message = "Tempo must be a whole number between 60 and 180 BPM";
        setSnackbarVisibility({
          open: true,
          message: message,
        });
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    for (const field of steps[activeStep].fields) {
      if (
        field !== "master_description" &&
        field !== "master_tags" &&
        field !== "works"
      ) {
        if (!validateField(field, masterState[field])) {
          return;
        }
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "master_title":
        message = "Please enter a title";
        break;
      case "master_subcategory":
        message = "Please enter a subtitle";
        break;
      case "category_id":
        message = "Please select a category";
        break;
      case "catalog_id":
        message = "Please select a catalog";
        break;
      case "master_key":
        message = "Please select a key";
        break;
      case "master_bpm":
        message = "Please enter a tempo";
        break;
      case "versions":
        message = "Please add at least one audio file";
        break;
      case "version_name":
        message = "Version name cannot be empty";
        break;
      case "stem_name":
        message = "Stem name cannot be empty";
        break;
      case "master_artwork":
        message = "Please set the image area";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleClose = () => {
    setConfirmationOpen(true);
  };

  const handleSubmit = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setLoading(true);
    const masterId = await createMasterEntry();
    if (!masterId) {
      setLoading(false);
      return;
    }
    if (!(await handleVersions(masterId))) {
      setLoading(false);
      return;
    }
    if (masterState.master_tags.length) {
      if (!(await handleTags(masterId))) {
        setLoading(false);
        return;
      }
    }
    if (masterState.works.length) {
      if (!(await handleWorks(masterId))) {
        setLoading(false);
        return;
      }
    }
    if (masterState.master_artwork.length) {
      if (!(await handleArtwork(masterId))) {
        setLoading(false);
        return;
      }
    }
    if (masterState.groups.length) {
      if (!(await handleDistribution(masterId))) {
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    doSearch();
  };

  const createMasterEntry = () => {
    const postData = {
      master_title: masterState.master_title.trim(),
      master_subcategory: masterState.master_subcategory.trim(),
      category_id: parseInt(masterState.category_id.trim()),
      catalog_id: parseInt(masterState.catalog_id.trim()),
      master_key: masterState.master_key.trim(),
      master_bpm: parseFloat(masterState.master_bpm.trim()),
      master_display_date: new Date(masterState.master_display_date)
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      master_description: masterState.master_description.trim(),
      master_status: 0,
      user_id: 1,
    };

    let currentProcessing = { ...processing };
    currentProcessing.master.status = "processing";
    setProcessing(currentProcessing);

    return createMaster(postData).then((res) => {
      if (!res.data.result) {
        currentProcessing.master.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.master.status = "success";
      setProcessing({ ...currentProcessing });
      return res.data.result;
    });
  };

  const handleVersions = async (masterId) => {
    let currentProcessing = { ...processing };
    let newVersion;
    for (const version of masterState.versions) {
      newVersion = {
        version_id: version.version_id,
        status: "processing",
        stems: [],
      };
      currentProcessing.versions[version.version_id] = newVersion;
      setProcessing({ ...currentProcessing });
      const versionId = await createVersionEntry(version, masterId);

      if (!versionId) {
        currentProcessing.versions[version.version_id].status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
      currentProcessing.versions[version.version_id].status = "success";
      setProcessing({ ...currentProcessing });
      if (!(await handleStems(version, versionId, masterId))) {
        return false;
      }
    }
    return true;
  };

  const createVersionEntry = (version, masterId) => {
    const postData = {
      version_name: version.version_name.trim(),
      master_id: parseInt(masterId),
      version_primary: version.version_primary,
      version_duration: 10,
      version_status: version.version_status,
    };

    return createVersion(masterId, postData).then((res) => {
      return res.data.result;
    });
  };

  const handleStems = async (version, versionId, masterId) => {
    let currentProcessing = { ...processing };
    let newStem;
    let stemId;
    let relId;
    for (const stem of version.version_stems) {
      newStem = {
        stem_id: stem.stem_id,
        status: "processing",
      };
      currentProcessing.versions[version.version_id].stems[
        stem.stem_id
      ] = newStem;
      setProcessing({ ...currentProcessing });

      if (!stem.stem_library_id) {
        const stemFilename = await submitAsset(stem.blob, "stem", "mp3").then(
          (res) => {
            return res.data;
          }
        );

        if (!stemFilename) {
          currentProcessing.versions[version.version_id].stems[
            stem.stem_id
          ].status = "fail";
          setProcessing({ ...currentProcessing });
          return false;
        }

        stemId = await createStemEntry(stem, stemFilename, versionId, masterId);
      } else {
        const payload = {
          stem_library_id: stem.stem_library_id,
          "versions-stem_library_position": stem.stem_position,
          "versions-stem_library_volume": stem.stem_volume,
        };

        relId = await createStemRelationship(masterId, versionId, payload);
      }

      if (!stemId && !relId) {
        currentProcessing.versions[version.version_id].stems[
          stem.stem_id
        ].status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }

      currentProcessing.versions[version.version_id].stems[
        stem.stem_id
      ].status = "success";
      setProcessing({ ...currentProcessing });
    }
    return true;
  };

  const handleTags = async (masterId) => {
    let postData = { master_id: masterId };
    let currentProcessing = { ...processing };
    currentProcessing.tags.status = "processing";
    setProcessing(currentProcessing);
    for (const tag of masterState.master_tags) {
      let tagId;
      if (tag.tagIsNew) {
        const newTag = { tag_name: tag.value };
        tagId = await createTag(newTag).then((res) => {
          return res.data.result;
        });
        if (!tagId) {
          currentProcessing.tags.status = "fail";
          setProcessing({ ...currentProcessing });
          return false;
        }
      } else {
        tagId = tag.id;
      }
      postData.tag_id = tagId;
      const relationshipId = await createTagRelationship(masterId, postData);
      if (!relationshipId) {
        currentProcessing.tags.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
    }
    currentProcessing.tags.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const handleWorks = async (masterId) => {
    let currentProcessing = { ...processing };
    currentProcessing.works.status = "processing";
    setProcessing(currentProcessing);
    for (const work of masterState.works) {
      let workId;
      if (work.source !== "local") {
        const newWork = {
          registered_work_title: work.title.trim(),
          registered_work_identification_code: work.id_code.trim(),
        };
        workId = await createRegisteredWork(newWork).then((res) => {
          return res.data.result;
        });
        if (!workId) {
          currentProcessing.works.status = "fail";
          setProcessing({ ...currentProcessing });
          return false;
        }
        for (const credit of work.credits) {
          const newCredit = {
            credit_name: credit.trim(),
          };
          const creditId = await createCredit(newCredit).then((res) => {
            return res.data.result;
          });
          if (!creditId) {
            currentProcessing.works.status = "fail";
            setProcessing({ ...currentProcessing });
            return false;
          }
          let postData = { registered_work_id: workId, credit_id: creditId };
          const workCreditRelId = await createCreditRelationship(
            workId,
            postData
          );
          if (!workCreditRelId) {
            currentProcessing.works.status = "fail";
            setProcessing({ ...currentProcessing });
            return false;
          }
        }
      } else {
        workId = work.registered_work_id;
      }
      let postData = { registered_work_id: workId, master_id: masterId };
      postData.registered_work_id = workId;
      const workMasterRelId = await createWorkMasterRelationship(
        masterId,
        postData
      );
      if (!workMasterRelId) {
        currentProcessing.works.status = "fail";
        setProcessing({ ...currentProcessing });
        return false;
      }
    }
    currentProcessing.works.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const handleDistribution = async (masterId) => {
    const groupArray = [];
    masterState.groups.forEach((group) => {
      groupArray.push(group.group_id);
    });
    const postData = {
      skin_id: skinConfig.skin_id,
      build_type: 1,
      build_data: {
        master_id: masterId,
        groups: groupArray,
      },
    };
    const currentProcessing = { ...processing };
    currentProcessing.distribution.status = "processing";
    setProcessing(currentProcessing);
    const buildId = await createMasterBuildEntry(masterId, postData).then(
      (res) => {
        return res.data.result;
      }
    );
    if (!buildId) {
      currentProcessing.distribution.status = "fail";
      setProcessing({ ...currentProcessing });
      return false;
    }
    currentProcessing.distribution.status = "success";
    setProcessing({ ...currentProcessing });
    return true;
  };

  const handleArtwork = async (masterId) => {
    const mimeType = imageSource.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const extension = Mime.extension(mimeType);
    const artworkBlob = await getFileBlob(masterState.master_artwork);
    let currentProcessing = { ...processing };
    currentProcessing.artwork.status = "processing";
    setProcessing(currentProcessing);
    const artworkFilename = await submitAsset(
      artworkBlob,
      "artwork",
      extension
    ).then((res) => {
      return res.data;
    });
    if (!artworkFilename) {
      currentProcessing.artwork.status = "fail";
      setProcessing({ ...currentProcessing });
      return false;
    }
    const postData = { master_artwork: artworkFilename };
    return await editMaster(masterId, postData).then((res) => {
      if (!res) {
        currentProcessing.artwork.status = "fail";
        setProcessing({ ...currentProcessing });
      }
      currentProcessing.artwork.status = "success";
      setProcessing({ ...currentProcessing });
      return true;
    });
  };

  const getFileBlob = (file) => {
    return Axios.get(file, {
      responseType: "blob",
    }).then((blob) => {
      return blob.data;
    });
  };

  const createStemEntry = (stem, stemFilename, versionId, masterId) => {
    const postData = {
      stem_name: stem.stem_name.trim(),
      stem_position: stem.stem_position,
      stem_position_is_percent: 0,
      stem_volume: stem.stem_volume,
      stem_filename: stemFilename,
      version_id: versionId,
    };

    return createStem(masterId, versionId, postData).then((res) => {
      return res.data.result;
    });
  };

  const actionButtons = [
    {
      label: activeStep === steps.length ? "Done" : "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      isHidden: false,
      fn: activeStep === steps.length ? handleConfirmationConfirm : handleClose,
    },
    {
      label: "Back",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: true,
      disabled: activeStep === 0,
      isHidden: activeStep === steps.length,
      fn: handleBack,
    },
    {
      label: activeStep === steps.length - 1 ? "Submit" : "Next",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      isHidden: activeStep === steps.length,
      fn: activeStep === steps.length - 1 ? handleSubmit : handleNext,
    },
  ];

  return (
    <React.Fragment>
      <Confirmation
        actionButtons={confirmationButtons}
        title={`Are you sure?`}
        message={`If you proceed, all changes will be lost.`}
        open={confirmationOpen}
      />
      <CustomDialogTitle
        title={
          masterState.master_title.length
            ? titleCase(sanitizeTitle(masterState.master_title))
            : `New Content`
        }
      />
      <CustomDialogContent>
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.root}
          >
            {steps.map((step) => (
              <Step key={step.stepTitle}>
                <StepLabel>{step.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Processing processing={processing} masterState={masterState} />
              </div>
            ) : (
              <div>{getStepContent(activeStep)}</div>
            )}
          </div>
        </div>
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedContentBuilder.propTypes = {
  allCategories: PropTypes.array.isRequired,
  allCatalogs: PropTypes.array.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  skinConfig: PropTypes.object.isRequired,
  availableGroups: PropTypes.array.isRequired,
  updateAvailableGroups: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
};

const ContentBuilder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedContentBuilder);

export default ContentBuilder;
