import React from "react";
import PropTypes from "prop-types";
import CustomizedTextField from "../../../library/CustomizedTextField";

const Metadata = (props) => {
  const { data, handleChange, handleBlur, disabled, access } = props;

  const { registered_work_title, registered_work_identification_code } = data;

  return (
    <React.Fragment>
      <CustomizedTextField
        required
        fullWidth
        id="workTitle"
        label="Title"
        value={registered_work_title}
        handleOnChange={handleChange("registered_work_title")}
        handleOnBlur={handleBlur("registered_work_title")}
        disabled={disabled || !access.update}
      />
      <CustomizedTextField
        required
        fullWidth
        id="workIdentificationCode"
        label="Identification Code"
        value={registered_work_identification_code}
        handleOnChange={handleChange("registered_work_identification_code")}
        handleOnBlur={handleBlur("registered_work_identification_code")}
        disabled={disabled || !access.update}
      />
    </React.Fragment>
  );
};

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
};

export default Metadata;
