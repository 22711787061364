import React from "react";
import { Fab, withStyles, Tooltip, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -20,
		marginLeft: -20,
	},
	buttonWrapper: {
		position: "relative",
		display: "inline-block",
	},
});

class DownloadActionButton extends React.Component {
	state = {
		loading: false,
	};

	handleClick = (event, action) => {
		event.stopPropagation();
		this.setState({ loading: true });
		this.props.actionFunction(action).then(() => {
			this.setState({ loading: false });
		});
	};

	render() {
		const { children, classes, tooltipLabel, action } = this.props;
		const { loading } = this.state;
		return (
			<React.Fragment>
				<div className={classes.buttonWrapper}>
					<Tooltip title={tooltipLabel}>
						<Fab
							color="secondary"
							aria-label="add"
							size="small"
							disabled={loading}
							onClick={(event) => {
								this.handleClick(event, action);
							}}
						>
							{children}
						</Fab>
					</Tooltip>
					{loading && (
						<CircularProgress
							color="secondary"
							size={40}
							className={classes.buttonProgress}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

DownloadActionButton.propTypes = {
	children: PropTypes.node.isRequired,
	action: PropTypes.string.isRequired,
	tooltipLabel: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	actionFunction: PropTypes.func.isRequired,
};

export default withStyles(styles)(DownloadActionButton);
