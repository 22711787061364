import React from "react";
import { Checkbox, TextField, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { sortObject } from "../../../helpers/SharedFunctions";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.action.active} !important`,
    },
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  formLabelRoot: {},
});

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const UserGroups = (props) => {
  const preSelectedGroups = (selectedGroups, allGroups) => {
    const selectedGroupArray = [];
    selectedGroups.forEach((element) => {
      const index = allGroups.findIndex((item) => item.id === element.id);
      if (index > -1) {
        selectedGroupArray.push(allGroups[index]);
      }
    });
    return selectedGroupArray;
  };

  const allGroups = sortObject(props.allGroups, "value");
  const { userGroups, handleSelect, classes, access } = props;

  return (
    <Autocomplete
      fullWidth
      multiple
      disabled={!access.update}
      id="group-selector"
      options={allGroups}
      disableCloseOnSelect
      getOptionLabel={(option) => option.value}
      value={preSelectedGroups(userGroups, allGroups)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.value}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </React.Fragment>
      )}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Groups"
          placeholder={access.update ? "Add Group" : ""}
          InputLabelProps={{
            classes: {
              root: classes.formLabelRoot,
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            ...params.InputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
            },
          }}
        />
      )}
    />
  );
};

export default withStyles(styles)(UserGroups);
