import React from "react";
import {
  Button,
  DialogActions,
  makeStyles,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

const CustomDialogActions = (props) => {
  const { actionButtons, disabled, loading } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Divider />
      <DialogActions>
        {actionButtons.map(
          (value, key) =>
            !value.isHidden &&
            (value.hasLoadingState ? (
              <div className={classes.buttonWrapper} key={key}>
                <Button
                  onClick={(event) => value.fn(event)}
                  color={value.color}
                  disabled={
                    value.hasDisabledState && (disabled || value.disabled)
                  }
                  type={value.type ? value.type : "button"}
                >
                  {value.label}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color={value.color}
                  />
                )}
              </div>
            ) : (
              <Button
                onClick={(event) => value.fn(event)}
                color={value.color}
                disabled={
                  value.hasDisabledState && (disabled || value.disabled)
                }
                key={key}
                type={value.type ? value.type : "button"}
              >
                {value.label}
              </Button>
            ))
        )}
      </DialogActions>
    </React.Fragment>
  );
};

CustomDialogActions.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  actionButtons: PropTypes.array.isRequired,
};

export default CustomDialogActions;
