import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Tooltip,
  withTheme,
  Grid,
  Paper,
} from "@material-ui/core";
import { ZoomIn, ZoomOut, Clear } from "@material-ui/icons";
import WaveformPlaylist from "waveform-playlist";
import EventEmitter from "event-emitter";
import "../../../library/Multitrack.css";
import AudioPlayerControls from "../../../audioplayer/AudioPlayerControls";
import { darken } from "@material-ui/core/styles";
import CustomizedTextField from "../../../library/CustomizedTextField";
import StemLibraryButton from "./stem_library/StemLibraryButton";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  playlistWrapper: {
    color: theme.palette.text.disabled,
  },
  progress: {
    position: "absolute",
    left: theme.spacing(1),
    top: "50%",
    right: theme.spacing(1),
    height: "1px",
  },
  controlWrapper: {
    height: "50px",
    margin: "4px",
    padding: "5px",
    backgroundColor: darken(theme.palette.background.paper, 0.1),
  },
  actionWrapper: {
    height: "50px",
    margin: "4px",
    backgroundColor: darken(theme.palette.background.paper, 0.1),
  },
  paper: {
    margin: theme.spacing(0.5),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  main: {
    flex: 1,
  },
  zoomButton: {
    margin: theme.spacing(1),
  },
  SkeletonWrapper: {
    margin: theme.spacing(0.5),
  },
  loadingFeedback: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100px",
    transform: `translate(-50%,-50%)`,
    backgroundColor: darken(theme.palette.background.paper, 0.5),
  },
  backdrop: {
    position: "absolute",
  },
  disabled: {
    cursor: `default !important`,
  },
}));

const Multitrack = (props) => {
  const playlist = useRef(null);
  const ee = useRef(null);
  const timer = useRef({});
  const classes = useStyles();
  const [playState, setPlayState] = useState("stop");
  const [zoomMin, setZoomMin] = useState(false);
  const [zoomMax, setZoomMax] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [controls, setControls] = useState([]);
  const {
    stems,
    index,
    deleteStem,
    keyboardActive,
    handleStemChange,
    handleOnFocus,
    handleOnBlur,
    stemLibrary,
    insertLibraryStems,
    handleStemPositionChange,
    selectedGroup,
    loading,
  } = props;

  useEffect(() => {
    playlist.current = WaveformPlaylist({
      samplesPerPixel: 500,
      mono: true,
      waveHeight: 50,
      container: document.getElementById(`playlist-${index}`),
      state: "cursor",
      colors: {
        waveOutlineColor: darken(props.theme.palette.background.paper, 0.1),
        timeColor: props.theme.palette.divider,
      },
      timescale: false,
      controls: {
        show: false,
      },
      zoomLevels: [125, 250, 500, 1000, 2000, 4000, 8000],
      seekStyle: "fill",
      isAutomaticScroll: true,
      states: {
        cursor: true,
        fadein: false,
        fadeout: false,
        select: false,
        shift: true,
      },
    });
    EventEmitter();
    ee.current = playlist.current.getEventEmitter();
    ee.current.on("shift", function(delta, track) {
      clearTimeout(timer.current[track.name]);
      timer.current[track.name] = setTimeout(() => {
        handleStemPositionChange(track.name, track.startTime);
        clearTimeout(timer.current[track.name]);
      }, 1000);
    });
    ee.current.on("audiosourcesrendered", function() {
      setAudioLoaded(true);
    });

    return () => {
      if (ee.current !== null) {
        destroyMultitrack();
      }
    };
  }, []);

  useEffect(() => {
    if (playlist.current) {
      setAudioLoaded(false);
      ee.current.emit("clear");
      const currentControls = [];
      const cue = [];
      stems.forEach((stem) => {
        currentControls.push(stem);
        cue.push({
          src: stem.blob,
          name: stem.stem_id,
          start: stem.stem_position,
        });
      });
      playlist.current.load(cue);
      setControls(currentControls);
    }
  }, [stems.length]);

  useEffect(() => {
    if (loading && playState !== "stop") {
      setPlayState("stop");
    }
  }, [loading]);

  const destroyMultitrack = () => {
    setPlayState("stop");
    const container = document.getElementById(`playlist-${index}`);
    while (container.firstChild) {
      container.firstChild.remove();
    }
    ee.current.emit("clear");
  };

  useEffect(() => {
    if (ee.current !== null) {
      switch (playState) {
        case "play":
          ee.current.emit("play");
          break;
        case "pause":
          ee.current.emit("pause");
          break;
        case "stop":
          ee.current.emit("stop");
          break;
        default:
          ee.current.emit("stop");
      }
    }
  }, [playState]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyDownCommand(event, null);
    };

    const handleKeyUp = (event) => {
      handleKeyUpCommand(event, null);
    };

    if (!keyboardActive) {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      return;
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [playState, keyboardActive]);

  const handleKeyDownCommand = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (loading) {
      return;
    }
    switch (event.key) {
      case "Enter":
        handleStop();
        return;
      case " ":
        handlePlayPause();
        return;
      case "Shift":
        changeInteractionState("shift");
        return;
      default:
        break;
    }
  };

  const handleDeleteStem = (stemIndex) => {
    setPlayState("stop");
    deleteStem(stemIndex);
  };

  const handleKeyUpCommand = (event) => {
    event.stopPropagation();
    event.preventDefault();
    switch (event.key) {
      case "Shift":
        changeInteractionState("cursor");
        return;
      default:
        break;
    }
  };

  const handlePlayPause = () => {
    let newState = playState;
    if (newState === "play") {
      newState = "pause";
    } else {
      newState = "play";
    }

    setPlayState(newState);
  };

  const handleStop = () => {
    setPlayState("stop");
  };

  const changeInteractionState = (interactionState) => {
    ee.current.emit("statechange", interactionState);
  };

  const zoomIn = () => {
    if (playlist.current.zoomIndex - 1 === 0) {
      setZoomMax(true);
    } else {
      !zoomMax && setZoomMax(false);
    }
    zoomMin && setZoomMin(false);
    ee.current.emit("zoomin");
  };

  const zoomOut = () => {
    if (playlist.current.zoomIndex + 2 === playlist.current.zoomLevels.length) {
      setZoomMin(true);
    } else {
      !zoomMin && setZoomMin(false);
    }
    zoomMax && setZoomMax(false);
    ee.current.emit("zoomout");
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div>
          <Paper variant="outlined" className={classes.paper}>
            {controls.map((stem, stemIndex) => (
              <div className={classes.controlWrapper} key={stemIndex}>
                <CustomizedTextField
                  margin={"dense"}
                  value={stem.stem_name}
                  placeholder={"Stem Name"}
                  handleOnChange={handleStemChange(stemIndex)}
                  handleOnFocus={handleOnFocus}
                  handleOnBlur={handleOnBlur}
                  id={`stem_name_${stemIndex}`}
                  disabled={
                    loading ||
                    !!stem["versions-stem_library_id"] ||
                    !!stem["stem_library_id"] ||
                    !audioLoaded
                  }
                />
              </div>
            ))}
          </Paper>
        </div>
        <div className={classes.main}>
          <Paper variant="outlined" className={classes.paper}>
            <span className={classes.disabled}>
              <div id={`playlist-${index}`} className={classes.playlistWrapper}>
                {!audioLoaded &&
                  stems.map((stem, stemIndex) => (
                    <div key={stemIndex} className={classes.SkeletonWrapper}>
                      <Skeleton variant={"rect"} height={50} animation="wave" />
                    </div>
                  ))}
              </div>
            </span>
          </Paper>
        </div>
        <div>
          <Paper variant="outlined" className={classes.paper}>
            {controls.map((stem, stemIndex) => (
              <div className={classes.actionWrapper} key={stemIndex}>
                <div className={classes.buttonWrapper}>
                  <IconButton
                    onClick={() => {
                      handleDeleteStem(stemIndex);
                    }}
                    disabled={loading || !audioLoaded}
                  >
                    <Clear />
                  </IconButton>
                </div>
              </div>
            ))}
          </Paper>
        </div>
      </div>
      <Grid container>
        <Grid item xs={4} align="left">
          {!!stemLibrary.length && (
            <StemLibraryButton
              insertLibraryStems={insertLibraryStems}
              stemLibrary={stemLibrary}
              index={index}
              selectedGroup={selectedGroup}
              disabled={loading || !audioLoaded}
            />
          )}
        </Grid>
        <Grid item xs={4} align="center">
          <AudioPlayerControls
            handlePlayPause={handlePlayPause}
            handleStop={handleStop}
            playState={playState}
            disabled={loading || !audioLoaded}
          />
        </Grid>
        <Grid item xs={4} align="right">
          <div>
            <Tooltip title="Zoom In">
              <span>
                <IconButton
                  onClick={zoomIn}
                  aria-label="zoom in"
                  className={classes.zoomButton}
                  disabled={loading || !audioLoaded || zoomMax}
                >
                  <ZoomIn />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Zoom Out">
              <span>
                <IconButton
                  onClick={zoomOut}
                  aria-label="zoom out"
                  className={classes.zoomButton}
                  disabled={loading || !audioLoaded || zoomMin}
                >
                  <ZoomOut />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Multitrack.propTypes = {
  stems: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  deleteStem: PropTypes.func.isRequired,
  keyboardActive: PropTypes.bool.isRequired,
  handleStemChange: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  stemLibrary: PropTypes.array.isRequired,
  insertLibraryStems: PropTypes.func.isRequired,
  handleStemPositionChange: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withTheme(Multitrack);
