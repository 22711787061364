import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles } from "@material-ui/core";
import { getContent } from "../helpers/ApiCalls";
import EnhancedTable from "../library/EnhancedTable";
import ContentListActions from "./ContentListActions";
import {
	titleCase,
	formatDate,
	translateKey,
	sanitizeTitle,
	createSkeletonData,
} from "../helpers/SharedFunctions";
import { connect } from "react-redux";
import { setSearchState } from "../actions/index";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column",
		height: "100%",
		overflow: "hidden",
		maxWidth: "100vw",
	},
	artworkColumn: {
		[theme.breakpoints.up("md")]: {
			width: "5%",
		},
	},
	titleColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "45%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "36%",
		},
		[theme.breakpoints.up("md")]: {
			width: "25%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "20%",
		},
		paddingLeft: theme.spacing(1),
	},
	subTitleColumn: {
		[theme.breakpoints.only("lg")]: {
			width: "12%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "16%",
		},
	},
	categoryColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "25%",
		},
		[theme.breakpoints.only("sm")]: {
			width: "20%",
		},
		[theme.breakpoints.only("md")]: {
			width: "15%",
		},
		[theme.breakpoints.only("lg")]: {
			width: "12%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "16%",
		},
	},
	keyColumn: {
		[theme.breakpoints.up("sm")]: {
			width: "10%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "8%",
		},
	},
	tempoColumn: {
		[theme.breakpoints.up("sm")]: {
			width: "10%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "8%",
		},
	},
	dateColumn: {
		[theme.breakpoints.up("md")]: {
			width: "15%",
		},
		[theme.breakpoints.only("xl")]: {
			width: "12%",
		},
	},
	actionColumn: {
		[theme.breakpoints.only("xs")]: {
			width: "10%",
		},
		[theme.breakpoints.only("sm")]: {
			width: "8%",
		},
		[theme.breakpoints.up("md")]: {
			width: "5%",
		},
	},
	xsHidden: {
		[theme.breakpoints.only("xs")]: {
			display: "none",
		},
	},
	smHidden: {
		[theme.breakpoints.only("sm")]: {
			display: "none",
		},
	},
	mdHidden: {
		[theme.breakpoints.only("md")]: {
			display: "none",
		},
	},
	lgHidden: {
		[theme.breakpoints.only("lg")]: {
			display: "none",
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		activeCatalog: state.activeCatalog,
		activeSearch: state.activeSearch,
		activeGroup: state.activeGroup,
		searching: state.searching,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSearchState: (searchState) => dispatch(setSearchState(searchState)),
	};
};

const ConnectedContentList = React.memo((props) => {
	const {
		activeCatalog,
		setSearchState,
		activeSearch,
		playMaster,
		activeGroup,
		closeMediaInteraction,
		audioFile,
		searching,
	} = props;

	const [selectedRow, setSelectedRow] = useState(null);
	const [items, setItems] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(null);
	const [orderDir, setOrderDir] = useState("desc");
	const [orderBy, setOrderBy] = useState("master_display_date");
	const classes = useStyles();
	const {
		xsHidden,
		smHidden,
		mdHidden,
		artworkColumn,
		titleColumn,
		subTitleColumn,
		categoryColumn,
		keyColumn,
		tempoColumn,
		dateColumn,
	} = classes;
	const columns = [
		{
			id: "master_artwork",
			numeric: false,
			disablePadding: false,
			label: "",
			classes: [smHidden, xsHidden, artworkColumn],
			type: "artwork",
			disableSort: true,
		},
		{
			id: "master_title",
			numeric: false,
			disablePadding: false,
			label: "Title",
			classes: [titleColumn],
		},
		{
			id: "master_subcategory",
			numeric: false,
			disablePadding: false,
			label: "Subtitle",
			classes: [xsHidden, smHidden, mdHidden, subTitleColumn],
		},
		{
			id: "category_name",
			numeric: false,
			disablePadding: false,
			label: "Category",
			classes: [categoryColumn],
		},
		{
			id: "master_key",
			numeric: false,
			disablePadding: false,
			label: "Key",
			classes: [xsHidden, keyColumn],
		},
		{
			id: "master_bpm",
			numeric: false,
			disablePadding: false,
			label: "Tempo",
			classes: [xsHidden, tempoColumn],
		},
		{
			id: "master_display_date",
			numeric: false,
			disablePadding: false,
			label: "Date",
			classes: [xsHidden, smHidden, dateColumn],
		},
	];

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
			return;
		}
		activeGroup && activeCatalog && doSearch(activeSearch);
	}, [
		activeSearch,
		activeCatalog,
		activeGroup,
		rowsPerPage,
		orderBy,
		orderDir,
	]);

	useEffect(() => {
		page !== null && activeGroup && activeCatalog && doSearch(activeSearch);
	}, [page]);

	useEffect(() => {
		audioFile && closeMediaInteraction();
		selectedRow && setSelectedRow(null);
	}, [activeGroup, activeCatalog]);

	const skeleton = useMemo(() => {
		return createSkeletonData(columns, rowsPerPage);
	}, [rowsPerPage]);

	const doSearch = () => {
		setItems(skeleton);
		setSearchState(true);
		const {
			query,
			categories,
			keys,
			tags,
			tempos,
			durations,
		} = activeSearch;
		let queryString = `catalog=${activeCatalog}`;
		if (query.length) {
			queryString = `&q=${encodeURIComponent(query)}`;
		}
		if (categories.length) {
			categories.forEach((category) => {
				queryString += `&category[]=${category}`;
			});
		}
		if (tags.length) {
			tags.forEach((tag) => {
				queryString += `&tag[]=${tag.tag_id}`;
			});
		}
		if (keys.length) {
			keys.forEach((key) => {
				queryString += `&key[]=${encodeURIComponent(key)}`;
			});
		}
		if (tempos.length) {
			tempos.forEach((tempo) => {
				queryString += `&tempo[]=${encodeURIComponent(tempo)}`;
			});
		}
		if (durations.length) {
			durations.forEach((duration) => {
				queryString += `&duration[]=${encodeURIComponent(duration)}`;
			});
		}
		queryString += `&limit=${encodeURIComponent(rowsPerPage)}`;
		queryString += `&offset=${encodeURIComponent(rowsPerPage * page)}`;
		queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
		queryString += `&orderDir=${encodeURIComponent(orderDir)}`;

		getContent(activeGroup, queryString).then((res) => {
			setItems(res.content);
			setTotalItems(res.total);
			setSearchState(false);
		});
	};

	const formatData = (data, field) => {
		switch (field) {
			case "master_title":
				return titleCase(sanitizeTitle(data));
			case "master_subcategory":
				return titleCase(data);
			case "category_name":
				return titleCase(data);
			case "master_display_date":
				return formatDate(data) ? formatDate(data) : "N/A";
			case "master_key":
				return translateKey(data);
			case "master_bpm":
				return Math.round(data);
			default:
				return data;
		}
	};

	const handleRowClick = (index, value) => {
		setSelectedRow(index);
		playMaster(value);
	};

	const handleArrowNav = (index) => {
		setSelectedRow(index);
	};

	return (
		<Paper className={classes.container}>
			<EnhancedTable
				items={items}
				columns={columns}
				handleRowClick={handleRowClick}
				formatData={formatData}
				selectedRow={selectedRow}
				classNames={classes}
				numberOfActions={3}
				idField={"master_id"}
				orderBy={orderBy}
				orderDir={orderDir}
				handleArrowNav={handleArrowNav}
				totalItems={totalItems}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				page={page}
				setPage={setPage}
				searching={searching}
				setOrderDir={setOrderDir}
				setOrderBy={setOrderBy}
			>
				<ContentListActions className={classes.actionColumn} />
			</EnhancedTable>
		</Paper>
	);
});

ConnectedContentList.propTypes = {
	activeCatalog: PropTypes.number,
	setSearchState: PropTypes.func.isRequired,
	activeSearch: PropTypes.object.isRequired,
	playMaster: PropTypes.func.isRequired,
	activeGroup: PropTypes.number,
	closeMediaInteraction: PropTypes.func.isRequired,
	audioFile: PropTypes.object,
	searching: PropTypes.bool.isRequired,
};

const ContentList = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedContentList);

export default ContentList;
