import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
import {
  titleCase,
  sortArray,
  searchAssociativeArray,
} from "../../helpers/SharedFunctions";
import CustomDialogActions from "../../library/dialog/CustomDialogActions";
import CustomDialogTitle from "../../library/dialog/CustomDialogTitle";
import CustomDialogContent from "../../library/dialog/CustomDialogContent";
import Metadata from "./wizard/Metadata";
import {
  setSnackbarVisibility,
  setDialogVisibility,
  updateAllCatalogs,
} from "../../actions/index";
import { connect } from "react-redux";
import {
  getCatalogs,
  getAllCatalogs,
  getGroupUsers,
  getUsers,
  getGroupLibrary,
  getStemLibrary,
  editGroup,
  deleteGroupCatalogRelationship,
  createGroupCatalogRelationship,
  createUserRelationship,
  removeUserRelationship,
  deleteStemLibraryFile,
  createStemLibraryFile,
  uploadFile,
  createGroupBuildEntry,
  deleteGroupCatalogContent,
  getGroupBuilds,
  deleteBuild,
  deleteStemLibraryFiles,
} from "../../helpers/ApiCalls";
import TabView from "./wizard/TabView";
import TransferList from "../../library/TransferList";
import ChipSelector from "../../library/ChipSelector";

const useStyles = makeStyles((theme) => ({
  dialogTabs: {
    color: theme.palette.text.primary,
  },
  root: {
    position: "absolute",
    top: theme.spacing(3),
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    right: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
}));

const mapStateToProps = (state) => {
  return {
    allCatalogs: state.allCatalogs,
    allGroups: state.allGroups,
    skinConfig: state.skinConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarVisibility: (snackbarContent) =>
      dispatch(setSnackbarVisibility(snackbarContent)),
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
    updateAllCatalogs: (allCatalogs) =>
      dispatch(updateAllCatalogs(allCatalogs)),
  };
};

const ConnectedGroupCard = (props, ref) => {
  const {
    cardContent,
    setSnackbarVisibility,
    setDialogVisibility,
    access,
    doSearch,
    allCatalogs,
    updateAllCatalogs,
    skinConfig,
  } = props;

  const timerRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [groupState, setGroupState] = useState(null);
  const [editedFields, setEditedFields] = useState([]);
  const [stemLibraries, setStemLibraries] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [originalGroupData, setOriginalGroupData] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (!groupState) {
      return;
    }
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      compareStates();
    }, 1000);
  }, [groupState]);

  useEffect(() => {
    prepareInitialData();
  }, []);

  useEffect(() => {
    if (groupState && groupState.catalogs && groupState.catalogs.length) {
      getStemLibraries();
    }
  }, [groupState && groupState.catalogs]);

  const prepareInitialData = async () => {
    const queryString = `status=${encodeURIComponent(1)}`;
    const userResult = await getUsers(queryString);
    if (userResult && userResult.users) {
      setAllUsers(mapChipData(userResult.users));
    }
    if (!allCatalogs.length) {
      const catalogResult = await getAllCatalogs();
      if (catalogResult && catalogResult.catalogs) {
        updateAllCatalogs(catalogResult.catalogs);
      }
    }
    const groupId = cardContent.group_id;
    const groupUsers = await getGroupUsers(groupId);
    const groupCatalogs = await getCatalogs(groupId);
    const stemLibraries = await getStemLibraryFiles(groupCatalogs, groupId);
    const initialData = {
      group_id: groupId,
      group_name: cardContent.group_name,
      group_status: cardContent.group_status,
      catalogs: groupCatalogs,
      users: groupUsers && groupUsers.users && mapChipData(groupUsers.users),
      stemLibraryFiles: stemLibraries,
    };
    setOriginalGroupData(JSON.parse(JSON.stringify(initialData)));
    setGroupState(initialData);
  };

  const mapChipData = (data) => {
    return data.length
      ? data.map((item) => ({
          id: item.user_id,
          value: `${titleCase(
            `${item.user_first_name} ${item.user_last_name}`
          )} [${item.user_email}]`,
        }))
      : [];
  };

  const removeLibraryFile = (stemLibraryId) => {
    let currentState = { ...groupState };
    delete currentState.stemLibraryFiles[stemLibraryId];
    setGroupState(currentState);
  };

  const getStemLibraries = async () => {
    const promises = [];
    groupState.catalogs.forEach((catalog) => {
      const catalogId = catalog.catalog_id;
      if (!stemLibraries[catalogId]) {
        promises.push(
          new Promise((resolve, reject) => {
            const queryString = `status=${encodeURIComponent(1)}`;
            getStemLibrary(catalogId, queryString).then((res) => {
              const stemLibraryData = res.reduce((acc, curr) => {
                acc[curr.stem_library_id] = curr;
                return acc;
              }, {});
              const stemLibrayObj = {};
              stemLibrayObj[catalogId] = stemLibraryData;
              resolve(stemLibrayObj);
            });
          })
        );
      }
    });
    Promise.all(promises).then((values) => {
      let libraries = { ...stemLibraries };
      values.forEach((value) => {
        libraries = { ...value, ...libraries };
      });
      setStemLibraries(libraries);
    });
  };

  const getStemLibraryFiles = async (catalogs, groupId) => {
    const promises = [];
    catalogs.forEach((catalog) => {
      const catalogId = catalog.catalog_id;
      promises.push(
        new Promise((resolve, reject) => {
          const queryString = `status=${encodeURIComponent(1)}`;
          getGroupLibrary(groupId, catalogId, queryString).then((res) => {
            const stemLibraryData = res.reduce((acc, curr) => {
              if (
                curr.stem_library_file_id &&
                curr.stem_library_file_filename
              ) {
                let obj = { ...curr };
                obj.filename = `${skinConfig.skin_masters_url}/${obj.stem_library_file_filename}`;
                acc[curr.stem_library_id] = obj;
              }
              return acc;
            }, {});
            resolve(stemLibraryData);
          });
        })
      );
    });
    return Promise.all(promises).then((values) => {
      let libraries = {};
      values.forEach((value) => {
        libraries = { ...value, ...libraries };
      });
      return libraries;
    });
  };

  const compareStates = (input, value) => {
    let currentEditedFields = [...editedFields];
    for (const [key] of Object.entries(groupState)) {
      const hasChanged = hasFieldChanged(key);
      if (hasChanged && currentEditedFields.indexOf(key) === -1) {
        currentEditedFields.push(key);
        setEditedFields(currentEditedFields);
      } else if (!hasChanged && currentEditedFields.indexOf(key) !== -1) {
        currentEditedFields.splice(currentEditedFields.indexOf(key), 1);
        setEditedFields(currentEditedFields);
      }
    }
  };

  const evaluateDeepState = (originalArr, newArr, idField, fields) => {
    let hasChanged = false;
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalArr.length; ++i) {
      if (!searchAssociativeArray(originalArr[i][idField], newArr, idField)) {
        hasChanged = true;
        break;
      }
    }
    //check if current object was originally present
    for (i = 0; i < newArr.length; ++i) {
      let currentObj = newArr[i];
      let originalObj = searchAssociativeArray(
        currentObj[idField],
        originalArr,
        idField
      );
      if (!originalObj) {
        hasChanged = true;
        break;
      }
      //evaluate specified new object fields against original
      if (fields && fields.length) {
        let x = 0;
        for (x = 0; x < fields.length; ++x) {
          let field = fields[x];
          if (currentObj[field] !== originalObj[field]) {
            hasChanged = true;
            break;
          }
        }
      }
    }
    return hasChanged;
  };

  const hasFieldChanged = (field) => {
    const originalState = originalGroupData[field];
    const newState = groupState[field];

    switch (field) {
      case "catalogs":
        return evaluateDeepState(originalState, newState, "catalog_id", null);
      case "users":
        return evaluateDeepState(originalState, newState, "id", null);
      case "stemLibraryFiles":
        return evaluateDeepState(
          Object.values(originalState),
          Object.values(newState),
          "stem_library_id",
          null
        );
      default:
    }
    return originalState !== newState;
  };

  const handleChange = (input) => (event) => {
    let currentState = { ...groupState };
    let value = event.target.value;
    currentState[input] = value;
    setGroupState(currentState);
  };

  const handleMetadata = () => {
    const postData = {};
    const fields = ["group_name"];

    for (let i = 0; i < fields.length; i++) {
      if (!editedFields.includes(fields[i])) {
        continue;
      }
      let value = groupState[fields[i]];

      if (!validateField(fields[i], value)) {
        return false;
      }
      if (typeof value === "string") {
        value = value.trim();
      }
      postData[fields[i]] = value;
    }
    if (!Object.keys(postData).length) {
      return true;
    }
    return editGroup(cardContent.group_id, postData).then((res) => {
      if (!res.data.result) {
        return false;
      }
      return true;
    });
  };

  const handleUsers = async () => {
    if (!editedFields.includes("users")) {
      return true;
    }

    const currentUsers = [...groupState.users];
    const originalUsers = [...originalGroupData.users];
    const usersToRemove = [];
    const usersToCreate = [];
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalUsers.length; ++i) {
      if (
        originalUsers.length &&
        !searchAssociativeArray(originalUsers[i]["id"], currentUsers, "id")
      ) {
        usersToRemove.push(originalUsers[i]["id"]);
      }
    }
    //check if current object was originally present
    for (i = 0; i < currentUsers.length; ++i) {
      if (!searchAssociativeArray(currentUsers[i]["id"], originalUsers, "id")) {
        usersToCreate.push(currentUsers[i]["id"]);
      }
    }
    if (usersToRemove.length) {
      for (const user of usersToRemove) {
        removeUserRelationship(cardContent.group_id, user).then((res) => {
          if (!res) {
            return false;
          }
        });
      }
    }
    if (usersToCreate.length) {
      for (const user of usersToCreate) {
        const postData = {
          group_id: cardContent.group_id,
          user_id: user,
        };
        createUserRelationship(cardContent.group_id, user, postData).then(
          (res) => {
            if (!res) {
              return false;
            }
          }
        );
      }
    }

    return true;
  };

  const handleCatalogs = async () => {
    if (!editedFields.includes("catalogs")) {
      return true;
    }
    const currentCatalogs = [...groupState.catalogs];
    const originalCatalogs = [...originalGroupData.catalogs];
    const catalogsToRemove = [];
    const catalogsToCreate = [];
    let i = 0;
    //check whether any original objects have been removed

    for (i = 0; i < originalCatalogs.length; ++i) {
      if (
        originalCatalogs.length &&
        !searchAssociativeArray(
          originalCatalogs[i]["catalog_id"],
          currentCatalogs,
          "catalog_id"
        )
      ) {
        catalogsToRemove.push(originalCatalogs[i]["catalog_id"]);
      }
    }
    //check if current object was originally present
    for (i = 0; i < currentCatalogs.length; ++i) {
      if (
        !searchAssociativeArray(
          currentCatalogs[i]["catalog_id"],
          originalCatalogs,
          "catalog_id"
        )
      ) {
        catalogsToCreate.push(currentCatalogs[i]["catalog_id"]);
      }
    }
    if (catalogsToRemove.length) {
      for (const catalog of catalogsToRemove) {
        deleteGroupCatalogRelationship(cardContent.group_id, catalog).then(
          (res) => {
            if (!res.data.result) {
              return false;
            }
            deleteGroupCatalogContent(cardContent.group_id, catalog);
            deleteStemLibraryFiles(cardContent.group_id, catalog);
          }
        );
        if (!(await deletePendingBuilds(catalog))) {
          return false;
        }
      }
    }
    if (catalogsToCreate.length) {
      for (const catalog of catalogsToCreate) {
        let postData = {
          group_id: cardContent.group_id,
          catalog_id: catalog,
        };
        const relId = await createGroupCatalogRelationship(
          cardContent.group_id,
          postData
        ).then((res) => {
          return res.data.result;
        });
        if (!relId) {
          return false;
        }
        if (!(await scheduleBuild(catalog))) {
          return false;
        }
      }
    }

    return true;
  };

  const handleStemLibraryFiles = async (groupId) => {
    const currentStemLibraryFiles = [
      ...Object.values(groupState.stemLibraryFiles),
    ];
    const originalStemLibraryFiles = [
      ...Object.values(originalGroupData.stemLibraryFiles),
    ];
    const stemLibraryFilesToRemove = [];
    const stemLibraryFilesToCreate = [];
    let i = 0;
    //check whether any original objects have been removed
    for (i = 0; i < originalStemLibraryFiles.length; ++i) {
      if (
        !searchAssociativeArray(
          originalStemLibraryFiles[i]["stem_library_id"],
          currentStemLibraryFiles,
          "stem_library_id"
        )
      ) {
        stemLibraryFilesToRemove.push(originalStemLibraryFiles[i]);
      }
    }

    //check if current object was originally present
    for (i = 0; i < currentStemLibraryFiles.length; ++i) {
      if (
        !searchAssociativeArray(
          currentStemLibraryFiles[i]["stem_library_id"],
          originalStemLibraryFiles,
          "stem_library_id"
        )
      ) {
        stemLibraryFilesToCreate.push(currentStemLibraryFiles[i]);
      }
    }

    if (stemLibraryFilesToRemove.length) {
      for (const stemLibraryFile of stemLibraryFilesToRemove) {
        if (
          !(await deleteStemLibraryFile(
            cardContent.group_id,
            stemLibraryFile.stem_library_id
          ))
        ) {
          return false;
        }
        const catalog = stemLibraryFile.catalog_id;
        if (!(await scheduleBuild(catalog))) {
          return false;
        }
      }
    }
    if (stemLibraryFilesToCreate.length) {
      for (const stemLibraryFile of stemLibraryFilesToCreate) {
        const stemFilename = await submitAsset(
          stemLibraryFile.blob,
          "stem",
          "mp3"
        ).then((res) => {
          return res.data;
        });

        if (!stemFilename) {
          return false;
        }

        const stemLibraryFileId = await createStemLibraryFileEntry(
          stemLibraryFile,
          stemFilename,
          cardContent.group_id
        );
        if (!stemLibraryFileId) {
          return false;
        }
        const catalog = stemLibraryFile.catalog_id;
        if (!(await scheduleBuild(catalog))) {
          return false;
        }
      }
    }
    return true;
  };

  const submitAsset = (blob, asset, extension) => {
    const skinId = skinConfig.skin_id;

    const postData = {
      assetBlob: blob,
      skinId: skinId,
      asset: asset,
      extension: extension,
    };
    return uploadFile(postData).then((response) => {
      return response;
    });
  };

  const createStemLibraryFileEntry = async (stem, stemFilename, groupId) => {
    const postData = {
      stem_library_id: stem.stem_library_id,
      stem_library_file_filename: stemFilename,
      group_id: groupId,
    };

    return createStemLibraryFile(stem.stem_library_id, groupId, postData).then(
      (res) => {
        return res.data.result;
      }
    );
  };

  const handleEditGroup = async () => {
    setLoading(true);
    let snackbarContent = {
      open: true,
      message: "An error occured. Please contact support",
    };
    if (!(await handleMetadata())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleCatalogs())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleUsers())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    if (!(await handleStemLibraryFiles())) {
      setSnackbarVisibility(snackbarContent);
      return false;
    }
    doSearch();
    setLoading(false);
    snackbarContent.message = "Update Successful";
    setSnackbarVisibility(snackbarContent);
    handleDialogClose();
    return true;
  };

  const deletePendingBuilds = async (catalog) => {
    const queryString = `status[]=pending`;
    const buildList = await getGroupBuilds(
      skinConfig.skin_id,
      cardContent.group_id,
      queryString
    );
    for (const build of buildList) {
      if (parseInt(build.build_data.catalog_id) === parseInt(catalog)) {
        if (!(await deleteBuild(skinConfig.skin_id, build.build_id))) {
          return false;
        }
      }
    }
    return true;
  };

  const scheduleBuild = async (catalog) => {
    //remove any pending group builds for a specific catalog
    if (!(await deletePendingBuilds(catalog))) {
      return false;
    }
    const postData = {
      skin_id: skinConfig.skin_id,
      build_type: 2,
      build_data: {
        group_id: cardContent.group_id,
        catalog_id: catalog,
      },
    };
    const buildId = await createGroupBuildEntry(
      cardContent.group_id,
      postData
    ).then((res) => {
      return res.data.result;
    });
    if (!buildId) {
      return false;
    }
    return true;
  };

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleBlur = (inputField) => (event) => {
    const inputValue = event.target.value;
    validateField(inputField, inputValue);
  };

  const handleFieldChange = (field, value) => {
    let currentState = { ...groupState };
    currentState[field] = value;
    setGroupState(currentState);
  };

  const validateField = (inputField, value) => {
    if (value.length === 0) {
      handleEmptyField(inputField);
      return false;
    }
    return true;
  };

  const handleEmptyField = (inputField) => {
    let message;
    switch (inputField) {
      case "catalog_name":
        message = "Please enter a name";
        break;
      default:
        message = "Field cannot be empty";
    }
    setSnackbarVisibility({
      open: true,
      message: message,
    });
  };

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
    };
    setDialogVisibility(dialogContent);
  };

  const catalogs = useMemo(() => {
    return sortArray(
      allCatalogs.filter(function(obj) {
        return parseInt(obj.catalog_status) && obj;
      }),
      "catalog_name"
    );
  }, [allCatalogs]);

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
    {
      label: "Save",
      color: "secondary",
      hasLoadingState: true,
      hasDisabledState: true,
      fn: handleEditGroup,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle
        title={titleCase(
          groupState ? groupState.group_name : cardContent.group_name
        )}
      />
      {groupState && (
        <Tabs
          className={classes.dialogTabs}
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Metadata" />
          <Tab label="Catalogs" />
          <Tab label="Users" />
          <Tab label="Library" />
        </Tabs>
      )}
      <CustomDialogContent>
        {groupState && (
          <div className={classes.root}>
            {activeTab === 0 && (
              <Metadata
                handleBlur={handleBlur}
                handleChange={handleChange}
                data={groupState}
                disabled={loading}
                access={access}
              />
            )}
            {activeTab === 1 && (
              <TransferList
                availableData={catalogs}
                selectedData={groupState.catalogs}
                handleSelectedData={handleFieldChange}
                idField={"catalog_id"}
                labelField={"catalog_name"}
                objProp={"catalogs"}
                headerName={"catalogs"}
                loading={loading}
              />
            )}
            {activeTab === 2 && (
              <ChipSelector
                allData={allUsers}
                selectedData={groupState.users}
                setSnackbarVisibility={setSnackbarVisibility}
                handleSelect={handleFieldChange}
                fieldName={`users`}
                loading={loading}
                access={access}
                chipType={`User`}
                allowCreate={false}
              />
            )}
            {activeTab === 3 && (
              <TabView
                access={access}
                handleChange={handleFieldChange}
                stemLibraryFiles={groupState.stemLibraryFiles}
                allCatalogs={allCatalogs}
                stemLibraries={stemLibraries}
                removeLibraryFile={removeLibraryFile}
                loading={loading}
              />
            )}
          </div>
        )}
      </CustomDialogContent>
      <CustomDialogActions
        actionButtons={actionButtons}
        disabled={loading || editedFields.length === 0 || !access.update}
        loading={loading}
      />
    </React.Fragment>
  );
};

ConnectedGroupCard.propTypes = {
  cardContent: PropTypes.object.isRequired,
  setSnackbarVisibility: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  allCatalogs: PropTypes.array.isRequired,
  updateAllCatalogs: PropTypes.func.isRequired,
  skinConfig: PropTypes.object.isRequired,
};

const GroupCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedGroupCard);

export default GroupCard;
