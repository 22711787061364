import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import {
  CheckCircleOutline,
  HighlightOff,
  MoreHoriz,
  Person,
  Business,
  MailOutline,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    position: "relative",
  },
  progressWrapper: {
    padding: theme.spacing(0.25),
    width: `24px`,
    height: `24px`,
  },
  iconWrapper: {
    marginLeft: theme.spacing(4),
  },
  progress: {
    color: theme.palette.text.primary,
  },
}));

const Processing = (props) => {
  const { processing, userState } = props;
  const classes = useStyles();
  const getIcon = (status) => {
    switch (status) {
      case "pending":
        return <MoreHoriz />;
      case "processing":
        return (
          <div className={classes.progressWrapper}>
            <CircularProgress size={20} className={classes.progress} />
          </div>
        );
      case "success":
        return <CheckCircleOutline color="secondary" />;
      case "fail":
        return <HighlightOff />;
      default:
        return <MoreHoriz />;
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Submitting user data" />
            <ListItemIcon className={classes.iconWrapper}>
              {getIcon(processing.user.status)}
            </ListItemIcon>
          </ListItem>
          {!!userState.groups.length && (
            <ListItem>
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Adding groups" />
              <ListItemIcon className={classes.iconWrapper}>
                {getIcon(processing.groups.status)}
              </ListItemIcon>
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary="Emailing user login instructions" />
            <ListItemIcon className={classes.iconWrapper}>
              {getIcon(processing.token.status)}
            </ListItemIcon>
          </ListItem>
        </List>
      </Paper>
    </div>
  );
};

Processing.propTypes = {
  processing: PropTypes.object.isRequired,
  userState: PropTypes.object.isRequired,
};

export default Processing;
