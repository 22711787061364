import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function UserMenu(props) {
  const handleLogout = () => {
    props.logout();
    props.setAnchorEl(null);
  };

  const handleClose = () => {
    props.setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="userMenu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
