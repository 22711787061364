import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setDialogVisibility } from "../../actions/index";
import CustomDialogTitle from "./CustomDialogTitle";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogActions from "./CustomDialogActions";

const mapDispatchToProps = (dispatch) => {
  return {
    setDialogVisibility: (dialogContent) =>
      dispatch(setDialogVisibility(dialogContent)),
  };
};

const ConnectedAlert = (props) => {
  const { message, title, setDialogVisibility, callback } = props;

  const handleDialogClose = () => {
    const dialogContent = {
      open: false,
      body: null,
      fullScreen: false,
    };
    setDialogVisibility(dialogContent);
    callback && callback();
  };

  const actionButtons = [
    {
      label: "Close",
      color: "default",
      hasLoadingState: false,
      hasDisabledState: false,
      fn: handleDialogClose,
    },
  ];

  return (
    <React.Fragment>
      <CustomDialogTitle title={title} />
      <CustomDialogContent>{message}</CustomDialogContent>
      <CustomDialogActions actionButtons={actionButtons} />
    </React.Fragment>
  );
};

ConnectedAlert.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

const Alert = connect(null, mapDispatchToProps)(ConnectedAlert);

export default Alert;
